import {
  NOTIFICATION_CLEAR,
  NOTIFICATION_DISMISS,
  NOTIFICATION_SHOW,
} from './types'

const reducers = {}
const initialState = []

function filterNotifications(notifications, id) {
  return notifications.filter(notification => notification.id !== id)
}

export function showNotification(state, { payload }) {
  const filteredNotifications = filterNotifications(state, payload.id)
  return [payload, ...filteredNotifications]
}

export function dismissNotification(state, { payload }) {
  return filterNotifications(state, payload.id)
}

export function clearNotifications() {
  return initialState
}

reducers[NOTIFICATION_CLEAR] = clearNotifications
reducers[NOTIFICATION_DISMISS] = dismissNotification
reducers[NOTIFICATION_SHOW] = showNotification

export default function reducer(state = initialState, action) {
  const handler = reducers[action.type]
  if (handler) return handler(state, action)
  return state
}
