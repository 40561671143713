import createCachedSelector from 're-reselect'

import gaugeCacheKey from './cacheKeys/gaugeCacheKey'
import selectDecoratedGauge from './selectDecoratedGauge'

export default createCachedSelector(
  state => state,
  (state, props) => props,
  (state, props) => {
    const { gauges, join: { through: joinThrough } = {} } = props
    function isJoinedThroughGauge({ id }) {
      return id && joinThrough === id
    }
    const joinedThroughGauge = gauges.find(isJoinedThroughGauge)
    if (!joinedThroughGauge) return null
    return selectDecoratedGauge(state, {
      ...props,
      isJoinedThrough: true,
      gauge: joinedThroughGauge,
    })
  }
)(gaugeCacheKey)
