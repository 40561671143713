export default function pageXValues(
  xValues,
  dataSetPage,
  dataSetPerPage,
  page,
  perPage
) {
  if (!page || !xValues) return xValues
  if (page === dataSetPage && perPage === dataSetPage) return xValues // Server side paging matches UI paging so just return the values

  if (!dataSetPage || !dataSetPerPage) {
    return xValues.slice((page - 1) * perPage, page * perPage)
  }
  const from = (page - 1) * perPage - (dataSetPage - 1) * dataSetPerPage
  const to = page * perPage - (dataSetPage - 1) * dataSetPerPage
  return xValues.slice(from, to)
}
