import React, { PureComponent } from 'react'
import { isFunction } from 'util/functions'

export default function withSearchKbController(WrappedComponent) {
  class WithSearchKbController extends PureComponent {
    handleOnEnter = item => {
      const { openKbArticlePopout } = this.props
      const { id, knowledgeBaseId } = item
      if (isFunction(openKbArticlePopout))
        openKbArticlePopout(id, knowledgeBaseId)
    }

    handleOnShiftEnter = (item, _, evt) => {
      const { insertKbLink } = this.props
      const { id, knowledgeBaseId } = item
      if (isFunction(insertKbLink)) insertKbLink(id, knowledgeBaseId, evt)
    }

    handleEsc = () => {
      const { hideKbArticlePopout } = this.props
      if (isFunction(hideKbArticlePopout)) hideKbArticlePopout()
    }

    render() {
      const { forwardedRef, onItemFocus } = this.props
      return (
        <WrappedComponent
          {...this.props}
          onEnter={this.handleOnEnter}
          onShiftEnter={this.handleOnShiftEnter}
          onTab={onItemFocus}
          onUp={onItemFocus}
          onDown={onItemFocus}
          onPageDown={onItemFocus}
          onPageUp={onItemFocus}
          onEsc={this.handleEsc}
          ref={forwardedRef}
        />
      )
    }
  }

  return React.forwardRef((props, ref) => {
    return <WithSearchKbController {...props} forwardedRef={ref} />
  })
}
