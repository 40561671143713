export const UPDATE_MERGING_SEARCH_TERM = 'merging/UPDATE_SEARCH_TERM'
export const UPDATE_MERGING_MERGE_TICKET = 'merging/UPDATE_MERGE_TICKET'
export const CHANGE_MERGE_DIRECTION = 'merging/CHANGE_MERGE_DIRECTION'
export const RESET_TO_DEFAULTS = 'merging/RESET_TO_DEFAULTS'

export const FETCH_MERGEABLE_TICKETS = 'merging/FETCH_MERGEABLE_TICKETS'
export const FETCH_MERGEABLE_TICKETS_STARTED =
  'merging/FETCH_MERGEABLE_TICKETS_STARTED'
export const FETCH_MERGEABLE_TICKETS_SUCCESS =
  'merging/FETCH_MERGEABLE_TICKETS_SUCCESS'
export const FETCH_MERGEABLE_TICKETS_FAILED =
  'merging/FETCH_MERGEABLE_TICKETS_FAILED'

export const MERGE_TICKET = 'merging/MERGE_TICKET'
export const MERGE_TICKET_STARTED = 'merging/MERGE_TICKET_STARTED'
export const MERGE_TICKET_SUCCESS = 'merging/MERGE_TICKET_SUCCESS'
export const MERGE_TICKET_FAILED = 'merging/MERGE_TICKET_FAILED'
