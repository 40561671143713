import { starredCannedReply } from 'ducks/entities/schema'
import { schema } from 'normalizr'
import { hash } from 'util/scatterSwap'

export const starredCannedRepliesNormalizationSchema = {
  starredCannedReplies: {
    nodes: [starredCannedReply],
  },
}

export const starredCannedReplyNormalizationSchema = {
  starredCannedReplyCreate: {
    starredCannedReply,
  },
}

export const starredCannedReplyDeleteNormalizationSchema = new schema.Entity(
  'starredCannedReply',
  {},
  {
    processStrategy: value => {
      return {
        id: hash(value.messageTemplateId),
      }
    },
  }
)
