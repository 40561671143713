import moment from 'moment-timezone-all'

export default function adjustTimeframeToPreviousPeriod(query) {
  const { timeframe: { start, end } = {}, timezone } = query
  const mStart = moment.tz(start, timezone)
  const mEnd = moment.tz(end, timezone)
  const diff = moment.duration(mEnd.diff(start))
  return {
    ...query,
    timeframe: {
      end: mStart.format(),
      start: mStart.subtract(diff).format(),
    },
  }
}
