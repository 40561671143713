import React from 'react'
import PropTypes from 'prop-types'

import * as CustomPropTypes from 'ducks/reports/propTypes'
import { Table } from 'util/ui'
import { isFunction } from 'util/functions'

import styles from './styles.less'
import TextCell from './cells/TextCell'

TableCell.propTypes = {
  column: PropTypes.shape({
    header: PropTypes.string.isRequired,
    accessor: PropTypes.string.isRequired,
    component: CustomPropTypes.component,
    sort: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  }).isRequired,
  row: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
}

function extractData(props) {
  const { row, column } = props
  if (isFunction(column.accessor)) {
    return column.accessor(props)
  }
  return row[column.accessor]
}

export default function TableCell(props) {
  const {
    column,
    column: { header, component: CellComponent = TextCell, empty = '' },
    row,
    data,
  } = props
  const value = extractData({ row, column, data })
  return (
    <Table.Cell className={styles[header]}>
      {(value || value === 0) && (
        <CellComponent column={column} data={data} row={row} value={value} />
      )}
      {!value &&
        empty && <div className={styles.TableCell_noData}>{empty}</div>}
    </Table.Cell>
  )
}
