import React from 'react'

import { Segment } from 'util/ui'
import { Header, SubHeader } from 'shared/components/ui/Typography'
import styles from './styles.less'

export default function HeaderView({
  customersHelped,
  team: { agents: { length: agentCount = 0 } = {}, name },
}) {
  return (
    <Segment>
      <Header as="h1" size="medium" className={styles.avatarHeader}>
        {name}
      </Header>
      <SubHeader color="light" size="tiny">
        This {app.t('team')} has{' '}
        <strong className={styles.strong}>
          {app.t('n_agents', { count: agentCount })}
        </strong>
        {customersHelped && [
          ' & has helped ',
          <strong className={styles.strong}>{customersHelped}</strong>,
          ' customers to date',
        ]}
        .
      </SubHeader>
    </Segment>
  )
}
