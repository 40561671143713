import { connect } from 'react-redux'

import { averageResolutionTimeGauge } from 'ducks/reports/gauges'
import { HeadlineVisualization } from 'ducks/reports/visualizations'

const staticProps = {
  gauges: [averageResolutionTimeGauge],
  scale: { y: 'duration' },
  title: '$Avg. Resolution Time',
}

export default function AverageResolutionTime(props) {
  return <HeadlineVisualization {...props} {...staticProps} />
}
