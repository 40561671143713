import { KB_PAGE } from './pages'

function selectBase(state) {
  return state.reports.kb
}

export function selectArticles(state) {
  const { articles } = selectBase(state)
  return articles
}

export const selectIsOnArticlesPage = state => state.ducksPage.page === KB_PAGE
