import { ratio } from '../calculators/ratio'
import { countUniquePageVisitsOnClientId } from '../queries/countUniquePageVisitsOnClientId'
import { countUniqueSearchesOnClientId } from '../queries/countUniqueSearchesOnClientId'

export const performedSearchRatioGauge = {
  calculate: ratio,
  description: 'The percentage of visitors that performed at least one search.',
  queries: [countUniqueSearchesOnClientId, countUniquePageVisitsOnClientId],
  title: 'Performed a search',
}
