import { doGraphqlRequest } from 'ducks/requests/operations'

import { FETCH_TICKET_COMMENTS } from '../types'

export const COMMENT_FIELDS = `
  id
  isNote
  isForward
  body
  plainTextBody
  isAgentResponse
  type
  attachments
  deliverBy
  conversationType
  commentTemplateId
  authorEmail
  createdAt
  deliveries {
    type
    email
  }
`

export const doFetchTicketComments = (ticketId, options = {}) => dispatch => {
  const query = `
      query CommentsQuery(
        $ticketId: String!
      ) {
        comments(ticketId: $ticketId) {
          ${COMMENT_FIELDS}
        }
      }
    `
  return dispatch(
    doGraphqlRequest(FETCH_TICKET_COMMENTS, query, { ticketId }, options)
  )
}
