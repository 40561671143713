import cn from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import * as CustomPropTypes from 'ducks/reports/propTypes'
import { BoxHeadline } from 'shared/components/ui/Typography'
import { numberToSize } from 'util/numbers'
import { Icon } from 'util/ui'

import Tooltip from './Tooltip'
import styles from './styles.less'

Change.propTypes = {
  append: PropTypes.string,
  change: CustomPropTypes.change,
  percent: PropTypes.bool,
}

Change.defaultProps = {
  append: '',
  change: undefined,
  percent: false,
}

export default function Change({
  append,
  as,
  filledY,
  change,
  change: {
    difference = filledY,
    isInfinity = false,
    isBad = false,
    isGood = false,
    isNegative = false,
    isNeutral = false,
    isPositive = false,
    percent = false,
    previous = 0,
  } = {},
  percent: showAsPercent,
  scale,
  textAlign = 'left',
}) {
  if (!change) return null
  const percentValue =
    isInfinity || percent === null
      ? 'N/A'
      : `${numberToSize(Math.abs(percent), 1)}%`
  const value = typeof difference === 'number' ? Math.abs(difference) : 'N/A'
  const isInfinityAndPercent = showAsPercent && isInfinity
  return (
    <Tooltip isGood={isGood} isBad={isBad} scale={scale} previous={previous}>
      <BoxHeadline
        as={as || 'div'}
        color="ultraLight"
        fontWeight="light"
        size="small"
        textAlign={textAlign}
      >
        <BoxHeadline
          color={
            cn({
              bio: isGood && !isInfinityAndPercent,
              berry: isBad && !isInfinityAndPercent,
              dust: (!isGood && !isBad && !isNeutral) || isInfinityAndPercent,
            }) || null
          }
          fontWeight="light"
          size="small"
          textAlign={textAlign}
        >
          <Icon
            className={styles.reportIcon}
            name={cn({
              'caret up': isPositive && !isInfinityAndPercent,
              'caret down': isNegative && !isInfinityAndPercent,
              minus: (!isPositive && !isNegative) || isInfinityAndPercent,
            })}
          />
          {(!isPositive && !isNegative) || isInfinityAndPercent ? ' ' : ''}
          {showAsPercent ? percentValue : value}
        </BoxHeadline>
        {append}
      </BoxHeadline>
    </Tooltip>
  )
}
