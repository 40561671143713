import { averageFirstResponseTimeWithinBusinessHours } from '../queries/averageFirstResponseTimeWithinBusinessHours'
import { averageFirstResponseTime } from '../queries/averageFirstResponseTime'

export const averageFirstResponseTimeByAgentGauge = {
  businessHoursQueries: [
    {
      ...averageFirstResponseTimeWithinBusinessHours,
      groupBy: 'conversation.assignee.id',
    },
  ],
  id: 'avg_first_response_time_by_agent',
  isNegativeChangeGood: true,
  queries: [
    {
      ...averageFirstResponseTime,
      groupBy: 'conversation.assignee.id',
      key: 'averageFirstResponseTimeByAgent',
    },
  ],
  scale: { y: 'duration' },
  title: `$Average First Response Time By ${app.t('Agent')}`,
}
