export { agentNameByAgentGauge } from './agentNameByAgentGauge'
export {
  articleRatingsByArticleAndGrade,
} from './articleRatingsByArticleAndGrade'
export { articleTitlesByArticleGauge } from './articleTitlesByArticleGauge'
export { articleViewsPerDayGauge } from './articleViewsPerDayGauge'
export { articleViewsByArticleGauge } from './articleViewsByArticleGauge'
export {
  averageFirstResponseTimeByAgentGauge,
} from './averageFirstResponseTimeByAgentGauge'
export {
  averageResponseTimeByAgentGauge,
} from './averageResponseTimeByAgentGauge'
export {
  averageFirstResponseTimeForEachDayGauge,
} from './averageFirstResponseTimeForEachDayGauge'
export { averageFirstResponseTimeGauge } from './averageFirstResponseTimeGauge'
export {
  averageRepliesPerResolutionForEachDayGauge,
} from './averageRepliesPerResolutionForEachDayGauge'
export {
  averageRepliesPerResolutionGauge,
} from './averageRepliesPerResolutionGauge'
export {
  averageResolutionTimeByAgentGauge,
} from './averageResolutionTimeByAgentGauge'
export {
  averageResolutionTimeForEachDayGauge,
} from './averageResolutionTimeForEachDayGauge'
export { averageResolutionTimeGauge } from './averageResolutionTimeGauge'
export { averageResponseTimeGauge } from './averageResponseTimeGauge'
export {
  averageSearchResultsByTermGauge,
} from './averageSearchResultsByTermGauge'
export { averageTimeOnArticleGauge } from './averageTimeOnArticleGauge'
export {
  conversationsAssignedCreatedRatioGauge,
} from './conversationsAssignedCreatedRatioGauge'
export {
  conversationsAssignedCustomersForEachDayGauge,
} from './conversationsAssignedCustomersForEachDayGauge'
export {
  conversationsAssignedCustomersPerDayGauge,
} from './conversationsAssignedCustomersPerDayGauge'
export {
  conversationsAssignedForEachDayGauge,
} from './conversationsAssignedForEachDayGauge'
export {
  conversationsAssignedPerDayGauge,
} from './conversationsAssignedPerDayGauge'
export {
  conversationsAssignedResolvedRatioGauge,
} from './conversationsAssignedResolvedRatioGauge'
export {
  conversationsCreatedForEachDayGauge,
} from './conversationsCreatedForEachDayGauge'
export {
  conversationsCreatedPerDayGauge,
} from './conversationsCreatedPerDayGauge'
export {
  conversationsResolvedForEachDayGauge,
} from './conversationsResolvedForEachDayGauge'
export {
  conversationsResolvedPerDayGauge,
} from './conversationsResolvedPerDayGauge'
export { customerLeftRatingRatioGauge } from './customerLeftRatingRatioGauge'
export { customersForEachDayGauge } from './customersForEachDayGauge'
export { customersPerDayGauge } from './customersPerDayGauge'
export { firstReplyResolvedRatioGauge } from './firstReplyResolvedRatioGauge'
export {
  happinessAwesomeRatingForEachDayGauge,
} from './happinessAwesomeRatingForEachDayGauge'
export {
  happinessBadRatingForEachDayGauge,
} from './happinessBadRatingForEachDayGauge'
export {
  happinessOkRatingForEachDayGauge,
} from './happinessOkRatingForEachDayGauge'
export {
  happinessScoreRatioByAgentGauge,
} from './happinessScoreRatioByAgentGauge'
export {
  happinessScoreRatioByTeamGauge,
} from './happinessScoreRatioByTeamGauge'
export { happinessScoreRatioGauge } from './happinessScoreRatioGauge'
export {
  messagesReceivedByHourOfWeekGauge,
} from './messagesReceivedByHourOfWeekGauge'
export {
  messagesReceivedByQuarterHourOfWeekGauge,
} from './messagesReceivedByQuarterHourOfWeekGauge'
export { messagesSentByHourOfWeekGauge } from './messagesSentByHourOfWeekGauge'
export {
  messagesSentByQuarterHourOfWeekGauge,
} from './messagesSentByQuarterHourOfWeekGauge'
export {
  netDiffOpenAssignedAgentConversationsGauge,
} from './netDiffOpenAssignedAgentConversationsGauge'
export { netDiffOpenConversationsGauge } from './netDiffOpenConversationsGauge'
export { performedSearchRatioGauge } from './performedSearchRatioGauge'
export { popularArticleGauge } from './popularArticleGauge'
export { ratingByGradeGauge } from './ratingByGradeGauge'
export { ratingHistoryGauge } from './ratingHistoryGauge'
export {
  searchesClickThroughRateRatioGauge,
} from './searchesClickThroughRateRatioGauge'
export { tagCountByTagGauge } from './tagCountByTagGauge'
export { tagCountByTagPerDayGauge } from './tagCountByTagPerDayGauge'
export { tagNameByTagGauge } from './tagNameByTagGauge'
export { teamNameByTeamGauge } from './teamNameByTeamGauge'
export { teamNamesByAgentGauge } from './teamNamesByAgentGauge'
export {
  topClickedArticleBySearchTermGauge,
} from './topClickedArticleBySearchTermGauge'
export { totalArticleViewsGauge } from './totalArticleViewsGauge'
export {
  totalConversationsAssignedByAgentGauge,
} from './totalConversationsAssignedByAgentGauge'
export {
  totalConversationsAssignedByTeamGauge,
} from './totalConversationsAssignedByTeamGauge'
export {
  totalConversationsAssignedCustomersGauge,
} from './totalConversationsAssignedCustomersGauge'
export {
  totalConversationsAssignedGauge,
} from './totalConversationsAssignedGauge'
export {
  totalConversationsCreatedGauge,
} from './totalConversationsCreatedGauge'
export {
  totalConversationsResolvedByAgentGauge,
} from './totalConversationsResolvedByAgentGauge'
export {
  totalConversationsResolvedByTeamGauge,
} from './totalConversationsResolvedByTeamGauge'
export {
  totalConversationsResolvedGauge,
} from './totalConversationsResolvedGauge'
export { totalCustomersGauge } from './totalCustomersGauge'
export {
  totalFailedSearchesByTermGauge,
} from './totalFailedSearchesByTermGauge'
export { totalFailedSearchesGauge } from './totalFailedSearchesGauge'
export { totalKbVisitorsGauge } from './totalKbVisitorsGauge'
export { totalKbVisitsGauge } from './totalKbVisitsGauge'
export { totalSearchesByTermGauge } from './totalSearchesByTermGauge'
export { totalSearchesGauge } from './totalSearchesGauge'
export { uniquePageVisitsPerDayGauge } from './uniquePageVisitsPerDayGauge'
export { viewedArticleRatioGauge } from './viewedArticleRatioGauge'
export { totalMessagesSentByAgentGauge } from './/totalMessagesSentByAgentGauge'
