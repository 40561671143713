import { adjustScopeConversationAssigneeToActionChange } from './scopes'

export const countOpenUnassignmentsFromScopedTeamOrAgent = {
  adjustScope: adjustScopeConversationAssigneeToActionChange,
  analysisType: 'count',
  eventCollection: 'conversation.assigned',
  filters: [
    {
      propertyName: 'conversation.state',
      operator: 'eq',
      propertyValue: 'opened',
    },
  ],
  key: 'countOpenUnassignmentsFromScopedTeamOrAgent',
}
