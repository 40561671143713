import { connect } from 'react-redux'

import { selectKbRatingType } from 'ducks/reports/controls'

import {
  articleRatingsByArticleAndGrade,
  articleTitlesByArticleGauge,
  articleViewsByArticleGauge,
  concatDatumsIntoArray,
} from 'ducks/reports/gauges'
import { TableV2Visualization } from 'ducks/reports/visualizations'

import sortArrayYRatings from './sortArrayYRatings'

const staticProps = {
  gauges: [
    { ...articleTitlesByArticleGauge, title: 'Article' },
    {
      ...articleViewsByArticleGauge,
      id: 'count',
      title: 'Pageviews',
      scale: { y: 'percent_total' },
    },
    {
      ...articleRatingsByArticleAndGrade,
      collapseDataSets: concatDatumsIntoArray,
      scale: { y: 'ratings' },
      sort: { method: sortArrayYRatings },
    },
  ],
  key: 'kb-popular-articles',
  pagination: {
    customizable: true,
    perPage: 10,
    minPerPage: true,
  },
  sort: {
    by: 'count',
  },
  title: 'Most viewed articles',
}

export default connect(selectProps)(PopularArticlesTable)

function selectProps(state) {
  return {
    kbRatingType: selectKbRatingType(state),
  }
}
const RATINGS = {
  upvote: 'ratings-thumbs',
  emoji: 'ratings',
}

function PopularArticlesTable(props) {
  const { kbRatingType } = props
  const combinedGauges = [...staticProps.gauges]
  combinedGauges[2] = {
    ...combinedGauges[2],
    scale: { y: RATINGS[kbRatingType] },
  }
  const combinedProps = {
    ...staticProps,
    ...props,
    gauges: combinedGauges,
  }
  return <TableV2Visualization {...combinedProps} />
}
