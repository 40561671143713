import single from '../calculators/single'
import { averageRepliesPerConversation } from '../queries/averageRepliesPerConversation'

export const averageRepliesPerResolutionGauge = {
  calculate: single,
  description: `The $average replies made by an ${app.t(
    'agent'
  )} to resolve a conversation.`,
  isNegativeChangeGood: true,
  queries: [averageRepliesPerConversation],
  title: '$Average Replies',
  scale: { y: 'number' },
}
