export default function previousQueriesCacheKey(state, props) {
  const {
    gauge: { queries },
    includePrevious,
    isSorted,
    joinedXValues,
    loadable,
    pagination,
    queryBase,
    scope,
    sort,
    xValues,
  } = props
  return JSON.stringify({
    includePrevious,
    isSorted,
    joinedXValues,
    loadable,
    pagination,
    queries,
    queryBase,
    scope,
    sort,
    xValues,
  })
}
