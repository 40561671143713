import React from 'react'

export default class ReportsVisJSONView extends React.PureComponent {
  componentDidMount() {
    this.props.doLoadGauge(this.gauge)
  }

  get gauge() {
    const { gauges, gauge: gaugeSingular } = this.props
    return gaugeSingular || gauges[0]
  }

  render() {
    const { title } = this.props
    const { title: gaugeTitle, result, loaded, loading, errored } = this.gauge
    return (
      <div>
        <h2>{title || gaugeTitle}</h2>
        {errored && <h3>Error!</h3>}
        {loading && <h3>Loading...</h3>}
        {loaded && <pre>{JSON.stringify(result, null, 2)}</pre>}
      </div>
    )
  }
}
