import { memoize } from 'util/memoization'

export default memoize(applyPaginationAndSortingToQuery)

function applyPaginationAndSortingToQuery(query, pagination, sort) {
  const { maxPerPage, minPerPage } = query
  const { direction } = sort || {}
  const { page, perPage } = pagination || {}

  const calcPerPage = minPerPage - minPerPage % perPage
  const calcMaxPerPage = calcPerPage < perPage ? perPage : calcPerPage
  const calcPage = Math.ceil(page * (perPage / calcMaxPerPage))
  if (maxPerPage < perPage)
    throw new Error(
      'A query cannot limit per page to less that the UI has requested'
    )
  return {
    ...query,
    direction,
    page: (pagination && calcPage) || page,
    perPage: (pagination && calcMaxPerPage) || perPage,
  }
}
