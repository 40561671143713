import { countConversationsAssigned } from '../queries/countConversationsAssigned'

export const totalConversationsAssignedByAgentGauge = {
  id: 'conversations_by_agent',
  queries: [
    {
      ...countConversationsAssigned,
      groupBy: 'conversation.assignee.id',
      key: 'countConversationsAssignedByAssignee',
    },
  ],
  title: `Conversations By ${app.t('Agent')}`,
}
