import createCachedSelector from 're-reselect'

import gaugeCacheKey from './cacheKeys/gaugeCacheKey'
import selectDecoratedGauge from './selectDecoratedGauge'
import selectDecoratedJoinedThroughGauge from './selectDecoratedJoinedThroughGauge'

export default createCachedSelector(
  selectDecoratedJoinedThroughGauge,
  state => state,
  (state, props) => props,
  (decoratedJoinedThroughGauge, state, props) => {
    const { gauges, sort: { by: sortBy } = {} } = props
    function isSortedGauge({ id }) {
      return (
        id &&
        sortBy &&
        sortBy.match(
          new RegExp(`^${id.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&')}(-|$)`)
        )
      )
    }
    // We need to decorate the sorted gauge first so that the other gauges
    // can derive whether they can be loaded or not.
    const sortedGauge = gauges.find(isSortedGauge)
    if (!sortedGauge) return null
    // eslint-disable-next-line no-use-before-define
    return selectDecoratedGauge(state, {
      ...props,
      decoratedJoinedThroughGauge,
      gauge: sortedGauge,
      skipSortedGauge: true,
    })
  }
)(gaugeCacheKey)
