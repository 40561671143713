import graphql from 'api/graphql'
import { oauthTokenSelector } from 'selectors/app/base'
import { BootstrapLoader } from 'util/bootstrap'
import { widgetsQuery } from '../../api'
import requestAction from './requestAction'
import successAction from './successAction'
import failAction from './failAction'

export default function createFetchWidgetsLoader() {
  return new BootstrapLoader(
    'widgets',
    requestAction,
    successAction,
    failAction,
    getState => {
      return graphql(oauthTokenSelector(getState()), widgetsQuery())
    }
  )
}
