import { all, any, uniq, product } from 'util/arrays'
import uniqWith from 'lodash.uniqwith'
import isEqual from 'lodash.isequal'
import { toDate, diff as dateDiff } from 'util/date'
import { toInt } from 'util/ordinal'
import { eq, getOperatorFn } from 'util/operators'

const equalsTrue = m => eq(m, true)

// Loose equality match
const isTrue = str => str == 'true' // eslint-disable-line eqeqeq

const priorityMap = {
  low: 1000,
  medium: 2000,
  high: 3000,
  urgent: 4000,
}

const statusMap = {
  unread: 1000, // Ticket
  open: 2000, // Room
  opened: 2000, // Ticket (Change to open)
  follow_up: 3000, // Ticket (Pending removal)
  pending: 4000, // Ticket (Change to snoozed)
  snoozed: 4000, // Room
  closed: 5000, // Ticket/Room
  spam: 6000, // Ticket/Room
  trash: 7000, // Room (Need to add for tickets)
}

const hoursSinceOperands = (then, value, gateCondition = true) => ({
  prop: dateDiff('hours', toDate(then), new Date()),
  value: toInt(value),
  gateCondition,
})

const hoursSinceStateOperands = (conversation, value, state = null) =>
  hoursSinceOperands(
    conversation.stateChangedAt,
    value,
    state ? conversation.state === state : true
  )

const secondsUntilOperands = (until, value, gateCondition = true) => ({
  prop: until ? dateDiff('seconds', new Date(), toDate(until)) : undefined,
  value: toInt(value),
  gateCondition,
})

const getOperands = ({ param, value }, conversation) => {
  const {
    state,
    isStarred,
    priority,
    isTrash,
    channelType,
    snoozedUntil,
    stateChangedByAgentId,
    assignedAgentId,
    assignedTeamId,
    interactionCount,
    updatedAt,
    assignedAt,
    labels,
    lastUnansweredUserMessageAt,
  } = conversation
  switch (param) {
    case 'starred':
      return { prop: isStarred, value: isTrue(value) }
    case 'priority':
      return {
        prop: priority,
        value: priorityMap[value.toLowerCase()],
      }
    case 'status': {
      // NOTE: I've removed the snoozed logic from this code. We should rather add
      // this hacky logic to the reducer add create a proper "snoozed" state
      return {
        prop: statusMap[state.toLowerCase()],
        value: statusMap[value.toLowerCase()],
      }
    }
    case 'deleted':
      return { prop: isTrash, value: isTrue(value) }
    case 'channel':
      return { prop: channelType, value }
    case 'snooze_until': {
      const indefinitely = 999999999

      // When folder filter is snoozed indefinitely and conversation
      // is snoozed indefinitely
      if (value === 'indefinitely' && snoozedUntil === 'SNOOZED_INDEFINITELY')
        return { prop: indefinitely, value: indefinitely }

      // when folder condition is snoozed indefinitely and conversation
      // is snoozedUntil
      if (value === 'indefinitely')
        return secondsUntilOperands(snoozedUntil, indefinitely)

      // when folder condition is snoozed unil X and conversation is
      // snoozed indefinitely
      if (snoozedUntil === 'SNOOZED_INDEFINITELY') {
        return { prop: indefinitely, value }
      }

      // When neither folder condition or conversation is snoozed indefinitely
      return secondsUntilOperands(snoozedUntil, value)
    }
    case 'snooze_state': {
      if (state !== 'snoozed') {
        return { prop: 0, value: 1 }
        // value of null means "Anyone", so if we have anyone,
        // we return a preformatted pair that will work in operation
      } else if (!!stateChangedByAgentId && value === null) {
        return { prop: 1, value: 1 }
      } else if (stateChangedByAgentId === null && value === null) {
        return { prop: 0, value: 1 }
      }
      return { prop: stateChangedByAgentId, value }
    }
    case 'assigned_agent':
      return {
        prop: assignedAgentId,
        value,
      }
    case 'assigned_group':
      return { prop: assignedTeamId, value }
    case 'interaction_count':
      return { prop: interactionCount, value }
    case 'hours_since_updated':
      return hoursSinceOperands(updatedAt, value)
    case 'hours_since_status_changed':
      return hoursSinceStateOperands(conversation, value)
    case 'hours_since_open':
      return hoursSinceStateOperands(conversation, value, 'open')
    case 'hours_since_pending':
      return hoursSinceStateOperands(conversation, value, 'pending')
    case 'hours_since_closed':
      return hoursSinceStateOperands(conversation, value, 'closed')
    case 'hours_since_assigned':
      return hoursSinceOperands(assignedAt, value)
    case 'hours_since_last_unanswered_user_message':
      return hoursSinceOperands(lastUnansweredUserMessageAt, value)
    case 'labels':
      return { prop: labels.map(label => label.name), value }
    default:
      return { prop: conversation[param], value }
  }
}

// Determines if the given ticket matches the given filter condition
// Returns true/false
export const matchConversation = (condition, conversation) => {
  const opFn = getOperatorFn(condition.operator)
  const { prop, value, gateCondition = true } = getOperands(
    condition,
    conversation
  )
  return opFn(prop, value) && gateCondition
}

// Determines if the given ticket matches any/all (matchType) of the the given
// filter conditions
export const matchFilter = (filter, conversation) => {
  const { conditions, matchType } = filter
  const matchFn = matchType === 'all' ? all : any
  const matches = conditions.map(condition =>
    matchConversation(condition, conversation)
  )
  return matchFn(equalsTrue, matches)
}

// This method deviates from the server implementation because we already know "who"
// we're generating this for. The net effect is that the server version will generate
// a folder search for each agent and this version only generate a folder search
// for the current agent
const computeFolderSearches = (conversation, filter) => {
  const matchesFilter = matchFilter(filter, conversation)
  if (matchesFilter) {
    const filters = [`folder:${filter.id}`]
    if (!conversation.isRead) {
      filters.push(`folderunread:${filter.id}`)
    }
    return filters
  }
  return []
}

const stringifySearches = searches => {
  return uniqWith(searches, isEqual)
    .map(search => {
      return (
        search
          .map(searchPart => {
            if (Array.isArray(searchPart)) return searchPart.join(' ')
            return searchPart
          })
          .join(' ')
          // Strip leading and trialing spaces
          .replace(/^\s+|\s+$/g, '')
          .replace(/\s+/, ' ')
      )
    })
    .filter(queryId => !!queryId)
    .reduce((combinedSearches, queryId) => {
      // eslint-disable-next-line no-param-reassign
      combinedSearches[queryId] = true
      return combinedSearches
    }, {})
}

const computeConversationSearches = (conversation, foldersById) => {
  // 1 to 1 port of the logic in index_room_service. Make sure you keep that version
  // insync if you change this version
  const {
    channelId,
    state,
    assignedType,
    assignedAgentId,
    assignedTeamId,
    draftAgentIds,
    mentionAgentIds,
    isStarred,
    // isRead,
    isRated,
    lastRating,
    tagIds,
  } = conversation

  // Channel searches
  const channels = [null, `channel:${channelId}`]

  // State searches
  const states = []
  // eslint-disable-next-line default-case
  switch (state) {
    case 'open':
      states.push(null, 'is:open')
      break
    case 'snoozed':
      states.push(null, 'is:snoozed')
      break
    case 'closed':
      states.push(null, 'is:closed')
      break
    case 'spam':
      states.push(null, 'is:spam')
      break
    case 'trash':
      states.push(null, 'is:trash')
      break
  }

  // Agent searches
  const agents = []
  const teams = []
  const unassigned = [null]

  switch (assignedType) {
    case 'both':
      agents.push(null, 'is:assigned', `agent:ag_${assignedAgentId}`)
      teams.push(null, 'is:assigned', `team:tm_${assignedTeamId}`)
      break
    case 'agent':
      agents.push(null, 'is:assigned', `agent:ag_${assignedAgentId}`)
      break
    case 'team':
      teams.push(null, 'is:assigned', `team:tm_${assignedTeamId}`)
      break
    default:
      agents.push(null, 'assignee:unassigned')
      teams.push(null, 'group:unassigned')
      unassigned.push(null, 'is:unassigned')
  }

  // Draft searches (not currently implemented)
  const drafts = []
  draftAgentIds.forEach(agentId => {
    drafts.push(`draft:ag_${agentId}`)
  })

  // Mention searches
  const mentions = []
  mentionAgentIds.forEach(agentId => {
    mentions.push(`mentions:ag_${agentId}`)
  })

  // Starred searches
  const starred = isStarred ? [null, 'is:starred'] : [null]
  // Unread searches (doesnt appear to be used)
  // const unread = !isRead ? [null, 'is:unread'] : [null]
  // Rating searches
  const ratings = isRated
    ? [null, 'is:rated', `rating:${lastRating.grade}`]
    : [null]
  // Tag searches
  const tags = [null, ...tagIds.map(tagId => `tag:tag_${tagId}`)]

  // Folder searches
  const filters = Object.values(foldersById)
  const folders = filters
    .map(filter => computeFolderSearches(conversation, filter))
    .flat()

  return stringifySearches(
    [].concat(
      product(product(channels, unassigned), states),
      product(product(channels, teams), states),
      product(product(channels, agents), states),
      product(product(channels, states), tags),
      product(product(channels, drafts), states),
      product(product(channels, starred), states),
      product(product(channels, mentions), states),
      product(product(channels, ratings), states),
      product(channels, folders)
    )
  )
}

export const calculateBasicDiff = (
  updatedConversation,
  currentConversation,
  foldersById
) => {
  const updatedSearches = computeConversationSearches(
    updatedConversation,
    foldersById
  )
  const currentSearches = computeConversationSearches(
    currentConversation,
    foldersById
  )
  const updatedQueryIds = Object.keys(updatedSearches)
  const currentQueryIds = Object.keys(currentSearches)
  const allQueryIds = uniq(updatedQueryIds.concat(currentQueryIds))
  const diff = {}
  allQueryIds.forEach(queryId => {
    const valueIs = currentSearches[queryId]
    const valueWillBe = updatedSearches[queryId]
    if (valueIs && !valueWillBe) diff[queryId] = -1
    if (!valueIs && valueWillBe) diff[queryId] = 1
  })
  return diff
}
