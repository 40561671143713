import { userResolution } from './filters'
import { adjustScopeConversationAssigneeToActionUser } from './scopes'

export const countConversationsResolved = {
  adjustScope: adjustScopeConversationAssigneeToActionUser,
  analysisType: 'count',
  eventCollection: 'conversation.resolved',
  filters: [userResolution],
  key: 'countConversationsResolved',
}
