import React from 'react'

export default function withPagingForVisualizations(ComposedComponent) {
  return class PagingForVisualizations extends React.Component {
    state = {}

    get page() {
      const { componentState } = this.props
      if (
        componentState &&
        componentState.inMemoryPager &&
        componentState.inMemoryPager.page
      ) {
        return componentState.inMemoryPager.page
      }
      return 1
    }

    get pageSize() {
      const { componentState, defaultPageSize = 10 } = this.props
      if (
        componentState &&
        componentState.inMemoryPager &&
        componentState.inMemoryPager.per_page
      ) {
        return componentState.inMemoryPager.per_page
      }
      return defaultPageSize
    }

    get totalPages() {
      const { tableData } = this.props
      if (Array.isArray(tableData) && tableData.length > 0) {
        return Math.ceil(tableData.length / this.pageSize)
      }
      return 1
    }

    get inMemoryPagination() {
      return {
        current: this.page,
        total: this.totalPages,
      }
    }

    get gaugePagination() {
      const { gauges } = this.props
      let pagination = null
      gauges.forEach(gauge => {
        if (!gauge.loaded) return null
        if (!pagination && gauge.pagination && gauge.pagination.total > 1) {
          pagination = {
            ...gauge.pagination,
            current: gauge.pagination.page,
          }
        }

        return null
      })
      return pagination
    }

    get tableData() {
      const { tableData, sort } = this.props
      if (!sort || sort.type === 'inmemory') {
        const skip = (this.page - 1) * this.pageSize
        return tableData.slice(skip, skip + this.pageSize)
      }
      return tableData
    }

    get pagination() {
      const { sort } = this.props
      if (!sort || sort.type === 'inmemory') {
        return this.inMemoryPagination
      }
      return this.gaugePagination
    }

    render() {
      return (
        <ComposedComponent
          {...this.props}
          dataPagination={this.pagination}
          tableData={this.tableData}
        />
      )
    }
  }
}
