import { syncEntity } from 'ducks/entities/actionUtils'
import { doApiWriteRequest } from 'ducks/requests/operations'
import { teamApiV1ResponseSchema } from './schema'

import { CREATE_TEAMS_DRAFT, SAVE_TEAM, DELETE_TEAM } from './actionTypes'

export const doCreateTeamDraft = id => ({
  type: CREATE_TEAMS_DRAFT,
  ...syncEntity('team', id, 'merge', 'current'),
})

export const doSaveTeam = (id, fields, options = {}) => dispatch => {
  // and on here  (i.e. on save button click) you just copy the entity from pending to current store
  const entityType = 'team'
  const entity = { ...fields, id }
  const isUpdate = id !== 'new'

  let saveUrl = 'v1/groups'
  let saveMethod = 'POST'
  let targetStores = ['pending', 'current']
  if (isUpdate) {
    saveUrl += `/${id}`
    saveMethod = 'PUT'
    targetStores = ['pending']
  }

  const payload = {
    ...entity,
    agents: entity.agents.map(agent => agent.id),
  }

  // api call
  return dispatch(
    doApiWriteRequest(SAVE_TEAM, saveUrl, payload, {
      method: saveMethod,
      optimist: {
        entities: {
          [entityType]: {
            [id]: entity,
          },
        },
      },
      normalizationSchema: teamApiV1ResponseSchema,
      searches: {
        additionalActions: [
          {
            type: 'INVALIDATE_ENTITIES',
            entityTypes: ['team'],
            phases: ['SUCCESS'],
          },
        ],
      },
      moduleOptions: {
        toasts: {
          enabled: true,
          started: {
            enabled: true,
            content: isUpdate
              ? `${app.t('Team')} changes saved`
              : `New ${app.t('team')} created`,
          },
          success: {
            enabled: false,
          },
          failed: {
            content: isUpdate
              ? `${app.t('Team')} changes failed`
              : `${app.t('Team')} creation failed`,
            onClickAction: () => {
              dispatch(doSaveTeam(id, fields, options))
            },
          },
        },
        entities: {
          additionalActions: [
            {
              entityType,
              entityId: id,
              stores: targetStores,
              operation: 'remove',
              phases: ['SUCCESS'],
            },
          ],
        },
      },
    })
  )
}

// eslint-disable-next-line no-unused-vars
export const doDeleteTeam = (id, options = {}) => dispatch => {
  return dispatch(
    doApiWriteRequest(
      DELETE_TEAM,
      `v1/groups/${id}`,
      {},
      {
        method: 'DELETE',
        optimist: {},
        normalizationSchema: teamApiV1ResponseSchema,
        searches: {
          additionalActions: [
            {
              type: 'INVALIDATE_ENTITIES',
              entityTypes: ['team'],
              phases: ['SUCCESS'],
            },
          ],
        },
        moduleOptions: {
          toasts: {
            enabled: true,
            started: {
              enabled: true,
              content: `${app.t('Team')} deleted`,
            },
            success: {
              enabled: false,
            },
            failed: {
              content: `${app.t('Team')} deletion failed`,
              onClickAction: () => {
                dispatch(doDeleteTeam(id, options))
              },
            },
          },
          entities: {
            targetOperation: 'remove',
          },
        },
      }
    )
  )
}

export const doDeleteTeams = (ids, options = {}) => dispatch => {
  return Promise.all(ids.map(id => dispatch(doDeleteTeam(id, options))))
}
