import { memoize } from 'util/memoization'
import moment from 'moment-timezone-all'
import { createSelector } from 'reselect'

import { selectTimezone } from 'ducks/reports/controls'
import { mapObject } from 'util/objects'

function selectBase(state) {
  return state.reports.queries
}

const decorateQuery = memoize((query, timezone) => {
  return {
    ...query,
    results:
      query.results &&
      query.results.map &&
      query.results.map(result => {
        if (!result.timeframe) return result
        return {
          ...result,
          timeframe: {
            ...result.timeframe,
            start:
              result.timeframe.start &&
              moment.tz(result.timeframe.start, timezone),
            end:
              result.timeframe.end && moment.tz(result.timeframe.end, timezone),
          },
        }
      }),
  }
})
const decorateQueries = mapObject(decorateQuery)

export const selectQueriesById = createSelector(
  selectTimezone,
  selectBase,
  (timezone, queries) => {
    return decorateQueries(queries.byId, timezone)
  }
)

export const selectQueriesByIdRaw = createSelector(selectBase, queries => {
  return queries.byId
})
