import React, { PureComponent } from 'react'
import { compose } from 'redux'

import withMenuPositioning from 'shared/ui/Dropdown/withMenuPositioning'
import withOpenState from 'shared/ui/withOpenState'
import Content from './Content'

class Dropdown extends PureComponent {
  handleOnClick = evt => {
    const { onClick, onOpen, open, hasKnowledgebases } = this.props
    if (!open && !hasKnowledgebases) onOpen()
    if (onClick) onClick(evt)
  }

  render() {
    const {
      bottom,
      left,
      open,
      right,
      top,
      trigger,
      hasKnowledgebases,
      ...rest
    } = this.props
    return (
      <div onClick={this.handleOnClick}>
        {trigger}
        <Content open={open} offset={{ left, right, top, bottom }} {...rest} />
      </div>
    )
  }
}

export default compose(withOpenState, withMenuPositioning)(Dropdown)
