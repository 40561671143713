import { css } from '@emotion/react'

const teamLeaderBoardTable = theme => css`
  @media (max-width: 1650px) {
    &&& {
      thead th,
      tbody td {
        padding-right: ${theme.spacing[5]};

        &:not(:first-of-type) {
          padding-left: ${theme.spacing[5]};
        }
      }
    }
  }

  .agent-link-avatar,
  .agent-link-name {
    vertical-align: middle;
  }

  .agent-link-name {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 160px;

    @media (max-width: 1650px) {
      max-width: 120px;
    }
  }
`

export const styles = {
  teamLeaderBoardTable,
}
