import { VictoryTheme } from 'victory'
import grooveTheme from './grooveTheme'

VictoryTheme.grayscale = grooveTheme
VictoryTheme.groove = grooveTheme

export { default as AreaChart } from './components/AreaChart'
export { default as BarChartVisualization } from './components/BarChart'
export { default as BusiestTimeVisualization } from './components/BusiestTime'
export { default as ProgressBarVisualization } from './components/ProgressBar'
export { default as HeadlineVisualization } from './components/Headline'
export { default as LineChart } from './components/LineChart'
export { default as LineChartV2Visualization } from './components/LineChartV2'
export { default as Loading } from './components/Loading'
export { default as JSONVisualization } from './components/JSON'
export { default as PercentGauge } from './components/PercentGauge'
export { default as PercentBarVisualization } from './components/PercentBar'
export { default as TableVisualization } from './components/Table'
export { default as TableV2Visualization } from './components/TableV2'
export { default as grooveTheme } from './grooveTheme'
export { default as replaceAverage } from './util/replaceAverage'
export * from './sorts'
