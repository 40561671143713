export const FETCH_SPAMMERS = 'spammers/FETCH_SPAMMERS'
export const FETCH_SPAMMERS_STARTED = 'spammers/FETCH_SPAMMERS_STARTED'
export const FETCH_SPAMMERS_SUCCESS = 'spammers/FETCH_SPAMMERS_SUCCESS'
export const FETCH_SPAMMERS_FAILED = 'spammers/FETCH_SPAMMERS_FAILED'

export const CREATE_SPAMMER = 'spammers/CREATE_SPAMMER'
export const CREATE_SPAMMER_STARTED = 'spammers/CREATE_SPAMMER_STARTED'
export const CREATE_SPAMMER_SUCCESS = 'spammers/CREATE_SPAMMER_SUCCESS'
export const CREATE_SPAMMER_FAILED = 'spammers/CREATE_SPAMMER_FAILED'

export const DELETE_SPAMMER = 'spammers/DELETE_SPAMMER'
export const DELETE_SPAMMER_STARTED = 'spammers/DELETE_SPAMMER_STARTED'
export const DELETE_SPAMMER_SUCCESS = 'spammers/DELETE_SPAMMER_SUCCESS'
export const DELETE_SPAMMER_FAILED = 'spammers/DELETE_SPAMMER_FAILED'
