import React from 'react'

import ClickOutside from 'components/ClickOutside'
import { Portal } from 'util/ui'

import DatePickerDropdown from './DatePickerDropdown'
import DatePickerDropdownContent from './DatePickerDropdownContent'

export default function DatePickerView(props) {
  const {
    endDate,
    focusedInput,
    onCancel,
    onFocusChange,
    prerender,
    startDate,
    dropdownLabel,
    className,
  } = props
  const isOpen = !!focusedInput
  return (
    <React.Fragment>
      <DatePickerDropdown
        focusedInput={focusedInput}
        endDate={endDate}
        isOpen={isOpen}
        onFocusChange={onFocusChange}
        startDate={startDate}
        className={className}
        dropdownLabel={dropdownLabel}
      />
      <Portal open={prerender || isOpen}>
        <ClickOutside disabled={!focusedInput} onTrigger={onCancel}>
          {(prerender || isOpen) && (
            <DatePickerDropdownContent {...props} isOpen={isOpen} />
          )}
        </ClickOutside>
      </Portal>
    </React.Fragment>
  )
}
