import { selectLocationState } from 'redux-first-router'
import { createBasicSelector } from 'util/redux'

const selectCurrentConversationId = createBasicSelector(
  selectLocationState,
  location =>
    location &&
    location.query &&
    location.query.conversationId &&
    // Not a big fan of parsing the roomId to integer here, but as far as I can see the location
    // reducer is part of the redux-first-router and I dont have a way to tell it to save this variable
    // as a integer
    parseInt(location.query.conversationId, 10)
)

export default selectCurrentConversationId
