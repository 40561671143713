import { countArticleViews } from '../queries/countArticleViews'
import single from '../calculators/single'

export const articleViewsByArticleGauge = {
  queries: [
    {
      ...countArticleViews,
      groupBy: 'article.id',
    },
  ],
  title: 'Viewed Articles',
}
