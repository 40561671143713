import React from 'react'
import { v4 as uuidV4 } from 'uuid'

import SpacedItems from 'shared/components/ui/SpacedItems'

import { ReportsLayout } from 'ducks/reports/layout'
import * as CustomPropTypes from 'ducks/reports/propTypes'

import AgentsOverviewTable from '../AgentsOverviewTable'

const agentsOverviewTableId = uuidV4()

Page.propTypes = {
  queryBase: CustomPropTypes.queryBase.isRequired,
}

export default function Page({ queryBase }) {
  return (
    <ReportsLayout>
      <SpacedItems.Container direction="vertical" gap="ginormous">
        <SpacedItems.Item>
          <AgentsOverviewTable
            id={agentsOverviewTableId}
            queryBase={queryBase}
          />
        </SpacedItems.Item>
      </SpacedItems.Container>
    </ReportsLayout>
  )
}
