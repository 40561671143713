import { connect } from 'react-redux'
import { createSelector } from 'reselect'

import {
  selectUniquePageVisitsPerDayGauge,
  selectArticleViewsPerDayGauge,
} from 'ducks/reports/gauges'
import { BarChartVisualization } from 'ducks/reports/visualizations'
import { asArray } from 'util/selectors'

function joinAllTimeframeValuesFormatter(item, itemGauge, gauges) {
  const timeframeValues = []
  gauges.forEach(set => {
    if (set && set.result) {
      const timeValueItem = set.result.find(
        setItem =>
          setItem.timeframe.start === item.timeframe.start &&
          setItem.timeframe.end === item.timeframe.end
      )
      timeframeValues.push(
        `${set.title} = ${timeValueItem && timeValueItem.value}`
      )
    }
  })
  return timeframeValues.join('\n')
}
const selectGauges = createSelector(
  selectUniquePageVisitsPerDayGauge,
  selectArticleViewsPerDayGauge,
  asArray
)
const select = (state, props) => ({
  gauges: selectGauges(state, props),
  labelFormatter: joinAllTimeframeValuesFormatter,
})
export default connect(select)(BarChartVisualization)
