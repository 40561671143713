import React from 'react'

import DatePickerButtons from './DatePickerButtons'
import DatePickerPresets from './DatePickerPresets'
import styles from './styles.less'

export default function DatePickerFooter({
  onApply,
  onCancel,
  onFocusChange,
  earliestStartDate,
  today,
  presets,
}) {
  return (
    <div className={styles.DatePickerFooter}>
      <DatePickerPresets
        onApply={onApply}
        onFocusChange={onFocusChange}
        earliestStartDate={earliestStartDate}
        today={today}
        presets={presets}
      />
      <DatePickerButtons onApply={onApply} onCancel={onCancel} />
    </div>
  )
}
