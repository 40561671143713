import React from 'react'
import cn from 'classnames'
import md5 from 'util/md5'
import TextBody from 'components/App/DesktopView/Layout/TicketInspector/Changesets/ExpandedMessage/TextBody'

import TicketPreviewCommentHeader from './Header'
import styles from './styles.less'

function TicketPreviewComment(
  {
    ticketId,
    authorName,
    authorEmail,
    body,
    createdAt,
    isNote,
    cc,
    bcc,
    isForward,
    isAgentResponse,
  },
  ref
) {
  return (
    <div
      className={cn(styles.container, {
        [styles.note]: isNote,
        [styles.forward]: isForward,
        [styles.agent]: isAgentResponse && !(isNote || isForward),
      })}
      ref={ref}
    >
      <TicketPreviewCommentHeader
        ticketId={ticketId}
        authorName={authorName}
        authorEmail={authorEmail}
        createdAt={createdAt}
        cc={cc}
        bcc={bcc}
      />
      <TextBody key={md5(body)} text={body} />
    </div>
  )
}

export default React.forwardRef(TicketPreviewComment)
