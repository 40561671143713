import { sortByKey } from 'util/arrays/sorting'
import { all } from 'util/arrays'

export function sortEntities(orderBy, entities) {
  if (!orderBy) return entities

  return sortByKey(entities, orderBy.field, {
    direction: orderBy.direction,
  })
}

// Kevin Rademan (2021-09-21)
// HACK: I've messed up the queryKeyToFilterMap in src/ducks/searches/utils/query.js
// which results in non-filter information being returned by constructGraphQLFilterObject
// We'll come back to fix this at a later stage
const INVALID_KEYS = ['pageSize', 'cursor', 'entityType']
const FUZZY_KEYS = ['name']
export function filterEntities(filterBy, entities) {
  if (!filterBy) return entities
  const filterKeys = Object.keys(filterBy).filter(
    k => !INVALID_KEYS.includes(k)
  )
  if (!filterBy || filterKeys.length === 0) return entities

  return entities.filter(entity => {
    return all(fKey => {
      const entityVal = entity[fKey]
      const filterVal = filterBy[fKey]
      if ([entityVal, filterVal].includes(undefined, null)) return false
      const isFuzzy = FUZZY_KEYS.includes(fKey)
      const isArray = Array.isArray(entityVal)
      if (isArray) return entityVal.includes(filterVal)
      if (isFuzzy)
        return entityVal
          .toString()
          .toLowerCase()
          .includes(filterVal)
      return entityVal === filterVal
    }, filterKeys)
  })
}
