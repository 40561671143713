import single from '../calculators/single'
import { averageFirstResponseTime } from '../queries/averageFirstResponseTime'
import { averageFirstResponseTimeWithinBusinessHours } from '../queries/averageFirstResponseTimeWithinBusinessHours'

export const averageFirstResponseTimeForEachDayGauge = {
  businessHoursQueries: [
    {
      ...averageFirstResponseTimeWithinBusinessHours,
      interval: 'daily',
    },
  ],
  calculate: single,
  queries: [
    {
      ...averageFirstResponseTime,
      interval: 'daily',
      key: 'averageFirstResponseTimeForEachDay',
    },
  ],
  title: '$Average First Response Time',
}
