import graphql from 'api/graphql'
import { oauthTokenSelector } from 'selectors/app'

const fields = `
meta {
  totalCount
}
data {
  id
  knowledgeBase {
    siteUrl
  }
  slug
  title
}
`
function doFetchSortedArticles(token, sortOrder, page, perPage) {
  const query = `
    query SortedArticles($page: Int, $perPage: Int, $order: String) {
      articles(page: $page, perPage: $perPage, order: $order) {
        ${fields}
      }
    }
  `
  const variables = {
    page,
    perPage,
    order: `title ${sortOrder || 'asc'}`,
  }
  return graphql(token, query, variables)
}

function doFetchArticlesIds(token, ids) {
  const query = `
    query ArticlesByIds($ids: [ID], $perPage: Int) {
      articles(ids: $ids, perPage: $perPage) {
        ${fields}
      }
    }
  `
  const variables = { ids, perPage: ids.length }
  return graphql(token, query, variables)
}

const groupBy = 'article.id'

export const articleTitlesByArticleGauge = {
  fill: 'x',
  id: 'articleTitlesByArticle',
  queries: [
    {
      groupBy,
      key: 'articleTitlesByArticle',
      loader: query => {
        const {
          filters,
          sort: { direction } = {},
          pagination: { page, perPage } = {},
        } = query
        return (dispatch, getState) => {
          const idFilter = filters.find(f => f.propertyName === groupBy) || {}
          const { propertyValue: ids } = idFilter || {}
          const state = getState()
          const token = oauthTokenSelector(state)
          if (ids && ids.length === 0) return []
          return (ids
            ? doFetchArticlesIds(token, ids)
            : doFetchSortedArticles(token, direction, page, perPage)
          ).then(res => {
            const { meta, data } = res.json.data.articles
            const totalCount = meta && meta.totalCount
            return {
              totalCount,
              result: data.map(
                ({ id, knowledgeBase: { siteUrl }, slug, title }) => {
                  return {
                    [groupBy]: id,
                    result: title,
                    url: `${siteUrl}/${slug}`,
                  }
                }
              ),
            }
          })
        }
      },
      minPerPage: 25,
      maxPerPage: 50,
    },
  ],
  scale: { x: 'id', y: 'article' },
  sort: { direction: 'ASC' },
  title: 'Tag',
}
