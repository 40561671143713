import { selectGroups } from 'selectors/app'

export const teamNameByTeamGauge = {
  calculate: gauge => {
    const { stateData } = gauge
    return stateData.map(a => ({
      id: a.id,
      result: a.name,
    }))
  },
  id: 'team_name_by_team',
  selector: selectGroups,
  title: app.t('Teams'),
}
