import { connect } from 'react-redux'

import { selectPerformedSearchRatioGauge } from 'ducks/reports/gauges'
import { PercentGauge } from 'ducks/reports/visualizations'

const staticSelect = {
  scale: { y: 'percent' },
  title: 'Performed a search',
  trafficLight: true,
}
const select = (state, props) => ({
  ...staticSelect,
  gauge: selectPerformedSearchRatioGauge(state, props),
})
export default connect(select)(PercentGauge)
