import { Label } from 'shared/components/ui/Typography'
import { formatNumber } from 'util/numbers'

export default function SearchCountCell(props) {
  const { value, row } = props
  return (
    <Label as="div" size="small">
      {formatNumber(value)}
      <span style={{ color: 'gray' }}>
        {` (${Math.round(value / row.total_search * 10000) / 100}%)`}
      </span>
    </Label>
  )
}
