import GA from 'util/googleAnalytics'
import { selectTicketById } from 'selectors/tickets/byId/selectTicketById'
import { selectCurrentTicketId } from 'selectors/tickets/current/selectCurrentTicketId'
import { selectCurrentUser } from 'ducks/currentUser/selectors/selectCurrentUser'
import { followPayload } from 'optimistic/follow'
import { selectCurrentFolderId } from 'selectors/app'
import { selectLatestTicketSearchQueryObject } from 'selectors/search'
import { doShowSnackbar } from 'actions/snackbar'
import { doSendChangeset } from 'actions/tickets/changeset/doSendChangeset'
import { batchActions } from 'util/redux'

export const TRACK_TYPE_FOLLOW_TICKET_TOP_BAR = 'Inbox - Email Top Bar'
export const TRACK_TYPE_FOLLOW_TICKET_NOTE = 'Inbox - Email Note Form'

export function doTicketChangeFollowState(
  ticketId,
  follow,
  { suppressSnackbar = false, trackType = null } = {}
) {
  return (dispatch, getState) => {
    const state = getState()
    const { follower_ids: followerIds = [] } = selectTicketById(state, ticketId)
    const currentUserId = selectCurrentUser(state).id
    const optimisticData = followPayload(
      state,
      ticketId,
      selectCurrentFolderId(state),
      selectLatestTicketSearchQueryObject(state),
      follow,
      followerIds,
      currentUserId
    )
    const actions = [
      doSendChangeset(
        ticketId,
        { follows: follow },
        { optimisticData, skipTicketTouch: true }
      ),
    ]
    if (!suppressSnackbar) {
      actions.push(
        doShowSnackbar(`Conversation ${follow ? 'followed' : 'unfollowed'}.`)
      )
    }
    if (trackType) {
      GA.track(trackType, 'Clicked', 'Follow ticket')
    }
    return dispatch(batchActions(actions))
  }
}

export function doFollowTicketById(ticketId, options) {
  return doTicketChangeFollowState(ticketId, true, options)
}

export function doUnfollowTicketById(ticketId, options) {
  return doTicketChangeFollowState(ticketId, false, options)
}

export function doFollowCurrentTicket(options) {
  return (dispatch, getState) => {
    const state = getState()
    const currentTicketId = selectCurrentTicketId(state)
    return dispatch(doFollowTicketById(currentTicketId, options))
  }
}

export function doUnfollowCurrentTicket(options) {
  return (dispatch, getState) => {
    const state = getState()
    const currentTicketId = selectCurrentTicketId(state)
    return dispatch(doUnfollowTicketById(currentTicketId, options))
  }
}
