import React from 'react'
import PropTypes from 'prop-types'

import { isFunction } from 'util/functions'
import { Table, Pagination } from 'util/ui'

import TablePageSizeSelector from './TablePageSizeSelector'

TableFooter.propTypes = {
  columns: PropTypes.array.isRequired,
  dataPagination: PropTypes.object,
  onPageSizeChange: PropTypes.func,
  onPageChange: PropTypes.func,
}

TableFooter.defaultProps = {
  dataPagination: null,
  onPageSizeChange: () => {},
  onPageChange: () => {},
}

function createHandler(func, props) {
  const { setComponentState } = props
  if (isFunction(func)) {
    return (evt, data) => {
      setComponentState(func(evt, data))
    }
  }
  return () => {}
}

export default function TableFooter(props) {
  const { onPageSizeChange, onPageChange, dataPagination, columns } = props
  const { current, pages } = dataPagination || {}
  return (
    <Table.Footer>
      <Table.Row>
        <Table.HeaderCell colSpan={columns.length} textAlign="right">
          <TablePageSizeSelector
            onChange={createHandler(onPageSizeChange, props)}
          />
          {pages > 1 && (
            <Pagination
              activePage={current}
              boundaryRange={1}
              onPageChange={createHandler(onPageChange, props)}
              siblingRange={2}
              totalPages={pages}
              firstItem={null}
              lastItem={null}
            />
          )}
        </Table.HeaderCell>
      </Table.Row>
    </Table.Footer>
  )
}
