import { single } from '../calculators/single'
import { averageResolutionTime } from '../queries/averageResolutionTime'
import { averageResolutionTimeWithinBusinessHours } from '../queries/averageResolutionTimeWithinBusinessHours'

export const averageResolutionTimeGauge = {
  businessHoursQueries: [averageResolutionTimeWithinBusinessHours],
  calculate: single,
  description:
    'The $average time to resolve a conversation. If a conversation is reopened, then this time is added into the average.',
  isNegativeChangeGood: true,
  queries: [averageResolutionTime],
  title: '$Average Resolution Time',
}
