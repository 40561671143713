export default function extractV1ApiPagination(payload, { type = null } = {}) {
  const nodeKey =
    type ||
    Object.keys(payload).filter(
      k => k !== 'meta' && Array.isArray(payload[k])
    )[0]

  const {
    [nodeKey]: nodes,
    meta: {
      pagination: {
        current_page: currentPage = 1,
        total_pages: totalPageCount = 1,
        total_count: totalCount,
      } = {},
    } = {},
  } = payload

  const hasNextPage = currentPage < totalPageCount
  const hasPreviousPage = currentPage > 1
  const startCursor = hasPreviousPage ? currentPage - 1 : null
  const endCursor = hasNextPage ? currentPage + 1 : null

  return {
    type: nodeKey,
    nodes,
    startCursor,
    endCursor,
    hasNextPage,
    hasPreviousPage,
    totalCount: totalCount || nodes.length,
    totalPageCount,
  }
}
