import React from 'react'
import Link from 'redux-first-router-link'

import { LinkWithReportContext } from 'ducks/reports/controls'
// TODO: Fix direct duck reference without creating circular dependancy
import { TEAM_DETAIL_PAGE } from 'ducks/reports/detail/pages'

export default function TeamCellView({ team: { id, name } = {} }) {
  return (
    <span>
      {id && (
        <LinkWithReportContext to={{ type: TEAM_DETAIL_PAGE, payload: { id } }}>
          {name}
        </LinkWithReportContext>
      )}
      {!id && name}
    </span>
  )
}
