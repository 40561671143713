import { combineReducers } from 'core/redux'

import articles from './articles'
import knowledgeBases from './knowledgeBases'
import articlePreview from './articlePreview'

export default combineReducers({
  articles,
  knowledgeBases,
  articlePreview,
})
