export default function NumberCell(props) {
  const { value, column } = props

  const config = column.config || {}
  let numberValue = 0
  if (Number.isInteger(value)) {
    numberValue = parseInt(value, 10)
  }
  if (config.roundTo) {
    numberValue.toFixed(config.roundTo)
  }
  return <span>{numberValue}</span>
}
