export default function gaugeCacheKey(state, props) {
  const {
    decoratedJoinedThroughGauge,
    decoratedSortedGauge,
    includePrevious,
    join: { through: joinThrough } = {},
    gauge,
    nullIsValidX,
    pagination: { page, perPage } = {},
    sort: { by: sortBy, direction: sortDirection } = {},
    xValueTranslations,
  } = props
  const { loaded: decoratedSortedGaugeLoaded } = decoratedSortedGauge || {}
  return JSON.stringify({
    decoratedJoinedThroughGauge,
    decoratedSortedGaugeLoaded,
    includePrevious,
    joinThrough,
    gauge,
    nullIsValidX,
    page,
    perPage,
    sortBy,
    sortDirection,
    xValueTranslations,
  })
}
