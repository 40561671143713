// Constants for imap and smpt server details
export const IMAP_SERVER = 'incoming_email_server'
export const SMTP_SERVER = 'outgoing_email_server'
export const EMAIL_SERVER_DOMAIN = 'address'
export const EMAIL_SERVER_DOMAIN_PORT = 'port'
export const EMAIL_SERVER_LOGIN_EMAIL = 'user_name'
export const EMAIL_SERVER_LOGIN_PASSWORD = 'password'
export const EMAIL_SERVER_AUTH_SSL = 'use_ssl'

export const EMAIL_SERVER_IS_UPDATE_PASSWORD = 'is_update_password'

export const MIGRATING_STATE = 'migrating'
