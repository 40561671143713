// Assignment Module. The marriage of an agent and a group. Or none of those.
//
// Tis borne of ticket selector jank. We would decorate tickets with an
// `assignment`, the definition of which hasnt really been thought out that
// well. Its used in a few places now, so we bring that logic together here in an
// attempt at keeping our house tidy.
import { memoize } from 'util/memoization'

import PropTypes from 'prop-types'
import { fullName, getAgentNameOrYou } from 'util/agents'

// borrows a convention for naming a module struct’s type `t`.
const t = PropTypes.shape({
  agentId: PropTypes.string,
  agentName: PropTypes.string,
  agentFullName: PropTypes.string,
  groupId: PropTypes.string,
  groupName: PropTypes.string,
  label: PropTypes.string,
  isAssignedToSelf: PropTypes.bool,
})

function fromTicket(ticket, agentsById, groupsById, currentUser) {
  const { assignee, assigned_group_id: groupId } = ticket
  const { id: agentId } = assignee || {}
  const group = groupsById && groupId ? groupsById[groupId] : undefined
  const agent = agentsById[agentId]

  return build(agent, group, currentUser, agentsById)
}

const agentMemoization = memoize(agent => agent)

function build(agent, group, currentUser, agentsById) {
  if (!agent && !group) return null

  let agentFullName
  let agentName = null

  if (agent) {
    agentFullName = fullName(agent)
    agentName = getAgentNameOrYou(agent, currentUser, agentsById)
  }

  const groupId = group && group.id
  const groupName = group ? group.name : undefined

  return agentMemoization({
    agentId: agent ? agent.id : undefined,
    agentName,
    agentFullName,
    groupId,
    groupName,
    label: [groupName, agentName].filter(n => n).join(': '),
    isAssignedToSelf: agent && agent.id === currentUser.id,
  })
}

export default {
  t,
  fromTicket,
  build,
}
