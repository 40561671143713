import { memoize } from 'util/memoization'
import PropTypes from 'prop-types'
import React from 'react'
import {
  VictoryArea,
  VictoryAxis,
  VictoryChart,
  VictoryLegend,
  VictoryLine,
  VictoryScatter,
  VictoryVoronoiContainer,
} from 'victory'

import grooveTheme from '../../grooveTheme'
import formatAxisValueAs from '../../util/formatAxisValueAs'
import formatDatumForTooltip from '../../util/formatDatumForTooltip'
import Tooltip from '../Tooltip'
import LegendSymbol from './LegendSymbol'

const makeFormatAxisValueAs = memoize(as => {
  return value => formatAxisValueAs(as, value)
})

function scatterSize(d, active) {
  return active && d.y !== null ? 4 : 0
}

LineChartView.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
}

LineChartView.defaultProps = {
  height: null,
  width: null,
}

export default function LineChartView({
  areaChart,
  dataSets,
  domain,
  dataSetsWithPrevious = [],
  height,
  legendDataSets,
  scale = {},
  theme = grooveTheme,
  width,
  targetRef, // from react-resize-detector (withResizeDetector)
}) {
  const AreaOrLine = areaChart ? VictoryArea : VictoryLine
  return (
    <div ref={targetRef}>
      <VictoryChart
        containerComponent={
          <VictoryVoronoiContainer
            responsive={false}
            voronoiBlacklist={dataSets.map(({ id }) => `primary-line-${id}`)}
            voronoiDimension="x"
            labels={formatDatumForTooltip}
            labelComponent={<Tooltip />}
          />
        }
        height={Math.round(height) || 300}
        theme={theme}
        scale={scale}
        width={width || 500}
        domain={domain}
      >
        {legendDataSets && (
          <VictoryLegend
            data={legendDataSets}
            dataComponent={<LegendSymbol />}
            gutter={30}
            orientation="horizontal"
            padding={0}
            x={0}
            y={0}
          />
        )}
        <VictoryAxis
          tickFormat={makeFormatAxisValueAs(scale.formatXAs || scale.x)}
        />
        <VictoryAxis
          crossAxis
          dependentAxis
          tickFormat={makeFormatAxisValueAs(scale.formatYAs || scale.y)}
        />
        {dataSetsWithPrevious.length > 0 &&
          dataSetsWithPrevious
            .map(dataSet => {
              const { color, id, previousColor, previousData } = dataSet
              if (previousData.length > 1) {
                return (
                  <VictoryLine
                    data={previousData}
                    key={id}
                    style={{
                      data: {
                        stroke: previousColor || color,
                        strokeDasharray: '4, 4',
                        strokeWidth: 2,
                      },
                    }}
                  />
                )
              } else if (previousData.length === 1) {
                return (
                  <VictoryScatter
                    data={previousData}
                    key={id}
                    name="secondary-scatter"
                    size={5}
                    style={{
                      data: {
                        fill: previousColor || color,
                      },
                    }}
                  />
                )
              }
              return null
            })
            .filter(x => !!x)}
        {dataSets.map(dataSet => {
          const { color, data, id } = dataSet
          if (!data) return []
          if (data.length > 1) {
            return [
              <AreaOrLine
                data={data}
                key={`primary-line-${id}`}
                name={`primary-line-${id}`}
                style={{
                  data: {
                    stroke: color,
                    strokeWidth: 2,
                    fill: areaChart ? color : null,
                    fillOpacity: areaChart ? 0.1 : 0,
                  },
                }}
              />,
              <VictoryScatter
                data={data}
                key={`primary-scatter-hover-${id}`}
                name={`primary-scatter-hover-${id}`}
                size={scatterSize}
                style={{
                  data: {
                    stroke: color,
                    strokeWidth: 4,
                    fill: grooveTheme.colors.white,
                    fillOpacity: 1,
                  },
                }}
              />,
            ]
          } else if (data.length === 1) {
            return (
              <VictoryScatter
                data={data}
                size={5}
                key={id}
                name="primary-scatter"
                style={{
                  data: {
                    fill: color,
                  },
                }}
              />
            )
          }
          return null
        })}
      </VictoryChart>
    </div>
  )
}
