import {
  searchStarted,
  searchSuccess,
  searchFailed,
  searchInvalidate,
  searchInvalidateEntity,
  searchUpdateCursor,
  searchAddCursorEntityIds,
  searchRemoveCursorEntityIds,
} from 'ducks/searches/utils/action'
import { isFunction } from 'util/functions'
import merge from 'deepmerge'
import extractors from './extractPagination'

export default function searchesActionModule(phase, action, payload, options) {
  const {
    searches: {
      queryId,
      cursor,
      extractPagination = 'gqlv2',
      additionalActions = [],
    } = {},
  } = options

  const extractor = isFunction(extractPagination)
    ? extractPagination
    : extractors[extractPagination]

  const searchActions = []

  additionalActions.forEach(additionalAction => {
    if (additionalAction.type === 'INVALIDATE') {
      const { queryId: invalidateQueryId, phases = [] } = additionalAction
      if (phases.includes(phase)) {
        searchActions.push(searchInvalidate(invalidateQueryId))
      }
    } else if (additionalAction.type === 'INVALIDATE_ENTITIES') {
      const { entityTypes, phases = [] } = additionalAction
      if (phases.includes(phase)) {
        searchActions.push(searchInvalidateEntity(entityTypes))
      }
    } else if (additionalAction.type === 'UPDATE_CURSOR') {
      const {
        type,
        queryId: updateQueryId,
        phases = [],
        ...updates
      } = additionalAction
      if (phases.includes(phase)) {
        searchActions.push(searchUpdateCursor(updateQueryId, updates))
      }
    } else if (
      ['ADD_CURSOR_ENTITY_IDS', 'REMOVE_CURSOR_ENTITY_IDS'].includes(
        additionalAction.type
      )
    ) {
      const {
        type,
        queryId: updateQueryId,
        entityIds = [],
        entityTypes = [],
        phases = [],
      } = additionalAction

      if (phases.includes(phase)) {
        let actionFunc

        if (type === 'ADD_CURSOR_ENTITY_IDS') {
          actionFunc = searchAddCursorEntityIds
        } else if (type === 'REMOVE_CURSOR_ENTITY_IDS') {
          actionFunc = searchRemoveCursorEntityIds
        }

        if (type === 'ADD_CURSOR_ENTITY_IDS' && !entityIds.length) {
          const { normalizedEntities } = action

          entityTypes.forEach(entityType => {
            const normalizedEntity = normalizedEntities[entityType]

            if (!normalizedEntity) {
              return
            }

            const normalizedEntityObjectKeys = Object.keys(normalizedEntity)

            normalizedEntityObjectKeys.forEach(k => entityIds.push(k))
          })
        }

        if (actionFunc) {
          searchActions.push(actionFunc(updateQueryId, entityIds))
        }
      }
    }
  })

  if (queryId) {
    if (phase === 'STARTED') {
      searchActions.push(searchStarted(queryId))
    } else if (phase === 'SUCCESS') {
      const {
        nodes,
        startCursor,
        endCursor,
        hasNextPage,
        hasPreviousPage,
        totalCount,
        totalPageCount,
      } = extractor(payload, {
        phase,
        action,
        payload,
        options,
      })
      searchActions.push(
        searchSuccess({
          queryId,
          nodes,
          currentCursor: cursor,
          startCursor,
          endCursor,
          hasNextPage,
          hasPreviousPage,
          totalCount,
          totalPageCount,
        })
      )
    } else if (phase === 'FAILED') {
      searchActions.push(searchFailed(queryId, cursor, payload))
    }
  }
  if (searchActions.length > 0) {
    Object.assign(action, merge.all([...searchActions]))
  }
  return action
}
