// Extract all the data sets from an array of gauges and concat them into a
// single array.
import createCachedSelector from 're-reselect'

import fullPropsSetCacheKey from './cacheKeys/fullPropsSetCacheKey'
import selectDataSets from './selectDataSets'
import selectDecoratedGauges from './selectDecoratedGauges'
import selectDecoratedJoinedThroughGauge from './selectDecoratedJoinedThroughGauge'

export default createCachedSelector(
  selectDataSets,
  selectDecoratedGauges,
  selectDecoratedJoinedThroughGauge,
  (state, { pagination }) => pagination,
  (dataSets, decoratedGauges, decoratedJoinedThroughGauge, pagination) => {
    const joinedDataSet = dataSets.find(
      ({ isJoinedThrough }) => isJoinedThrough
    )
    const totalCount = joinedDataSet
      ? joinedDataSet.totalCount
      : Math.max(...dataSets.map(({ totalCount: x }) => x))
    return {
      ...pagination,
      totalCount,
    }
  }
)(fullPropsSetCacheKey)
