import { countConversationsResolved } from '../queries/countConversationsResolved'

export const totalConversationsResolvedByTeamGauge = {
  id: 'resolutions_by_team',
  queries: [
    {
      ...countConversationsResolved,
      groupBy: 'conversation.assignee_team.id',
      key: 'countConversationsResolvedByTeam',
    },
  ],
  title: `Resolutions By ${app.t('Team')}`,
}
