import { createPayload } from 'optimistic/base'
import { unSnoozePayload } from 'optimistic/snooze'
import { selectRawById } from 'selectors/tickets/byId/selectRawById'
import { optimisticUpdatedAt } from 'util/optimistic'
import { toServerString } from 'util/date'

import { sortByKey } from 'util/arrays'

function buildNewActions(newStatus, userInitiated) {
  if (newStatus === 'unread') return []
  if (newStatus === 'opened' && userInitiated === false) return []
  return [
    {
      change_type: 'State',
      change: {
        state: newStatus,
        type: 'State',
      },
    },
  ]
}

// builds a mock GraphQL response of a createChangeset mutation for a
// ticket state change
export const statusChangePayload = (
  state,
  ticketId,
  newStatus,
  folderId,
  search,
  options = {}
) => {
  const ticket = selectRawById(state)[ticketId] || {}
  const { userInitiated, changesetId } = options
  const updatedAt = options.updatedAt || toServerString(new Date())

  if (userInitiated && ticket.snoozedUntil) {
    return unSnoozePayload(state, ticketId, search, updatedAt)
  }

  const newProperties = { state: newStatus }
  if (userInitiated !== false) newProperties.updated_at = updatedAt

  return createPayload(
    state,
    ticketId,
    'status',
    newProperties,
    buildNewActions(newStatus, userInitiated),
    folderId,
    search,
    changesetId
  )
}

// Returns the optimistic data for a bulk starring action.
export const bulkStatusChangePayload = (
  state,
  ticketIds,
  newState,
  folderId,
  search,
  changesetId
) =>
  sortByKey(
    ticketIds.map(id => {
      return statusChangePayload(state, id, newState, folderId, search, {
        updatedAt: optimisticUpdatedAt(),
        changesetId,
      })
    }),
    'id'
  )
