import { ratio } from '../calculators/ratio'
import { countMessageRatingsCreated } from '../queries/countMessageRatingsCreated'
import { countMessageRatingAwesome } from '../queries/countMessageRatingAwesome'

export const happinessScoreRatioGauge = {
  calculate: ratio,
  description: 'The percentage of ratings graded "awesome".',
  queries: [countMessageRatingAwesome, countMessageRatingsCreated],
  scale: { y: 'percent' },
  title: 'CSAT score',
}
