import React from 'react'

import AverageFirstResponseTimeForEachDay from './AverageFirstResponseTimeForEachDay'
import AverageRepliesForEachDay from './AverageRepliesForEachDay'
import AverageResolutionTimeForEachDay from './AverageResolutionTimeForEachDay'

export default function ProductivityOverviewHeadlineLineView({
  activeGauge,
  queryBase,
  resolutionsQueryBase,
}) {
  return (
    <div>
      {(activeGauge === 'avg-resolution-time' || !activeGauge) && (
        <AverageResolutionTimeForEachDay
          queryBase={queryBase}
          resolutionsQueryBase={resolutionsQueryBase}
          includePreviousPeriod
        />
      )}
      {activeGauge === 'avg-first-response-time' && (
        <AverageFirstResponseTimeForEachDay
          queryBase={queryBase}
          resolutionsQueryBase={resolutionsQueryBase}
          includePreviousPeriod
        />
      )}
      {activeGauge === 'avg-replies-per-resolution' && (
        <AverageRepliesForEachDay
          queryBase={queryBase}
          resolutionsQueryBase={resolutionsQueryBase}
          includePreviousPeriod
        />
      )}
    </div>
  )
}
