/* eslint-disable react/forbid-prop-types */
import React from 'react'
import PropTypes from 'prop-types'

import { isFunction } from 'util/functions'
import { Table, Grid } from 'util/ui'
import { Header, SubHeader } from 'shared/components/ui/Typography'
import uuid from 'util/uuid'

import TableHeader from './TableHeader'
import TableFooter from './TableFooter'
import TableCell from './TableCell'
import TableFilter from './TableFilter'
import styles from './styles.less'

export default class TableView extends React.PureComponent {
  static propTypes = {
    columns: PropTypes.array.isRequired,
    title: PropTypes.string.isRequired,
    queryBase: PropTypes.object,
    key: PropTypes.string,
    dataPagination: PropTypes.object,
    filter: PropTypes.object,
    sort: PropTypes.object,
    paging: PropTypes.object.isRequired,
    tableData: PropTypes.array,
  }

  static defaultProps = {
    key: uuid(),
    dataPagination: null,
    filter: {},
    sort: {},
    queryBase: {},
    paging: { visible: false },
    tableData: [],
  }

  constructor(...args) {
    super(...args)
    this.handlePagesizeChange = this.handlePagesizeChange.bind(this)
    this.handlePageChange = this.handlePageChange.bind(this)
  }

  handlePagesizeChange(evt, data) {
    const { paging } = this.props
    if (paging && isFunction(paging.onPageSizeChange)) {
      return paging.onPageSizeChange(evt, data.value, this.props)
    }
    return {}
  }

  handlePageChange(evt, data) {
    const {
      dataPagination: { current: currentPage },
      paging,
    } = this.props

    if (paging && isFunction(paging.onPageChange)) {
      let page = data.activePage
      if (page === '-') {
        page = currentPage - 1
      } else if (page === '+') {
        page = currentPage + 1
      }
      return paging.onPageChange(evt, page, this.props)
    }
    return {}
  }

  render() {
    const { columns, filter, paging, subtitle, tableData, title } = this.props
    const { key, ...noKeyProps } = this.props
    const showFilter = filter && filter.options
    const headerColumns = showFilter ? 2 : 1
    const data = tableData
    return (
      <Grid>
        <Grid.Row columns={headerColumns}>
          <Grid.Column>
            <Header as="h3" className={styles.TableView_Header} size="small">
              {title}
            </Header>
            {subtitle && (
              <SubHeader
                as="h4"
                className={styles.TableView_SubHeader}
                size="small"
              >
                {subtitle}
              </SubHeader>
            )}
          </Grid.Column>
          {showFilter && (
            <Grid.Column textAlign="right">
              <TableFilter {...noKeyProps} />
            </Grid.Column>
          )}
        </Grid.Row>
        <Grid.Row columns={1}>
          <Grid.Column>
            <Table sortable selectable>
              <TableHeader {...noKeyProps} />
              <Table.Body>
                {data.length > 0 &&
                  data.map((row, rowIndex) => {
                    const rowKey = `${key}-body-row-${rowIndex}`
                    const tableColumns = columns.map(column => (
                      <TableCell
                        key={`${rowKey}-column-${column.header}`}
                        column={column}
                        row={row}
                        data={data}
                      />
                    ))
                    return <Table.Row key={rowKey}>{tableColumns}</Table.Row>
                  })}
                {!data.length && (
                  <Table.Row
                    className={styles.noData}
                    key={`${key}-body-row-empty`}
                  >
                    <Table.Cell colSpan={columns.length} textAlign="center">
                      No data to show
                    </Table.Cell>
                  </Table.Row>
                )}
              </Table.Body>
              {paging &&
                paging.visible && (
                  <TableFooter
                    {...noKeyProps}
                    onPageSizeChange={this.handlePagesizeChange}
                    onPageChange={this.handlePageChange}
                  />
                )}
            </Table>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    )
  }
}
