import { memoize } from 'util/memoization'

export default memoize(applyXValuesFilterToQuery)

function applyXValuesFilterToQuery(query, xValues) {
  const { groupBy: individualGroupBy } = query
  return {
    ...query,
    filters: xValues
      ? [
          ...query.filters,
          {
            propertyName: individualGroupBy,
            operator: 'in',
            propertyValue: xValues,
          },
        ]
      : query.filters,
  }
}
