import { averageFirstResponseTimeGauge } from 'ducks/reports/gauges'
import { HeadlineVisualization } from 'ducks/reports/visualizations'

const staticProps = {
  gauges: [averageFirstResponseTimeGauge],
  scale: { y: 'duration' },
  title: '$Avg. First Response Time',
}

export default function AverageFirstResponseTime(props) {
  return <HeadlineVisualization {...props} {...staticProps} />
}
