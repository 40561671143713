export function ratio(gauge) {
  if (Array.isArray(gauge)) {
    if (gauge[1] === 0) return null
    return (gauge[0] || 0) / gauge[1]
  }
  const { loaded, queries } = gauge
  if (!loaded) return null
  const topQuery = queries[0]
  const bottomQuery = queries[1]
  const top = topQuery.result
  const bottom = bottomQuery.result
  if (Array.isArray(top)) {
    return top.map(individualTop => {
      const individualBottom = bottom.find(x => {
        return x[bottomQuery.groupBy] === individualTop[topQuery.groupBy]
      })
      const calculatedValue =
        individualBottom === 0
          ? null
          : individualTop.result / individualBottom.result
      return {
        [topQuery.groupBy]: individualTop[topQuery.groupBy],
        result: calculatedValue,
      }
    })
  }
  if (bottom === 0) return null
  return top / bottom
}
