import React from 'react'

import { Grid } from 'util/ui'

import styles from './styles.less'
import OverviewRow from './OverviewRow'
import OverviewColumn from './OverviewColumn'
import OverviewCell from './OverviewCell'

const Overview = ({ title, main, children }) => {
  return (
    <Grid stackable celled padded className={styles.overviewContainer}>
      <Grid.Row columns={1}>
        <h1 className={styles.reportHeader}>{title}</h1>
      </Grid.Row>
      {main && (
        <Grid.Row columns={1} className={`${styles.chartContainer}`}>
          {main}
        </Grid.Row>
      )}
      {children}
    </Grid>
  )
}

Overview.Row = OverviewRow
Overview.Column = OverviewColumn
Overview.Cell = OverviewCell

export default Overview
