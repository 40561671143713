import createCachedSelector from 're-reselect'

import { selectAreBusinessHoursEnabled } from 'ducks/reports/controls'
import { createArraySelector } from 'util/selectors'

import queriesCacheKey from './cacheKeys/queriesCacheKey'
import selectDecoratedQuery from './selectDecoratedQuery'

const selectDecoratedQueries = createCachedSelector(
  selectAreBusinessHoursEnabled,
  state => state,
  (state, props) => props,
  (areBusinessHoursEnabled, state, props) => {
    const {
      gauge,
      gauge: { queries: gaugeQueries, businessHoursQueries = gaugeQueries },
      queries = areBusinessHoursEnabled ? businessHoursQueries : gaugeQueries,
      ...rest
    } = props
    const r = queries.map(query =>
      selectDecoratedQuery(state, { ...rest, gauge, query })
    )
    return r
  }
)(queriesCacheKey)

// This second layer of cached selector with createArraySelector, ensures the
// same array is returned if every item in the array is the same.
export default createCachedSelector(selectDecoratedQueries, gauges => {
  return gauges
})(queriesCacheKey, { selectorCreator: createArraySelector })
