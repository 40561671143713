import { Popup } from 'util/ui'
import { formatNumber } from 'util/numbers'

import formatPercent from './formatPercent'

export default function formatPercentTotal({ filledY, sum }) {
  return (
    <div>
      {formatNumber(filledY)}
      <Popup
        content={`percentage of total: ${sum}`}
        inverted
        position="bottom center"
        on="hover"
        trigger={
          <span style={{ color: 'gray' }}>
            {' ('}
            {formatPercent({ filledY: filledY / sum })}
            {')'}
          </span>
        }
      />
    </div>
  )
}
