import { useCallback, useMemo, useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  selectCurrentPage,
  selectQueryParams,
  selectCurrentPayload,
  selectQueryParamsQueryId,
} from 'selectors/location'
import {
  queryIdToQuery,
  targetQuery,
  clearTargetQuery,
} from 'ducks/searches/utils/query'
import { searchInvalidateEntity } from 'ducks/searches/utils/action'
import {
  selectSearchByQueryId,
  selectSearchEntitiesByQueryIdAndCursor,
  selectSearchEntitiesByQueryId,
  selectSearchEntitiesDenormalizedByQueryId,
  selectSearchEntitiesDenormalizedByQueryIdAndCursor,
} from 'ducks/searches/selectors'
import { selectIsPolling } from 'ducks/batchJobs/selectors'
import { debounce, isFunction } from 'util/functions'
import { setKind } from 'redux-first-router'
import useDeepCompareEffect from 'util/hooks/useDeepCompareEffect'
import usePrevious from 'util/hooks/usePrevious'
import { diff } from 'deep-object-diff'
import storage from 'util/storage'
import { all, uniq } from 'util/arrays'
import isDeepEqual from 'fast-deep-equal'

import {
  selectTableQueryById,
  selectTableScrollPositionById,
} from './selectors'
import { TABLE_CACHE_INVALIDATE } from './actionTypes'

const DEFAULT_SELECTED_IDS = []
const DEFAULT_SELECTION_MODE = 'ids'

function defaultSortTransform(columnId, desc) {
  return {
    id: columnId,
    desc,
  }
}

function lowercaseSortTransform(columnId, desc) {
  return {
    id: columnId.toLowerCase(),
    desc,
  }
}

function saveLocalStorage(tableId, key, value) {
  const current = storage.get(`dt-${tableId}`)
  storage.set(`dt-${tableId}`, {
    ...current,
    [key]: value,
  })
}

const buildDataTablePageAction = ({
  targetId,
  componentQuery,
  kind,
  storeQueryKeys = [],
  scrollPosition = 0,
}) => (dispatch, getState) => {
  const state = getState()
  const pageType = selectCurrentPage(state)
  const pagePayload = selectCurrentPayload(state)
  const currentPageQuery = selectQueryParams(state)

  const pageAction = {
    type: pageType,
    payload: pagePayload,
    meta: {
      query: {
        ...clearTargetQuery(targetId, currentPageQuery),
        ...targetQuery(targetId, componentQuery),
      },
    },
  }
  if (storeQueryKeys.length > 0) {
    pageAction.tables = {
      tableId: targetId,
      storeQueryKeys: uniq(storeQueryKeys),
      scrollPosition,
    }
  }

  return dispatch(kind ? setKind(pageAction, kind) : pageAction)
}

function isLoadableQueryId(queryId, { parseToIntKeys }) {
  const query = queryIdToQuery(queryId, { parseToIntKeys })

  if (!query) return false

  const queryKeys = Object.keys(query)
  return all(
    key => {
      return queryKeys.includes(key)
    },
    ['pageSize', 'entityType', 'cursor']
  )
}

export function useDataTable(
  id,
  entityType,
  loader,
  {
    pagination: {
      defaultPageSize: inputDefaultPageSize = 20,
      defaultCursor = null,
      defaultOrderBy = null,
      showSizeChanger = true,
      pageSizeOptions = [10, 20, 30],
      showLessItems = true,
      filterDelay = 1000,
      parseToIntKeys = [],
      mode: paginationMode = 'number',
    } = {},
    filtering: { baseFilters: inputBaseFilters = {} } = {},
    sorting: { transformSort = null } = {},
    autoLoad = true,
    scroll: {
      resetScrollOnPageChange = true,
      persistScrollPosition = false,
      onScroll,
    } = {},
  }
) {
  const dispatch = useDispatch()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const dataTableLocalStorage = useMemo(() => storage.get(`dt-${id}`) || {}, [])
  const defaultPageSize = useMemo(
    () =>
      (showSizeChanger
        ? dataTableLocalStorage.pageSize
        : inputDefaultPageSize) || inputDefaultPageSize,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [showSizeChanger]
  )

  // NOTE: if you:
  // -- change the useState default values in below section
  // -- add/remove the useState hooks below
  // make sure to mirror those changes in performCleanup()
  const [selectedIds, setSelectedIds] = useState(DEFAULT_SELECTED_IDS)
  const [selectionMode, setSelectionMode] = useState(DEFAULT_SELECTION_MODE)
  const [baseFilters, setBaseFilters] = useState(inputBaseFilters)
  const previousInputBaseFilters = usePrevious(inputBaseFilters)
  const previousId = usePrevious(id)

  const isPollingBatchJobs = useSelector(selectIsPolling)
  const persistedQuery = useSelector(state => selectTableQueryById(state, id))
  const persistedQueryRef = useRef(persistedQuery)
  const scrollPosition = useRef(0)

  const queryId = useSelector(state =>
    selectQueryParamsQueryId(state, { targetId: id })
  )
  const previousQueryId = usePrevious(queryId)
  const isEntryPoint = useRef(!isLoadableQueryId(queryId, { parseToIntKeys }))
  const persistedScrollPosition = useSelector(state =>
    selectTableScrollPositionById(state, id)
  )
  const [initialScrollPosition, setInitialScrollPosition] = useState(
    persistedScrollPosition
  )
  // END NOTE

  const baseStoreQueryKeys = useMemo(
    () =>
      [
        'cursor',
        'pageSize',
        'orderBy',
        persistScrollPosition && 'scroll',
      ].filter(f => !!f),
    [persistScrollPosition]
  )

  const baseQuery = useMemo(
    () => ({
      pageSize: defaultPageSize,
      cursor: defaultCursor,
      orderBy: defaultOrderBy || undefined,
      entityType,
      ...baseFilters,
      ...queryIdToQuery(queryId, { parseToIntKeys }),
      ...(isEntryPoint.current && queryId === ''
        ? persistedQueryRef.current
        : {}),
    }),
    [
      queryId,
      entityType,
      defaultCursor,
      defaultPageSize,
      defaultOrderBy,
      parseToIntKeys,
      baseFilters,
    ]
  )

  const { pageSize, orderBy } = baseQuery

  const {
    totalCount,
    totalPages,
    loading = null,
    loaded = null,
    errored,
    cursors = {},
  } = useSelector(state => selectSearchByQueryId(state, queryId))

  const currentPageCursor = baseQuery.cursor
  const hasCurrentPage = !!cursors[currentPageCursor]
  const {
    next: nextPageCursor,
    previous: previousPageCursor,
    hasNextPage,
    hasPreviousPage,
    isStale,
  } =
    cursors[currentPageCursor] || {}

  const loadedEntities = useSelector(
    state => selectSearchEntitiesByQueryId(state, queryId),
    isDeepEqual
  )
  const currentPageEntities = useSelector(
    state =>
      selectSearchEntitiesByQueryIdAndCursor(state, queryId, currentPageCursor),
    isDeepEqual
  )

  const loadedDenormalizedEntities = useSelector(
    state => selectSearchEntitiesDenormalizedByQueryId(state, queryId),
    isDeepEqual
  )
  const currentPageDenormalizedEntities = useSelector(
    state =>
      selectSearchEntitiesDenormalizedByQueryIdAndCursor(
        state,
        queryId,
        currentPageCursor
      ),
    isDeepEqual
  )

  const load = useCallback(
    () => {
      if (isEntryPoint.current) {
        const firstPageQuery = {
          ...baseQuery,
          cursor: currentPageCursor,
        }
        isEntryPoint.current = false
        dispatch(
          buildDataTablePageAction({
            targetId: id,
            componentQuery: firstPageQuery,
            kind: 'redirect',
            scrollPosition: scrollPosition.current,
            storeQueryKeys: Object.keys(firstPageQuery),
          })
        )
      } else if (queryId) {
        dispatch(
          loader({
            queryId,
            size: pageSize,
            cursor: currentPageCursor,
            orderBy,
          })
        )
      }
    },
    [
      dispatch,
      id,
      baseQuery,
      loader,
      queryId,
      pageSize,
      currentPageCursor,
      orderBy,
      isEntryPoint,
    ]
  )

  const gotoPage = useCallback(
    (gotoCursor, { persist = true } = {}) => {
      const gotoPageQuery = {
        ...baseQuery,
        cursor: gotoCursor,
      }
      const storeQueryKeys = persist ? baseStoreQueryKeys : []
      dispatch(
        buildDataTablePageAction({
          targetId: id,
          componentQuery: gotoPageQuery,
          storeQueryKeys,
          scrollPosition: 0,
        })
      )
    },
    [dispatch, id, baseQuery, baseStoreQueryKeys]
  )

  const nextPage = useCallback(
    options => {
      gotoPage(nextPageCursor, options)
    },
    [gotoPage, nextPageCursor]
  )

  const previousPage = useCallback(
    options => {
      gotoPage(previousPageCursor, options)
    },
    [gotoPage, previousPageCursor]
  )

  const changeSort = useCallback(
    (oBy, { persist = true, resetPage = true } = {}) => {
      const orderByQuery = {
        ...baseQuery,
        orderBy: oBy || undefined,
      }
      const storeQueryKeys = persist ? baseStoreQueryKeys : []
      if (resetPage) {
        orderByQuery.cursor = defaultCursor
      }
      dispatch(
        buildDataTablePageAction({
          targetId: id,
          componentQuery: orderByQuery,
          storeQueryKeys,
          scrollPosition: scrollPosition.current,
        })
      )
    },
    [dispatch, id, baseQuery, baseStoreQueryKeys, defaultCursor]
  )

  const changePageSize = useCallback(
    (pSize, { resetPage = true, persist = true } = {}) => {
      const pageSizeQuery = {
        ...baseQuery,
        pageSize: pSize,
      }
      if (resetPage) {
        pageSizeQuery.cursor = defaultCursor
      }
      saveLocalStorage(id, 'pageSize', pSize)
      const storeQueryKeys = persist ? baseStoreQueryKeys : []
      dispatch(
        buildDataTablePageAction({
          targetId: id,
          componentQuery: pageSizeQuery,
          storeQueryKeys,
          scrollPosition: scrollPosition.current,
        })
      )
    },
    [dispatch, id, baseQuery, defaultCursor, baseStoreQueryKeys]
  )

  const changeFilterInternal = useCallback(
    (fType, fBy, { resetPage, kind, persist } = {}) => {
      const filterByQuery = { ...baseQuery }
      if (fBy === null) {
        delete filterByQuery[fType]
      } else {
        filterByQuery[fType] = fBy
      }
      if (resetPage) {
        filterByQuery.cursor = defaultCursor
      }
      const storeQueryKeys = persist ? [...baseStoreQueryKeys, fType] : []
      dispatch(
        buildDataTablePageAction({
          targetId: id,
          componentQuery: filterByQuery,
          kind,
          storeQueryKeys,
          scrollPosition: scrollPosition.current,
        })
      )
    },
    [dispatch, id, baseQuery, defaultCursor, baseStoreQueryKeys]
  )

  const changeFilterDelayed = useMemo(
    () => {
      return debounce(changeFilterInternal, filterDelay)
    },
    [changeFilterInternal, filterDelay]
  )

  const changeFilter = useCallback(
    (...args) => {
      changeFilterDelayed.cancel()
      return changeFilterInternal(...args)
    },
    [changeFilterDelayed, changeFilterInternal]
  )

  const changeFilters = useCallback(
    (changes, { resetPage, kind, persist } = {}) => {
      const filterByQuery = { ...baseQuery }
      const storeQueryKeys = [...baseStoreQueryKeys]
      changes.forEach(({ key, value }) => {
        if (value === null) {
          delete filterByQuery[key]
        } else {
          filterByQuery[key] = value
          if (persist) storeQueryKeys.push(key)
        }
      })
      if (resetPage) {
        filterByQuery.cursor = defaultCursor
        storeQueryKeys.push('cursor')
      }
      dispatch(
        buildDataTablePageAction({
          targetId: id,
          componentQuery: filterByQuery,
          kind,
          storeQueryKeys,
          scrollPosition: scrollPosition.current,
        })
      )
    },
    [dispatch, id, baseQuery, defaultCursor, baseStoreQueryKeys]
  )

  const handleOnSelectedRowIdsChange = useCallback(
    selection => {
      // Selection passed in as a object with an id true false map
      // eg {id1: true, id2: false, id3: true}
      const ids = Object.keys(selection).filter(rowId => selection[rowId])
      setSelectedIds(ids)
    },
    [setSelectedIds]
  )

  const filtering = useMemo(
    () => ({
      changeFilter,
      changeFilters,
      changeFilterDelayed,
    }),
    [changeFilter, changeFilters, changeFilterDelayed]
  )

  const pagination = useMemo(
    () => ({
      total: totalCount,
      current: baseQuery.cursor,
      previous: previousPageCursor,
      next: nextPageCursor,
      pageSize: baseQuery.pageSize,
      pageTotal: totalPages,
      showSizeChanger,
      pageSizeOptions,
      showLessItems,
      defaultPageSize,
      gotoPage,
      changePageSize,
      server: true,
      nextPage,
      previousPage,
      currentEntities: currentPageEntities,
      currentDenormalizedEntities: currentPageDenormalizedEntities,
      hasNextPage,
      hasPreviousPage,
      simple: paginationMode === 'cursor',
    }),
    [
      baseQuery,
      totalCount,
      totalPages,
      defaultPageSize,
      showSizeChanger,
      pageSizeOptions,
      showLessItems,
      gotoPage,
      nextPage,
      previousPage,
      changePageSize,
      previousPageCursor,
      nextPageCursor,
      currentPageEntities,
      currentPageDenormalizedEntities,
      hasNextPage,
      hasPreviousPage,
      paginationMode,
    ]
  )

  const defaultSortBy = useMemo(() => {
    return (orderBy || '')
      .split('-')
      .map(oBy => {
        if (oBy.length === 0) return null
        // The regex approach ensures that we split by the last occurence of _
        // eslint-disable-next-line no-unused-vars
        const [_, columnId, direction] = oBy.match(/(.*)_(.*)/)
        let sortTransformer = defaultSortTransform
        if (isFunction(transformSort)) {
          sortTransformer = transformSort
        } else if (transformSort === 'lowercase') {
          sortTransformer = lowercaseSortTransform
        }

        return sortTransformer(columnId, direction === 'DESC')
      })
      .filter(sb => !!sb)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const sorting = useMemo(
    () => ({
      changeSort,
      defaultSortBy,
    }),
    [changeSort, defaultSortBy]
  )

  const handleSaveScrollPosition = useCallback(
    () => {
      dispatch(
        buildDataTablePageAction({
          targetId: id,
          componentQuery: baseQuery,
          kind: 'redirect',
          scrollPosition: scrollPosition.current,
          storeQueryKeys: ['scroll'],
        })
      )
    },
    [dispatch, id, baseQuery]
  )

  const handleSaveScrollPositionDebounced = useMemo(
    () => debounce(handleSaveScrollPosition, 50),
    [handleSaveScrollPosition]
  )

  const previousHandleSaveScrollPositionDebounced = usePrevious(
    handleSaveScrollPositionDebounced
  )

  const performCleanup = useCallback(
    tableId => {
      if (previousHandleSaveScrollPositionDebounced) {
        previousHandleSaveScrollPositionDebounced.cancel()
      }

      // NOTE: if you change any of the values here, make sure to change it in the initialization above
      setSelectedIds(DEFAULT_SELECTED_IDS)
      setSelectionMode(DEFAULT_SELECTION_MODE)
      setBaseFilters(inputBaseFilters)
      setInitialScrollPosition(persistScrollPosition)

      dispatch(
        buildDataTablePageAction({
          targetId: tableId,
          componentQuery: {},
          kind: 'redirect',
          scrollPosition: scrollPosition.current,
        })
      )
    },
    [
      previousHandleSaveScrollPositionDebounced,
      dispatch,
      inputBaseFilters,
      persistScrollPosition,
    ]
  )

  useEffect(
    () => {
      if (previousHandleSaveScrollPositionDebounced?.cancel) {
        previousHandleSaveScrollPositionDebounced.cancel()
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [baseQuery]
  )

  const handleOnScroll = useCallback(
    e => {
      scrollPosition.current = e.target.scrollTop
      if (persistScrollPosition) {
        handleSaveScrollPositionDebounced()
      }
      if (onScroll) onScroll(e)
    },
    [onScroll, handleSaveScrollPositionDebounced, persistScrollPosition]
  )

  const scroll = useMemo(
    () => ({
      resetScrollOnPageChange,
      onScroll: handleOnScroll,
      initialScrollPosition,
    }),
    [resetScrollOnPageChange, handleOnScroll, initialScrollPosition]
  )

  const selection = useMemo(
    () => ({
      ids: selectedIds,
      mode: selectionMode,
      handleOnSelectedRowIdsChange,
      handleOnSelectedModeChange: setSelectionMode,
    }),
    [selectedIds, selectionMode, handleOnSelectedRowIdsChange]
  )

  const shouldLoad =
    !loading && !errored && (!loaded || !hasCurrentPage || isStale)

  useEffect(
    () => {
      if (autoLoad && shouldLoad) {
        load()
      }
    },
    [load, shouldLoad, autoLoad]
  )

  const wasPollingBatchJobs = usePrevious(isPollingBatchJobs)
  useEffect(
    () => {
      if (isPollingBatchJobs) return
      // When we stop polling for changes, it means that no batch operations are active anymore.
      // At this point we should clear the entity cache for the current table to force the data to reload

      if (wasPollingBatchJobs) {
        dispatch({
          type: TABLE_CACHE_INVALIDATE,
          ...searchInvalidateEntity(entityType),
        })
      }
    },
    [dispatch, entityType, isPollingBatchJobs, wasPollingBatchJobs]
  )

  useEffect(
    // Not a typo. Returning a function from useEffect allows you to do
    // cleanup similar to the componentWillUnmount step. We're using this
    // to remove any table parameters from the url
    () => () => {
      performCleanup(id)
      isEntryPoint.current = true
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  useEffect(
    () => () => {
      if (previousId && id && id !== previousId) {
        performCleanup(previousId)
        isEntryPoint.current = true
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id, previousId]
  )

  useEffect(
    () => {
      // Ensure that the table content is showing when clicking on the current page multiple times.
      if (previousQueryId && !queryId) {
        performCleanup(id)
        isEntryPoint.current = true
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [previousQueryId, queryId, id]
  )

  useDeepCompareEffect(
    () => {
      const filterDiff = diff(previousInputBaseFilters, inputBaseFilters)
      const changes = Object.keys(filterDiff).map(k => ({
        key: k,
        value: filterDiff[k],
      }))
      if (changes.length > 0) {
        changeFilters(changes, { resetPage: true, kind: 'redirect' })
      }
    },
    [inputBaseFilters]
  )

  return {
    load,
    totalPages,
    totalCount,
    loadedEntities,
    loadedDenormalizedEntities,
    isLoading: loading,
    isLoaded: loaded,
    shouldLoad,
    isError: errored,
    pagination,
    sorting,
    filtering,
    selection,
    query: baseQuery,
    queryId,
    scroll,
  }
}
