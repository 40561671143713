import { createSelector } from 'reselect'

import {
  selectArticleViewsPerDayGauge,
  selectConversationsCreatedForEachDayGauge,
  selectConversationsResolvedForEachDayGauge,
  selectCustomersForEachDayGauge,
  selectHappinessAwesomeForEachDayGauge,
  selectHappinessBadForEachDayGauge,
  selectHappinessOkForEachDayGauge,
  selectHappinessScoreRatioByAgentGauge,
  selectHappinessScoreRatioByTeamGauge,
  selectTotalConversationsAssignedByAgentGauge,
  selectTotalConversationsAssignedByTeamGauge,
  selectTotalConversationsResolvedByAgentGauge,
  selectTotalConversationsResolvedByTeamGauge,
  selectTotalMessagesSentByAgentGauge,
  selectUniquePageVisitsPerDayGauge,
} from 'ducks/reports/gauges'

import { CONVERSATIONS_PAGE } from './pages'

export const selectGaugesForConversationCustomerAndResolutionsForEachDay = createSelector(
  selectConversationsCreatedForEachDayGauge,
  selectCustomersForEachDayGauge,
  selectConversationsResolvedForEachDayGauge,
  (
    conversationsCreatedForEachDayGauge,
    customersForEachDayGauge,
    conversationsResolvedForEachDayGauge
  ) => {
    return [
      conversationsCreatedForEachDayGauge,
      customersForEachDayGauge,
      conversationsResolvedForEachDayGauge,
    ]
  }
)

export const selectGaugesForHappinessForEachDay = createSelector(
  selectHappinessAwesomeForEachDayGauge,
  selectHappinessOkForEachDayGauge,
  selectHappinessBadForEachDayGauge,
  (
    happinessAwesomeRatingForEachDayGauge,
    happinessOkRatingForEachDayGauge,
    happinessBadRatingForEachDayGauge
  ) => {
    return [
      happinessAwesomeRatingForEachDayGauge,
      happinessOkRatingForEachDayGauge,
      happinessBadRatingForEachDayGauge,
    ]
  }
)

export const selectAgentOverview = createSelector(
  selectTotalConversationsAssignedByAgentGauge,
  selectTotalConversationsResolvedByAgentGauge,
  selectHappinessScoreRatioByAgentGauge,
  selectTotalMessagesSentByAgentGauge,
  (
    totalConversationsAssignedByAgentGauge,
    totalConversationsResolvedByAgentGauge,
    happinessScoreRatioByAgentGauge,
    totalMessagesSentByAgentGauge
  ) => {
    return [
      totalConversationsAssignedByAgentGauge,
      totalConversationsResolvedByAgentGauge,
      happinessScoreRatioByAgentGauge,
      totalMessagesSentByAgentGauge,
    ]
  }
)

export const selectTeamOverview = createSelector(
  selectTotalConversationsAssignedByTeamGauge,
  selectTotalConversationsResolvedByTeamGauge,
  selectHappinessScoreRatioByTeamGauge,
  (
    totalConversationsAssignedByTeamGauge,
    totalConversationsResolvedByTeamGauge,
    happinessScoreRatioByTeamGauge
  ) => {
    return [
      totalConversationsAssignedByTeamGauge,
      totalConversationsResolvedByTeamGauge,
      happinessScoreRatioByTeamGauge,
    ]
  }
)

export const selectArticlePerformance = createSelector(
  selectArticleViewsPerDayGauge,
  selectUniquePageVisitsPerDayGauge,
  (articleViewsPerDayGauge, uniquePageVisitsPerDayGauge) => {
    return [articleViewsPerDayGauge, uniquePageVisitsPerDayGauge]
  }
)

export const selectIsOnConversationsPage = state =>
  state.ducksPage.page === CONVERSATIONS_PAGE
