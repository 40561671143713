/* eslint-disable no-param-reassign */
import snakecase from 'lodash.snakecase'
import { snakecaseKeys } from 'util/objects'

export function queryObjectToQueryIdObject(query) {
  // @TODO really should white list rather than black list this
  const {
    _id,
    businessHours,
    count,
    errored,
    error,
    isPrevious,
    join,
    joinedThrough,
    loadable,
    loaded,
    loader,
    loading,
    pages,
    result,
    retries,
    retry,
    scope,
    sorted,
    status,
    total,
    totalCount,
    ...rest
  } = query
  const { key, sort, pagination, ...restForQuery } = rest
  return loader ? rest : restForQuery
}
export function queryObjectToQueryId(query) {
  const { id, ...rest } = query
  if (id) return id
  return JSON.stringify(queryObjectToQueryIdObject(rest))
}

const QUERY_KEYS = [
  'agentId',
  'analysisType',
  'businessHours',
  'eventCollection',
  'filters',
  'grade',
  'groupId',
  'groupBy',
  'key',
  'interval',
  'page',
  'per_page',
  'targetProperty',
  'timeframe',
  'timezone',
]
export function queryObjectToRequestQueryObject(query) {
  return QUERY_KEYS.reduce((result, key) => {
    let value = query[key]

    if (key === 'groupBy' && Array.isArray(value)) {
      value = value.join(',')
    } else if (Array.isArray(value)) {
      value = value.map(x => {
        if (typeof x === 'object') return snakecaseKeys(x)
        return x
      })
      value = JSON.stringify(value)
    } else if (value !== null && typeof value === 'object') {
      value = Object.keys(value).reduce((result2, key2) => {
        result2[snakecase(key2)] = value[key2]
        return result2
      }, {})
      value = JSON.stringify(value)
    }

    if (value !== null && value !== undefined) {
      result[snakecase(key)] = value
    }

    if (key === 'analysisType' && value === 'average') {
      const { average } = query
      const percentiles = average && average.match(/^percentile_(\d+)$/)
      if (average === 'median') {
        result.analysis_type = 'median'
      } else if (percentiles) {
        result.analysis_type = 'percentile'
        result.percentile = percentiles[1]
      }
    }

    return result
  }, {})
}
