import { countConversationsAssigned } from '../queries/countConversationsAssigned'

export const totalConversationsAssignedByTeamGauge = {
  id: 'conversations_by_team',
  queries: [
    {
      ...countConversationsAssigned,
      groupBy: 'conversation.assignee_team.id',
      key: 'countConversationsAssignedByTeam',
    },
  ],
  title: `Conversations By ${app.t('Team')}`,
}
