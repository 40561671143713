import { replaceAverage } from 'ducks/reports/visualizations'
import { Dropdown } from 'util/ui'

import styles from './styles.less'

export default function HeadlineChartSelectorView({
  activeGauge,
  average,
  doActivateGauge,
}) {
  let title
  if (activeGauge === 'avg-first-response-time')
    title = '$Average First Response Time'
  else if (activeGauge === 'avg-replies-per-resolution')
    title = '$Average Replies Per Resolution'
  else title = '$Average Resolution Time'
  return (
    <Dropdown
      inline
      color="black"
      text={replaceAverage(title, average)}
      className={styles.chartSelector}
      icon="caret down"
    >
      <Dropdown.Menu>
        <Dropdown.Item
          text={replaceAverage('$Average Resolution Time', average)}
          value="avg-resolution-time"
          onClick={doActivateGauge}
        />
        <Dropdown.Item
          text={replaceAverage('$Average First Response Time', average)}
          value="avg-first-response-time"
          onClick={doActivateGauge}
        />
        <Dropdown.Item
          text={replaceAverage('$Average Replies Per Resolution', average)}
          value="avg-replies-per-resolution"
          onClick={doActivateGauge}
        />
      </Dropdown.Menu>
    </Dropdown>
  )
}
