import cn from 'classnames'
import React from 'react'

import { Header, SubHeader } from 'shared/components/ui/Typography'
import { Popup, Table } from 'util/ui'

import BusiestCellAsString from './BusiestCellAsString'
import collapseData, { DAYS, TIMES } from './collapseData'
import Legend from './Legend'
import styles from './styles.less'
import TooltipContent from './TooltipContent'

// TODO post upgrate to React 16.6 change to React.memo(function BusiestTimeView(...
export default class BusiestTimeView extends React.PureComponent {
  render() {
    const {
      dataSets,
      loaded,
      measuring,
      measuringSingle,
      quarterHours,
      queryBase: { timeframe } = {},
      timezoneOffset,
    } = this.props
    const {
      busiestCell,
      busiestCellCount,
      dataByCell,
      sum,
      totalDaysInTimeFrame,
    } = collapseData(
      dataSets[0].dataByX,
      quarterHours,
      timeframe,
      timezoneOffset
    )
    return (
      <div className={styles.BusiestTime}>
        <div className={styles.BusiestTimeHeader}>
          <Header size="small">Busiest time for {measuring}</Header>
          <SubHeader as="div" color="dark" size="tiny">
            {loaded &&
              busiestCell &&
              busiestCellCount === 1 && (
                <BusiestCellAsString busiest={busiestCell / 3} />
              )}
            {loaded &&
              !busiestCell &&
              'There is no significant busiest time during this period.'}
            {loaded &&
              busiestCellCount > 1 &&
              'There are multiple busiest times during this period.'}
            {!loaded && '\u00A0'}
          </SubHeader>
        </div>
        <Table className={styles.BusiestTimeTable}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell />
              {Object.keys(DAYS).map(day => {
                return <Table.HeaderCell key={day}>{day}</Table.HeaderCell>
              })}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {Object.keys(TIMES).map(label => {
              return (
                <Table.Row key={label}>
                  <Table.HeaderCell>{label}</Table.HeaderCell>
                  {Object.keys(DAYS).map(day => {
                    const cellNumber = (DAYS[day] - 1) * 24 + TIMES[label][0]
                    const cell = dataByCell[cellNumber]
                    const colorNumber = cell && cell.percentageOfTotalBracket
                    return (
                      <Popup
                        content={
                          <TooltipContent
                            cell={cell}
                            measuring={measuring}
                            measuringSingle={measuringSingle}
                            sum={sum}
                            totalDaysInTimeFrame={totalDaysInTimeFrame}
                          />
                        }
                        inverted
                        key={day}
                        mouseLeaveDelay={0}
                        on="hover"
                        position="bottom center"
                        trigger={
                          <Table.Cell
                            className={cn({
                              [styles[`color_${colorNumber}`]]:
                                cell && cell.y > 0,
                            })}
                          >
                            <div className={styles.cellContent}>
                              {cell && cell.busiest && colorNumber >= 4
                                ? `Busiest`
                                : ''}
                            </div>
                          </Table.Cell>
                        }
                        verticalOffset={-7}
                      />
                    )
                  })}
                </Table.Row>
              )
            })}
          </Table.Body>
        </Table>
        <div className={styles.BusiestTimeFooter}>
          Quietest
          <Legend />
          Busiest
        </div>
      </div>
    )
  }
}
