import { createSelector } from 'reselect'
import { statusStates } from 'constants/status_states'
import { selectIsBootstrapTypedInStates } from 'selectors/bootstrap/makeSelectIsBootstrapTypesLoaded'
import { selectBase } from './base'

export const selectWidgetsBootstrapStatus = createSelector(
  selectBase,
  base => base.bootstrapStatus
)

export const selectWidgetsBootstrapped = createSelector(
  selectWidgetsBootstrapStatus,
  bootstrapStatus => bootstrapStatus === statusStates.SUCCESS
)

export const selectIsWidgetBootstrapInitial = state => {
  return selectIsBootstrapTypedInStates(state, ['widgets'], ['INITIAL'])
}

export const selectWidgetsIsLoading = createSelector(
  selectBase,
  selectWidgetsBootstrapStatus,
  (base, bootstrapStatus) =>
    base.isLoading || bootstrapStatus !== statusStates.SUCCESS
)

export const selectWidgetsIsSaving = createSelector(
  selectBase,
  base => base.isSaving
)
