import graphql from 'api/graphql'
import { BEGIN, COMMIT, REVERT } from 'redux-optimist'
import { v4 as uuidV4 } from 'uuid'

import { oauthTokenSelector } from 'selectors/app'
import {
  selectCurrentKnowledgeBaseId,
  selectCurrentKnowledgeBaseLocale,
} from 'subapps/kb/selectors/knowledge_bases'
import { doGraphqlRequest } from 'ducks/requests/operations'
import { selectIsLoaded, selectIsLoading } from './selectors'
import * as types from './types'

export function doFetchTranslations(type, id, locale, refetch) {
  return (dispatch, getState) => {
    const state = getState()

    const isTranslationsLoaded = selectIsLoaded(state)
    const isTranslationsLoading = selectIsLoading(state)

    if ((isTranslationsLoading || isTranslationsLoaded) && !refetch) {
      return false
    }

    const token = oauthTokenSelector(state)

    dispatch({
      id,
      type: types.FETCH_TRANSLATIONS_REQUEST,
      translationType: type,
    })

    const query = `
      query TranslationsQuery($type: String!, $id: ID!, $locale: String!) {
        translations(type: $type, id: $id, locale: $locale)
      }
    `

    const variables = {
      type,
      id,
      locale,
    }

    return graphql(token, query, variables)
      .then(res => {
        const data = res.json.data
        dispatch({
          type: types.FETCH_TRANSLATIONS_SUCCESS,
          data: data.translations,
        })
      })
      .catch(err => {
        dispatch({
          type: types.FETCH_TRANSLATIONS_FAILURE,
          data: { err },
        })
      })
  }
}

export function doUpdateTranslations(...args) {
  return dispatch => {
    const [
      type,
      id,
      locale,
      translations,
      { shouldShowToasts = false } = {},
    ] = args
    const mutation = `
      mutation UpdateTranslations(
        $type: String!,
        $id: ID!,
        $locale: String!,
        $input: JSON!
      ){
        updateTranslations(
          type: $type,
          id: $id,
          locale: $locale,
          input: $input
        )
      }
    `

    const variables = {
      type,
      id,
      locale,
      input: translations,
    }

    return dispatch(
      doGraphqlRequest(types.UPDATE_TRANSLATIONS, mutation, variables, {
        optimist: {},
        transformResponse: data => ({ data: data.updateTranslations }),
        moduleOptions: {
          toasts: {
            enabled: shouldShowToasts,
            started: {
              enabled: false,
            },
            success: {
              enabled: true,
              content: 'Translations updated',
            },
            failed: {
              content: 'Translations update failed',
              onClickAction: () => {
                dispatch(doUpdateTranslations(...args))
              },
            },
          },
        },
      })
    )
  }
}

function doResetTranslations(type, id, locale) {
  return (dispatch, getState) => {
    const state = getState()
    const token = oauthTokenSelector(state)

    dispatch({
      type: types.RESET_TRANSLATIONS_REQUEST,
    })

    const mutation = `
      mutation ResetTranslations(
        $type: String!,
        $id: ID!,
        $locale: String!,
      ){
        resetTranslations(
          type: $type,
          id: $id,
          locale: $locale,
        )
      }
    `

    const variables = {
      type,
      id,
      locale,
    }

    return graphql(token, mutation, variables)
      .then(res => {
        const data = res.json.data

        dispatch({
          type: types.RESET_TRANSLATIONS_SUCCESS,
          data: data.resetTranslations,
        })
      })
      .catch(err => {
        dispatch({
          type: types.RESET_TRANSLATIONS_FAILURE,
          data: { err },
        })
      })
  }
}

export function doDeleteTranslation(type, id, locale, keyPath) {
  return (dispatch, getState) => {
    const state = getState()
    const token = oauthTokenSelector(state)
    const tid = uuidV4()

    dispatch({
      type: types.DELETE_TRANSLATION_REQUEST,
      keyPath,
      optimist: { type: BEGIN, id: tid },
    })

    const mutation = `
      mutation DestroyTranslation(
        $key: String!,
        $type: String!
        $id: ID!,
        $locale: String!,
      ){
        destroyTranslation(
          key: $key,
          type: $type,
          id: $id,
          locale: $locale,
        )
      }
    `

    const variables = {
      key: keyPath,
      type,
      id,
      locale,
    }

    return graphql(token, mutation, variables)
      .then(res => {
        const data = res.json.data

        dispatch({
          type: types.DELETE_TRANSLATION_SUCCESS,
          data: data.destroyTranslation,
          optimist: { type: COMMIT, id: tid },
        })
      })
      .catch(err => {
        dispatch({
          type: types.DELETE_TRANSLATION_FAILURE,
          data: { err },
          optimist: { type: REVERT, id: tid },
        })
      })
  }
}

export function doFetchCurrentKbTranslations(locale, refetch) {
  return (dispatch, getState) => {
    const state = getState()
    const id = selectCurrentKnowledgeBaseId(state)
    return dispatch(doFetchTranslations('kb', id, locale, refetch))
  }
}

export function doUpdateCurrentKbTranslations(locale, translations) {
  return (dispatch, getState) => {
    const state = getState()
    const id = selectCurrentKnowledgeBaseId(state)
    return dispatch(doUpdateTranslations('kb', id, locale, translations))
  }
}

export function doResetCurrentKbTranslations() {
  return (dispatch, getState) => {
    const state = getState()
    const id = selectCurrentKnowledgeBaseId(state)
    const locale = selectCurrentKnowledgeBaseLocale(state)
    return dispatch(doResetTranslations('kb', id, locale))
  }
}

export function doDeleteCurrentKbTranslation(key) {
  return (dispatch, getState) => {
    const state = getState()
    const id = selectCurrentKnowledgeBaseId(state)
    const locale = selectCurrentKnowledgeBaseLocale(state)
    return dispatch(doDeleteTranslation('kb', id, locale, key))
  }
}
