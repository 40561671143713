import { connect } from 'react-redux'
import { selectIsAddingNote } from 'selectors/page'
import {
  selectLastRequestedSearchTerm,
  selectArticlesWithKnowledgebase,
  selectKnowledgeBaseOptions,
  selectArticlePreviewUrl,
  selectPrimaryKnowledgeBaseId,
  selectHasMultipleKnowledgebases,
  selectArticlePreviewArticle,
  selectHasKnowledgebases,
} from '../../selectors/kbSearch'
import {
  doOpenKbArticlePopout,
  doSearchForArticles,
  doFetchKnowledgeBases,
} from '../../operations'

import SearchKbView from './view'

const select = state => ({
  items: selectArticlesWithKnowledgebase(state),
  knowledgeBaseOptions: selectKnowledgeBaseOptions(state),
  lastRequestedSearchTerm: selectLastRequestedSearchTerm(state),
  articlePreviewArticle: selectArticlePreviewArticle(state),
  articlePreviewUrl: selectArticlePreviewUrl(state),
  primaryKnowledgeBaseId: selectPrimaryKnowledgeBaseId(state),
  isAddingNote: selectIsAddingNote(state),
  hasMultipleKnowledgebases: selectHasMultipleKnowledgebases(state),
  hasKnowledgebases: selectHasKnowledgebases(state),
})

const perform = {
  searchForArticles: doSearchForArticles,
  openKbArticlePopout: doOpenKbArticlePopout,
  fetchKnowledgeBases: doFetchKnowledgeBases,
}

export default connect(select, perform)(SearchKbView)
