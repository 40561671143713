import { connect } from 'react-redux'

import { totalConversationsCreatedGauge } from 'ducks/reports/gauges'
import { HeadlineVisualization } from 'ducks/reports/visualizations'

const staticProps = {
  gauges: [totalConversationsCreatedGauge],
  title: 'New Conversations',
}

export default function TotalConversationsCreatedHeadline(props) {
  return (
    <HeadlineVisualization
      {...props}
      {...staticProps}
      fullstackId="newConversationsMetric"
    />
  )
}
