import { connect } from 'react-redux'

import { selectHappinessScoreRatioGauge } from 'ducks/reports/gauges'
import { PercentGauge } from 'ducks/reports/visualizations'

const staticSelect = {
  scale: { y: 'percent' },
  title: 'CSAT score',
  trafficLight: [50, 80],
}
const select = (state, props) => ({
  ...staticSelect,
  gauge: selectHappinessScoreRatioGauge(state, props),
})
export default connect(select)(PercentGauge)
