import { connect } from 'react-redux'

import { selectQueryBase } from 'ducks/reports/controls'

import PageView from './PageView'

const select = state => ({
  queryBase: selectQueryBase(state),
})

export default connect(select)(PageView)
