import { createSelector } from 'reselect'
import { any } from 'util/arrays'
import { createBasicSelector } from 'util/redux'

function selectBase(state) {
  return state.reports
}

export const selectBusinessHours = createBasicSelector(
  selectBase,
  base => base.businessHours
)

export const selectAreAnyBusinessHoursSet = createSelector(
  selectBusinessHours,
  ({ days }) => any(day => day.enabled, Object.values(days))
)

export const selectBusinessHoursTimezone = createBasicSelector(
  selectBusinessHours,
  businessHours => businessHours.timezone
)
