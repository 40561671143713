import { emptyArr } from 'util/arrays'

import { countArticleRatings } from '../queries/countArticleRatings'

export const articleRatingsByArticleAndGrade = {
  fill: emptyArr,
  id: 'articleRatingsByArticleAndGrade',
  queries: [
    {
      ...countArticleRatings,
      key: 'countArticleRatingsByArticleAndGrade',
      groupBy: ['article.id', 'rating.grade_int'],
    },
  ],
  title: 'Article Ratings',
}
