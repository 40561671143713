import { createActionTypeReducer } from 'util/reducers'
import {
  MERGE_TICKET_STARTED,
  MERGE_TICKET_FAILED,
  MERGE_TICKET_SUCCESS,
  CHANGE_MERGE_DIRECTION,
  UPDATE_MERGING_SEARCH_TERM,
  UPDATE_MERGING_MERGE_TICKET,
  RESET_TO_DEFAULTS,
} from './types'

const defaultState = {
  direction: 'normal',
  mergeTicket: {
    id: null,
    title: null,
  },
  searchTerm: '',
  isMerging: false,
}

function resetToDefaults(draftState) {
  draftState.direction = 'normal'
  draftState.mergeTicket = { id: null, title: null }
  draftState.searchTerm = ''
  draftState.isMerging = false
}

export default createActionTypeReducer(
  {
    [UPDATE_MERGING_SEARCH_TERM]: (draftState, action) => {
      const { term } = action.data

      draftState.searchTerm = decodeURIComponent(term)
      return draftState
    },
    [UPDATE_MERGING_MERGE_TICKET]: (draftState, action) => {
      const { mergeTicket } = action.data

      if (!mergeTicket) {
        draftState.mergeTicket = { id: null, title: null }
        return draftState
      }

      const { id, title } = mergeTicket
      draftState.mergeTicket = { id, title }

      return draftState
    },
    [CHANGE_MERGE_DIRECTION]: (draftState, action) => {
      const { direction } = action.data

      draftState.direction = direction
      return draftState
    },
    [RESET_TO_DEFAULTS]: draftState => {
      resetToDefaults(draftState)
      return draftState
    },
    [MERGE_TICKET_STARTED]: draftState => {
      draftState.isMerging = true
      return draftState
    },
    [MERGE_TICKET_FAILED]: draftState => {
      resetToDefaults(draftState)
      return draftState
    },
    [MERGE_TICKET_SUCCESS]: draftState => {
      resetToDefaults(draftState)
      return draftState
    },
  },
  defaultState
)
