import {
  doApiWriteRequest,
  doApiReadRequest,
  doGraphqlRequest,
} from 'ducks/requests/operations'
import {
  collectionQuery,
  generateUpdateMutation,
  allFields,
  DESTROY_MUTATION,
} from 'ducks/mailboxes/api'
import {
  selectChannelEntitiesToAddAfterMailboxCreation,
  selectCurrentChannels,
} from 'ducks/channels/selectors'
import { createDoFetchInMemoryByQueryId } from 'ducks/searches/operations/createDoFetchInMemoryByQueryId'
import { convertHexToRGBA } from 'util/colors'
import { getRawId } from 'util/globalId'
import { FETCH_CHANNELS } from 'ducks/channels/actionTypes'
import { changeEntity } from 'ducks/entities/actionUtils'
import { doBuildInboxMenuFromMailboxes } from 'ducks/folders/operations/collections'
import { selectMailboxes } from 'selectors/mailboxes/selectMailboxes'
import { selectCurrentAgents } from 'ducks/agents/selectors'
import {
  selectCurrentUser,
  selectCurrentUserId,
} from 'ducks/currentUser/selectors/base'
import { selectFolders } from 'ducks/folders/selectors/folders'
import { isAgentInGroup } from 'selectors/app'
import * as storage from 'util/storage'
import {
  GMAIL_IMPORT,
  SEND_VERIFICATION,
  SEND_SETUP_INSTRUCTIONS,
  FETCH_MAILBOXES,
  UPDATE_MAILBOX,
  UPDATE_MAILBOX_SUCCESS,
  TEST_SMTP,
  TEST_IMAP,
  CHECK_DNS,
  DESTROY_MAILBOX,
  SMTP_CHECK,
} from './actionTypes'
import {
  mailboxGraphQlResponseSchema,
  mailboxUpdateGraphQlResponseSchema,
  mailboxDeleteGraphQlResponseSchema,
} from './schema'
import { EMAIL_SERVER_DOMAIN } from './constants'

export const LOAD_ALL_MAILBOXES_QUERYID =
  'entityType:channel pageSize:10000 type:inboxes'

export const doGmailImport = (
  id,
  {
    import_labels: importLabels = false,
    close_tickets: importCloseTickets = false,
    pages_to_import: pagesToImport = 1,
    stop_importing_at: stopImportingAt,
  } = {},
  options = {}
) => async dispatch => {
  const payload = {
    mailbox_id: id,
    close_tickets: importCloseTickets,
    process_labels: importLabels,
    pages_to_import: pagesToImport,
    stop_importing_at: stopImportingAt,
  }

  return dispatch(
    doApiWriteRequest(GMAIL_IMPORT, 'api/gmail_imports', payload, {
      ...options,
      throwOnError: true,
      method: 'POST',
    })
  )
}

export const doSmtpCheck = id => dispatch => {
  return dispatch(
    doApiWriteRequest(SMTP_CHECK, `api/mailboxes/${id}/delivery_checks`, {
      throwOnError: true,
      method: 'POST',
    })
  )
}

const throwErrorIfVerifyIMAPFails = (response, inComingEmailServer) => {
  if (!response.imap?.success || !response.smtp?.success) {
    let errorCode = 'ERR_AUTH_IMAP'
    const { responseText } = response.imap || {}
    if (/Invalid credentials/i.test(responseText)) {
      errorCode = 'ERR_IMAP_CREDENTIALS'
    } else if (
      /app.*password/i.test(responseText) &&
      inComingEmailServer[EMAIL_SERVER_DOMAIN].includes('gmail.com')
    ) {
      errorCode = 'ERR_IMAP_APP_PASSWORD'
    }
    throw new Error(errorCode)
  }
}

// Verify imap and smtp credentials before creating a mailbox
export const doVerifyIMAP = (
  {
    incoming_email_server: inComingEmailServer,
    outgoing_email_server: outgoingEmailServer,
  },
  options
) => async dispatch => {
  const oauthTest = storage.get('testOAuthWindowOverride') || {}
  if (oauthTest.skipEmailOauth) {
    return { imap: { success: true }, smtp: { success: true } }
  }
  const response = await dispatch(
    doApiWriteRequest(
      TEST_IMAP,
      'v1/verify_imap',
      {
        imap: inComingEmailServer,
        smtp: outgoingEmailServer,
      },
      {
        ...options,
        throwOnError: true,
        method: 'POST',
      }
    )
  )
  throwErrorIfVerifyIMAPFails(response, inComingEmailServer)
  return response
}

// Verify imap and smtp credentials after creating a mailbox
export const doVerifyMailboxIMAP = (
  mailboxId,
  {
    incoming_email_server: inComingEmailServer,
    outgoing_email_server: outgoingEmailServer,
  },
  options
) => async dispatch => {
  const response = await dispatch(
    doApiWriteRequest(
      TEST_IMAP,
      `v1/mailboxes/${mailboxId}/verify_imap`,
      {
        imap: inComingEmailServer,
        smtp: outgoingEmailServer,
      },
      {
        ...options,
        throwOnError: true,
        method: 'PUT',
      }
    )
  )
  throwErrorIfVerifyIMAPFails(response, inComingEmailServer)
  return response
}

export const doSendVerification = (id, options) => dispatch => {
  return dispatch(
    doApiWriteRequest(
      SEND_VERIFICATION,
      `v1/mailboxes/${id}/send_forwarding_verification`,
      {},
      {
        ...options,
        throwOnError: true,
        method: 'POST',
      }
    )
  )
}

export const doSendSetupInstructions = (id, payload, options) => dispatch => {
  return dispatch(
    doApiWriteRequest(
      SEND_SETUP_INSTRUCTIONS,
      `v1/mailboxes/${id}/send_forwarding_instructions`,
      payload,
      {
        ...options,
        throwOnError: true,
        method: 'POST',
      }
    )
  )
}

export function doFetchMailboxes(
  options = {
    requestType: FETCH_MAILBOXES,
    variables: { include_inaccessible: false },
  }
) {
  const { requestType, variables } = options

  const queryId = LOAD_ALL_MAILBOXES_QUERYID
  const cursor = 'all'
  return doGraphqlRequest(requestType, collectionQuery(), variables, {
    normalizationSchema: mailboxGraphQlResponseSchema,
    searches: {
      queryId,
      cursor,
      extractPagination: ({ mailboxes: nodes }) => {
        return {
          type: 'channel',
          nodes,
          startCursor: 1,
          endCursor: 1,
          hasNextPage: false,
          hasPreviousPage: false,
          totalCount: nodes.length,
          totalPageCount: 1,
        }
      },
    },
    throwOnError: true,
    includeLegacyPayload: true,
  })
}

export const doFetchMailboxesIncludingInaccessible = () => dispatch => {
  return dispatch(
    doFetchMailboxes(
      // deliberately a different request type so it does not update state.mailboxes
      // otherwise it would include the inaccessible mailboxes there
      { requestType: FETCH_CHANNELS, variables: { include_inaccessible: true } }
    )
  )
}

export const doFetchMailboxesInMemory = createDoFetchInMemoryByQueryId({
  fromQueryId: LOAD_ALL_MAILBOXES_QUERYID,
  entityType: 'channel',
  doLoadAllFn: doFetchMailboxesIncludingInaccessible,
})

function calculateMailboxPosition(mailbox, currentOrderedMailboxes) {
  if (currentOrderedMailboxes[mailbox.position + 1]) {
    return currentOrderedMailboxes[mailbox.position + 1].position - 10
  } else if (currentOrderedMailboxes[mailbox.position - 1]) {
    return currentOrderedMailboxes[mailbox.position - 1].position + 10
  }
  return mailbox.position
}

export const doUpdateMailbox = (id, mailbox, options = {}) => async (
  dispatch,
  getState
) => {
  const state = getState()
  const {
    responseFields = allFields,
    updatePosition = false,
    queryId,
    orderedIds = null,
    rebuildMenu = true,
  } = options
  const orderedChannels = selectCurrentChannels(state)

  const mailboxInput = { ...mailbox }
  delete mailboxInput.forward_email_address
  delete mailboxInput.id
  delete mailboxInput.position
  const optimisticMailbox = {
    ...mailbox,
  }
  if (mailboxInput.color && mailboxInput.color.startsWith('#')) {
    mailboxInput.color = convertHexToRGBA(mailboxInput.color)
  }
  if (updatePosition) {
    mailboxInput.row_order_position = mailbox.position
    optimisticMailbox.position = calculateMailboxPosition(
      mailbox,
      orderedChannels
    )
  }

  const entityId = id
  const entityType = 'channel'

  const optimist = {
    entities: {
      [entityType]: {
        [entityId]: optimisticMailbox,
      },
    },
  }

  const response = await dispatch(
    doGraphqlRequest(
      UPDATE_MAILBOX,
      generateUpdateMutation(responseFields),
      {
        id,
        input: mailboxInput,
      },
      {
        normalizationSchema: mailboxUpdateGraphQlResponseSchema,
        throwOnError: true,
        includeLegacyPayload: true,
        optimist,
        searches: {
          additionalActions: [
            {
              type: 'INVALIDATE_ENTITIES',
              entityTypes: [entityType],
              phases: ['SUCCESS'],
            },
            {
              type: 'UPDATE_CURSOR',
              queryId,
              entityIds: orderedIds,
              phases: ['STARTED'],
            },
          ],
        },

        // Required to make the format of UPDATE_MAILBOX_SUCCESS backward compatibile
        // with existing reducers
        transformResponse: data => {
          return {
            mailbox: data.updateMailbox,
          }
        },
        ...options,
      }
    )
  )

  if (rebuildMenu) {
    dispatch(doBuildInboxMenuFromMailboxes())
  }

  return response
}

export const doUpdateMailboxLocally = (mailbox, { shouldRebuildMenu } = {}) => (
  dispatch,
  getState
) => {
  if (!mailbox) return false
  const state = getState()
  const currentUserId = selectCurrentUserId(state)
  const {
    restriction_type: restrictionType,
    group_ids: groupIds,
    user_ids: userIds,
    // channel_type from realtime update is small case, so we need to convert it to uppercase
    channel_type: channelType,
  } = mailbox
  const channelTypeUpperCase = channelType?.toUpperCase()
  let isInAccessibleMailbox = false
  if (restrictionType === 'users') {
    isInAccessibleMailbox =
      userIds.length !== 0 ? !userIds.includes(currentUserId) : false
  } else if (restrictionType === 'group') {
    isInAccessibleMailbox = !groupIds.some(groupId =>
      isAgentInGroup(groupId, currentUserId)
    )
  }
  const newMailbox = {
    ...mailbox,
  }
  if (channelTypeUpperCase) {
    newMailbox.channel_type = channelTypeUpperCase
  }
  const entitiesToAdd = selectChannelEntitiesToAddAfterMailboxCreation(
    state,
    newMailbox
  )
  const updateMailboxBoxAction = {
    type: UPDATE_MAILBOX_SUCCESS,
    payload: {
      mailbox: newMailbox,
      isInAccessibleMailbox,
    },
    ...changeEntity('channel', mailbox.id, newMailbox),
  }
  if (shouldRebuildMenu) {
    dispatch({ ...updateMailboxBoxAction, ...entitiesToAdd })
    return dispatch(doBuildInboxMenuFromMailboxes())
  }
  return dispatch(updateMailboxBoxAction)
}

export const doTestMailboxSmtp = (
  { id, username, password, authentication, address, port, useSsl },
  options = {}
) => dispatch => {
  return dispatch(
    doApiWriteRequest(
      TEST_SMTP,
      `api/settings/mailboxes/test_smtp`,
      {
        smtp: {
          id,
          user_name: username,
          password,
          authentication,
          address,
          port,
          use_ssl: useSsl ? '1' : '0',
        },
      },
      {
        ...options,
        throwOnError: true,
        method: 'POST',
      }
    )
  )
}

export const doCheckMailboxDns = (
  mailboxId,
  { email, validate },
  options = {}
) => dispatch => {
  return dispatch(
    doApiReadRequest(
      CHECK_DNS,
      `api/settings/mailboxes/${mailboxId}/dns_check`,
      { email, validate },
      {
        ...options,
        throwOnError: true,
        method: 'GET',
      }
    )
  )
}

export const doDeleteMailbox = (gid, options = {}) => (dispatch, getState) => {
  const state = getState()
  const mailboxes = (selectMailboxes(state) || []).filter(m => m.id !== gid)
  const folders = selectFolders(state)
  const agents = selectCurrentAgents(state)
  const currentUser = selectCurrentUser(state)

  return dispatch(
    doGraphqlRequest(
      DESTROY_MAILBOX,
      DESTROY_MUTATION,
      {
        id: gid,
      },
      {
        meta: {
          leftNavPayload: { folders, mailboxes, agents, currentUser },
        },
        throwOnError: true,
        normalizationSchema: mailboxDeleteGraphQlResponseSchema,
        includeLegacyPayload: true,
        transformResponse: () => {
          return {
            // HACK (jscheel): API doesn't return anything and legacy handlers
            // expect a raw scatterswapped id
            id: getRawId(gid),
            gid,
          }
        },
        searches: {
          additionalActions: [
            {
              type: 'INVALIDATE_ENTITIES',
              entityTypes: ['channel'],
              phases: ['SUCCESS'],
            },
          ],
        },
        ...options,
        moduleOptions: {
          ...options.moduleOptions,
          entities: {
            targetOperation: 'remove',
            additionalActions: [
              {
                entityType: 'channel',
                entityId: gid,
                stores: ['pending', 'current'],
                operation: 'remove',
                phases: ['SUCCESS'],
              },
            ],
          },
        },
      }
    )
  )
}

export const doFetchMailboxesAndBuildInboxMenu = () => async dispatch => {
  // Should fech mailboxes in case new folders are added
  await dispatch(doFetchMailboxes())
  // we have this instead of after doCreateSingleFolder/doUpdateSingleFolder on drawers because we do not sync
  // CREATE_FOLDER_SUCCESS & UPDATE_FOLDER_SUCCESS with state.leftnav.folders & state.foldersV2.byId (too cumbersome due to difference in data and structure)
  // Easier to maintain by just rebuilding. Also keep in mind this datagrid's FETCH_FOLDERS_V2_SUCCESS syncs with state.foldersV2.byId
  // and we need that updated data to rebuild leftnav
  dispatch(doBuildInboxMenuFromMailboxes())
}
