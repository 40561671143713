import { createSelector } from 'reselect'
import { selectHasRealConfirmedMailboxes } from 'selectors/trial_setup'
import {
  isBoostrappedSelector,
  selectAccountUsageOnboarding,
} from 'selectors/app/base'
import { selectKnowledgeBases } from 'subapps/kb/selectors/knowledge_bases'
import { selectIsKbsLoaded } from 'subapps/kb/selectors/knowledge_bases/selectIsKbsLoaded'
import { selectIsTestAccount } from 'selectors/account'
import { diff } from 'util/date'
import { MOUNTED } from './constants'

export function selectLayoutMounted(start) {
  const { reports: { layout } = {} } = start
  return layout === MOUNTED
}

export const selectShowEmptyState = createSelector(
  isBoostrappedSelector,
  selectAccountUsageOnboarding,
  selectHasRealConfirmedMailboxes,
  selectIsKbsLoaded,
  selectKnowledgeBases,
  selectIsTestAccount,
  (
    appBootstrapped,
    usageOnboarding,
    hasRealConfirmedMailboxes,
    kbsLoaded,
    kbs = [],
    isTestAccount
  ) => {
    if (isTestAccount || !appBootstrapped) return false
    const inboxDataGathered =
      hasRealConfirmedMailboxes && (usageOnboarding?.tickets_created || 0) >= 25
    const kbDataGathered =
      kbsLoaded && kbs.some(kb => diff('days', kb.createdAt, new Date()) > 7)

    return !inboxDataGathered && !kbDataGathered
  }
)

export const selectShowContent = createSelector(
  isBoostrappedSelector,
  selectIsKbsLoaded,
  (appBootstrapped, isKbsLoaded) => appBootstrapped && isKbsLoaded
)
