import Bugsnag from '@bugsnag/js'
import grooveAPI from 'api/groove/api'
import config from 'config'
import { fetchingStatusesSelector, oauthTokenSelector } from 'selectors/app'
import { logError } from 'util/debug'
import { debounce } from 'util/functions'
import storage from 'util/storage'

import {
  selectAreControlsPending,
  selectTimezone,
} from 'ducks/reports/controls'

import {
  queryObjectToQueryId,
  queryObjectToRequestQueryObject,
} from '../queryTransformations'
import {
  FETCH_REPORT_QUERIES_REQUEST,
  FETCH_REPORT_QUERIES_RESPONSE,
  FETCH_REPORT_QUERIES_ERROR,
  FETCH_REPORT_QUERY_REQUEST,
  FETCH_REPORT_QUERY_RESPONSE,
  FETCH_REPORT_QUERY_ERROR,
} from '../types'

const BATCH_SIZE_LIMIT = 50
const BATCH_WAIT_TIME = 50
const reportsUseProduction = storage.get('reportsUseProduction')
const groove = grooveAPI(
  reportsUseProduction ? config.production_report_url : config.report_url
)

function doFetchReportQueryRequest(query) {
  const { queryToId = queryObjectToQueryId } = query
  return {
    type: FETCH_REPORT_QUERY_REQUEST,
    payload: {
      queryId: queryToId(query),
      query,
    },
  }
}

function doFetchReportQueryResponse(query) {
  const { queryToId = queryObjectToQueryId } = query
  return {
    type: FETCH_REPORT_QUERY_RESPONSE,
    payload: {
      queryId: queryToId(query),
      query,
      pagination: query.result &&
        query.result.page && {
          current: query.result.page,
          total: query.result.pages,
        },
    },
  }
}

function doFetchReportQueryError(query) {
  const { queryToId = queryObjectToQueryId } = query
  return {
    type: FETCH_REPORT_QUERY_ERROR,
    error: true,
    payload: {
      queryId: queryToId(query),
      query,
    },
  }
}

function doFetchReportQueriesRequest(queries) {
  return {
    type: FETCH_REPORT_QUERIES_REQUEST,
    payload: {
      queries,
      queryIds: queries.map(query => {
        const { queryToId = queryObjectToQueryId } = query
        return queryToId(query)
      }),
    },
  }
}

function doFetchReportQueriesResponse(queries, { queries: result }) {
  return {
    type: FETCH_REPORT_QUERIES_RESPONSE,
    payload: {
      queries,
      queryIds: queries.map(query => {
        const { queryToId = queryObjectToQueryId } = query
        return queryToId(query)
      }),
      result,
    },
  }
}

function doFetchReportQueriesError(queries, error) {
  return {
    type: FETCH_REPORT_QUERIES_ERROR,
    error: true,
    payload: {
      error: error.message,
      queryIds: queries.map(query => {
        const { queryToId = queryObjectToQueryId } = query
        return queryToId(query)
      }),
    },
  }
}

let QUERY_BUFFERS = {}
let QUERY_BUFFERS_IDS = {}
function bufferQuery(query = null, queryBatch = 'default') {
  if (!QUERY_BUFFERS[queryBatch]) QUERY_BUFFERS[queryBatch] = []
  if (!QUERY_BUFFERS_IDS[queryBatch]) QUERY_BUFFERS_IDS[queryBatch] = []
  const buffer = QUERY_BUFFERS[queryBatch]
  const idBuffer = QUERY_BUFFERS_IDS[queryBatch]
  const { queryToId = queryObjectToQueryId } = query
  const queryId = queryToId(query)
  const isQueryAlreadyQueued = idBuffer.includes(queryId)
  if (query && !isQueryAlreadyQueued) {
    buffer.push(query)
    idBuffer.push(queryId)
  }
  return [...buffer]
}

const debouncedFlushBuffers = debounce(flushBuffers, BATCH_WAIT_TIME)

function flushBuffers(dispatch, getState) {
  const state = getState()
  const token = oauthTokenSelector(state)
  const buffers = Object.values(QUERY_BUFFERS)
  const keys = Object.keys(QUERY_BUFFERS)
  buffers.forEach((buffer, bufferIndex) => {
    if (buffer.length === 0) return
    dispatch(doFetchReportQueriesRequest(buffer))
    groove
      .post(
        token,
        'batch',
        { batch: keys[bufferIndex] },
        JSON.stringify({ queries: buffer.map(queryObjectToRequestQueryObject) })
      )
      .then(x => {
        const queries = x?.json?.queries || []
        queries.forEach((result, queryIndex) => {
          if (
            buffer[queryIndex] &&
            (buffer[queryIndex].retries || 0) < 3 &&
            (result.retry || result.retry === 0)
          ) {
            const query = {
              ...buffer[queryIndex],
              retries: (buffer[queryIndex].retries || 0) + 1,
            }

            setTimeout(() => {
              bufferQuery(query, 'retry')
              debouncedFlushBuffers(dispatch, getState)
            }, result.retry)
          }
        })
        dispatch(doFetchReportQueriesResponse(buffer, x.json))
      })
      .catch(error => {
        logError(error)
        Bugsnag.notify(error)
        dispatch(doFetchReportQueriesError(buffer, error))
      })
  })
  QUERY_BUFFERS = {}
  QUERY_BUFFERS_IDS = {}
}

let LOADER_QUERY_BUFFER = {}
function bufferLoaderQuery(dispatch, query) {
  const { queryToId = queryObjectToQueryId } = query
  const queryId = queryToId(query)
  const buffedQuery = LOADER_QUERY_BUFFER[queryId]
  if (!buffedQuery) LOADER_QUERY_BUFFER[queryId] = query
  return LOADER_QUERY_BUFFER
}

function flushLoaderBuffer(dispatch, getState) {
  Object.values(LOADER_QUERY_BUFFER).forEach(query => {
    runLoader(dispatch, getState, query)
  })
  LOADER_QUERY_BUFFER = {}
}

function runLoader(dispatch, getState, query) {
  const { loader } = query
  dispatch(doFetchReportQueryRequest(query))

  const returnedValue = loader(query)(dispatch, getState)
  const asPromise = returnedValue.then
    ? returnedValue
    : Promise.resolve(returnedValue)
  asPromise
    .then(queryResult => {
      dispatch(doFetchReportQueryResponse({ ...query, ...queryResult }))
    })
    .catch(error => {
      logError(error)
      Bugsnag.notify(error)
      dispatch(doFetchReportQueryError(query))
    })
}

const debouncedFlushLoaderBuffer = debounce(flushLoaderBuffer, BATCH_WAIT_TIME)

export default function doFetchReportQuery(query, queryBatch = 'default') {
  return (dispatch, getState) => {
    const { errored, loadable, loaded, loader, loading } = query
    const state = getState()
    const areControlsPending = selectAreControlsPending(state)
    const timezone = selectTimezone(state)

    const shouldFetchReportQuery =
      loadable !== false &&
      !loaded &&
      !errored &&
      !loading &&
      !areControlsPending &&
      timezone &&
      (!loader || fetchingStatusesSelector(state).fetchAppData === false) // If the query is a loader query we want to wait until the app is bootstrapped

    if (!shouldFetchReportQuery) return
    if (loader) {
      const loaderBuffer = bufferLoaderQuery(dispatch, query)
      if (Object.keys(loaderBuffer).length >= BATCH_SIZE_LIMIT) {
        flushLoaderBuffer(dispatch, getState)
      } else {
        debouncedFlushLoaderBuffer(dispatch, getState)
      }
      return
    }
    const buffer = bufferQuery(query, queryBatch)
    if (buffer.length >= BATCH_SIZE_LIMIT) {
      flushBuffers(dispatch, getState)
    } else {
      debouncedFlushBuffers(dispatch, getState)
    }
  }
}
