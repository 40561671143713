import { doGraphqlRequest } from 'ducks/requests/operations'

import {
  selectIsArticlePreviewLoading,
  selectIsArticlePreviewLoaded,
  selectIsArticlePreviewArticleId,
  selectIsArticlePreviewBroken,
} from '../../selectors'
import { FETCH_KB_PREVIEW_ARTICLE } from '../../types'

const articleFields = `
  authorId
  author {
    avatar_url
    name
  }
  categoryId
  category {
    id
    title
    slug
  }
  createdAt
  description
  featured
  id
  knowledgeBaseId
  knowledgeBase {
    title
    enabled
  }
  metaDescription
  metaRobots
  ogDescription
  ogImageUrl
  ogTitle
  pageTitle
  ratings {
    count
    value
  }
  slug
  state
  tags
  title
  updatedAt
  body
`

export const doLoadArticlePreview = (
  { articleId, knowledgeBaseId },
  options
) => (dispatch, getState) => {
  const state = getState()
  // Only load when we pass in both
  if (!articleId || !knowledgeBaseId) return Promise.resolve()
  // While loading articles, ignore new requests to load a different article
  const isArticlePreviewLoading = selectIsArticlePreviewLoading(state)
  if (isArticlePreviewLoading) return Promise.resolve()

  // If the user is requesting an already loaded article, just return the existing article
  const isArticlePreviewLoaded = selectIsArticlePreviewLoaded(state)
  const isArticlePreviewBroken = selectIsArticlePreviewBroken(state)
  const stateArticleId = selectIsArticlePreviewArticleId(state)
  if (
    stateArticleId === articleId &&
    (isArticlePreviewLoaded || isArticlePreviewBroken)
  )
    return Promise.resolve()

  const query = `
      query ArticleQuery($knowledgeBaseId: ID!, $articleId: ID!) {
        article(knowledgeBaseId: $knowledgeBaseId, id: $articleId){
          ${articleFields}
          body
        }
      }
    `

  return dispatch(
    doGraphqlRequest(
      FETCH_KB_PREVIEW_ARTICLE,
      query,
      { articleId, knowledgeBaseId },
      options
    )
  )
}
