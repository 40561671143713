import { connect } from 'react-redux'

import { totalFailedSearchesByTermGauge } from 'ducks/reports/gauges'
import {
  sortPercentChange,
  sortX,
  TableV2Visualization,
} from 'ducks/reports/visualizations'

const staticProps = {
  gauges: [
    {
      ...totalFailedSearchesByTermGauge,
      id: 'term',
      title: 'Search Term',
      scale: { y: 'x' },
      sort: { direction: 'ASC', method: sortX },
    },
    {
      ...totalFailedSearchesByTermGauge,
      id: 'count',
      title: 'Count',
      scale: { y: 'percent_total' },
    },

    {
      ...totalFailedSearchesByTermGauge,
      id: 'change',
      title: '% Change',
      scale: { y: 'percent_change' },
      sort: { method: sortPercentChange },
    },
  ],
  key: 'kb-failed-searches',
  pagination: {
    customizable: true,
    perPage: 10,
    minPerPage: true,
  },
  sort: {
    by: 'count',
  },
  title: 'Failed Searches',
}

export default function FailedSearchesTable(props) {
  return <TableV2Visualization {...staticProps} {...props} />
}
