import { connect } from 'react-redux'

import { customersPerDayGauge } from 'ducks/reports/gauges'
import { HeadlineVisualization } from 'ducks/reports/visualizations'

const staticProps = {
  gauges: [customersPerDayGauge],
}
export default function CustomersPerDayHeadline(props) {
  return <HeadlineVisualization {...props} {...staticProps} />
}
