import { useState, useEffect } from 'react'
import posthog from 'posthog-js'
import config from 'config'
import Bugsnag from '@bugsnag/js'
import debug from 'util/debug'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectCurrentUserGlobalId,
  selectCurrentUserEmail,
} from 'ducks/currentUser/selectors/base'
import { selectAccount } from 'ducks/accounts/selectors/selectAccount'
import storage from 'util/storage'
import { isGid, buildId } from 'util/globalId'
import { strftime } from 'util/date'

const LAST_IDENTIFY_STORAGE_KEY = 'posthogLastIdentify'
const pendingEventQueue = []
const emptyFunction = () => {}
const getPendingEventQueue = () => pendingEventQueue

const defaultTrackingContextValue = {
  initialized: false,
  identified: false,
  setTrackingContextValue: emptyFunction,
  getPendingEventQueue,
  client: null,
  reset: false,
}
let globalTrackingContext = defaultTrackingContextValue
export const PH_DEBUG = storage.get('posthogDebug')
export const IS_IMPERSONATING = storage.get('impersonating')

const capturePendingEvents = () => {
  const { initialized, client } = globalTrackingContext
  if (!initialized) {
    if (pendingEventQueue.length > 50) {
      pendingEventQueue.length = 0
    }
    return
  }
  while (pendingEventQueue.length > 0) {
    const [action, args] = pendingEventQueue.shift()
    client[action](...args)
  }
}
defaultTrackingContextValue.capturePendingEvents = capturePendingEvents

export const getTrackingContext = () => globalTrackingContext

export const useTrackingContext = () => {
  const agentId = useSelector(selectCurrentUserGlobalId)
  const agentEmail = useSelector(selectCurrentUserEmail)
  const { name, subdomain, id: accountHashedId } = useSelector(selectAccount)
  const accountId = buildId('Account', accountHashedId)

  const [trackingContextValue, setTrackingContextValue] = useState(
    defaultTrackingContextValue
  )
  globalTrackingContext = trackingContextValue

  const { initialized, identified, reset } = trackingContextValue

  const dispatch = useDispatch()

  useEffect(() => {
    setTrackingContextValue(prevTrackingContextValue => ({
      ...prevTrackingContextValue,
      setTrackingContextValue,
    }))
  }, [])

  useEffect(
    () => {
      capturePendingEvents()
    },
    [trackingContextValue]
  )

  useEffect(
    () => {
      if (IS_IMPERSONATING) return
      if (initialized || !config.posthog.enabled) return
      if (subdomain?.startsWith('fullstacktest')) return

      try {
        posthog.init(config.posthog.apiKey, {
          api_host: config.posthog.apiHost,
          autocapture: false,
          capture_pageview: false,
          cross_subdomain_cookie: true,
          disable_session_recording: true,
          secure_cookie: true,
          advanced_disable_decide: false,
          opt_in_site_apps: true,
          loaded: ph => {
            window.posthog = ph
            if (PH_DEBUG) {
              ph.debug()
            }
            // If we cant init posthog, mark it as initialized anyway. Broken tracking
            // should not result in a broken app
            setTrackingContextValue(prevState => ({
              ...prevState,
              initialized: true,
              client: ph,
            }))
          },
        })
        posthog.reloadFeatureFlags()
      } catch (error) {
        Bugsnag.notify(error)
        debug('Unable to initialize posthog', { error })
      }
    },
    [initialized, setTrackingContextValue, subdomain, dispatch]
  )

  useEffect(
    () => {
      if (identified || reset || !initialized || !agentId || !accountHashedId)
        return

      try {
        const currentTrackingTimestamp = strftime('%Y%m%d%H')

        // Ensure that we only execute identify under these circumstances
        // 1. The current tracking id isnt a global id. This means we havnt connected
        //      the website session to the logged in session yet
        // 2. If we have done an identify for the current hour.
        // 3. If we can't find account group data
        if (
          !isGid(posthog.get_distinct_id()) ||
          currentTrackingTimestamp !== storage.get(LAST_IDENTIFY_STORAGE_KEY) ||
          !posthog.getGroups()?.account
        ) {
          storage.set(LAST_IDENTIFY_STORAGE_KEY, currentTrackingTimestamp)
          posthog.identify(agentId, {
            account_id: accountId,
            user_id: agentId,
            email: agentEmail,
          })
          posthog.group('account', accountId, { name, subdomain })
          posthog.group('platform', 'web', { name: 'Web' })
        }
      } catch (error) {
        Bugsnag.notify(error)
        debug('Unable to idenfity user in posthog', { error, agentId })
      }
      setTrackingContextValue(prevState => ({
        ...prevState,
        identified: true,
        accountId,
      }))
    },
    [
      initialized,
      agentId,
      agentEmail,
      accountId,
      identified,
      setTrackingContextValue,
      name,
      subdomain,
      reset,
      accountHashedId,
    ]
  )

  return {
    trackingContextValue,
  }
}
