import { createSelector } from 'reselect'
import merge from 'deepmerge'

const base = state => state.translations || {}

export const selectData = state => base(state).data || {}
export const selectDefaultTranslations = state => base(state).default || {}

export const selectUserTranslations = createSelector(
  selectData,
  selectDefaultTranslations,
  (data, defaults) => merge(defaults, data)
)

export const selectEnglishTranslations = state => base(state).english || {}
export const selectTranslationsType = state => base(state).type
export const selectTranslationsId = state => base(state).id
export const selectIsLoading = state => base(state).isLoading === true
export const selectIsLoaded = state => base(state).isLoaded === true

const selectPersistedDraft = state => base(state).persistedDraft || {}

export const selectPreviewTranslations = createSelector(
  selectUserTranslations,
  selectPersistedDraft,
  (userTranslations, persistedDraft) => merge(userTranslations, persistedDraft)
)
