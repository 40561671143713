import averageIntervals from '../intervalCollapsers/averageIntervals'
import { countConversationsCreatedUniqueCustomer } from '../queries/countConversationsCreatedUniqueCustomer'

export const customersPerDayGauge = {
  collapseInterval: averageIntervals,
  description:
    'Average number of customers that sent in a conversations (one customer may have multiple conversations) including spam and deleted conversations per day.',
  title: 'Avg. Customers per Day',
  queries: [
    {
      ...countConversationsCreatedUniqueCustomer,
      interval: 'daily',
      key: 'countConversationsCreatedUniqueCustomerForEachDay',
    },
  ],
}
