export const FETCH_REPORT_QUERIES_REQUEST =
  'reports/queries/FETCH_REPORT_QUERIES_REQUEST'
export const FETCH_REPORT_QUERIES_RESPONSE =
  'reports/queries/FETCH_REPORT_QUERIES_RESPONSE'
export const FETCH_REPORT_QUERIES_ERROR =
  'reports/queries/FETCH_REPORT_QUERIES_ERROR'

export const FETCH_REPORT_QUERY_REQUEST =
  'reports/queries/FETCH_REPORT_QUERY_REQUEST'
export const FETCH_REPORT_QUERY_RESPONSE =
  'reports/queries/FETCH_REPORT_QUERY_RESPONSE'
export const FETCH_REPORT_QUERY_ERROR =
  'reports/queries/FETCH_REPORT_QUERY_ERROR'
