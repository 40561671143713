import { memoize } from 'util/memoization'

import decorateDatum from './decorateDatum'

export default memoize(decorateDataSet)

function decorateDataSet(
  {
    data,
    previousPeriod,
    previousPeriod: { data: previousData } = {},
    ...dataSet
  },
  sortBy
) {
  const {
    group,
    groupId,
    isNegativeChangeGood,
    isNegativeGood,
    name,
    nullIsValidX,
    scale,
    sortKey,
    xValueTranslations,
  } = dataSet
  const isEmptyDataSet = data && data.length === 0
  const isNullDataSet = !data && !isEmptyDataSet
  const isSorted = sortBy === sortKey
  const sum =
    data &&
    data.reduce((reduction, datum) => {
      const y = datum && datum.y
      if (typeof y === 'number') return reduction + y
      return reduction
    }, 0)
  const decoratedName = (xValueTranslations && xValueTranslations[name]) || name
  const decorations = {
    group,
    groupId,
    isEmptyDataSet,
    isNegativeChangeGood,
    isNegativeGood,
    isNullDataSet,
    name: decoratedName,
    scale,
    sum,
  }
  const dataSetDecorations = {
    ...decorations,
    dataSetName: decoratedName,
  }
  const decoratedPreviousData =
    previousData &&
    previousData.map(datum =>
      decorateDatum(datum, null, { ...dataSetDecorations, isPrevious: true })
    )
  const decoratedData =
    data &&
    data
      .map(datum => {
        const previousDatum =
          decoratedPreviousData &&
          decoratedPreviousData.find(pd => pd.x === datum.x)
        return decorateDatum(datum, previousDatum, dataSetDecorations)
      })
      .filter(datum => !!datum)
  const dataByX =
    decoratedData &&
    decoratedData.reduce((result, datum) => {
      if (!datum) return null
      // eslint-disable-next-line no-param-reassign
      result[datum.x] = datum
      return result
    }, {})
  const xValues = data && data.map(({ x }) => x)
  const xValuesWithoutNull = nullIsValidX
    ? xValues
    : xValues && xValues.filter(x => x !== null)

  return {
    ...dataSet,
    ...decorations,
    isSorted,
    data: decoratedData,
    dataByX,
    name: decoratedName,
    previousPeriod: previousPeriod && {
      ...previousPeriod,
      data: decoratedPreviousData,
    },
    scale,
    totalCount: dataSet.totalCount || data.length,
    xValues: xValuesWithoutNull,
  }
}
