import { formatNumber } from 'util/numbers'

import config from './config'

export default function PageViewCell(props) {
  const { value, row } = props
  return (
    <div>
      {formatNumber(value)}
      <span style={{ color: 'gray' }}>
        {` (${Math.round(value / row.total_page_views * 10000) / 100}%)`}
      </span>
    </div>
  )
}
