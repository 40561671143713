import config from 'config'
import { getTabId } from 'util/tabId'
import { getVersion } from 'util/version'
import { selectIsRefreshingToken, selectAccountSubdomain } from 'selectors/app'
import { selectAccountSubdomainFromLocation } from 'selectors/location'
import { LOGOUT_PAGE } from 'constants/pages'
import {
  TOKEN_REFRESH_REQUEST,
  TOKEN_REFRESH_SUCCESS,
  TOKEN_REFRESH_FAILURE,
} from 'constants/action_types'
import grooveAPI from 'api/groove'
import storage from 'util/storage'
import Bugsnag from '@bugsnag/js'

const reportToBugsnag = message => {
  try {
    Bugsnag.notify(new Error(message), event => {
      // eslint-disable-next-line no-param-reassign
      event.severity = 'info'
    })
  } catch (e) {
    // eslint-disable-next-line no-console
    console.debug('Failed to report to Bugsnag', e)
    Bugsnag.notify(e)
  }
}

// NOTE: We hit this endpoint after a user as signed up in order to add the
// refresh token to the session.
export function doReloadTokenInfo() {
  return async dispatch => {
    const { token, previousToken } = storage.get('auth') || {}
    if (!token) return null

    const response = await grooveAPI.get(token, 'oauth/token/info', {})
    const { json } = response
    const { expires_in_seconds: expiresInSeconds } = json
    // If no expiresInSeconds response is returned by the server then don't
    // update the expiresAt in the store because that would immediately expire
    // the token and log the user out. This shouldn't happen but there may be
    // agents with weird local storage states right now. This whole PR can be
    // reverted next week.
    const expiresAt = expiresInSeconds
      ? Math.floor(new Date() / 1000 + expiresInSeconds)
      : null
    storage.set('auth', {
      authenticated: true,
      token,
      expiresAt,
      previousToken,
    })
    dispatch({
      type: TOKEN_REFRESH_SUCCESS,
      data: {
        token,
        expiresAt,
      },
    })
    return null
  }
}

export function doRefreshIfNecessary(timeOffset = null) {
  return async (dispatch, getState) => {
    const { token, expiresAt } = storage.get('auth') || {}
    if (!token) return null
    if (!expiresAt) return null

    // Time Offset or 30 mins from now. Doing this because there seems to be
    // a race condition happening sometimes when this code is run before
    // requests. The time offset allows us to pass in a different value, so the
    // global setInterval running in the App component refreshes before the race
    // condition can happen.
    const timestamp = timeOffset || Math.floor(Date.now() / 1000 + 1 * 60 * 30)
    if (expiresAt >= timestamp) return null

    const state = getState()
    const isRefreshing = selectIsRefreshingToken(state)

    if (isRefreshing) return null
    dispatch({ type: TOKEN_REFRESH_REQUEST })

    const url = `${config.api_url}/oauth/token`
    const headers = {
      'Content-Type': 'application/json',
      'X-Client-Tab-Id': getTabId(),
      'X-Client-App-Version': getVersion(),
    }
    // The account subdomain isn't in the store on first load, so fall back to
    // using the from location selector, which uses the URL.
    // There is an edge case in dev, where the url is mobile.groovehq.docker
    // which is not going to work either way.
    const subdomain =
      selectAccountSubdomain(state) || selectAccountSubdomainFromLocation(state)
    const body = {
      grant_type: 'refresh_token',
      client_id: config.oauthClientId,
      subdomain,
    }
    const request = new Request(url, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: new Headers(headers),
      credentials: 'include',
    })
    try {
      const response = await fetch(request)
      const parsed = await response.json()
      const { access_token: newToken, expires_in: expiresIn } = parsed
      const newExpiresAt = Math.floor(new Date().getTime() / 1000 + expiresIn)
      if (newToken) {
        storage.set('auth', {
          authenticated: true,
          token: newToken,
          expiresAt: newExpiresAt,
        })
        dispatch({
          type: TOKEN_REFRESH_SUCCESS,
          data: {
            token: newToken,
            expiresAt: newExpiresAt,
          },
        })
      } else {
        reportToBugsnag(`Failed to refresh token: ${JSON.stringify(parsed)}`)
        dispatch({ type: LOGOUT_PAGE })
      }
      return parsed
    } catch (e) {
      dispatch({ type: TOKEN_REFRESH_FAILURE })
      dispatch({ type: LOGOUT_PAGE })
      // reportToBugsnag(`Failed to refresh token: ${e.message}`)
      Bugsnag.notify(e)
      return null
    }
  }
}
