import { userResolution } from './filters'
import { adjustScopeConversationAssigneeToActionUser } from './scopes'

export const countMessagesSent = {
  adjustScope: adjustScopeConversationAssigneeToActionUser,
  analysisType: 'count',
  eventCollection: 'message.sent',
  filters: [userResolution],
  key: 'countMessagesSent',
}
