import grooveAPI from 'api/groove/api'
import config from 'config'
import debug from 'util/debug'
import storage from 'util/storage'

import { oauthTokenSelector } from 'selectors/app'

import { selectShouldFetchCustomersHelped } from './selectors'
import {
  FETCH_CUSTOMERS_HELPED_REQUEST,
  FETCH_CUSTOMERS_HELPED_RESPONSE,
  FETCH_CUSTOMERS_HELPED_ERROR,
} from './types'

const reportsUseProduction = storage.get('reportsUseProduction')
const groove = grooveAPI(
  reportsUseProduction ? config.production_api_url : config.api_url
)

function doFetchCustomersHelpedRequest() {
  return {
    type: FETCH_CUSTOMERS_HELPED_REQUEST,
  }
}

function doFetchCustomersHelpedResponse(result) {
  return {
    type: FETCH_CUSTOMERS_HELPED_RESPONSE,
    payload: {
      result,
    },
  }
}

function doFetchCustomersHelpedError() {
  return {
    type: FETCH_CUSTOMERS_HELPED_ERROR,
    error: true,
  }
}

export function doFetchCustomersHelped() {
  return (dispatch, getState) => {
    const state = getState()
    const token = oauthTokenSelector(state)
    if (!selectShouldFetchCustomersHelped(state)) return
    dispatch(doFetchCustomersHelpedRequest())
    groove
      .get(
        (reportsUseProduction && reportsUseProduction.token) || token,
        `/v2/reports/customers_helped`
      )
      .then(result => {
        dispatch(doFetchCustomersHelpedResponse(result.json))
      })
      .catch(e => {
        debug('Error in doFetchCustomersHelped', e)
        dispatch(doFetchCustomersHelpedError())
      })
  }
}
