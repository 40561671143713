export function flatternRoutes(ducks) {
  return Object.keys(ducks).reduce((result, duckName) => {
    const { routes } = ducks[duckName]
    if (routes) {
      Object.keys(routes).forEach(routeKey => {
        const route = routes[routeKey]
        if (route) result[routeKey] = route
      })
    }
    return result
  }, {})
}
