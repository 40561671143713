// Calculates and adds the query ID to the query object.

import { memoize } from 'util/memoization'

import { queryObjectToQueryId } from 'ducks/reports/queries'

export default memoize(applyIdToQuery)

function applyIdToQuery(query) {
  const { queryToId = queryObjectToQueryId } = query
  return {
    ...query,
    id: queryToId(query),
  }
}
