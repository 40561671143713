import Link from 'redux-first-router-link'
import { createSelector } from 'reselect'
import createCachedSelector from 're-reselect'
import { createBasicSelector } from 'util/redux'
import { emptyObj } from 'util/objects'
import { emptyArr, compact } from 'util/arrays'
import { selectRoomFromUrl } from 'ducks/chat/selectors/rooms'
import { selectWidgetFromUrl } from 'ducks/widgets/selectors/widgets'
import { selectFoldersById } from 'ducks/folders/selectors/folders'
import selectFolderItems from 'ducks/folders/selectors/items/selectItems'
import { matchFilter } from 'ducks/searches/utils/diff'
import {
  selectCurrentEntitiesStore,
  selectCurrentEntitiesNormalizrStore,
} from 'ducks/entities/selectors'
import { denormalize } from 'normalizr'
import entitySchemas from 'ducks/entities/schema'
import { selectCurrentPage } from 'selectors/location'
import { queryIdToQuery, removeKeysFromQueryId } from '../utils/query'

function selectBase(state) {
  return state.searchV2 || emptyObj
}

export const selectByQueryId = createBasicSelector(
  selectBase,
  base => base.byQueryId
)

// Deprecated, query id should be tracked outside of the searches duck
export const selectCurrentQueryId = createBasicSelector(
  selectBase,
  base => base.currentQueryId
)

export const selectCurrentQueryOrderBy = createBasicSelector(
  selectCurrentQueryId,
  queryId => {
    const { orderBy } = queryIdToQuery(queryId) || {}

    return orderBy
  }
)

export const selectCurrentQueryOrderByOrDefault = createBasicSelector(
  selectCurrentQueryOrderBy,
  orderBy => orderBy || 'LAST_USER_OR_AGENT_MESSAGE_AT_DESC'
)

export const selectLastUpdatedAt = createBasicSelector(
  selectBase,
  base => base.lastUpdatedAt
)

export function selectQueryIdFromProps(_, props) {
  return props.queryId || null
}

function selectQueryId(_state, queryId) {
  return removeKeysFromQueryId(['cursor'], queryId)
}

function selectQueryIdAndEntityId(_state, queryId, entitiyId) {
  return `${removeKeysFromQueryId(['cursor'], queryId)}-${entitiyId}`
}

export const selectSearchByQueryId = createCachedSelector(
  selectByQueryId,
  selectQueryId,
  (byQueryId, queryId) => {
    return byQueryId[queryId] || emptyObj
  }
)(selectQueryId)

export const selectSearchTotalCountByQueryId = createCachedSelector(
  selectSearchByQueryId,
  searchByQueryId => searchByQueryId.entityCount || 0
)(selectQueryId)

export const selectSearchFilterByQueryId = createCachedSelector(
  selectSearchByQueryId,
  searchByQueryId => searchByQueryId.filter || emptyObj
)(selectQueryId)

export const selectSearchIsLoadingByQueryId = createCachedSelector(
  selectSearchByQueryId,
  searchByQueryId => !!searchByQueryId.loading
)(selectQueryId)

export const selectSearchIsLoadedByQueryId = createCachedSelector(
  selectSearchByQueryId,
  searchByQueryId => !!searchByQueryId.loaded
)(selectQueryId)

export const selectSearchCurrentPageCursorByQueryId = createCachedSelector(
  selectSearchByQueryId,
  searchByQueryId => searchByQueryId.currentPageCursor || null
)(selectQueryId)

export const selectSearchNextPageCursorByQueryId = createCachedSelector(
  selectSearchByQueryId,
  searchByQueryId => {
    return searchByQueryId.nextPageCursor || null
  }
)(selectQueryId)

export const selectSearchHasMoreByQueryId = createCachedSelector(
  selectSearchByQueryId,
  searchByQueryId => searchByQueryId.hasAllPages !== true
)(selectQueryId)

export const selectSearchEntityIdsByQueryId = createCachedSelector(
  selectSearchByQueryId,
  searchByQueryId => searchByQueryId.entityIds || emptyArr
)(selectQueryId)

export const selectSearchEntitiesByQueryId = createCachedSelector(
  selectSearchByQueryId,
  selectCurrentEntitiesStore,
  (searchByQueryId, entitiesStore) => {
    const { entityType } = searchByQueryId.filter || {}
    if (!entityType) return emptyArr
    if (!entitiesStore[entityType]) return emptyArr
    const entityIds = searchByQueryId.entityIds || emptyArr
    const entityById = entitiesStore[entityType].byId

    return entityIds.map(entityId => entityById[entityId]).filter(e => !!e)
  }
)(selectQueryId)

export const selectSearchEntitiesDenormalizedByQueryId = createCachedSelector(
  selectSearchByQueryId,
  selectCurrentEntitiesNormalizrStore,
  (searchByQueryId, currentEntitiesNormalizrStore) => {
    const { entityType } = searchByQueryId.filter || {}
    if (!entityType) return emptyArr
    if (!currentEntitiesNormalizrStore[entityType]) return emptyArr
    const entityIds = searchByQueryId.entityIds || emptyArr

    return compact(
      denormalize(
        entityIds,
        [entitySchemas[entityType]],
        currentEntitiesNormalizrStore
      )
    )
  }
)(selectQueryId)

export const selectSearchCursorByQueryIdAndCursor = createCachedSelector(
  selectSearchByQueryId,
  (_state, _queryId, cursor) => cursor,
  (searchByQueryId, cursor) =>
    (searchByQueryId.cursors ? searchByQueryId.cursors[cursor] : emptyObj) ||
    emptyObj
)((_state, queryId, cursor) => `${queryId}-${cursor}`)

export const selectSearchEntityIdsByQueryIdAndCursor = createCachedSelector(
  selectSearchCursorByQueryIdAndCursor,
  searchCursor => searchCursor.entityIds || emptyArr
)((_state, queryId, cursor) => `${queryId}-${cursor}`)

const queryIdAndCursor = (_state, queryId, cursor) => `${queryId}-${cursor}`
export const selectSearchEntitiesByQueryIdAndCursor = createCachedSelector(
  selectSearchByQueryId,
  selectSearchEntityIdsByQueryIdAndCursor,
  selectCurrentEntitiesStore,
  (searchByQueryId, searchEntityIds, entitiesStore) => {
    const { entityType } = searchByQueryId.filter || {}
    if (!entityType) return emptyArr
    if (!entitiesStore[entityType]) return emptyArr
    const entityById = entitiesStore[entityType].byId

    return searchEntityIds
      .map(entityId => entityById[entityId])
      .filter(e => !!e)
  }
)(queryIdAndCursor)

export const selectSearchEntitiesDenormalizedByQueryIdAndCursor = createCachedSelector(
  selectSearchByQueryId,
  selectSearchEntityIdsByQueryIdAndCursor,
  selectCurrentEntitiesNormalizrStore,
  (searchByQueryId, searchEntityIds, currentEntitiesNormalizrStore) => {
    const { entityType } = searchByQueryId.filter || {}
    if (!entityType) return emptyArr
    if (!currentEntitiesNormalizrStore[entityType]) return emptyArr

    return compact(
      denormalize(
        searchEntityIds,
        [entitySchemas[entityType]],
        currentEntitiesNormalizrStore
      )
    )
  }
)(queryIdAndCursor)

export const selectSearchIsLoadingMoreByQueryId = createCachedSelector(
  selectSearchIsLoadingByQueryId,
  selectSearchIsLoadedByQueryId,
  (isLoading, isLoaded) => isLoading && isLoaded
)(selectQueryId)

export const selectCurrentSearchIsError = createSelector(
  selectByQueryId,
  selectCurrentQueryId,
  (byQueryId, queryId) => !!(byQueryId[queryId] || emptyObj).error
)

export const selectSearchIsErrorByQueryId = createCachedSelector(
  selectSearchByQueryId,
  searchByQueryId => !!searchByQueryId.error
)(selectQueryId)

export const selectEntityCountByQueryId = createCachedSelector(
  selectSearchByQueryId,
  searchByQueryId => searchByQueryId.entityCount || 0
)(selectQueryId)

export const selectConversationUnreadCountByQueryId = createCachedSelector(
  selectByQueryId,
  selectQueryId,
  selectRoomFromUrl,
  selectFoldersById,
  (searchesByQueryId, queryId, roomFromUrl, foldersById) => {
    if (!queryId.includes('folder:')) return null

    const unreadQueryId = queryId.replace('folder:', 'folderunread:')
    const { entityCount = 0 } = searchesByQueryId[unreadQueryId] || emptyObj
    const isRoomOpen = !!roomFromUrl.id

    // While a conversation is open, we want to always regard is as "read"
    if (
      entityCount > 0 &&
      isRoomOpen &&
      !roomFromUrl.isRead &&
      !roomFromUrl.ignoreNextClose
    ) {
      const { folderId } = queryIdToQuery(queryId) || {}
      const folder = foldersById[folderId]
      const isInFolder = matchFilter(folder, roomFromUrl)
      if (isInFolder) return entityCount - 1
    }

    return entityCount
  }
)(selectQueryId)

export const selectUnreadByChannelType = createSelector(
  selectFolderItems,
  selectRoomFromUrl,
  selectByQueryId,
  selectFoldersById,
  (folderItems, roomFromUrl, searchesByQueryId, foldersById) => {
    const results = folderItems
      .filter(f => f.displayUnreadIndicator)
      .reduce((unreadByChannelType, folderItem) => {
        const { queryId } = folderItem
        const unreadQueryId = queryId.replace('folder:', 'folderunread:')
        let { entityCount = 0 } = searchesByQueryId[unreadQueryId] || emptyObj
        const { folderId, type } = queryIdToQuery(queryId) || {}

        const isRoomOpen = !!roomFromUrl.id
        if (
          entityCount > 0 &&
          isRoomOpen &&
          !roomFromUrl.isRead &&
          !roomFromUrl.ignoreNextClose
        ) {
          const folder = foldersById[folderId]
          const isInFolder = matchFilter(folder, roomFromUrl)
          if (isInFolder) entityCount -= 1
        }
        if (unreadByChannelType[type] === undefined) {
          Object.assign(unreadByChannelType, { [type]: false })
        }
        Object.assign(unreadByChannelType, {
          [type]: unreadByChannelType[type] || entityCount > 0,
        })
        return unreadByChannelType
      }, {})
    return results
  }
)

export const selectSortKeyForAgent = () => 'LAST_USER_OR_AGENT_MESSAGE_AT'

const hackAgentSortOptions = [
  {
    id: 'newest',
    name: 'Newest',
    selectedOptionText: 'Newest first',
    orderBy: 'LAST_USER_OR_AGENT_MESSAGE_AT_DESC',
  },
  {
    id: 'oldest',
    name: 'Oldest',
    selectedOptionText: 'Oldest first',
    orderBy: 'LAST_USER_OR_AGENT_MESSAGE_AT_ASC',
  },
  {
    id: 'longestWaiting',
    name: 'Waiting Longest',
    selectedOptionText: 'Waiting Longest',
    orderBy: 'LAST_UNANSWERED_USER_MESSAGE_AT_ASC',
  },
]

export const selectAgentSortOptions = createSelector(
  selectWidgetFromUrl,
  widget => {
    return widget?.kind !== 'facebook'
      ? hackAgentSortOptions
      : [
          {
            id: 'expiringSoon',
            name: 'Expiring soonest',
            selectedOptionText: 'Expiring soonest',
            orderBy: 'EXPIRES_AT_ASC',
          },
          ...hackAgentSortOptions,
        ]
  }
)

export const selectSortOptions = createSelector(
  selectCurrentQueryId,
  selectAgentSortOptions,
  selectCurrentPage,
  selectCurrentQueryOrderBy,
  (currentQueryId, agentSortOptions, currentPageType, currentOrderBy) => {
    return agentSortOptions.map(
      ({ id, name, orderBy, selectedOptionText }) => ({
        active: currentOrderBy === orderBy,
        as: Link,
        key: id,
        text: name,
        selectedOptionText,
        to: {
          type: currentPageType,
          payload: {
            query: queryIdToQuery(`${currentQueryId} orderBy:${orderBy}`),
          },
        },
        value: id,
      })
    )
  }
)

export const selectSearchNextEntityIdByQueryIdAndEntityId = createCachedSelector(
  selectSearchEntityIdsByQueryId,
  (_state, _queryId, entityId) => entityId,
  (ids, entityId) => {
    if (!ids) return null
    if (!entityId) return null
    const currentEntityIndex = ids.indexOf(entityId)
    return ids[currentEntityIndex + 1]
  }
)(selectQueryIdAndEntityId)

export const selectSearchPreviousEntityIdByQueryIdAndEntityId = createCachedSelector(
  selectSearchEntityIdsByQueryId,
  (_state, _queryId, entityId) => entityId,
  (ids, entityId) => {
    if (!ids) return null
    if (!entityId) return null
    const currentEntityIndex = ids.indexOf(entityId)
    return ids[currentEntityIndex - 1]
  }
)(selectQueryIdAndEntityId)
