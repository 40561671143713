import { Grid } from 'util/ui'
import { formatNumber } from 'util/numbers'

export default function SingleRating({ rating, total }) {
  let emoji
  switch (rating) {
    case 0:
      emoji = '😣'
      break
    case 3:
      emoji = '😬'
      break
    case 7:
      emoji = '😃'
      break
    case 10:
      emoji = '😄'
      break
    default:
      emoji = '❓'
      break
  }

  const formattedNumber = formatNumber(total)

  return (
    <Grid.Column>
      <span>
        {emoji} {formattedNumber !== 'NaN' ? formattedNumber : '-'}
      </span>
    </Grid.Column>
  )
}
