import colorizeDataSet from './colorizeDataSet'

// We could memoize this but at this point there is so much data in gauges
// That it's quicker to recalculate the colors than it is to serialize and look
// up the cache.
export default function colorizeDataSetsOnGauges(gauges) {
  const isSingleDataSet =
    gauges
      .map(({ dataSets: { length } = [] }) => length)
      .reduce((result, value) => result + value, 0) === 1
  let index = -1
  return gauges.map(gauge => {
    const { dataSets = [] } = gauge
    return {
      ...gauge,
      dataSets: dataSets.map(dataSet => {
        index += 1
        return colorizeDataSet(dataSet, index, isSingleDataSet)
      }),
    }
  })
}
