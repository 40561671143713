import { connect } from 'react-redux'

import { selectRatingByGradeGauge } from 'ducks/reports/gauges'
import {
  ProgressBarVisualization,
  grooveTheme,
} from 'ducks/reports/visualizations'

const { dust, green, red } = grooveTheme.colors

const staticSelect = {
  colorScale: [green, dust, red],
}
const select = (state, props) => {
  return {
    ...staticSelect,
    gauge: selectRatingByGradeGauge(state, props),
  }
}
export default connect(select)(ProgressBarVisualization)
