import createCachedSelector from 're-reselect'

import fullPropsSetCacheKey from './cacheKeys/fullPropsSetCacheKey'
import selectDataSets from './selectDataSets'

const DEFAULT_SCALE = { x: 'time', y: 'linear' }
const SCALE_TYPES = {
  change: 'linear',
  duration: 'linear',
  percent: 'linear',
}

export default createCachedSelector(
  selectDataSets,
  (state, { scale }) => scale,
  (dataSets, scale = {}) => {
    const { scale: dataSetScale = DEFAULT_SCALE } = dataSets[0] || {}
    const propScale = {}
    const { x, y } = scale
    if (!x && !y) return dataSetScale
    if (x) {
      propScale.x = SCALE_TYPES[x] || x
      if (x === 'change') propScale.formatXAs = 'change'
      if (x === 'duration') propScale.formatXAs = 'duration'
      if (x === 'percent') propScale.formatXAs = 'percent'
    }
    if (y) {
      propScale.y = SCALE_TYPES[y] || y
      if (y === 'change') propScale.formatYAs = 'change'
      if (y === 'duration') propScale.formatYAs = 'duration'
      if (y === 'percent') propScale.formatYAs = 'percent'
    }

    return {
      ...dataSetScale,
      ...propScale,
    }
  }
)(fullPropsSetCacheKey)
