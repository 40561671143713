import { isEmpty, omit } from 'util/objects'
import storage from 'util/storage'

import { statusStates } from 'constants/status_states'
import { getRawId } from 'util/globalId'
import {
  BOOTSTRAP_WIDGETS,
  FETCH_WIDGETS_REQUEST,
  FETCH_WIDGETS_SUCCESS,
  FETCH_WIDGETS_FAILED,
  FETCH_WIDGET_SETTINGS_REQUEST,
  FETCH_WIDGET_SETTINGS_SUCCESS,
  FETCH_WIDGET_SETTINGS_FAILURE,
  FETCH_WIDGET_SNIPPET_REQUEST,
  FETCH_WIDGET_SNIPPET_SUCCESS,
  FETCH_WIDGET_SNIPPET_FAILURE,
  UPDATE_WIDGET_SETTINGS_STARTED,
  UPDATE_WIDGET_SETTINGS_SUCCESS,
  UPDATE_WIDGET_SETTINGS_FAILED,
  PUBLISH_WIDGET_SETTINGS_STARTED,
  PUBLISH_WIDGET_SETTINGS_SUCCESS,
  PUBLISH_WIDGET_SETTINGS_FAILED,
  REVERT_WIDGET_SETTINGS_REQUEST,
  REVERT_WIDGET_SETTINGS_SUCCESS,
  REVERT_WIDGET_SETTINGS_FAILURE,
  CREATE_WIDGET_STARTED,
  CREATE_WIDGET_SUCCESS,
  CREATE_WIDGET_FAILED,
  UPDATE_WIDGET_STARTED,
  UPDATE_WIDGET_SUCCESS,
  DELETE_WIDGET_STARTED,
  UPDATE_WIDGET_FAILED,
  DELETE_WIDGET_SUCCESS,
  DELETE_WIDGET_FAILED,
  UPDATE_WIDGET_PREVIEW_MESSAGE,
  CREATE_DRAFT_WIDGET_REQUEST,
  CREATE_DRAFT_WIDGET_SUCCESS,
  UPDATE_DRAFT_WIDGET,
  DESTROY_DRAFT_WIDGET,
  FETCH_WIDGET_BYID_SUCCESS,
} from './types'

const draftKey = 'groove-draftWidget'

const defaultState = {
  byId: {},
  ids: [],
  isLoading: false,
  isSaving: false,
  bootstrapStatus: statusStates.IDLE,
  settingsFetchingStatusById: {},
  settings: {},
  snippet: '',
  error: null,
  isCreatingDraftWidget: false,
  draftWidget: JSON.parse(sessionStorage.getItem(draftKey)),
  draftWidgetErrors: {},
  recentlyCreatedWidgetId: null, // NOTE (jscheel): We keep this ref so we can load the installation
}

const reducers = {}

reducers[BOOTSTRAP_WIDGETS] = state => {
  return {
    ...state,
    bootstrapStatus: statusStates.PENDING,
  }
}

reducers[FETCH_WIDGETS_REQUEST] = state => {
  return {
    ...state,
    isLoading: true,
  }
}

reducers[FETCH_WIDGETS_SUCCESS] = (state, action) => {
  const {
    payload: { widgets: data },
  } = action

  const newById = {}
  const newIds = []

  data.forEach(widget => {
    newById[widget.id] = { ...state.byId[widget.id], ...widget }
    newIds.push(widget.id)
  })

  // NOTE (jscheel): We want to re-validate the draft widget here in case it was
  // hydrated from localStorage, because some of the widget validation relies on
  // other widgets to check for uniqueness.
  return validateDraftWidget({
    ...state,
    byId: { ...state.byId, ...newById },
    ids: newIds,
    isLoading: false,
    bootstrapStatus: statusStates.SUCCESS,
  })
}

reducers[FETCH_WIDGETS_FAILED] = (state, action) => {
  const {
    payload: { error },
  } = action

  return {
    ...state,
    isLoading: false,
    error,
  }
}

reducers[FETCH_WIDGET_SETTINGS_REQUEST] = (state, action) => {
  const {
    payload: { widgetId },
  } = action
  return {
    ...state,
    isLoading: true,
    settingsFetchingStatusById: {
      ...state.settingsFetchingStatusById,
      [widgetId]: statusStates.PENDING,
    },
  }
}

reducers[FETCH_WIDGET_SETTINGS_SUCCESS] = (state, action) => {
  const {
    payload: { widgetId, settings },
  } = action

  return {
    ...state,
    settings: {
      ...state.settings,
      [widgetId]: omit(['primaryColorRgb'], settings),
    },
    isLoading: false,
    settingsFetchingStatusById: {
      ...state.settingsFetchingStatusById,
      [widgetId]: statusStates.SUCCESS,
    },
  }
}

reducers[FETCH_WIDGET_SETTINGS_FAILURE] = (state, action) => {
  const {
    payload: { error, widgetId },
  } = action

  return {
    ...state,
    isLoading: false,
    error,
    settingsFetchingStatusById: {
      ...state.settingsFetchingStatusById,
      [widgetId]: statusStates.ERROR,
    },
  }
}

reducers[FETCH_WIDGET_SNIPPET_REQUEST] = state => {
  return {
    ...state,
    isLoading: true,
  }
}

reducers[FETCH_WIDGET_SNIPPET_SUCCESS] = (state, action) => {
  const {
    payload: { snippet },
  } = action

  return {
    ...state,
    snippet,
    isLoading: false,
  }
}

reducers[FETCH_WIDGET_SNIPPET_FAILURE] = (state, action) => {
  const {
    payload: { error },
  } = action

  return {
    ...state,
    isLoading: false,
    error,
  }
}

reducers[UPDATE_WIDGET_SETTINGS_STARTED] = (state, action) => {
  const {
    payload: {
      settings,
      widgetId,
      options: { commitType },
    },
  } = action

  const originalSettings = state.settings[widgetId] || {}

  const newState = {
    ...state,
    settings: {
      ...state.settings,
      [widgetId]: {
        ...originalSettings,
        ...settings,
        published: commitType === 'MERGE' ? settings.published : false,
      },
    },
    isSaving: true,
  }

  if (commitType === 'MERGE') {
    return {
      ...newState,
      byId: {
        ...newState.byId,
        [widgetId]: {
          ...newState.byId[widgetId],
          publishedSettings: omit(['primaryColorRgb'], {
            ...newState.byId[widgetId].publishedSettings,
            ...settings,
          }),
        },
      },
    }
  }

  return newState
}

reducers[UPDATE_WIDGET_SETTINGS_SUCCESS] = state => {
  // Dont update the local settings state after the server
  // responds. This causes the ui to flicker when multiple
  // changes are made in quick succession. It also causes
  // issues when the save operations return out of order
  // due to fluctuating reserver response times
  return {
    ...state,
    isSaving: false,
    error: null,
  }
}

reducers[UPDATE_WIDGET_SETTINGS_FAILED] = (state, action) => {
  const {
    payload: { error },
  } = action

  return {
    ...state,
    isSaving: false,
    error,
  }
}

reducers[PUBLISH_WIDGET_SETTINGS_STARTED] = state => {
  return {
    ...state,
    isSaving: true,
  }
}

reducers[PUBLISH_WIDGET_SETTINGS_SUCCESS] = (state, action) => {
  const {
    payload: { widgetId, settings },
  } = action

  return {
    ...state,
    settings: {
      ...state.settings,
      [widgetId]: omit(['primaryColorRgb'], settings),
    },
    byId: {
      ...state.byId,
      [widgetId]: {
        ...state.byId[widgetId],
        publishedSettings: omit(['primaryColorRgb'], settings),
      },
    },
    isSaving: false,
    error: null,
  }
}

reducers[PUBLISH_WIDGET_SETTINGS_FAILED] = (state, action) => {
  const {
    payload: { error },
  } = action

  return {
    ...state,
    isSaving: false,
    error,
  }
}

reducers[REVERT_WIDGET_SETTINGS_REQUEST] = state => {
  return {
    ...state,
    isSaving: true,
  }
}

reducers[REVERT_WIDGET_SETTINGS_SUCCESS] = (state, action) => {
  const {
    payload: { widgetId, settings },
  } = action

  return {
    ...state,
    settings: {
      ...state.settings,
      [widgetId]: omit(['primaryColorRgb'], settings),
    },
    isSaving: false,
    error: null,
  }
}

reducers[REVERT_WIDGET_SETTINGS_FAILURE] = (state, action) => {
  const {
    payload: { error },
  } = action

  return {
    ...state,
    isSaving: false,
    error,
  }
}

reducers[CREATE_WIDGET_STARTED] = state => {
  return {
    ...state,
    isSaving: true,
  }
}

reducers[CREATE_WIDGET_SUCCESS] = (state, action) => {
  const {
    payload: { widget },
  } = action
  const rawId = getRawId(widget.id)
  const newState = {
    ...state,
    ids: [...state.ids, rawId],
    byId: {
      ...state.byId,
      // NOTE: HACK! There is a race condition on widget creation, and the
      // has_access flag is not picked up. So, hard-code it here, which will
      // be updated on the next serialization.
      [rawId]: omit(['primaryColorRgb'], {
        ...widget,
        id: rawId,
        hasAccess: true,
      }),
    },
    isSaving: false,
    error: null,
    recentlyCreatedWidgetId: rawId,
  }

  return destroyDraftWidget(newState)
}

reducers[CREATE_WIDGET_FAILED] = (state, action) => {
  const {
    payload: { error },
  } = action

  return {
    ...state,
    isSaving: false,
    error,
  }
}

reducers[UPDATE_WIDGET_STARTED] = state => {
  return {
    ...state,
    isSaving: true,
  }
}

reducers[UPDATE_WIDGET_SUCCESS] = (state, action) => {
  const {
    payload: { widget },
  } = action

  return {
    ...state,
    byId: {
      ...state.byId,
      [widget.id]: widget,
    },
    isSaving: false,
    error: null,
  }
}

reducers[FETCH_WIDGET_BYID_SUCCESS] = (state, action) => {
  const { payload: widget } = action

  return {
    ...state,
    byId: {
      ...state.byId,
      [getRawId(widget.id)]: {
        ...widget,
        id: getRawId(widget.id),
      },
    },
    isSaving: false,
    error: null,
  }
}

reducers[UPDATE_WIDGET_FAILED] = (state, action) => {
  const {
    payload: { error },
  } = action

  return {
    ...state,
    isSaving: false,
    error,
  }
}

reducers[DELETE_WIDGET_STARTED] = state => {
  return {
    ...state,
    isSaving: true,
  }
}

reducers[DELETE_WIDGET_SUCCESS] = (state, action) => {
  const {
    payload: { widget },
  } = action
  const widgetId = widget?.id
  return {
    ...state,
    ids: state.ids.filter(id => id !== widgetId),
    byId: omit([widgetId], state.byId),
    settings: omit([widgetId], state.settings),
    isSaving: false,
    error: null,
  }
}

reducers[DELETE_WIDGET_FAILED] = (state, action) => {
  const {
    payload: { error },
  } = action

  return {
    ...state,
    isSaving: false,
    error,
  }
}

reducers[UPDATE_WIDGET_PREVIEW_MESSAGE] = (state, action) => {
  const {
    payload: { hide },
  } = action

  storage.set('hideWidgetPreviewMessage', hide)
  return {
    ...state,
    hidePreviewMessage: hide,
  }
}

reducers[CREATE_DRAFT_WIDGET_REQUEST] = state => {
  return {
    ...state,
    isCreatingDraftWidget: true,
  }
}

function validateDraftWidget(state) {
  const draftWidget = state.draftWidget
  let errors = {}
  if (!draftWidget) return state
  if (!draftWidget.name) {
    errors = { ...errors, name: 'Please give your widget a name' }
  }
  if (Object.values(state.byId || {}).some(w => w.name === draftWidget.name)) {
    errors = { ...errors, name: 'Another widget already has this name' }
  }

  if (isEmpty(state.draftWidgetErrors) && isEmpty(errors)) return state
  return {
    ...state,
    draftWidgetErrors: errors,
  }
}

reducers[CREATE_DRAFT_WIDGET_SUCCESS] = (state, { payload: { settings } }) => {
  const draftWidget = {
    name: '',
    settings: { ...settings },
  }

  sessionStorage.setItem(draftKey, JSON.stringify(draftWidget))

  return validateDraftWidget({
    ...state,
    isCreatingDraftWidget: false,
    draftWidget,
  })
}

reducers[UPDATE_DRAFT_WIDGET] = (
  state,
  { payload: { widgetFields, settings } }
) => {
  const draftWidget = {
    ...state.draftWidget,
    ...widgetFields,
    settings: {
      ...state.draftWidget.settings,
      ...settings,
    },
  }

  sessionStorage.setItem(draftKey, JSON.stringify(draftWidget))

  return validateDraftWidget({
    ...state,
    draftWidget,
  })
}

reducers[DESTROY_DRAFT_WIDGET] = state => {
  return destroyDraftWidget(state)
}

function destroyDraftWidget(state) {
  sessionStorage.removeItem(draftKey)
  return {
    ...state,
    draftWidget: null,
    draftWidgetErrors: {},
  }
}

function maybeDestroyDraftWidget(state, action) {
  // NOTE (jscheel): When the user leaves the create wizard, we no longer want
  // to maintain the draft, and we want to forget about the recentlyCreatedWidgetId.
  const { type } = action
  if (type.endsWith('_PAGE') && !type.startsWith('SETTINGS_WIDGET_CREATE_')) {
    return {
      ...state,
      ...destroyDraftWidget(state),
      recentlyCreatedWidgetId: null,
    }
  }
  return state
}

export default function reducer(state = defaultState, action) {
  const handler = reducers[action.type]
  let newState = state
  if (handler) newState = handler(newState, action)
  return maybeDestroyDraftWidget(newState, action)
}
