import { createSelector } from 'reselect'
import {
  selectCurrentPath as currentPathSelector,
  selectCurrentPage,
} from 'selectors/location'
import { SETTINGS_WIDGET_MANAGEMENT_PAGE } from 'constants/pages'
import {
  SETTINGS_WIDGET_CREATE_CUSTOMIZE_PAGE,
  SETTINGS_WIDGET_CREATE_INSTALL_PAGE,
  SETTINGS_WIDGET_CREATE_NEW_PAGE,
} from 'subapps/settings/types'
import { selectRecentlyCreatedWidgetId } from './widgets'

export const selectWidgetsIsOnPage = createSelector(
  currentPathSelector,
  path => {
    if (!path) return false
    if (path.match(/\/settings\/integrations\/widgets/)) {
      return true
    }
    return false
  }
)

export const selectIsOnCreatePage = createSelector(
  selectCurrentPage,
  currentPage =>
    currentPage && currentPage.startsWith('SETTINGS_WIDGET_CREATE_')
)

const selectCreatePagesSteps = createSelector(() => {
  // As the wizard gets more complicated, we can add logic in here to return
  // different arrays of steps. Each set of steps must always end with the
  // SETTINGS_WIDGET_CREATE_INSTALL_PAGE page.
  const pages = [
    {
      page: SETTINGS_WIDGET_CREATE_NEW_PAGE,
      description:
        'Give your widget a name and choose which features you would like to enable.',
    },
    {
      page: SETTINGS_WIDGET_CREATE_CUSTOMIZE_PAGE,
      description: "Let's customize your widget.",
    },
  ]

  pages.push({
    page: SETTINGS_WIDGET_CREATE_INSTALL_PAGE,
    description: 'Install your widget on your site.',
  })

  return pages
})

const selectCreatePagesArray = createSelector(
  selectCreatePagesSteps,
  createPages => createPages.map(p => p.page)
)

export const selectPreviousCreatePageRoute = createSelector(
  selectCurrentPage,
  selectCreatePagesArray,
  (currentPage, createPages) => {
    // NOTE (jscheel): Once the wizard is finished and the widget has been created,
    // we don't let the user go back in the process. They can just edit the widget
    // in the normal settings at this point.
    if (currentPage === SETTINGS_WIDGET_CREATE_INSTALL_PAGE) return null
    const idx = createPages.indexOf(currentPage)
    const prevPage = createPages[idx - 1]
    if (prevPage) {
      return {
        type: prevPage,
      }
    }
    return null
  }
)

export const selectNextCreatePageRoute = createSelector(
  selectCurrentPage,
  selectCreatePagesArray,
  selectRecentlyCreatedWidgetId,
  (currentPage, createPages, recentWidgetId) => {
    const nextPage = createPages[createPages.indexOf(currentPage) + 1]
    if (nextPage) {
      return {
        type: nextPage,
      }
    }
    return {
      type: SETTINGS_WIDGET_MANAGEMENT_PAGE,
      payload: {
        id: recentWidgetId,
      },
    }
  }
)

export const selectIsFirstCreateStep = createSelector(
  selectCurrentPage,
  selectCreatePagesArray,
  (currentPage, createPages) => createPages.indexOf(currentPage) === 0
)

export const selectIsFinalCreateStep = createSelector(
  selectCurrentPage,
  currentPage => currentPage === SETTINGS_WIDGET_CREATE_INSTALL_PAGE
)

// NOTE (jscheel): Naming is hard
export const selectShouldCreateWidgetFromDraftBeforeNextStep = createSelector(
  selectNextCreatePageRoute,
  nextPageRoute => nextPageRoute?.type === SETTINGS_WIDGET_CREATE_INSTALL_PAGE
)

export const selectCreateStepProgress = createSelector(
  selectCurrentPage,
  selectCreatePagesSteps,
  (currentPage, createPagesSteps) => {
    const currentStepIdx = createPagesSteps.findIndex(
      p => p.page === currentPage
    )

    return {
      step: currentStepIdx + 1,
      of: createPagesSteps.length,
      description: createPagesSteps[currentStepIdx].description,
    }
  }
)
