import moment from 'moment-timezone-all'

export default function adjustTimeframesToUTC(query, offset) {
  if (!query) return query
  const { result, timezone } = query
  if (result && result.map) {
    return {
      ...query,
      result: result.map(x => {
        if (x.timeframe) {
          return {
            ...x,
            timeframe: {
              end: moment
                .tz(x.timeframe.end, timezone)
                .utcOffset(offset, true)
                .utcOffset(offset)
                .format(),
              start: moment
                .tz(x.timeframe.start, timezone)
                .utcOffset(offset, true)
                .utcOffset(offset)
                .format(),
            },
          }
        }
        return x
      }),
    }
  }
  return query
}
