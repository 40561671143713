import { compose } from 'redux'
import { connect } from 'react-redux'
import {
  doShowTicketPreviewModal,
  doHideTicketPreviewModal,
} from 'actions/modals'
import { makeIsTicketPreviewOpen } from 'selectors/modals'

import View from './view'

const select = () => {
  const selectIsModalOpen = makeIsTicketPreviewOpen()
  return (state, props) => {
    return {
      isModalOpen: selectIsModalOpen(state, props),
    }
  }
}

const perform = {
  showTicketPreviewModal: doShowTicketPreviewModal,
  hideTicketPreviewModal: doHideTicketPreviewModal,
}

export default compose(connect(select, perform), View)
