import { totalConversationsAssignedGauge } from 'ducks/reports/gauges'
import { HeadlineVisualization } from 'ducks/reports/visualizations'

const staticProps = {
  gauges: [totalConversationsAssignedGauge],
  title: 'Total Conversations',
}

export default function TotalConversationsAssignedHeadline(props) {
  return <HeadlineVisualization {...props} {...staticProps} />
}
