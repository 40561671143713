import { countUniqueFailedSearchesOnSearchId } from '../queries/countUniqueFailedSearchesOnSearchId'

export const totalFailedSearchesByTermGauge = {
  id: 'total_search_by_term',
  isNegativeChangeGood: true,
  queries: [
    {
      ...countUniqueFailedSearchesOnSearchId,
      groupBy: 'search.term',
    },
  ],
  title: 'Failed searches by term',
}
