import cn from 'classnames'
import React from 'react'

import { propFunc } from 'util/functions'
import { Icon } from 'util/ui'

import DatePickerInput from './DatePickerInput'
import { START_DATE, END_DATE } from './constants'
import styles from './styles.less'

export default function DatePickerDropdown({
  endDate,
  isOpen,
  onFocusChange,
  startDate,
  className,
  dropdownLabel = 'Current Period:',
}) {
  return (
    <div
      className={cn(
        styles.DatePickerDropdown,
        {
          [styles.DatePickerDropdown_active]: isOpen,
        },
        className
      )}
      data-test-id="datePickerDropdown"
      onClick={propFunc(onFocusChange, START_DATE)}
    >
      <span className={styles.DatePickerDropdown_label}>{dropdownLabel}</span>
      <span className={styles.DatePickerDropdown_period}>
        <DatePickerInput
          date={startDate}
          onClick={onFocusChange}
          type={START_DATE}
        />
        <span> - </span>
        <DatePickerInput
          date={endDate}
          onClick={onFocusChange}
          type={END_DATE}
        />
        <span>&nbsp;</span>
        <Icon name="caret down" />
      </span>
    </div>
  )
}
