import { single } from '../calculators/single'
import { countConversationsCreatedUniqueCustomer } from '../queries/countConversationsCreatedUniqueCustomer'

export const customersForEachDayGauge = {
  calculate: single,
  queries: [
    {
      ...countConversationsCreatedUniqueCustomer,
      interval: 'daily',
      key: 'countConversationsCreatedUniqueCustomerForEachDay',
    },
  ],
  title: 'Customers',
}
