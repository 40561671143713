import { connect } from 'react-redux'

import { selectActiveGauge } from '../../selectors'
import ProductivityOverviewHeadlineLineView from './ProductivityOverviewHeadlineLineView'

const PRODUCTIVITY_GAUGE_SWITCH = 'PRODUCTIVITY_GAUGE_SWITCH'
const PRODUCTIVITY_GAUGE_SWITCH_PROPS = {
  gaugeSwitchInstance: PRODUCTIVITY_GAUGE_SWITCH,
}
const select = state => ({
  activeGauge: selectActiveGauge(state, PRODUCTIVITY_GAUGE_SWITCH_PROPS),
  ...PRODUCTIVITY_GAUGE_SWITCH_PROPS,
})

export default connect(select)(ProductivityOverviewHeadlineLineView)
