import { single } from '../calculators/single'
import { averageResolutionTime } from '../queries/averageResolutionTime'
import { averageResolutionTimeWithinBusinessHours } from '../queries/averageResolutionTimeWithinBusinessHours'

export const averageResolutionTimeForEachDayGauge = {
  businessHoursQueries: [
    {
      ...averageResolutionTimeWithinBusinessHours,
      interval: 'daily',
    },
  ],
  calculate: single,
  queries: [
    {
      ...averageResolutionTime,
      interval: 'daily',
      key: 'averageResolutionTimeForEachDay',
    },
  ],
  title: '$Average Resolution Time',
}
