import React from 'react'

import formatAxisValueAs from '../../util/formatAxisValueAs'
import PercentGaugeIndividual from './PercentGaugeIndividual'

PercentGaugeView.propTypes = {}

export default function PercentGaugeView({
  dataSets,
  errored,
  includePreviousPeriod,
  loaded,
  loading,
  scale,
  title,
  gauge,
  gauges,
}) {
  const defaultGauge = gauge || gauges[0]
  if (errored) {
    return (
      <PercentGaugeIndividual
        errored
        includePreviousPeriod={includePreviousPeriod}
        loaded={false}
        loading={false}
        title={defaultGauge.title || title}
        timeframe={defaultGauge.timeframe}
        scale={scale}
      />
    )
  }
  if (loading || (!loading && !errored && !loaded)) {
    return (
      <PercentGaugeIndividual
        errored={false}
        includePreviousPeriod={includePreviousPeriod}
        loaded={false}
        loading
        title={defaultGauge.title || title}
        timeframe={defaultGauge.timeframe}
        scale={scale}
      />
    )
  }
  return dataSets.map(({ data, description, name, timeframe }, dsIndex) => {
    return data.map((datum, index) => {
      return (
        <PercentGaugeIndividual
          key={datum.id || `pc-${dsIndex}-${index}`}
          datum={datum}
          description={description}
          includePreviousPeriod={includePreviousPeriod}
          errored={errored}
          loaded={loaded}
          loading={loading}
          scale={scale}
          timeframe={timeframe}
          title={`${title || name}${
            datum.x ? ` ${formatAxisValueAs(null, datum.x)}` : ''
          }`}
        />
      )
    })
  })
}
