import { countMessageRatingsCreated } from '../queries/countMessageRatingsCreated'

export const ratingByGradeGauge = {
  expectedGroups: [0, 1, 2],
  queries: [
    {
      ...countMessageRatingsCreated,
      groupBy: 'action.change.grade_int',
    },
  ],
  scale: { x: 'rating' },
  title: 'Ratings by grade',
}
