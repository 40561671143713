import PropTypes from 'prop-types'

export const change = PropTypes.shape({
  difference: PropTypes.number.isRequired,
  isBad: PropTypes.bool.isRequired,
  isGood: PropTypes.bool.isRequired,
  isInfinity: PropTypes.bool.isRequired,
  isNegativeGood: PropTypes.bool,
  isNegativeChangeGood: PropTypes.bool,
  isNegative: PropTypes.bool.isRequired,
  isNeutral: PropTypes.bool.isRequired,
  isPositive: PropTypes.bool.isRequired,
  percent: PropTypes.number.isRequired,
  previous: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ratio: PropTypes.number.isRequired,
})

export const component = PropTypes.oneOfType([PropTypes.string, PropTypes.func])

export const queryBase = PropTypes.shape({
  timeframe: PropTypes.shape({
    end: PropTypes.string,
    start: PropTypes.string,
  }),
})
