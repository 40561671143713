import { css } from '@emotion/react'
import { rem } from '@groovehq/internal-design-system/lib/util/strings'

const container = css`
  padding: 0 ${rem(12)};
`

const card = css`
  margin-top: ${rem(120)};
  max-width: ${rem(393)};

  .activation-card-icon {
    padding: ${rem(6)};
  }
`

export const styles = {
  container,
  card,
}
