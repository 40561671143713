import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { setComponentState as setComponentStateAction } from './actions'
import { selectComponentStateById } from './selectors'

const select = (state, props) => {
  return {
    ...props,
    componentState: selectComponentStateById(state, props),
  }
}
const perform = {
  setComponentState: setComponentStateAction,
}

export default function withComponentState(ComposedComponent) {
  class ComponentState extends React.Component {
    static propTypes = {
      id: PropTypes.string.isRequired,
    }

    constructor(...args) {
      super(...args)
      this.setComponentState = this.setComponentState.bind(this)
    }

    setComponentState(query) {
      const { id, setComponentState } = this.props
      return setComponentState(id, query)
    }

    render() {
      // Note ,we're removing the redux setComponentState
      // from the props and using the method declared on this class
      const { setComponentState, ...props } = this.props
      return (
        <ComposedComponent
          setComponentState={this.setComponentState}
          {...props}
        />
      )
    }
  }

  return connect(select, perform)(ComponentState)
}
