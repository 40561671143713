import { averageResolutionTime } from '../queries/averageResolutionTime'
import { averageResolutionTimeWithinBusinessHours } from '../queries/averageResolutionTimeWithinBusinessHours'

export const averageResolutionTimeByAgentGauge = {
  businessHoursQueries: [
    {
      ...averageResolutionTimeWithinBusinessHours,
      groupBy: 'action.actor.user.id',
    },
  ],
  id: 'avg_resolution_time_by_agent',
  isNegativeChangeGood: true,
  queries: [
    {
      ...averageResolutionTime,
      groupBy: 'action.actor.user.id',
      key: 'averageResolutionTimeByAgent',
    },
  ],
  scale: { y: 'duration' },
  title: `$Average Resolution Time By ${app.t('Agent')}`,
}
