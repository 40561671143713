import { memoize } from 'util/memoization'
import { connect } from 'react-redux'
import { selectRatingHistoryGauge } from 'ducks/reports/gauges'
import { TableVisualization } from 'ducks/reports/visualizations'

function onRatingFilterChange(evt, value) {
  return {
    query: {
      page: 1,
      grade: value,
    },
    filter: {
      grade: value,
    },
  }
}

function onPageSizeChange(evt, value) {
  return {
    query: {
      page: 1,
      per_page: value,
    },
  }
}

function onPageChange(evt, value) {
  return {
    query: {
      page: value,
    },
  }
}

const getColumnConfig = memoize((hasAgentId, excludeAgentColumn) => {
  const columns = [
    {
      header: 'Conversation subject',
      accessor: 'subject',
      component: TableVisualization.ConversationCell,
      sort: 'subject',
    },
    {
      header: 'Customer',
      accessor: 'customer',
      component: TableVisualization.CustomerCell,
      sort: 'customer',
    },
    !excludeAgentColumn && {
      header: app.t('Agent'),
      accessor: 'agent_id',
      component: TableVisualization.AgentCell,
      sort: 'agent_id',
    },
    {
      header: 'Status',
      accessor: 'ticket_state',
      component: TableVisualization.ColorStatusCell,
      sort: 'ticket_state',
    },
    {
      header: 'Initial Response',
      accessor: 'initial_response_time',
      component: TableVisualization.CountDownCell,
      sort: {
        accessor: 'initial_response_time',
        type: 'number',
      },
    },
    {
      header: 'Resolution Time',
      accessor: 'resolution_time',
      component: TableVisualization.CountDownCell,
      sort: {
        accessor: 'resolution_time',
        type: 'number',
      },
    },
    {
      header: 'Rating',
      accessor: 'grade_int',
      component: TableVisualization.GradeToEmojiCell,
      sort: {
        accessor: 'grade_int',
        type: 'number',
      },
    },
  ].filter(x => !!x)

  if (hasAgentId) {
    columns.splice(2, 1)
  }

  return columns
})

function createRatingFilter() {
  return {
    accessor: 'grade',
    options: [
      {
        label: 'All',
        value: null,
      },
      {
        label: '😁',
        value: 0,
      },
      {
        label: '😕',
        value: 1,
      },
      {
        label: '😩',
        value: 2,
      },
    ],
    onClick: onRatingFilterChange,
  }
}

const createFilter = memoize(() => createRatingFilter())

const paging = {
  visible: true,
  onPageSizeChange,
  onPageChange,
}

const select = (state, props) => {
  return {
    gauge: selectRatingHistoryGauge(state, props),
    key: 'rating-history-table',
    title: props.title !== false ? props.title || 'Conversations' : null,
    subtitle:
      props.subtitle !== false
        ? props.subtitle ||
          'A list of all recent ratings over the selected period'
        : null,
    perPage: 10,
    page: 1,
    showPageSize: false,
    showPaging: false,
    filter: createFilter(props.filter),
    paging,
    sort: {},
    columns: getColumnConfig(
      !!props.queryBase.agent_id,
      props.excludeAgentColumn
    ),
  }
}
export default connect(select)(TableVisualization)
