import { connect } from 'react-redux'
import {
  selectIsArticlePreviewLoading,
  selectArticlePreviewArticle,
  selectIsArticlePreviewLoaded,
} from '../../selectors/kbSearch'
import { doLoadArticlePreview } from '../../operations'

import KbPreviewView from './view'

const select = () => {
  return state => {
    return {
      isLoading: selectIsArticlePreviewLoading(state),
      isLoaded: selectIsArticlePreviewLoaded(state),
      article: selectArticlePreviewArticle(state),
    }
  }
}

const perform = {
  loadArticlePreview: doLoadArticlePreview,
}

export default connect(select, perform)(KbPreviewView)
