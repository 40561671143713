import { smartPartialDiff } from 'util/folders'
import { deepCopy } from 'util/objects'
import { selectTicketsById } from 'selectors/tickets/byId/selectTicketsById'
import { selectCurrentUser } from 'ducks/currentUser/selectors/selectCurrentUser'
import { toServerString } from 'util/date'

export const diffProps = (state, ticketId) => ({
  actor: selectCurrentUser(state),
  ticket: selectTicketsById(state)[ticketId],
})

// the basic optimistic action payload. Compatible with
// UPDATE_TICKETS/ADD_CHANGESETS (and the like) reducers in both
// tickets/folders reducers
export const createPayload = (
  state,
  ticketId,
  type,
  ticketData,
  actions,
  // folderId typically the current folder for ticket list actions, or the
  // previous folder for inspector actions
  folderId,
  search,
  changesetId,
  actionsData
) => {
  const { actor, ticket } = diffProps(state, ticketId)
  // keep old props so that other filters can still use them
  const oldTicket = ticket
  const newTicket = Object.assign({}, deepCopy(ticket), ticketData)

  const defaultChangesetId = `optimistic-${type}-${ticketId}-${changesetId}`

  return {
    // Bit janky. The folder reducers look for id+diff (so allows us to
    // leverage the UPDATE_TICKETS reducer), while the changeset reducers look
    // at ticketId+ticketData+diff etc.
    id: ticketId,
    ticketId,
    ticketData,
    diff: smartPartialDiff(state, newTicket, oldTicket),
    changesetId: changesetId || defaultChangesetId,
    // decorate the actions payload with the actor (current user)
    actions: actions.map(action =>
      Object.assign(
        {},
        {
          actor: Object.assign({ type: 'Agent' }, actor),
          changeset: changesetId,
          created_at: toServerString(new Date()),
        },
        action
      )
    ),
    actionsData,
  }
}
