import themeVars from 'ui_theme/site/globals/site.variables'

// *
// * Colors
// *
const lightGreen = themeVars.lightBio
const green = themeVars.bio
const red = themeVars.berry
const orange = themeVars.lava
const yellow = themeVars.golden
const lightBlue = themeVars.lightSpace
const blue = themeVars.space
const sky = themeVars.sky
const darkBlue = themeVars.darkSpace
const pink = themeVars.juicy
const lightPurple = themeVars.lightNeon
const purple = themeVars.neon
const lightestGray = themeVars.ultraDust
const lightGray = themeVars.ultraLight
const gray = themeVars.light
const black = themeVars.dark
const dust = themeVars.dust
const white = '#ffffff'

const colors = [
  lightGreen,
  purple,
  yellow,
  pink,
  blue,
  green,
  lightBlue,
  orange,
  pink,
  sky,
]

// *
// * Typography
// *
const sansSerif = themeVars.pageFont
const headerSansSerif = themeVars.headerFont
const letterSpacing = 'normal'
const fontSize = 12
// *
// * Layout
// *
const baseProps = {
  width: 450,
  height: 300,
  padding: { top: 60, bottom: 60, left: 90, right: 80 },
  colorScale: colors,
}
// *
// * Labels
// *
const baseLabelStyles = {
  fontFamily: sansSerif,
  fontSize,
  letterSpacing,
  padding: 10,
  fontWeight: themeVars.pageFontWeightRegular,
  fill: black,
  stroke: 'transparent',
}

// *
// * Header
// *
const baseHeaderStyles = {
  fontFamily: headerSansSerif,
  fontSize,
  letterSpacing,
  padding: 10,
  fontWeight: themeVars.pageFontWeightSemiBold,
  fill: black,
  stroke: 'transparent',
  textTransform: 'uppercase',
}

const leftAlignLabelStyles = { ...baseLabelStyles, textAnchor: 'start' }
const centeredLabelStyles = { ...baseLabelStyles, textAnchor: 'middle' }
const rightAlignLabelStyles = { ...baseLabelStyles, textAnchor: 'end' }
// *
// * Strokes
// *
const strokeLinecap = 'round'
const strokeLinejoin = 'round'

export default {
  area: {
    style: {
      data: {
        fill: black,
      },
      labels: centeredLabelStyles,
    },
    ...baseProps,
  },
  axis: {
    style: {
      axis: {
        fill: 'transparent',
        stroke: lightestGray,
        strokeWidth: 1,
        strokeLinecap,
        strokeLinejoin,
      },
      axisLabel: {
        ...centeredLabelStyles,
        padding: 25,
      },
      grid: {
        fill: 'none',
        stroke: 'transparent',
        pointerEvents: 'painted',
      },
      ticks: {
        fill: 'transparent',
        size: 1,
        stroke: 'transparent',
      },
      tickLabels: {
        ...baseLabelStyles,
        fill: lightGray,
        fontSize: 12,
        padding: 7,
      },
    },
    ...baseProps,
  },
  dependentAxis: {
    style: {
      axis: {
        stroke: 'transparent',
      },
      tickLabels: {
        ...rightAlignLabelStyles,
        padding: 5,
        fontSize: 12,
      },
      grid: {
        fill: 'none',
        stroke: lightestGray,
        pointerEvents: 'painted',
      },
    },
  },
  bar: {
    style: {
      data: {
        fill: black,
        padding: 8,
        strokeWidth: 0,
      },
      labels: baseLabelStyles,
    },
    ...baseProps,
  },
  boxplot: {
    style: {
      max: { padding: 8, stroke: black, strokeWidth: 1 },
      maxLabels: baseLabelStyles,
      median: { padding: 8, stroke: black, strokeWidth: 1 },
      medianLabels: baseLabelStyles,
      min: { padding: 8, stroke: black, strokeWidth: 1 },
      minLabels: baseLabelStyles,
      q1: { padding: 8, fill: gray },
      q1Labels: baseLabelStyles,
      q3: { padding: 8, fill: gray },
      q3Labels: baseLabelStyles,
    },
    boxWidth: 20,
    ...baseProps,
  },
  candlestick: {
    style: {
      data: {
        stroke: black,
        strokeWidth: 1,
      },
      labels: centeredLabelStyles,
    },
    candleColors: {
      positive: '#ffffff',
      negative: black,
    },
    ...baseProps,
  },
  chart: baseProps,
  colors: {
    scale: colors,
    lightGreen,
    green,
    red,
    orange,
    yellow,
    lightBlue,
    blue,
    darkBlue,
    pink,
    lightPurple,
    purple,
    lightGray,
    gray,
    black,
    white,
    lightestGray,
    dust,
    sky,
  },
  errorbar: {
    borderWidth: 8,
    style: {
      data: {
        fill: 'transparent',
        stroke: black,
        strokeWidth: 2,
      },
      labels: centeredLabelStyles,
    },
    ...baseProps,
  },
  group: {
    colorScale: colors,
    ...baseProps,
  },
  smallLabel: {
    ...baseLabelStyles,
    fontSize: 10,
  },
  label: baseLabelStyles,
  largeLabel: {
    ...baseLabelStyles,
    fontSize: 32,
    fontWeight: themeVars.pageFontWeightSemiBold,
  },
  smallHeader: {
    ...baseHeaderStyles,
    fontSize: 12,
  },
  legend: {
    colorScale: colors,
    gutter: 10,
    orientation: 'vertical',
    titleOrientation: 'top',
    style: {
      data: {
        type: 'circle',
      },
      labels: {
        ...baseLabelStyles,
        fontSize: 10,
        fontWeight: themeVars.pageFontWeightSemiBold,
        letterSpacing: '2px',
        textTransform: 'uppercase',
      },
      title: {
        ...baseLabelStyles,
        padding: 5,
      },
    },
  },
  line: {
    style: {
      data: {
        fill: 'transparent',
        stroke: black,
        strokeWidth: 2,
      },
      labels: centeredLabelStyles,
    },
    ...baseProps,
  },
  pie: {
    style: {
      data: {
        padding: 0,
        stroke: 'transparent',
        strokeWidth: 1,
      },
      labels: {
        ...baseLabelStyles,
        padding: 20,
      },
    },
    colorScale: colors,
    width: 400,
    height: 400,
    padding: 50,
  },
  scatter: {
    style: {
      data: {
        fill: black,
        stroke: 'transparent',
        strokeWidth: 0,
      },
      labels: centeredLabelStyles,
    },
    ...baseProps,
  },
  stack: {
    colorScale: colors,
    ...baseProps,
  },
  tooltip: {
    style: {
      ...leftAlignLabelStyles,
      padding: 12,
      pointerEvents: 'none',
      fill: themeVars.white,
    },
    flyoutStyle: {
      ...leftAlignLabelStyles,
      stroke: themeVars.dark,
      strokeWidth: 0,
      fill: themeVars.dark,
      pointerEvents: 'none',
    },
    cornerRadius: 5,
    pointerLength: 6,
  },
  voronoi: {
    style: {
      data: {
        fill: 'transparent',
        stroke: 'transparent',
        strokeWidth: 0,
      },
      labels: {
        ...centeredLabelStyles,
        padding: 5,
        pointerEvents: 'none',
      },
      flyout: {
        stroke: black,
        strokeWidth: 1,
        fill: '#f0f0f0',
        pointerEvents: 'none',
      },
    },
    ...baseProps,
  },
}
