import cn from 'classnames'
import React from 'react'
import WithGlobalNav from '@groovehq/internal-design-system/lib/components/GlobalNav/WithGlobalNav/WithGlobalNav'

import AppNotices from 'components/AppNotices'
import TopNav from 'components/App/DesktopView/Layout/TopNav'
import Title from 'components/App/Title'
import Tracking from 'components/App/Tracking'
import { BusinessHoursNotice, ControlBar } from 'ducks/reports/controls'
import { Grid } from 'util/ui'
import SUI from 'shared/components/ui/SUI'
import { runOnNextTick } from 'util/functions'
import Loading from 'core/subapps/Loading'
import PlanLimitationCard from 'subapps/settings/components/PlanLimitationCard'
import { FEATURE_INBOX_MAX_REPORTING_DAYS } from 'ducks/billing/featureTypes'

import ReportsSidebar from '../ReportsSidebar'
import Empty from './Empty'
import styles from './styles.less'

export default class ReportsLayout extends React.Component {
  componentDidMount() {
    if (!this.props.layoutMounted) this.props.onComponentDidMount()
    const { fetchKnowledgeBases, isKbsLoaded } = this.props
    if (!isKbsLoaded) {
      runOnNextTick(() => fetchKnowledgeBases())
    }
  }

  componentDidUpdate() {
    if (!this.props.layoutMounted) this.props.onComponentDidUpdate()
  }

  render() {
    const {
      children,
      layoutMounted,
      hasKnowledgeBases,
      showEmptyState,
      showContent,
      hideTopContent,
      isAccountBootstrapped,
      currentPlan,
      reportingDaysLimit,
      isInKbReporting,
    } = this.props
    const content = showEmptyState ? (
      <Empty />
    ) : (
      <SUI transparent={false}>
        <div className={styles.app}>
          <div className={cn('AppMain', 'ReportsApp', styles.content)}>
            {!hideTopContent && (
              <ControlBar prerenderDatePicker={layoutMounted} />
            )}
            {layoutMounted && (
              <div
                className={cn(
                  styles.scrollContainer,
                  hideTopContent && 'grui py-10'
                )}
              >
                <div className={styles.widthContainer}>
                  <Grid columns={1} stackable padded>
                    <Grid.Column width={16}>
                      {!isNaN(Number(reportingDaysLimit)) && (
                        <PlanLimitationCard
                          className="grui mb-12 -mt-2"
                          title="Upgrade Now for Unlimited Insights!"
                          shouldGoToKbPlans={isInKbReporting}
                          trackingFeatureType={FEATURE_INBOX_MAX_REPORTING_DAYS}
                        >
                          Your {currentPlan?.name || ''} plan is{' '}
                          <strong>
                            limited to {reportingDaysLimit} days of reporting
                            history
                          </strong>.
                          <br />
                          Upgrade now to view your unlimited history!
                        </PlanLimitationCard>
                      )}
                      <BusinessHoursNotice />
                      <React.Fragment>{children}</React.Fragment>
                    </Grid.Column>
                  </Grid>
                </div>
              </div>
            )}
          </div>
        </div>
      </SUI>
    )

    if (!isAccountBootstrapped) return <Loading />

    return (
      <>
        <Title />
        <Tracking />
        <TopNav active="Reporting" />
        <WithGlobalNav
          topContent={<AppNotices />}
          leftSidebar={
            !showEmptyState && <ReportsSidebar hasKB={hasKnowledgeBases} />
          }
        >
          {showContent ? content : null}
        </WithGlobalNav>
      </>
    )
  }
}
