import { createActionTypeReducer } from 'util/reducers'
import { camelize } from 'util/strings'

const initialState = {}

export default createActionTypeReducer(
  {
    '*': (draftState, { type, meta: { requestKey } = {}, payload }) => {
      const actionName = camelize(type.substring(0, type.lastIndexOf('_')))
      const actionType = type.split('_').pop()
      const id = requestKey || actionName

      // Apply logic based on actionType, similar to switch-case in the original reducer
      switch (actionType) {
        case 'STARTED':
        case 'REQUEST':
          draftState[id] = {
            untried: false,
            loading: true,
            loaded: false,
            error: false,
          }
          break
        case 'SUCCESS':
          draftState[id] = {
            untried: false,
            loading: false,
            loaded: true,
            error: false,
          }
          break
        case 'FAILED':
          draftState[id] = {
            untried: false,
            loading: false,
            loaded: false,
            error: payload,
          }
          break
        default:
        // Do nothing for unknown action types
      }
      return draftState
    },
  },
  initialState
)
