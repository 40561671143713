import React from 'react'
import cn from 'classnames'
import Avatar from 'components/Avatar'

import { LinkWithReportContext } from 'ducks/reports/controls'
// TODO: Fix direct duck reference without creating circular dependancy
import { AGENT_DETAIL_PAGE } from 'ducks/reports/detail/pages'
import styles from './styles.less'

export default function AgentLinkView({ agent, agent: { id, name } = {} }) {
  let cell = (
    <div className={styles.AgentLinkView}>
      <Avatar
        size="32"
        actor={agent}
        className={cn(styles.AgentLinkView_avatar, 'agent-link-avatar')}
      />
      <span className={cn(styles.AgentLinkView_name, 'agent-link-name')}>
        {name}
      </span>
    </div>
  )

  if (id) {
    cell = (
      <LinkWithReportContext
        to={{
          type: AGENT_DETAIL_PAGE,
          payload: { id },
        }}
      >
        {cell}
      </LinkWithReportContext>
    )
  }

  return cell
}
