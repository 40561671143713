import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import Tooltip from '@groovehq/internal-design-system/lib/components/Tooltip/Tooltip'
import { Menu } from 'util/ui'
import { START_OF_REPORTS } from 'ducks/reports/constants'
import {
  selectTimezoneSource,
  selectTimezoneWithOffsetDisplay,
} from 'ducks/reports/controls/selectors'
import { selectMaxReportingDays } from 'ducks/billing/selectors/features'
import BusinessHoursControl from '../BusinessHoursControl'
import DateControl from '../DateControl'
import styles from './styles.less'

export default function ControlBar({ prerenderDatePicker }) {
  const source = useSelector(selectTimezoneSource)
  const timezone = useSelector(selectTimezoneWithOffsetDisplay)
  const maxReportingDays = useSelector(selectMaxReportingDays)

  const tooltipTitle = useMemo(
    () => {
      switch (source) {
        case 'ACCOUNT':
          return 'Account timezone used'
        case 'BUSINESS_HOURS':
          return 'Business hours timezone used'
        case 'BROWSER':
          return 'Browser timezone used'
        default:
          return 'Unknown timezone used'
      }
    },
    [source]
  )

  return (
    <Menu fixed="top" className={styles.ControlBar}>
      <Menu.Menu position="left">
        <Menu.Item fitted>
          <BusinessHoursControl />
        </Menu.Item>
      </Menu.Menu>
      <Menu.Menu position="left">
        <Menu.Item>
          <Tooltip title={tooltipTitle}>
            <i>Timezone Used: {timezone}</i>
          </Tooltip>
        </Menu.Item>
      </Menu.Menu>
      <Menu.Menu position="right">
        <Menu.Item fitted>
          <DateControl
            prerender={prerenderDatePicker}
            earliestStartDate={START_OF_REPORTS}
            // minDays overrides earliestStartDate if defined
            minDays={maxReportingDays}
          />
        </Menu.Item>
      </Menu.Menu>
    </Menu>
  )
}
