import { emptyArr } from 'util/arrays'

export default function concatDatumsIntoArray(gauge, dataSets) {
  const {
    colorScale,
    description,
    empty,
    fill,
    id: gaugeId,
    includePrevious,
    isJoinedThrough,
    isNegativeChangeGood,
    isNegativeGood,
    previousLoaded,
    previousQueries = emptyArr,
    queries = emptyArr,
    title: name,
    totalCount,
    scale,
    sort,
    xValueTranslations,
  } = gauge
  const query = queries[0]
  const newDataByX = {}
  const previousDataByX = {}

  dataSets.forEach(
    ({ data, group, previousPeriod: { data: previousData } = {} }) => {
      if (data)
        data.forEach(datum => {
          const { x } = datum
          if (!newDataByX[x]) {
            newDataByX[x] = { x }
            newDataByX[x].y = []
          }
          newDataByX[x].y.push({ ...datum, group })
        })
      if (previousData)
        previousData.forEach(datum => {
          const { x } = datum
          if (!previousDataByX[x]) {
            previousDataByX[x] = { x }
            previousDataByX[x].y = []
          }
          previousDataByX[x].y.push({ ...datum, group })
        })
    }
  )

  return [
    {
      colorScale,
      data: Object.values(newDataByX),
      description,
      empty,
      fill,
      id: gaugeId,
      isJoinedThrough,
      isNegativeChangeGood,
      isNegativeGood,
      name,
      queries,
      page: query && query.page,
      perPage: query && query.perPage,
      previousPeriod: includePrevious && {
        queries: previousQueries,
        data: previousLoaded && Object.values(previousDataByX),
      },
      scale,
      sort,
      sortKey: gaugeId,
      totalCount,
      xValueTranslations,
    },
  ]
}
