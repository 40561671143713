import createCachedSelector from 're-reselect'
import { queryStringToQueryId } from 'ducks/searches/utils/query'
import { selectQueryParams } from 'selectors/location'
import { emptyObj } from 'util/objects'
import { createBasicSelector } from 'util/redux'

const selectBase = state => state.tables || emptyObj

const tableIdCacheKey = (_, tableId) => tableId || 'empty-table-id'

export const selectTableQueryIdFromUrlById = createCachedSelector(
  selectQueryParams,
  (_, tableId) => tableId,
  (pageQuery, tableId) => queryStringToQueryId(pageQuery, { targetId: tableId })
)(tableIdCacheKey)

export const selectTablesById = createBasicSelector(
  selectBase,
  base => base.byId || emptyObj
)

export const selectTableById = createCachedSelector(
  selectTablesById,
  (_, tableId) => tableId,
  (byId, tableId) => {
    return byId[tableId] || emptyObj
  }
)(tableIdCacheKey)

export const selectTableQueryById = createCachedSelector(
  selectTableById,
  table => {
    return table.query || emptyObj
  }
)(tableIdCacheKey)

export const selectTableScrollPositionById = createCachedSelector(
  selectTableById,
  table => {
    return table.scrollPosition || 0
  }
)(tableIdCacheKey)
