import React from 'react'
import Link from 'redux-first-router-link'
import Menu from '@groovehq/internal-design-system/lib/components/Menu/Menu'

// TODO: Fix direct duck reference without creating circular dependancy
import { CONVERSATIONS_PAGE } from 'ducks/reports/conversations/pages'
import { AGENTS_PAGE } from 'ducks/reports/agents/pages'
import { HAPPINESS_PAGE } from 'ducks/reports/happiness/pages'
import { PRODUCTIVITY_PAGE } from 'ducks/reports/productivity/pages'

const ITEMS = {
  [CONVERSATIONS_PAGE]: 'Conversations',
  [PRODUCTIVITY_PAGE]: 'Productivity',
  [HAPPINESS_PAGE]: 'CSAT',
  [AGENTS_PAGE]: app.t('Agents'),
}

export default function ReportsSideBarMailboxItem({
  activeMailboxId,
  activePage,
  mailbox: { id, name },
  totalItemsCount,
  isSubMenuItem,
}) {
  const allMailboxesActive = !id && !activeMailboxId
  const activeMailbox =
    activeMailboxId === id || allMailboxesActive || totalItemsCount === 1
  return (
    <Menu.Item
      isSubMenuItem={isSubMenuItem}
      hasArrow
      defaultOpen={activeMailbox}
      subMenu={
        <>
          {Object.keys(ITEMS).map(key => {
            return (
              <Menu.Item
                key={key}
                className="grui"
                as={Link}
                to={{ type: key, payload: { query: { inbox_id: id } } }}
                isSubMenuItem
                active={activeMailbox && activePage === key}
              >
                {ITEMS[key]}
              </Menu.Item>
            )
          })}
        </>
      }
    >
      {name}
    </Menu.Item>
  )
}
