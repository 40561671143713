import React from 'react'
import Link from 'redux-first-router-link'
import Menu from '@groovehq/internal-design-system/lib/components/Menu/Menu'
// TODO: Fix direct duck reference without creating circular dependancy
import { KB_PAGE } from 'ducks/reports/kb/pages'

// NB KB_PAGE is currently a general Knowledge Base page but it will become
// the article insights page.
export default function ReportsSideBarMailboxItem({
  activeKnowledgeBaseId,
  activePage,
  knowledgeBase: { id, title },
  totalItemsCount,
  isSubMenuItem,
}) {
  const allKnowledgeBasesActive = !id && !activeKnowledgeBaseId
  const activeKnowledgeBase =
    activeKnowledgeBaseId === id ||
    allKnowledgeBasesActive ||
    totalItemsCount === 1
  return (
    <Menu.Item
      as={Link}
      to={{ type: KB_PAGE, payload: { query: { knowledge_base_id: id } } }}
      active={activeKnowledgeBase && activePage === KB_PAGE}
      isSubMenuItem={isSubMenuItem}
    >
      {title}
    </Menu.Item>
  )
}
