import { actionsPartials, filterableFieldsPartials } from 'api/tickets'

export const mergeQuery = `
  mutation mergeTickets($ticketId: String, $childTicketIds: [String], $changesetId: String) {
    mergeTickets(ticketId: $ticketId, childTicketIds: $childTicketIds, changesetId: $changesetId) {
      ticket {
        id
        diff
        ${actionsPartials}
        ticket {
          state
          priority
          message_count
          labels {
            id
            name
          }
          ${filterableFieldsPartials}
          system_updated_at
        }
      }
      mergedTickets {
        tickets {
          id
          diff
        }
      }
    }
  }
`
