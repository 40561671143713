import React from 'react'
import { Button, Icon, Message } from 'util/ui'
import SpacedItems from 'shared/components/ui/SpacedItems'

export default function BusinessHoursNotice({
  areBusinessHoursEnabled,
  showBusinessHoursNotice,
  doHideBusinessHoursNotice,
}) {
  return (
    areBusinessHoursEnabled &&
    showBusinessHoursNotice && (
      <Message icon info>
        <Icon name="info circle" />
        <Message.Content>
          <SpacedItems.Container direction="horizontal" verticalAlign="center">
            <SpacedItems.Item>
              <Message.Header>Business Hours Applied</Message.Header>
              Message ratings and response time only include data for messages
              received during business hours.
            </SpacedItems.Item>
            <SpacedItems.Item gap="auto">
              <Button
                primary
                content="Got it!"
                onClick={doHideBusinessHoursNotice}
              />
            </SpacedItems.Item>
          </SpacedItems.Container>
        </Message.Content>
      </Message>
    )
  )
}
