export function sortDifference(a, b) {
  if (a.change.difference < b.change.difference) return -1
  if (a.change.difference > b.change.difference) return 1
  return 0
}

export function sortPercentChange(a, b) {
  if (!a.change.isInfinity && b.change.isInfinity)
    return b.change.isNegative ? -1 : 1
  if (a.change.isInfinity && !b.change.isInfinity)
    return a.change.isNegative ? 1 : -1
  if (a.change.percent < b.change.percent) return -1
  if (a.change.percent > b.change.percent) return 1
  return 0
}
export function sortX(a, b) {
  if (!a || (!a.x && a.x !== 0)) return -1
  if (!b || (!b.x && b.x !== 0)) return 1
  if (a && b && a.x && b.x && a.x.localeCompare && b.x.localeCompare)
    return a.x.localeCompare(b.x)
  if (!a || (b && a.x < b.x)) return -1
  if (!b || a.x > b.x) return 1
  return 0
}
export function sortY(a, b) {
  if (!a || (!a.y && a.y !== 0)) return -1
  if (!b || (!b.y && b.y !== 0)) return 1
  if (a && b && a.y && b.y && a.y.localeCompare && b.y.localeCompare)
    return a.y.localeCompare(b.y)
  if (!a || (b && a.y < b.y)) return -1
  if (!b || a.y > b.y) return 1
  return 0
}
