export const FETCH_STARRED_CANNED_REPLIES =
  'starredCannedReplies/FETCH_STARRED_CANNED_REPLIES'
export const FETCH_STARRED_CANNED_REPLIES_STARTED =
  'starredCannedReplies/FETCH_STARRED_CANNED_REPLIES_STARTED'
export const FETCH_STARRED_CANNED_REPLIES_SUCCESS =
  'starredCannedReplies/FETCH_STARRED_CANNED_REPLIES_SUCCESS'
export const FETCH_STARRED_CANNED_REPLIES_FAILED =
  'starredCannedReplies/FETCH_STARRED_CANNED_REPLIES_FAILED'

export const CREATE_STARRED_CANNED_REPLY =
  'starredCannedReplies/CREATE_STARRED_CANNED_REPLY'
export const CREATE_STARRED_CANNED_REPLY_STARTED =
  'starredCannedReplies/CREATE_STARRED_CANNED_REPLY_STARTED'
export const CREATE_STARRED_CANNED_REPLY_SUCCESS =
  'starredCannedReplies/CREATE_STARRED_CANNED_REPLY_SUCCESS'
export const CREATE_STARRED_CANNED_REPLY_FAILED =
  'starredCannedReplies/CREATE_STARRED_CANNED_REPLY_FAILED'

export const DELETE_STARRED_CANNED_REPLY =
  'starredCannedReplies/DELETE_STARRED_CANNED_REPLY'
export const DELETE_STARRED_CANNED_REPLY_STARTED =
  'starredCannedReplies/DELETE_STARRED_CANNED_REPLY_STARTED'
export const DELETE_STARRED_CANNED_REPLY_SUCCESS =
  'starredCannedReplies/DELETE_STARRED_CANNED_REPLY_SUCCESS'
export const DELETE_STARRED_CANNED_REPLY_FAILED =
  'starredCannedReplies/DELETE_STARRED_CANNED_REPLY_FAILED'
