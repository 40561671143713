import themeVars from 'ui_theme/site/globals/site.variables'

export default function calculateColor(value, { bad, good } = {}) {
  if (good && value >= good) {
    return themeVars.bio
  } else if (good && bad && value >= bad) {
    return themeVars.golden
  } else if (bad) {
    return themeVars.berry
  }
  return null
}
