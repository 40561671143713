import { isDefined } from 'util/nullOrUndefinedChecks'
import { getType } from 'util/globalId'

export const getEventRelatedConversationMeta = event => {
  if (!event) return {}

  const { meta } = event.getContent() || {}
  const { ticket_id: ticketId, changeset_id: changesetId } = meta || {}

  return {
    ticketId,
    changesetId,
  }
}

export const isEventAuthorAutomation = event => {
  if (!event) return true

  const { author } = event.getContent()
  const { id: authorId } = author || {}

  return isDefined(authorId)
    ? !['Agent', 'Contact', 'Team'].includes(getType(authorId))
    : true
}
