export default function serializeBusinessHours(businessHours) {
  const { days, timezone } = businessHours
  const newDays = {}
  for (let d = 1; d <= 7; d += 1) {
    newDays[d] = days[d].enabled ? days[d] : null
  }
  return {
    days: newDays,
    timezone,
  }
}
