import { createPayload } from 'optimistic/base'
import { toServerString } from 'util/date'
import { END_USER } from 'constants/changesetActionChangeTypes'

// builds mock data for a integration action.
export default function customerChangePayload(
  state,
  ticketId,
  customerData,
  previousCustomer,
  folderId,
  search,
  updatedAt = toServerString(new Date())
) {
  return createPayload(
    state,
    ticketId,
    'customer',
    { updated_at: updatedAt },
    [
      {
        change_type: END_USER,
        change: {
          ...customerData,
          type: 'Enduser',
          previousCustomer,
        },
      },
    ],
    folderId,
    search
  )
}
