import { conversationsAssignedCustomersPerDayGauge } from 'ducks/reports/gauges'
import { HeadlineVisualization } from 'ducks/reports/visualizations'

const staticProps = {
  gauges: [conversationsAssignedCustomersPerDayGauge],
  title: 'Customers per Day',
}

export default function CustomersAssignedPerDayHeadline(props) {
  return <HeadlineVisualization {...props} {...staticProps} />
}
