import {
  FETCH_KBS_STARTED,
  FETCH_KBS_SUCCESS,
  FETCH_KBS_FAILED,
} from '../../types'

const reducers = {}
export const defaultState = {
  isLoading: false,
  isLoaded: false,
  hasError: false,
  data: [],
}

reducers[FETCH_KBS_STARTED] = state => {
  return {
    ...state,
    isLoading: true,
  }
}

reducers[FETCH_KBS_SUCCESS] = (state, action) => {
  const {
    payload: { knowledgeBases },
  } = action

  return {
    ...state,
    isLoading: false,
    isLoaded: true,
    hasError: false,
    data: knowledgeBases,
  }
}

reducers[FETCH_KBS_FAILED] = (state, action) => {
  return {
    ...state,
    isLoading: false,
    hasError: true,
    error: action.payload.error,
  }
}

export default function reducer(state = defaultState, action) {
  // this is here because a long reducer with many ifs is unreadable
  const handler = reducers[action.type]
  if (handler) return handler(state, action)
  return state
}
