export default function requestActionModule(phase, action, payload, options) {
  const {
    requestType,
    requestId,
    requestParameters,
    includeLegacyPayload,
    successAction = {},
  } = options

  const request = {
    id: requestId,
    type: requestType,
    parameters: requestParameters,
  }

  Object.assign(action, {
    type: `${requestType}_${phase}`,
    request,
    payload,
  })

  if (phase === 'FAILED') {
    Object.assign(action, {
      originalAction: successAction,
      error: true,
      payload,
    })
  }

  if (includeLegacyPayload) {
    Object.assign(action, {
      data: action.payload,
    })
  }
  return action
}
