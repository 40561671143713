import { VictoryTooltip } from 'victory'

import grooveTheme from '../grooveTheme'

export default function Tooltip(props) {
  return (
    <VictoryTooltip
      {...props}
      dy={6}
      flyoutStyle={grooveTheme.tooltip.flyoutStyle}
      style={grooveTheme.tooltip.style}
      text={props.text.reverse()}
    />
  )
}
