import formatDataPointValueAs from './formatDataPointValueAs'

export default function formatDatumForTooltip({
  dataSetName,
  isPrevious,
  isSingleDataSet,
  isSingleDataSetWithoutPrevious,
  isSingleDatum,
  x,
  xActual,
  y,
  scale: { x: xScale, yScale, formatXAs = xScale, formatYAs = yScale } = {},
}) {
  const xFormatted = formatDataPointValueAs(formatXAs, xActual || x, true)
  const yFormatted = formatDataPointValueAs(formatYAs, y, true)
  if (isSingleDataSet && !isSingleDataSetWithoutPrevious && isSingleDatum) {
    return `${isPrevious ? 'Previous' : 'Current'}: ${yFormatted}`
  }
  if (isSingleDataSetWithoutPrevious && isSingleDatum) return yFormatted
  if (!isSingleDataSetWithoutPrevious && isSingleDatum)
    return `${dataSetName}: ${yFormatted}`
  if (isSingleDataSetWithoutPrevious) return `${xFormatted}: ${yFormatted}`
  if (isSingleDataSet && !isSingleDataSetWithoutPrevious)
    return `${isPrevious ? 'Previous' : 'Current'} ${xFormatted}: ${yFormatted}`

  return `${dataSetName} ${xFormatted}: ${yFormatted}`
}
