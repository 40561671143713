import moment from 'moment-timezone-all'

import { numberToSize } from 'util/numbers'
import ratingToEmoji from './ratingToEmoji'

export default function formatAxisValueAs(as, value) {
  if (as === 'rating') return ratingToEmoji(value)
  if (as === 'duration')
    return moment.duration(value, 'seconds').format('h[h] m[m] s[s]', {
      trim: 'both',
    })
  if (as === 'date' || value instanceof Date) {
    return moment(value).format("D MMM 'YY")
  }
  if (as === 'number' || !isNaN(value))
    return numberToSize(Math.round(value * 10) / 10, 1)
  return value
}
