import {
  DISABLE_BUSINESS_HOURS,
  ENABLE_BUSINESS_HOURS,
  HIDE_BUSINESS_HOURS_NOTICE,
  TIMEFRAME_CHANGE,
  TOGGLE_BUSINESS_HOURS,
} from './types'

export function doChangeTimeframe(start, end) {
  return {
    type: TIMEFRAME_CHANGE,
    payload: !end
      ? start
      : {
          end: end && end.format('YYYY-MM-DD'),
          start: start && start.format('YYYY-MM-DD'),
        },
  }
}

export function doDisbleBusinessHours() {
  return { type: DISABLE_BUSINESS_HOURS }
}

export function doEnableBusinessHours() {
  return { type: ENABLE_BUSINESS_HOURS }
}

export function doToggleBusinessHours() {
  return { type: TOGGLE_BUSINESS_HOURS }
}

export function doHideBusinessHoursNotice() {
  return {
    type: HIDE_BUSINESS_HOURS_NOTICE,
  }
}
