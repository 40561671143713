import React from 'react'
import Link from 'redux-first-router-link'

import { TICKET_PAGE } from 'constants/pages'

import TruncatedCell from './TruncatedCell'

export default function ConversationCell({
  row: { conversation_id: id } = {},
  value,
}) {
  return (
    <Link
      to={{
        type: TICKET_PAGE,
        payload: { id },
      }}
      target="_blank"
    >
      <TruncatedCell>{value}</TruncatedCell>
    </Link>
  )
}
