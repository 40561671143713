import { connect } from 'react-redux'

import { selectCustomerLeftRatingRatioGauge } from 'ducks/reports/gauges'
import { PercentGauge } from 'ducks/reports/visualizations'

const staticSelect = {
  scale: { y: 'percent' },
  title: 'Left a Rating',
  trafficLight: [20],
}
const select = (state, props) => ({
  ...staticSelect,
  gauge: selectCustomerLeftRatingRatioGauge(state, props),
})
export default connect(select)(PercentGauge)
