export const UPDATE_MESSAGE_TEMPLATE_OPTIMISTIC =
  'messageTemplates/UPDATE_MESSAGE_TEMPLATE_OPTIMISTIC'
export const UPLOAD_MESSAGE_TEMPLATE_ATTACHMENT =
  'messageTemplates/UPLOAD_MESSAGE_TEMPLATE_ATTACHMENT'
export const UPLOAD_MESSAGE_TEMPLATE_ATTACHMENT_STARTED =
  'messageTemplates/UPLOAD_MESSAGE_TEMPLATE_ATTACHMENT_STARTED'
export const UPLOAD_MESSAGE_TEMPLATE_ATTACHMENT_SUCCESS =
  'messageTemplates/UPLOAD_MESSAGE_TEMPLATE_ATTACHMENT_SUCCESS'
export const UPLOAD_MESSAGE_TEMPLATE_ATTACHMENT_FAILED =
  'messageTemplates/UPLOAD_MESSAGE_TEMPLATE_ATTACHMENT_FAILED'
