import { doFetchReportQuery } from 'ducks/reports/queries'

export function doFetchReportGauge(gauge, queryBatch) {
  return dispatch => {
    const {
      previousQueries: previousQueriesDefault,
      previousPeriod: {
        queries: previousQueries = previousQueriesDefault || [],
      } = {},
      queries = [],
    } = gauge

    return [...queries, ...previousQueries].forEach(query => {
      dispatch(doFetchReportQuery(query, queryBatch))
    })
  }
}
