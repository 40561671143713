import { changeEntity, mergeEntityChanges } from 'ducks/entities/actionUtils'
import { buildId } from 'util/globalId'
import { FETCH_WIDGETS_SUCCESS } from '../../types'

export default function successAction(res) {
  const data = res.json.data.widgets.data
  return {
    type: FETCH_WIDGETS_SUCCESS,
    payload: { widgets: data },
    ...mergeEntityChanges([
      ...data.map(widget => {
        const gid = buildId('Widget', widget.id)
        return changeEntity('channel', gid, { ...widget, id: gid })
      }),
    ]),
  }
}
