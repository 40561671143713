import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'

import { selectTimezone } from 'ducks/reports/controls'
import { all, any } from 'util/arrays'

const select = state => ({
  timezone: selectTimezone(state),
})

export default function withLoadingAndErroredProps(ComposedComponent) {
  LoadingAndErroredProps.propTypes = {
    gauges: PropTypes.arrayOf(PropTypes.object),
  }

  LoadingAndErroredProps.defaultProps = {
    gauges: undefined,
    gauge: undefined,
  }

  function LoadingAndErroredProps(props) {
    const { gauge, gauges = [gauge], timezone } = props
    const loaded = all(x => x.loaded, gauges)
    // If there is no timezone app data hasn't loaded yet which we need
    const loading =
      any(x => x.loading || x.loading === undefined, gauges) || !timezone
    const errored = any(x => x.errored, gauges)
    return (
      <ComposedComponent
        {...props}
        loaded={loaded}
        loading={loading}
        errored={errored}
      />
    )
  }

  return connect(select)(LoadingAndErroredProps)
}
