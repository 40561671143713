export default function fullPropsSetCacheKey(state, props) {
  const {
    includePrevious,
    join,
    gauges,
    nullIsValidX = false,
    offset,
    pagination,
    previous,
    queryBase,
    scale,
    scope,
    sort,
    xValues,
    xValueTranslations,
  } = props
  return JSON.stringify({
    includePrevious,
    join,
    gauges,
    nullIsValidX,
    offset,
    pagination,
    previous,
    queryBase,
    scale,
    scope,
    sort,
    xValues,
    xValueTranslations,
  })
}
