import { createPayload } from 'optimistic/base'
import { sortByKey } from 'util/arrays'
import { optimisticUpdatedAt } from 'util/optimistic'
import { toServerString } from 'util/date'

// builds mock data for a [un]star action.
export const starPayload = (
  state,
  ticketId,
  newPriority,
  folderId,
  search,
  changesetId,
  updatedAt = toServerString(new Date())
) =>
  createPayload(
    state,
    ticketId,
    'star',
    {
      priority: newPriority,
      starred: newPriority === 'urgent',
      updated_at: updatedAt,
    },
    [
      {
        change_type: 'Star',
        change: {
          starred: newPriority === 'urgent',
          type: 'Star',
        },
      },
    ],
    folderId,
    search,
    changesetId
  )

// Returns the optimistic data for a bulk starring action.
export const bulkStarPayload = (
  state,
  ticketIds,
  isStarred,
  folderId,
  search,
  changesetId
) =>
  sortByKey(
    // responses come back ordered by id
    ticketIds.map(id => {
      return starPayload(
        state,
        id,
        isStarred ? 'urgent' : 'low',
        folderId,
        search,
        changesetId,
        optimisticUpdatedAt()
      )
    }),
    'id'
  )
