import {
  tagCountByTagGauge,
  tagCountByTagPerDayGauge,
  tagNameByTagGauge,
} from 'ducks/reports/gauges'
import {
  LineChartV2Visualization,
  TableV2Visualization,
  sortDifference,
  grooveTheme,
} from 'ducks/reports/visualizations'

const tableProps = {
  chart: {
    component: LineChartV2Visualization,
    props: {
      enclosed: true,
      gauges: [tagCountByTagPerDayGauge],
      theme: {
        ...grooveTheme,
        chart: {
          ...grooveTheme.chart,
          padding: { top: 40, bottom: 30, left: 30, right: 30 },
        },
      },
    },
  },
  columnWidths: [null, 1, 1],
  includePrevious: true,
  gauges: [
    tagNameByTagGauge,
    { ...tagCountByTagGauge, title: 'Usage' },
    {
      ...tagCountByTagGauge,
      id: 'tagCountChangeByTagGauge',
      scale: { y: 'change' },
      title: 'Change',
      sort: { method: sortDifference },
    },
  ],
  pagination: {
    lite: true,
    perPage: 5,
    minPerPage: true,
  },
  sort: {
    by: 'tagCountByTagGauge',
  },
  title: 'Tag insights',
}

export default function TagInsights(props) {
  return <TableV2Visualization {...props} {...tableProps} />
}
