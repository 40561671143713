import { countMessageRatingsCreated } from '../queries/countMessageRatingsCreated'

export const happinessBadRatingForEachDayGauge = {
  calculate: gauge => {
    const { loaded, queries } = gauge
    if (!loaded) return null
    return queries[0].result.map(item => {
      const ratingValue = item.value.find(
        d => d['action.change.grade_int'] === 2
      )
      return {
        ...item,
        value: ratingValue ? ratingValue.result : 0,
      }
    })
  },
  queries: [
    {
      ...countMessageRatingsCreated,
      interval: 'daily',
      groupBy: 'action.change.grade_int',
      key: 'countMessageRatingsCreatedByGradeForEachDay',
    },
  ],
  title: '😩 BAD',
}
