export const countMessageRatingAwesome = {
  analysisType: 'count',
  eventCollection: 'message.rated',
  filters: [
    {
      operator: 'eq',
      propertyName: 'action.change.grade_int',
      propertyValue: 0,
    },
  ],
  key: 'countMessageRatingAwesome',
}
