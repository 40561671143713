import { connect } from 'react-redux'
import { selectShowKbSearchOnInboxEditor } from 'ducks/currentUser/selectors/preferences/selectShowKbSearchOnInboxEditor'
import { doToggleShowKbSearchOnInboxEditor } from '../../operations/kbSearch/toggle'
import { selectHasKnowledgebases } from '../../selectors/kbSearch'

import View from './view'

const select = state => ({
  hasKnowledgebases: selectHasKnowledgebases(state),
  isOpen: selectShowKbSearchOnInboxEditor(state),
})

const perform = {
  toggleKnowledgebaseToolbar: doToggleShowKbSearchOnInboxEditor,
}

export default connect(select, perform)(View)
