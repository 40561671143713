import { difference } from '../calculators/difference'
import { countConversationClosed } from '../queries/countConversationClosed'
import { countConversationOpened } from '../queries/countConversationOpened'

export const netDiffOpenConversationsGauge = {
  calculate: difference,
  description:
    'Difference in open conversations between the start and end of this period. Negative numbers are better and indicate that you are resolving more conversations than are being opened.',
  isNegativeChangeGood: true,
  isNegativeGood: true,
  queries: [countConversationOpened, countConversationClosed],
  title: 'Net Difference in Opened Assigned Conversations',
}
