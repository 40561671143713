import { connect } from 'react-redux'

import { selectAgentsById } from 'selectors/agents/base'

import TeamsCellView from './TeamsCellView'

const select = (state, { value }) => ({
  agent: selectAgentsById(state)[value],
})

export default connect(select)(TeamsCellView)
