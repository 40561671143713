import { PRODUCTIVITY_PAGE } from './pages'

function selectBase(state) {
  return state.reports.productivity
}

export function selectActiveGauge(state, { gaugeSwitchInstance }) {
  const { activeGauges } = selectBase(state)
  return activeGauges && activeGauges[gaugeSwitchInstance]
}

export const selectIsOnProductivityPage = state =>
  state.ducksPage.page === PRODUCTIVITY_PAGE
