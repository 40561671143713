import { combineReducers } from 'core/redux'

import { SWITCH_ACTIVE_GAUGE } from './types'

const defaultActiveGauge = {}

function activeGauges(
  state,
  { type, payload: { instanceId, activeGauge } = {} }
) {
  if (type === SWITCH_ACTIVE_GAUGE) {
    return {
      ...state,
      [instanceId]: activeGauge,
    }
  }
  return state || defaultActiveGauge
}

export default combineReducers({
  activeGauges,
})
