import Link from 'redux-first-router-link'

export default function LinkWithReportContextView({
  children,
  reportContextQueryParams,
  to,
  ...props
}) {
  return (
    <Link
      {...props}
      to={{
        ...to,
        payload: {
          ...to.payload,
          query: { ...reportContextQueryParams, ...to.payload.query },
        },
      }}
    >
      {children}
    </Link>
  )
}
