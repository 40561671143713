import { connect } from 'react-redux'

import { selectTimezoneOffset } from 'ducks/reports/controls'

function select(state) {
  return {
    timezoneOffset: selectTimezoneOffset(state),
  }
}

export default function withDecoratedGauges(ComposedComponent) {
  const connected = connect(select)(ComposedComponent)
  connected.displayName = 'WithTimeZoneOffset'
  return connected
}
