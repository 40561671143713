import moment from 'moment-timezone-all'
import { createSelector } from 'reselect'
import createCachedSelector from 're-reselect'

import { selectAccountPreferredReportsAverage } from 'selectors/app/base'
import { selectCurrentPath } from 'selectors/location'
import { selectPrefersExcludeImmediatelyClosedConversationsInReports } from 'selectors/app/selectAccountPreferences'
import { selectById as selectKbsById } from 'subapps/kb/selectors/knowledge_bases'
import { any, compact } from 'util/arrays'
import { relativeToAbsoluteDate } from 'util/date'
import { createBasicSelector } from 'util/redux'
import { isDefined } from 'util/nullOrUndefinedChecks'
import { selectAccountTimezone } from 'ducks/currentUser/selectors/preferences/selectAccountTimezone'
import { excludeImmediatelyClosedConversations } from '../gauges/queries/filters'
import { selectBusinessHoursTimezone } from '../businessHours'

function selectBase(state) {
  return state.reports.controls
}

export function selectActiveKnowledgeBaseId(state) {
  return state.location.query && state.location.query.knowledge_base_id
}

const selectActiveKnowledgeBaseFilter = createSelector(
  selectActiveKnowledgeBaseId,
  knowledgeBaseId => {
    if (knowledgeBaseId) {
      return {
        property_name: 'kb.id',
        operator: 'eq',
        property_value: knowledgeBaseId,
      }
    }
    return null
  }
)

export function selectActiveMailboxId(state) {
  return state.location.query && state.location.query.inbox_id
}

const selectActiveMailboxFilter = createSelector(
  selectActiveMailboxId,
  mailboxId => {
    if (mailboxId) {
      return {
        property_name: 'conversation.inbox.id',
        operator: 'eq',
        property_value: mailboxId,
      }
    }
    return null
  }
)

// https://trello.com/c/w6w20mOG/3198-add-feature-flag-to-exclude-immediately-closed-conversations-from-reports
// Conversations created by the compose conversation feature are created as closed. This inserts a 0 into the business hours data in keen.
// We exclude them from reports by ignoring conversations that are open for 0 seconds.
const selectExcludeImmediatelyClosedConversationsFilter = createSelector(
  selectPrefersExcludeImmediatelyClosedConversationsInReports,
  selectCurrentPath,
  (prefersExcludeImmediatelyClosedConversationsInReports, currentPath) => {
    const isInInboxReporting = currentPath.includes('/reports/inbox')
    if (
      prefersExcludeImmediatelyClosedConversationsInReports &&
      isInInboxReporting
    ) {
      return excludeImmediatelyClosedConversations
    }
    return null
  }
)

export function selectAreBusinessHoursEnabled(state) {
  const { areBusinessHoursEnabled = false } = selectBase(state)
  return !!areBusinessHoursEnabled
}

export function selectShowBusinessHoursNotice(state) {
  const { showBusinessHoursNotice = true } = selectBase(state)
  return showBusinessHoursNotice
}

export function selectAreControlsPending(state) {
  return !!selectFocusedInput(state)
}

export function selectFocusedInput(state) {
  return selectBase(state).focusedInput
}

const selectTimezoneWithSource = createSelector(
  selectBusinessHoursTimezone,
  selectAccountTimezone,
  (businessHoursTimezone, accountTimezone) => {
    if (isDefined(businessHoursTimezone)) {
      return {
        source: 'BUSINESS_HOURS',
        timezone: businessHoursTimezone,
      }
    }

    if (isDefined(accountTimezone)) {
      return {
        source: 'ACCOUNT',
        timezone: accountTimezone,
      }
    }

    return {
      source: 'BROWSER',
      timezone: moment.tz.guess(),
    }
  }
)

export const selectTimezoneSource = createBasicSelector(
  selectTimezoneWithSource,
  timezoneWithSource => {
    return timezoneWithSource.source
  }
)

export const selectTimezone = createBasicSelector(
  selectTimezoneWithSource,
  timezoneWithSource => {
    return timezoneWithSource.timezone
  }
)

export const selectTimezoneWithOffsetDisplay = createSelector(
  selectTimezone,
  timezone => {
    return `(GMT${moment.tz(timezone).format('ZZ')}) ${timezone}`
  }
)

export const selectTimezoneOffset = createSelector(selectTimezone, timezone => {
  return moment.tz(timezone).utcOffset() / 60
})

export function selectBrowserTimezoneOffset() {
  return new Date().getTimezoneOffset() / -60
}

const selectTimeframeRaw = createSelector(selectBase, base => base.timeframe)
const selectTimeframeRawProcessed = createSelector(selectTimeframeRaw, raw => {
  if (typeof raw === 'string') return relativeToAbsoluteDate(raw)
  return raw
})

export const selectTimeframe = createSelector(
  selectTimeframeRawProcessed,
  timeframe => {
    const { end, start } = timeframe
    return {
      end: moment(end),
      start: moment(start),
    }
  }
)

export const selectTimeframeForQuery = createSelector(
  selectTimeframeRawProcessed,
  selectTimezone,
  (timeframe, timezone) => {
    const { end, start } = timeframe
    const momentStart = moment.tz(start, timezone).startOf('day')
    const startOffset = momentStart.utcOffset()
    return {
      end: moment
        .tz(end, timezone)
        .add(1, 'day')
        .startOf('day')
        .utcOffset(startOffset, true)
        .utcOffset(startOffset)
        .format(),
      start: momentStart.format(),
    }
  }
)

export function selectTimeframeEnd(state) {
  return selectTimeframe(state).end
}

export function selectTimeframeStart(state) {
  return selectTimeframe(state).start
}

export const selectKbRatingType = createSelector(
  selectActiveKnowledgeBaseId,
  selectKbsById,
  (kbId, byId) => {
    if (kbId && byId[kbId]) return byId[kbId].ratingsType
    return any(
      ({ ratingsType }) => ratingsType === 'emoji',
      Object.values(byId)
    )
      ? 'emoji'
      : 'upvote'
  }
)

export const selectPreviousPeriodOffset = createSelector(
  selectTimeframe,
  ({ end, start }) => {
    const mEnd = moment(end)
    return moment.duration(mEnd.diff(start)).add(1, 'day')
  }
)

const selectFiltersForQuery = createSelector(
  selectActiveKnowledgeBaseFilter,
  selectActiveMailboxFilter,
  selectExcludeImmediatelyClosedConversationsFilter,
  (
    activeKnowledgeBaseFilter,
    activeMailboxFilter,
    excludeImmediatelyClosedConversationsFilter
  ) => {
    return compact([
      activeKnowledgeBaseFilter,
      activeMailboxFilter,
      excludeImmediatelyClosedConversationsFilter,
    ])
  }
)

export const selectQueryBase = createSelector(
  selectAccountPreferredReportsAverage,
  selectAreBusinessHoursEnabled,
  selectFiltersForQuery,
  selectTimeframeForQuery,
  selectTimezone,
  (average, businessHours, filters, timeframe, timezone) => {
    return {
      average,
      businessHours,
      filters,
      timeframe,
      timezone,
    }
  }
)

export const selectQueryBaseExclFilterProperties = createCachedSelector(
  selectQueryBase,
  (_, excludingFilterProperties) => excludingFilterProperties,
  (queryBase, excludingFilterProperties) => {
    if (
      !excludingFilterProperties ||
      !Array.isArray(excludingFilterProperties)
    ) {
      return queryBase
    }

    return {
      ...queryBase,
      filters: (queryBase?.filters || []).filter(
        filter => !excludingFilterProperties.includes(filter?.propertyName)
      ),
    }
  }
)(
  (_, excludingFilterProperties) =>
    (excludingFilterProperties || []).join(',') || 'UNKNOWN'
)

export const selectScopedQueryBase = createSelector(
  (state, props) => props.id,
  (state, props) => props.propertyName,
  selectQueryBase,
  (id, propertyName, queryBase) => {
    const query = {
      ...queryBase,
      scope: { [propertyName]: id },
    }
    if (propertyName === 'conversation.assignee.id') {
      query.agentId = id
    }
    if (propertyName === 'conversation.assignee_team.id') {
      query.groupId = id
    }
    return query
  }
)

export const selectToday = createSelector(selectTimezone, timezone => {
  return moment(
    moment
      .tz(timezone)
      .startOf('day')
      .format('YYYY-MM-DD')
  )
})
