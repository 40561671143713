import Bugsnag from '@bugsnag/js'
import groove from 'api/groove'

import { logError } from 'util/debug'

import { oauthTokenSelector } from 'selectors/app'
import { selectBusinessHours } from '../selectors'
import {
  BUSINESS_HOURS_LOAD_REQUEST,
  BUSINESS_HOURS_LOAD_RESPONSE,
  BUSINESS_HOURS_LOAD_ERROR,
} from '../types'

export default function doBusinessHoursLoad() {
  return (dispatch, getState) => {
    const state = getState()
    const token = oauthTokenSelector(state)
    const businessHours = selectBusinessHours(state)

    const shouldLoadBusinessHours = !(
      !businessHours.loading &&
      businessHours.loaded &&
      businessHours.persisted
    )
    if (!shouldLoadBusinessHours) return
    dispatch(doBusinessHoursLoadRequest())
    groove
      .get(token, `/v2/reports/business_hours`)
      .then(result => {
        dispatch(doBusinessHoursLoadResponse(result.json))
      })
      .catch(error => {
        logError(error)
        Bugsnag.notify(error)
        dispatch(doBusinessHoursLoadError(error))
      })
  }
}

function doBusinessHoursLoadRequest() {
  return {
    type: BUSINESS_HOURS_LOAD_REQUEST,
  }
}

function doBusinessHoursLoadResponse(result) {
  return {
    type: BUSINESS_HOURS_LOAD_RESPONSE,
    payload: {
      result,
    },
  }
}

function doBusinessHoursLoadError(error) {
  return {
    type: BUSINESS_HOURS_LOAD_ERROR,
    payload: {
      error,
    },
  }
}
