import createCachedSelector from 're-reselect'

import { selectQueriesByIdRaw } from 'ducks/reports/queries'
import { emptyObj } from 'util/objects'

import selectQueryReadyForState from './selectQueryReadyForState'
import queryCacheKey from './cacheKeys/queryCacheKey'

export default createCachedSelector(
  selectQueriesByIdRaw,
  selectQueryReadyForState,
  (queriesById, queryReadyForState) => {
    return queriesById[queryReadyForState.id] || emptyObj
  }
)(queryCacheKey)
