import { BASIC_TEMPLATE_FIELDS } from './queries'

export const createMutation = () => `
  mutation TagCreate(
    $color: String,
    $name: String,
  ) {
    tagCreate(
      input: {
        color: $color,
        name: $name,
      }
    ) {
      tag {
        ${BASIC_TEMPLATE_FIELDS}
      }
      errors {
        path
        message
      }
    }
  }
`

export const updateMutation = () => `
  mutation TagUpdate(
    $color: String,
    $name: String,
    $tagId: ID!,
  ) {
    tagUpdate(
      input: {
        color: $color,
        name: $name,
        tagId: $tagId,
      }
    ) {
      tag {
        ${BASIC_TEMPLATE_FIELDS}
      }
      errors {
        path
        message
      }
    }
  }
`

export const singleDeleteMutation = () => `
  mutation TagDelete(
    $tagId: ID!,
  ) {
    tagDelete(
      input: {
        tagId: $tagId,
      }
    ) {
      deletedTagId
      errors {
        path
        message
      }
    }
  }
`

export const batchDeleteMutation = () => `
  mutation TagsBatchDelete(
    $filter: TagFilter,
    $tagIds: [ID!]
  ) {
    tagBatchDelete(
      input: {
        filter: $filter,
        tagIds: $tagIds,
      }
    ) {
      jobId
      errors {
        path
        message
      }
    }
  }
`

export const mergeTagMutation = () => `
  mutation TagsMergeMutation(
    $parentTagId: ID!,
    $filter: TagFilter,
    $tagIds: [ID!]
  ) {
    tagMerge(
      input: {
        parentTagId: $parentTagId,
        filter: $filter,
        tagIds: $tagIds,
      }
    ) {
      jobId
      errors {
        path
        message
      }
    }
  }
`
