import { countConversationsResolved } from '../queries/countConversationsResolved'

export const totalConversationsResolvedByAgentGauge = {
  id: 'resolutions_by_agent',
  queries: [
    {
      ...countConversationsResolved,
      groupBy: 'action.actor.user.id',
      key: 'countConversationsResolvedByActor',
    },
  ],
  title: `Resolutions By ${app.t('Agent')}`,
}
