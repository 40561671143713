import React, { Fragment } from 'react'
import { propFunc } from 'util/functions'
import { asList, withKeyboardNavigation } from 'util/hoc'
import { getArticleState } from '../../utils/kbSearch'
import withSearchKbController from './withController'
import Item from './Item'
import Empty from './Empty'

function SearchKbList({
  items,
  openKbArticlePopout,
  focusedItem = {},
  insertKbLink,
  onItemFocus,
  getItemRef,
  primaryKnowledgeBaseId,
  isAddingNote,
  hasMultipleKnowledgebases,
}) {
  return (
    <Fragment>
      {items.length === 0 && (
        <Empty
          key={`kb-article-empty`}
          knowledgeBaseId={primaryKnowledgeBaseId}
        />
      )}
      {items.map(item => {
        const {
          id,
          title,
          description,
          knowledgeBaseId,
          knowledgeBase: { title: knowledgeBaseTitle } = {},
        } = item
        const itemState = getArticleState(item)
        return (
          <Item
            ref={getItemRef(item)}
            key={`kb-article-${id}`}
            id={id}
            knowledgeBaseId={knowledgeBaseId}
            knowledgeBaseTitle={knowledgeBaseTitle}
            title={title}
            description={description}
            state={itemState}
            onClick={openKbArticlePopout}
            onLinkClick={insertKbLink}
            isFocused={focusedItem.id === item.id}
            onMouseMove={propFunc(onItemFocus, item, true)}
            isAddingNote={isAddingNote}
            hasMultipleKnowledgebases={hasMultipleKnowledgebases}
          />
        )
      })}
    </Fragment>
  )
}

export default asList(
  withSearchKbController(withKeyboardNavigation(SearchKbList)),
  {
    trackingMode: 'id',
  }
)
