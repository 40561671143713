import { ratio } from '../calculators/ratio'
import { countConversationsResolvedWithAtLeastOneReply } from '../queries/countConversationsResolvedWithAtLeastOneReply'
import { countConversationsResolvedWithSingleReply } from '../queries/countConversationsResolvedWithSingleReply'

export const firstReplyResolvedRatioGauge = {
  calculate: ratio,
  description:
    'The percentage of conversations that were resolved with a single response.',
  queries: [
    countConversationsResolvedWithSingleReply,
    countConversationsResolvedWithAtLeastOneReply,
  ],
  title: 'First Reply Resolved',
}
