import { connect } from 'react-redux'

import { conversationsCreatedPerDayGauge } from 'ducks/reports/gauges'
import { HeadlineVisualization } from 'ducks/reports/visualizations'

const staticProps = {
  gauges: [conversationsCreatedPerDayGauge],
  title: 'Avg. conversations per day',
}

export default function ConversationsCreatedPerDayHeadline(props) {
  return <HeadlineVisualization {...props} {...staticProps} />
}
