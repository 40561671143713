import { sortY } from 'ducks/reports/visualizations'

function calcRatingScore({ y: ys }) {
  return ys.reduce(
    (reduction, { group, y }) => reduction + y * (group[0] - 5),
    0
  )
}

export default function sortArrayYRating(a, b) {
  const calcA = calcRatingScore(a)
  const calcB = calcRatingScore(b)
  if (calcA < calcB) return -1
  if (calcA > calcB) return 1
  return 0
}
