import { createPayload } from 'optimistic/base'
import { sortByKey } from 'util/arrays'
import { optimisticUpdatedAt } from 'util/optimistic'
import { toServerString } from 'util/date'
import { MAILBOX } from 'constants/changesetActionChangeTypes'
import { selectTicketById } from 'selectors/tickets/byId/selectTicketById'

// builds mock data for a [un]star action.
export const mailboxPayload = (
  state,
  ticketId,
  newMailboxId,
  folderId,
  search,
  updatedAt = toServerString(new Date())
) => {
  const previousMailboxId = selectTicketById(state, ticketId).mailboxId
  return createPayload(
    state,
    ticketId,
    'mailbox',
    {
      mailboxId: newMailboxId,
      updated_at: updatedAt,
    },
    [
      {
        change_type: MAILBOX,
        change: {
          mailbox: newMailboxId,
          previous_mailbox: previousMailboxId,
          type: 'Mailbox',
        },
      },
    ],
    folderId,
    search
  )
}
// Returns the optimistic data for a bulk starring action.
export const bulkMailboxPayload = (
  state,
  ticketIds,
  newMailboxId,
  folderId,
  search
) => {
  return sortByKey(
    // responses come back ordered by id
    ticketIds.map(id => {
      return mailboxPayload(
        state,
        id,
        newMailboxId,
        folderId,
        search,
        optimisticUpdatedAt()
      )
    }),
    'id'
  )
}
