import Label from '../../Label'

export default function GradeToEmojiCell(props) {
  const { value } = props
  let emoji
  switch (value) {
    case 0:
      emoji = '😁'
      break
    case 1:
      emoji = '😕'
      break
    case 2:
      emoji = '😩'
      break
    default:
      emoji = value
      break
  }
  return <Label>{emoji}</Label>
}
