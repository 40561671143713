export const FETCH_WALLETS = 'wallets/FETCH_WALLETS'
export const FETCH_WALLETS_STARTED = 'wallets/FETCH_WALLETS_STARTED'
export const FETCH_WALLETS_SUCCESS = 'wallets/FETCH_WALLETS_SUCCESS'
export const FETCH_WALLETS_FAILED = 'wallets/FETCH_WALLETS_FAILED'
export const UPDATE_WALLET_BY_REALTIME = 'wallets/UPDATE_WALLET_BY_REALTIME'
export const UPDATE_WALLET = 'wallets/UPDATE_WALLET'
export const UPDATE_WALLET_STARTED = 'wallets/UPDATE_WALLET_STARTED'
export const UPDATE_WALLET_SUCCESS = 'wallets/UPDATE_WALLET_SUCCESS'
export const UPDATE_WALLET_FAILED = 'wallets/UPDATE_WALLET_FAILED'

export const CHARGE_CREDIT_FROM_WALLET = 'ai/CHARGE_CREDIT_FROM_WALLET'
export const CHARGE_CREDIT_FROM_WALLET_STARTED =
  'ai/CHARGE_CREDIT_FROM_WALLET_STARTED'
export const CHARGE_CREDIT_FROM_WALLET_SUCCESS =
  'ai/CHARGE_CREDIT_FROM_WALLET_SUCCESS'
export const CHARGE_CREDIT_FROM_WALLET_FAILED =
  'ai/CHARGE_CREDIT_FROM_WALLET_FAILED'
