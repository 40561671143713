export default function parseBusinessHours(days = {}) {
  const newDays = {}
  for (let d = 1; d <= 7; d += 1) {
    const currentDay = days[d] || {}
    newDays[d] = {
      enabled: !(
        currentDay === undefined ||
        currentDay === null ||
        !(currentDay.start && currentDay.end)
      ),
      end: currentDay.end || '05:00 PM',
      start: currentDay.start || '09:00 AM',
    }
  }
  return newDays
}
