import moment from 'moment-timezone-all'

const MS_IN_WEEK = 1000 * 60 * 60 * 24 * 7

export default function calculateDaysInTimeframe({ end, start }) {
  return [1, 2, 3, 4, 5, 6, 7].map(day => {
    const startMoment = moment(start.substr(0, 10))
    const endMoment = moment(end.substr(0, 10)).subtract(1, 'day') // This is predicated on the fact that the end of the period is the start of the next day. It works for now
    const startDayOfWeekZeroIndexed = startMoment.day()
    const startDayOfWeek =
      startDayOfWeekZeroIndexed === 0 ? 7 : startDayOfWeekZeroIndexed
    const endDayOfWeekZeroIndexed = endMoment.day()
    const endDayOfWeek =
      endDayOfWeekZeroIndexed === 0 ? 7 : endDayOfWeekZeroIndexed
    const isSingleWeek =
      startMoment.format('GGGG-W') === endMoment.format('GGGG-W')
    const firstWeek =
      startDayOfWeek <= day && (!isSingleWeek || endDayOfWeek >= day) ? 1 : 0
    const wholeLastWeek = endDayOfWeek === 7
    const lastWeek =
      !isSingleWeek && !wholeLastWeek && endDayOfWeek >= day ? 1 : 0
    const wholeWeeks = !isSingleWeek
      ? (endMoment.clone().day(0) - startMoment.clone().day(7)) / MS_IN_WEEK
      : 0

    const positiveWholeWeeks = wholeWeeks > 0 ? wholeWeeks : 0
    return firstWeek + positiveWholeWeeks + lastWeek
  })
}
