import { uniq } from 'util/arrays'

export default function extractXValuesFromJoinedDataSet(
  dataSets,
  unpaged = false
) {
  const key = unpaged ? 'unpagedXValues' : 'xValues'
  const sortedDataSet = dataSets.find(({ isSorted }) => isSorted)
  if (sortedDataSet) return sortedDataSet[key]
  const joinedDataSet = dataSets.find(({ isJoinedThrough }) => isJoinedThrough)
  if (joinedDataSet) return joinedDataSet[key]
  return uniq([].concat(...dataSets.map(dataSet => dataSet[key])))
}
