import { combineReducers } from 'core/redux'

import {
  FETCH_REPORT_KB_ARTICLES_REQUEST,
  FETCH_REPORT_KB_ARTICLES_RESPONSE,
  FETCH_REPORT_KB_ARTICLES_ERROR,
} from './types'

const defaultKbArticles = []

function loadKbArticles(state, { type, payload: { result } = {} }) {
  if (type === FETCH_REPORT_KB_ARTICLES_REQUEST) {
    return {
      ...state,
      errored: false,
      loaded: false,
      loading: true,
    }
  } else if (type === FETCH_REPORT_KB_ARTICLES_RESPONSE) {
    return {
      ...state,
      loading: false,
      loaded: true,
      result,
    }
  } else if (type === FETCH_REPORT_KB_ARTICLES_ERROR) {
    return {
      ...state,
      errored: true,
      loading: false,
    }
  }
  return state || defaultKbArticles
}

export default combineReducers({
  articles: loadKbArticles,
})
