export const ratingHistoryGauge = {
  queries: [
    {
      analysisType: 'ratings',
      grade: null,
      ticket_state: null,
      per_page: 10,
      page: 1,
    },
  ],
  title: 'Rating History',
}
