export const walletMutation = `
  mutation WalletUpdate($walletId: ID!, $autorenew: Boolean!, $autorenewAmount: Int!, $autorenewUses: Int!, $autorenewThreshold: Int!) {
    walletUpdate(
      input: {
        walletId: $walletId
        autorenew: $autorenew
        autorenewAmount: $autorenewAmount
        autorenewUses: $autorenewUses
        autorenewThreshold: $autorenewThreshold
  }) {
        wallet {
          balance
          canUse
          id
          updatedAt
          ownerId
          limitType
          ownerType
          autorenew
          autorenewThreshold
          autorenewAmount
          autorenewUses
          autorenewAt
        }
    }
  }
`

export const walletChargeCreditMutation = `
  mutation WalletTransactionCreate($feature: String!) {
    walletTransactionCreate(input: { feature: $feature }) {
        errors {
          message
        }
        success
    }
  }
`
