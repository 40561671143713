// DEPRECATED LEGACY CODE
// This functionality is now provided by the visualizations enhancer HOCs.
// Some report widgets still use this code though.

import { createSelector } from 'reselect'
import moment from 'moment'
import merge from 'deepmerge'

import { all, any } from 'util/arrays'
import { queryObjectToQueryId, selectQueriesById } from 'ducks/reports/queries'
import {
  selectAreBusinessHoursEnabled,
  selectBrowserTimezoneOffset,
  selectPreviousPeriodOffset,
  selectTimeframe,
  selectTimezone,
} from 'ducks/reports/controls'
import { numberToSize } from 'util/numbers'
import { createArraySelector } from 'util/selectors'

import { selectComponentStateById } from '../visualizations/enhancers/withComponentState/selectors'
import adjustTimeframeToPreviousPeriod from './adjustTimeframeToPreviousPeriod'
import { first as defaultCalculate } from './calculators/first'
import { first as defaultPager } from './pagers'
import {
  agentNameByAgentGauge,
  articleViewsPerDayGauge,
  averageFirstResponseTimeForEachDayGauge,
  averageFirstResponseTimeGauge,
  averageFirstResponseTimeByAgentGauge,
  averageRepliesPerResolutionForEachDayGauge,
  averageRepliesPerResolutionGauge,
  averageResolutionTimeForEachDayGauge,
  averageResolutionTimeGauge,
  averageResolutionTimeByAgentGauge,
  averageResponseTimeGauge,
  averageSearchResultsByTermGauge,
  averageTimeOnArticleGauge,
  conversationsAssignedCreatedRatioGauge,
  conversationsAssignedCustomersForEachDayGauge,
  conversationsAssignedCustomersPerDayGauge,
  conversationsAssignedForEachDayGauge,
  conversationsAssignedPerDayGauge,
  conversationsAssignedResolvedRatioGauge,
  conversationsCreatedForEachDayGauge,
  conversationsCreatedPerDayGauge,
  conversationsResolvedForEachDayGauge,
  conversationsResolvedPerDayGauge,
  customerLeftRatingRatioGauge,
  customersForEachDayGauge,
  customersPerDayGauge,
  firstReplyResolvedRatioGauge,
  viewedArticleRatioGauge,
  happinessAwesomeRatingForEachDayGauge,
  happinessBadRatingForEachDayGauge,
  happinessOkRatingForEachDayGauge,
  happinessScoreRatioByAgentGauge,
  happinessScoreRatioByTeamGauge,
  happinessScoreRatioGauge,
  netDiffOpenAssignedAgentConversationsGauge,
  netDiffOpenConversationsGauge,
  performedSearchRatioGauge,
  popularArticleGauge,
  ratingByGradeGauge,
  ratingHistoryGauge,
  searchesClickThroughRateRatioGauge,
  tagCountByTagGauge,
  tagCountByTagPerDayGauge,
  teamNameByTeamGauge,
  teamNamesByAgentGauge,
  topClickedArticleBySearchTermGauge,
  totalArticleViewsGauge,
  totalConversationsAssignedByAgentGauge,
  totalConversationsAssignedByTeamGauge,
  totalConversationsAssignedCustomersGauge,
  totalConversationsAssignedGauge,
  totalConversationsCreatedGauge,
  totalConversationsResolvedByAgentGauge,
  totalConversationsResolvedByTeamGauge,
  totalConversationsResolvedGauge,
  totalCustomersGauge,
  totalFailedSearchesByTermGauge,
  totalFailedSearchesGauge,
  totalKbVisitorsGauge,
  totalKbVisitsGauge,
  totalMessagesSentByAgentGauge,
  totalSearchesByTermGauge,
  totalSearchesGauge,
  uniquePageVisitsPerDayGauge,
} from './definitions'

export const foo = 'bar'

const EMPTY_ARRAY = []

function applyScope(query) {
  const { filters, scope, ...rest } = query
  if (!scope) return query
  return {
    ...rest,
    filters: [
      ...filters,
      ...Object.keys(scope).map(propertyName => ({
        propertyName,
        operator: Array.isArray(scope[propertyName]) ? 'in' : 'eq',
        propertyValue: scope[propertyName],
      })),
    ],
  }
}

function makeSelectQueriesForGauge(
  gauge,
  needsPrevious = false,
  adjustQuery = q => q
) {
  return createSelector(
    (state, props) => props.queryBase,
    (state, props) => props.resolutionsQueryBase,
    (state, props) => needsPrevious && props.includePreviousPeriod !== true,
    selectQueriesById,
    selectComponentStateById,
    selectAreBusinessHoursEnabled,
    (
      queryBase,
      resolutionsQueryBase,
      returnEmpty,
      queriesById,
      componentStateById,
      areBusinessHoursEnabled
    ) => {
      if (returnEmpty) return EMPTY_ARRAY
      const {
        businessHoursQuery,
        businessHoursQueries = businessHoursQuery && [businessHoursQuery],
        query: querySingular,
        queries = querySingular && [querySingular],
      } = gauge
      const queriesToUse =
        areBusinessHoursEnabled && businessHoursQueries
          ? businessHoursQueries
          : queries
      return queriesToUse.map(query => {
        const finalQueryBase =
          query.eventCollection === 'conversation.resolved' &&
          resolutionsQueryBase
            ? resolutionsQueryBase
            : queryBase
        const queryWithBase = applyScope(
          adjustQuery(
            merge.all([
              applyScope(query),
              finalQueryBase,
              componentStateById.query || {},
            ])
          )
        )
        const { queryToId = queryObjectToQueryId } = query
        const id = queryToId(queryWithBase)

        if (queriesById[id]) return queriesById[id]
        return queryWithBase
      })
    }
  )
}

function mapResultTimeframes(queries, timezone) {
  return queries.map(query => {
    if (query.result && query.result.map) {
      return {
        ...query,
        result: query.result.map(x => {
          if (x.timeframe) {
            return {
              ...x,
              timeframe: {
                ...x.timeframe,
                end: moment.tz(x.timeframe.end, timezone).format(),
                start: moment.tz(x.timeframe.start, timezone).format(),
              },
            }
          }
          return x
        }),
      }
    }
    return query
  })
}

export function makeSelectGauge(gauge) {
  const selectQueriesForGauge = makeSelectQueriesForGauge(gauge)
  const selectQueriesForPreviousPeriod = makeSelectQueriesForGauge(
    gauge,
    true,
    adjustTimeframeToPreviousPeriod
  )
  return createArraySelector(
    (state, props) => props.queryBase,
    (state, props) => props.includePreviousPeriod,
    selectBrowserTimezoneOffset,
    selectPreviousPeriodOffset,
    selectQueriesForPreviousPeriod,
    selectQueriesForGauge,
    selectTimeframe,
    selectTimezone,
    (
      queryBase,
      includePreviousPeriod,
      offset,
      previousOffset,
      previousPeriodQueries,
      queries,
      timeframe,
      timezone
    ) => {
      const { calculate, pager, isNegativeChangeGood } = gauge
      const timeframeDiff = moment.duration(
        moment(timeframe.end).diff(timeframe.start)
      )
      const mappedQueries = mapResultTimeframes(queries, timezone)
      const mappedPreviousPeriodQueries = mapResultTimeframes(
        previousPeriodQueries,
        timezone
      )
      const selectedGauge = {
        ...gauge,
        queries: mappedQueries,
        query: mappedQueries.length === 1 ? mappedQueries[0] : false,
        queryBase,
        loaded: all(x => x && x.loaded, mappedQueries),
        loading: any(
          x => !x || (x && (x.loading || x.loading === undefined)),
          mappedQueries
        ),
        errored: any(x => x && x.errored, mappedQueries),
        timeframe: {
          ...timeframe,
          end: timeframe.end.format(),
          duration: timeframeDiff.asSeconds(),
          durationLabel: timeframeDiff.format(
            'y [years], m [months], d [days], h [hours]',
            { largest: 1 }
          ),
          start: timeframe.start.format(),
        },
        timezone,
      }
      const result = (calculate || defaultCalculate)(selectedGauge)
      const pagination = (pager || defaultPager)(selectedGauge)
      if (result && result[0] && result[0].timeframe) {
        selectedGauge.result = result.map(x => {
          return {
            ...x,
            timeframe: {
              end: x.timeframe.end,
              start: x.timeframe.start,
            },
          }
        })
      } else {
        selectedGauge.result = result
      }
      selectedGauge.pagination = pagination
      if (includePreviousPeriod) {
        selectedGauge.previousPeriod = {
          errored: any(x => x && x.errored, mappedPreviousPeriodQueries),
          loaded: all(x => x && x.loaded, mappedPreviousPeriodQueries),
          loading: any(x => x && x.loading, mappedPreviousPeriodQueries),
          queries: mappedPreviousPeriodQueries,
          query:
            mappedPreviousPeriodQueries.length === 1
              ? mappedPreviousPeriodQueries[0]
              : false,
          offset: previousOffset,
        }
        selectedGauge.previousPeriod.result = (calculate || defaultCalculate)(
          selectedGauge.previousPeriod
        )
        selectedGauge.previousPeriod.pagination = (pager || defaultPager)(
          selectedGauge.previousPeriod
        )
      }
      if (selectedGauge.previousPeriod && (result || result === 0)) {
        const { result: previousResult } = selectedGauge.previousPeriod
        if (previousResult || previousResult === 0) {
          const difference = result && previousResult && result - previousResult
          const ratioChange =
            difference && previousResult && difference / previousResult
          const percentageChange = ratioChange * 100
          const percentageChangeRounded = Math.round(percentageChange * 10) / 10
          const isNegative = difference < 0
          const isPositive = difference > 0
          selectedGauge.comparison = {
            difference,
            isBad: isNegativeChangeGood ? isPositive : isNegative,
            isGood: isNegativeChangeGood ? isNegative : isPositive,
            isInfinity: Math.abs(percentageChange) === Infinity,
            isPositive,
            isNegative,
            percent: percentageChange,
            percentageChangeRounded,
            percentageChangeLimited: numberToSize(
              Math.abs(percentageChangeRounded),
              1
            ),
            ratio: ratioChange,
          }
        }
      }
      return selectedGauge
    }
  )
}

export function makeSelectStateGauge(gauge) {
  return createArraySelector(
    (state, props) => props.queryBase,
    state => state,
    selectTimeframe,
    (queryBase, state, timeframe) => {
      const { calculate, selector } = gauge
      const timeframeDiff = moment.duration(timeframe.end.diff(timeframe.start))
      const stateData = selector(state, queryBase)
      const selectedGauge = {
        ...gauge,
        queryBase,
        loaded: stateData,
        loading: !stateData,
        errored: false,
        stateData,
        timeframe: {
          ...timeframe,
          end: timeframe.end.format(),
          duration: timeframeDiff.asSeconds(),
          durationLabel: timeframeDiff.format(
            'y [years], m [months], d [days], h [hours]',
            { largest: 1 }
          ),
          start: timeframe.start.format(),
        },
      }
      const result = calculate(selectedGauge)
      selectedGauge.result = result
      return selectedGauge
    }
  )
}

export const selectAgentNameByAgentGauge = makeSelectStateGauge(
  agentNameByAgentGauge
)

export const selectArticleViewsPerDayGauge = makeSelectGauge(
  articleViewsPerDayGauge
)

export const selectAverageFirstResponseTimeForEachDayGauge = makeSelectGauge(
  averageFirstResponseTimeForEachDayGauge
)
export const selectAverageFirstResponseTimeGauge = makeSelectGauge(
  averageFirstResponseTimeGauge
)
export const selectAverageFirstResponseTimeByAgentGauge = makeSelectGauge(
  averageFirstResponseTimeByAgentGauge
)
export const selectAverageRepliesPerResolutionForEachDayGauge = makeSelectGauge(
  averageRepliesPerResolutionForEachDayGauge
)
export const selectAverageRepliesPerResolutionGauge = makeSelectGauge(
  averageRepliesPerResolutionGauge
)
export const selectAverageResolutionTimeForEachDayGauge = makeSelectGauge(
  averageResolutionTimeForEachDayGauge
)
export const selectAverageResolutionTimeGauge = makeSelectGauge(
  averageResolutionTimeGauge
)
export const selectAverageResolutionTimeByAgentGauge = makeSelectGauge(
  averageResolutionTimeByAgentGauge
)
export const selectAverageResponseTimeGauge = makeSelectGauge(
  averageResponseTimeGauge
)
export const selectAverageTimeOnArticleGauge = makeSelectGauge(
  averageTimeOnArticleGauge
)
export const selectConversationsAssignedCreatedRatioGauge = makeSelectGauge(
  conversationsAssignedCreatedRatioGauge
)
export const selectConversationsAssignedResolvedRatioGauge = makeSelectGauge(
  conversationsAssignedResolvedRatioGauge
)
export const selectConversationsAssignedCustomersForEachDayGauge = makeSelectGauge(
  conversationsAssignedCustomersForEachDayGauge
)
export const selectConversationsAssignedCustomersPerDayGauge = makeSelectGauge(
  conversationsAssignedCustomersPerDayGauge
)
export const selectConversationsAssignedForEachDayGauge = makeSelectGauge(
  conversationsAssignedForEachDayGauge
)
export const selectConversationsAssignedPerDayGauge = makeSelectGauge(
  conversationsAssignedPerDayGauge
)
export const selectConversationsCreatedForEachDayGauge = makeSelectGauge(
  conversationsCreatedForEachDayGauge
)
export const selectConversationsCreatedPerDayGauge = makeSelectGauge(
  conversationsCreatedPerDayGauge
)
export const selectConversationsResolvedForEachDayGauge = makeSelectGauge(
  conversationsResolvedForEachDayGauge
)
export const selectConversationsResolvedPerDayGauge = makeSelectGauge(
  conversationsResolvedPerDayGauge
)
export const selectCustomerLeftRatingRatioGauge = makeSelectGauge(
  customerLeftRatingRatioGauge
)
export const selectCustomersForEachDayGauge = makeSelectGauge(
  customersForEachDayGauge
)
export const selectCustomersPerDayGauge = makeSelectGauge(customersPerDayGauge)
export const selectFirstReplyResolvedRatioGauge = makeSelectGauge(
  firstReplyResolvedRatioGauge
)
export const selectViewedArticleRatioGauge = makeSelectGauge(
  viewedArticleRatioGauge
)
export const selectHappinessAwesomeForEachDayGauge = makeSelectGauge(
  happinessAwesomeRatingForEachDayGauge
)
export const selectHappinessBadForEachDayGauge = makeSelectGauge(
  happinessBadRatingForEachDayGauge
)
export const selectHappinessOkForEachDayGauge = makeSelectGauge(
  happinessOkRatingForEachDayGauge
)
export const selectnetDiffOpenAssignedAgentConversationsGauge = makeSelectGauge(
  netDiffOpenAssignedAgentConversationsGauge
)
export const selectNetDiffOpenConversations = makeSelectGauge(
  netDiffOpenConversationsGauge
)
export const selectTeamNameByTeamGauge = makeSelectStateGauge(
  teamNameByTeamGauge
)
export const selectTeamNamesByAgentGauge = makeSelectStateGauge(
  teamNamesByAgentGauge
)
export const selectTotalConversationsAssignedCustomersGauge = makeSelectGauge(
  totalConversationsAssignedCustomersGauge
)
export const selectTotalConversationsAssignedGauge = makeSelectGauge(
  totalConversationsAssignedGauge
)
export const selectRatingByGradeGauge = makeSelectGauge(ratingByGradeGauge)
export const selectHappinessScoreRatioByAgentGauge = makeSelectGauge(
  happinessScoreRatioByAgentGauge
)
export const selectHappinessScoreRatioByTeamGauge = makeSelectGauge(
  happinessScoreRatioByTeamGauge
)
export const selectHappinessScoreRatioGauge = makeSelectGauge(
  happinessScoreRatioGauge
)
export const selectPerformedSearchRatioGauge = makeSelectGauge(
  performedSearchRatioGauge
)
export const selectTagCountByTagGauge = makeSelectGauge(tagCountByTagGauge)
export const selectTagCountByTagPerDayGauge = makeSelectGauge(
  tagCountByTagPerDayGauge
)
export const selectTotalArticleViewsGauge = makeSelectGauge(
  totalArticleViewsGauge
)
export const selectTotalKbVisitorsGauge = makeSelectGauge(totalKbVisitorsGauge)
export const selectTotalKbVisitsGauge = makeSelectGauge(totalKbVisitsGauge)
export const selectPopularArticleGauge = makeSelectGauge(popularArticleGauge)
export const selectRatingHistoryGauge = makeSelectGauge(ratingHistoryGauge)
export const selectTotalConversationsAssignedByAgentGauge = makeSelectGauge(
  totalConversationsAssignedByAgentGauge
)
export const selectTotalConversationsAssignedByTeamGauge = makeSelectGauge(
  totalConversationsAssignedByTeamGauge
)
export const selectTotalConversationsCreatedGauge = makeSelectGauge(
  totalConversationsCreatedGauge
)
export const selectTotalConversationsResolvedByAgentGauge = makeSelectGauge(
  totalConversationsResolvedByAgentGauge
)
export const selectTotalConversationsResolvedByTeamGauge = makeSelectGauge(
  totalConversationsResolvedByTeamGauge
)
export const selectTotalConversationsResolvedGauge = makeSelectGauge(
  totalConversationsResolvedGauge
)
export const selectTotalCustomersGauge = makeSelectGauge(totalCustomersGauge)
export const selectUniquePageVisitsPerDayGauge = makeSelectGauge(
  uniquePageVisitsPerDayGauge
)

export const selectTotalSearchesGauge = makeSelectGauge(totalSearchesGauge)
export const selectTotalFailedSearchesGauge = makeSelectGauge(
  totalFailedSearchesGauge
)
export const selectTotalSearchesByTermGauge = makeSelectGauge(
  totalSearchesByTermGauge
)
export const selectTotalFailedSearchesByTermGauge = makeSelectGauge(
  totalFailedSearchesByTermGauge
)
export const selectAverageSearchResultsByTermGauge = makeSelectGauge(
  averageSearchResultsByTermGauge
)
export const selectSearchesClickThroughRateRatioGauge = makeSelectGauge(
  searchesClickThroughRateRatioGauge
)
export const selectTopClickedArticleBySearchTermGauge = makeSelectGauge(
  topClickedArticleBySearchTermGauge
)
export const selectTotalMessagesSentByAgentGauge = makeSelectGauge(
  totalMessagesSentByAgentGauge
)
