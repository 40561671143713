import { combineReducers } from 'core/redux'

import {
  FETCH_CUSTOMERS_HELPED_REQUEST,
  FETCH_CUSTOMERS_HELPED_RESPONSE,
  FETCH_CUSTOMERS_HELPED_ERROR,
} from './types'

const defaultState = {
  agentsById: {},
  errored: false,
  loaded: false,
  loading: false,
  teamsById: {},
}

export default function customersHelped(
  state,
  { type, payload: { result } = {} }
) {
  const { agents, teams } = result || {}
  if (type === FETCH_CUSTOMERS_HELPED_REQUEST) {
    return {
      ...state,
      errored: false,
      loaded: false,
      loading: true,
    }
  } else if (type === FETCH_CUSTOMERS_HELPED_RESPONSE) {
    return {
      ...state,
      agentsById: agents,
      errored: false,
      loading: false,
      loaded: true,
      teamsById: teams,
    }
  } else if (type === FETCH_CUSTOMERS_HELPED_ERROR) {
    return {
      ...state,
      errored: true,
      loading: false,
    }
  }
  return state || defaultState
}
