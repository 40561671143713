// Decorate an individual query with adjustments based on the provided
// props and suplemented with any state held in the store.
import createCachedSelector from 're-reselect'

import { selectBrowserTimezoneOffset } from 'ducks/reports/controls'
import { selectQueriesByIdRaw } from 'ducks/reports/queries'
import { emptyObj } from 'util/objects'

import adjustResultTimeframesToUTC from './adjustResultTimeframesToUTC'
import selectQueryWithState from './selectQueryWithState'
import queryCacheKey from './cacheKeys/queryCacheKey'

export default createCachedSelector(
  selectBrowserTimezoneOffset,
  selectQueryWithState,
  (offset, queryWithState) => {
    return adjustResultTimeframesToUTC(queryWithState, offset)
  }
)(queryCacheKey)
