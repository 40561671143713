import { uniq } from 'util/arrays'

import { sortY } from '../../../sorts'

export default function sortDataSet(
  dataSet,
  joinedXValues,
  sortDirection,
  defaultSortMethod = sortY
) {
  const {
    data,
    nullIsValidX,
    sort: { direction: dataSetDirection, sortMethod = defaultSortMethod } = {},
  } = dataSet
  const directionOrDefault = sortDirection || dataSetDirection || 'DESC'
  const sortedData = [...(data || [])].sort(sortMethod)
  if (directionOrDefault === 'DESC') sortedData.reverse()
  const sortedXValues = sortedData.map(datum => datum && datum.x)
  const combinedXValues = uniq(sortedXValues.concat(joinedXValues || []))
  const xValuesWithoutNull = nullIsValidX
    ? combinedXValues
    : combinedXValues.filter(x => x !== null)
  return xValuesWithoutNull
}
