// Applies the provided query base to the provided function

import { memoize } from 'util/memoization'

import { emptyArr } from 'util/arrays'
import { emptyObj } from 'util/objects'

export default memoize(applyQueryBaseToQuery)

function applyQueryBaseToQuery(query, queryBase, scope) {
  const { filters, scope: baseScope } = queryBase || emptyObj
  return {
    ...(queryBase || emptyObj),
    ...query,
    filters: [...(filters || emptyArr), ...(query.filters || emptyArr)],
    scope: (baseScope || scope) && {
      ...(baseScope || emptyObj),
      ...(scope || emptyObj),
    },
  }
}
