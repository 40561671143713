import { combineReducers } from 'core/redux'
import { persist, read } from 'util/persist'
import {
  DISABLE_BUSINESS_HOURS,
  ENABLE_BUSINESS_HOURS,
  HIDE_BUSINESS_HOURS_NOTICE,
  TIMEFRAME_CHANGE,
  TOGGLE_BUSINESS_HOURS,
} from './types'

const REPORTS_PATH_REGEX = /^\/reports/

function isValidTimeframe(timeframe) {
  // We only parse the timeframe when its an object. If its a string value (relative date)
  // then its always valid
  if (
    typeof timeframe === 'object' &&
    (timeframe.start === 'Invalid date' || timeframe.end === 'Invalid date')
  )
    return false
  return true
}

function reduceAreBusinessHoursEnabled(state, action) {
  const { type } = action
  if (type === DISABLE_BUSINESS_HOURS) {
    return persist('enableBusinessHours', false, { toUrl: true })
  } else if (type === ENABLE_BUSINESS_HOURS) {
    return persist('enableBusinessHours', true, { toUrl: true })
  } else if (type === TOGGLE_BUSINESS_HOURS) {
    return persist('enableBusinessHours', !state, { toUrl: true })
  }
  return read('enableBusinessHours', state, action, {
    default: false,
    toUrl: REPORTS_PATH_REGEX.exec(document.location.pathname),
  })
}

function reduceShowBusinessHoursNotice(state, action) {
  const { type } = action
  if (type === HIDE_BUSINESS_HOURS_NOTICE) {
    return persist('showBusinessHoursNotice', false)
  }
  return read('showBusinessHoursNotice', state, action, { default: true })
}

function reduceTimeframe(state, action) {
  const { type, payload } = action

  if (type === TIMEFRAME_CHANGE) {
    const newState = typeof payload === 'string' ? payload : { ...payload }
    return persist('reportsTimeframe', newState, { toUrl: true })
  }
  const defaultTimeframe = 'previous_7_days'
  const persistedTimeframe = read('reportsTimeframe', state, action, {
    default: defaultTimeframe,
    toUrl: REPORTS_PATH_REGEX.exec(document.location.pathname),
  })

  return isValidTimeframe(persistedTimeframe)
    ? persistedTimeframe
    : defaultTimeframe
}

export default combineReducers({
  areBusinessHoursEnabled: reduceAreBusinessHoursEnabled,
  showBusinessHoursNotice: reduceShowBusinessHoursNotice,
  timeframe: reduceTimeframe,
})
