import { totalCustomersGauge } from 'ducks/reports/gauges'
import { HeadlineVisualization } from 'ducks/reports/visualizations'

const staticProps = {
  gauges: [totalCustomersGauge],
  title: 'Customers Helped',
}
export default function TotalCustomersHeadline(props) {
  return <HeadlineVisualization {...props} {...staticProps} />
}
