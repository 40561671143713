import { ratio } from '../calculators/ratio'
import { countUniqueArticleViewsOnClientId } from '../queries/countUniqueArticleViewsOnClientId'
import { countUniquePageVisitsOnClientId } from '../queries/countUniquePageVisitsOnClientId'

export const viewedArticleRatioGauge = {
  calculate: ratio,
  description: 'The percentage of visitors that viewed at least one article.',
  queries: [countUniqueArticleViewsOnClientId, countUniquePageVisitsOnClientId],
  title: 'Viewed an Article',
}
