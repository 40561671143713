import toast from '@groovehq/internal-design-system/lib/components/Toast'
import { isFunction } from 'util/functions'

const startedToastDefaults = {
  enabled: false,
  content: 'Action started',
  type: 'positive',
  noAction: true,
}

const successToastDefaults = {
  enabled: true,
  content: 'Action completed',
  type: 'positive',
  noAction: true,
}

const failedToastDefaults = {
  enabled: true,
  content: 'Action failed',
  type: 'error',
  duration: null,
}

export default function toastActionModule(phase, action, _payload, options) {
  const {
    requestId,
    moduleOptions: {
      toasts: {
        enabled = false,
        started: startedToast = startedToastDefaults,
        success: successToast = successToastDefaults,
        failed: failedToast = failedToastDefaults,
      } = {},
    } = {},
  } =
    options || {}

  if (!enabled) return action

  if (phase === 'STARTED') {
    const startedToastConfig = {
      ...startedToastDefaults,
      ...startedToast,
      ...{
        key: requestId,
      },
    }
    startedToastConfig.content = isFunction(startedToastConfig.content)
      ? startedToastConfig.content(phase, action, _payload, options)
      : startedToastConfig.content
    if (startedToastConfig.enabled) {
      toast.notify(startedToastConfig)
    }
  } else if (phase === 'SUCCESS') {
    const successToastConfig = {
      ...successToastDefaults,
      ...successToast,
      ...{
        key: requestId,
      },
    }
    successToastConfig.content = isFunction(successToastConfig.content)
      ? successToastConfig.content(phase, action, _payload, options)
      : successToastConfig.content
    if (successToastConfig.enabled) {
      toast.notify(successToastConfig)
    }
  } else if (phase === 'FAILED') {
    const failedToastConfig = {
      ...failedToastDefaults,
      ...failedToast,
      ...{
        key: requestId,
      },
    }
    failedToastConfig.content = isFunction(failedToastConfig.content)
      ? failedToastConfig.content(phase, action, _payload, options)
      : failedToastConfig.content
    if (failedToastConfig.enabled) {
      toast.notify(failedToastConfig)
    }
  }

  return action
}
