import React from 'react'

import { Overview } from 'ducks/reports/overviewLayouts'

import {
  ConversationsResolvedPerDayHeadline,
  TotalConversationsResolvedHeadline,
  TotalMessagesSentHeadline,
  MessagesSentPerDayHeadline,
} from 'ducks/reports/conversations'

import ConversationsAssignedPerDayHeadline from './ConversationsAssignedPerDayHeadline'
import CustomersAssignedPerDayHeadline from './CustomersAssignedPerDayHeadline'
import AssignmentsAssignmentCustomersResolutionsForEachDay from './AssignmentsAssignmentCustomersResolutionsForEachDay'
import TotalConversationsAssignedHeadline from './TotalConversationsAssignedHeadline'
import TotalCustomersAssignedHeadline from './TotalCustomersAssignedHeadline'

export default function ConversationsAssignedOverview({
  queryBase,
  netDiffComponent,
  resolutionsQueryBase,
}) {
  return (
    <Overview
      title="Conversations"
      main={
        <AssignmentsAssignmentCustomersResolutionsForEachDay
          queryBase={queryBase}
          resolutionsQueryBase={resolutionsQueryBase}
        />
      }
    >
      <Overview.Row>
        <Overview.Column>
          <Overview.Cell>{netDiffComponent}</Overview.Cell>
        </Overview.Column>
        <Overview.Column>
          <Overview.Cell>
            <TotalConversationsAssignedHeadline
              includePrevious
              queryBase={queryBase}
            />
          </Overview.Cell>
          <Overview.Cell>
            <ConversationsAssignedPerDayHeadline
              includePrevious
              queryBase={queryBase}
            />
          </Overview.Cell>
        </Overview.Column>
        <Overview.Column>
          <Overview.Cell>
            <TotalConversationsResolvedHeadline
              includePrevious
              queryBase={queryBase}
              resolutionsQueryBase={resolutionsQueryBase}
            />
          </Overview.Cell>
          <Overview.Cell>
            <ConversationsResolvedPerDayHeadline
              includePrevious
              queryBase={queryBase}
              resolutionsQueryBase={resolutionsQueryBase}
            />
          </Overview.Cell>
        </Overview.Column>
        <Overview.Column>
          <Overview.Cell>
            <TotalCustomersAssignedHeadline
              includePrevious
              queryBase={queryBase}
            />
          </Overview.Cell>
          <Overview.Cell>
            <CustomersAssignedPerDayHeadline
              includePrevious
              queryBase={queryBase}
            />
          </Overview.Cell>
        </Overview.Column>
      </Overview.Row>
      <Overview.Row>
        <Overview.Column>
          <Overview.Cell>
            <TotalMessagesSentHeadline includePrevious queryBase={queryBase} />
          </Overview.Cell>
        </Overview.Column>
        <Overview.Column>
          <Overview.Cell>
            <MessagesSentPerDayHeadline includePrevious queryBase={queryBase} />
          </Overview.Cell>
        </Overview.Column>
        {/* empty column to force left border */}
        <Overview.Column />
      </Overview.Row>
    </Overview>
  )
}
