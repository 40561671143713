/* eslint-disable no-param-reassign */
import { doGraphqlRequest } from 'ducks/requests/operations'
import { selectWidgetsWithBridged } from 'ducks/widgets/selectors/widgets'
import { selectFoldersById } from 'ducks/folders/selectors/folders'
import { isChatChannelType } from 'ducks/channels/channelTypes'
import {
  constructGraphQLFilterObject,
  constructGraphQLOrderByObject,
} from '../utils/query'
import { SEARCH_CONVERSATIONS } from '../actionTypes'

const ticketConversationsQuery = `

  query ticketConversationsQuery($filter: ConversationFilter, $orderBy: ConversationOrder){
    conversations(filter: $filter, orderBy: $orderBy) {
      nodes {
        id
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
        startCursor
      }
    }
  }
`

const roomConversationsQuery = `
  query roomConversationsQuery($filter: RoomFilter, $cursor: String, $size: Int, $orderBy: RoomOrder ) {
    rooms(filter: $filter, after: $cursor, first: $size, orderBy: $orderBy) {
      totalCount
      totalPageCount
      nodes {
        id
        number
        contact {
          id
          name
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
        startCursor
      }
    }
  }
`

function hackRoomToConversationResponse(channelType) {
  return data => {
    if (isChatChannelType(channelType)) {
      data.rooms.nodes.forEach(node => {
        node.internalId = node.id
        node.id = parseInt(node.number, 10)
        delete node.number
      })
    }
    return data
  }
}

/*
  This method will be used to retrieve the conversations
  Currently there is a massive disparity in the api implementation between tickets and
  rooms. This method will hide those differences and return a standadised interface for
  the folder component.
 */
export function doFetchConversations({
  queryId,
  cursor,
  channelType,
  size = 20,
  options = {},
}) {
  return (dispatch, getState) => {
    const isWidget = true
    const state = getState()
    const filter = constructGraphQLFilterObject(queryId, state)

    let orderBy = constructGraphQLOrderByObject(queryId)

    // HACK: These should come from the backend API as the default sort order
    // for the filters. Incorporate together with filter permissions for chat.
    if (!orderBy && filter?.folderId && filter?.channelId) {
      const [, widgetId] = filter.channelId.split('_')
      const widget = selectWidgetsWithBridged(state).find(
        w => w.id === widgetId
      )
      if (widget) {
        const folder = selectFoldersById(state)[filter.folderId]
        if (['New messages', 'Ending soon'].includes(folder?.name)) {
          orderBy = { field: 'EXPIRES_AT', direction: 'ASC' }
        }
      }
    }

    return dispatch(
      doGraphqlRequest(
        SEARCH_CONVERSATIONS,
        isWidget ? roomConversationsQuery : ticketConversationsQuery,
        {
          filter,
          queryId,
          orderBy,
          cursor,
          size,
        },
        {
          ...options,
          app: true,
          transformResponse: hackRoomToConversationResponse(channelType),
          meta: {
            channelType,
          },
        }
      )
    )
  }
}
