import { averageResponseTimeGauge } from 'ducks/reports/gauges'
import { HeadlineVisualization } from 'ducks/reports/visualizations'

const staticSelect = {
  gauges: [averageResponseTimeGauge],
  scale: { y: 'duration' },
  title: '$Avg. Response Time',
}

export default function AverageResponseTime(props) {
  return <HeadlineVisualization {...props} {...staticSelect} />
}
