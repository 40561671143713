import React from 'react'

export default function TooltipContent({
  cell: { day, daysInTimeframeForDay, hour, percentageOfTotal, y } = {},
  measuring,
  measuringSingle,
  sum,
}) {
  if (y === 0 && measuring)
    return (
      <React.Fragment>
        {`No ${measuring} between `}
        <strong>{hour.replace('-', 'and').replace(/ /g, '\u00A0')}</strong>
      </React.Fragment>
    )
  return (
    <React.Fragment>
      {daysInTimeframeForDay <= 1 ? '' : 'An average of '}
      <strong>{Math.round(y * 10) / 10}</strong>
      {measuring && (
        <React.Fragment>
          {` ${y === 1 ? measuringSingle : measuring} on `}
          <strong>{day}</strong> between{' '}
          <strong>{hour.replace('-', 'and').replace(/ /g, '\u00A0')}</strong>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}
