import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { TimelineWindow, EventTimeline } from 'matrix-js-sdk'
import { useMatrixCurrentUserPrivateRoom } from 'ducks/chat/hooks/rooms'
import { subtract } from 'util/date'
import useIsMounted from 'util/hooks/useIsMounted'
import { getMatrixClient } from 'ducks/chat/utils/client'
import {
  isEventRead,
  isReadReceiptEventForCurrentUser,
} from 'ducks/chat/utils/rooms'
import { pick } from 'util/objects'
import { NOTIFICATION_EVENT_TYPES, TIMELINE_MAX_DAYS } from './constants'

const TIMELINE_PAGINATION_SIZE = 10

const calculateUnreadCount = countsByType => {
  return Object.values(countsByType).reduce((sum, eventTypeCount) => {
    return sum + eventTypeCount
  }, 0)
}

export const useNotifications = (eventTypes = NOTIFICATION_EVENT_TYPES) => {
  const { room, isMatrixClientReady } = useMatrixCurrentUserPrivateRoom()
  const isReady = isMatrixClientReady && !!room
  const [isLoadingTimeline, setIsLoadingTimeline] = useState(false)
  // if this value changes, it'll re-calculate the unread counts, and cached eventids visible in panel
  const [refreshStateMarker, setRefreshStateMarker] = useState(
    new Date().getTime()
  )
  const oldestEventTsCapRef = useRef(
    subtract(new Date(), TIMELINE_MAX_DAYS, 'day').getTime()
  )
  const timelineWindowRef = useRef()
  const isMounted = useIsMounted()
  const isLoading = !isReady || isLoadingTimeline

  const getEvents = useCallback(
    (eventTypeFilter = eventTypes) => {
      if (!isReady || !timelineWindowRef.current) return []

      return timelineWindowRef.current
        .getEvents()
        .filter(
          event =>
            eventTypeFilter.includes(event.getType()) &&
            event.getTs() > oldestEventTsCapRef.current
        )
    },
    [isReady, eventTypes]
  )

  const eventIds = useMemo(
    () => {
      return getEvents()
        .map(e => e.getId())
        .reverse() // reversed because timeline events are sorted oldest first
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getEvents, refreshStateMarker]
  )

  const refreshState = useCallback(() => {
    // will force recalculation of counts and memoized eventIds
    setRefreshStateMarker(new Date().getTime())
  }, [])

  const onTimelineUpdate = useCallback(
    (event, eventRoom) => {
      if (!isMounted() || !timelineWindowRef.current) return

      if (
        room.roomId === eventRoom.roomId &&
        NOTIFICATION_EVENT_TYPES.includes(event.getType())
      ) {
        // https://matrix-org.github.io/matrix-js-sdk/stable/classes/TimelineWindow.html#extend
        // extend timeline forwards to accept new event
        const wasExtended = timelineWindowRef.current.extend(
          EventTimeline.FORWARDS,
          1
        )
        if (wasExtended) {
          // new event was fetched
          refreshState()
        }
      }
    },
    [room, isMounted, refreshState]
  )

  const onRoomReceipt = useCallback(
    (event, eventRoom) => {
      if (!isMounted()) return

      if (
        room.roomId === eventRoom.roomId &&
        isReadReceiptEventForCurrentUser(event)
      ) {
        // no need to recalculate eventIds loaded, just counts
        setRefreshStateMarker(new Date().getTime())
      }
    },
    [room, isMounted]
  )

  const newestLoadedRoomEvent = useCallback(
    () => {
      if (!isReady || !timelineWindowRef.current) return null

      const roomEvents = timelineWindowRef.current.getEvents()
      return roomEvents[roomEvents.length - 1]
    },
    [isReady]
  )

  const oldestLoadedRoomEvent = useCallback(
    () => {
      if (!isReady || !timelineWindowRef.current) return null

      const roomEvents = timelineWindowRef.current.getEvents()
      return roomEvents[0]
    },
    [isReady]
  )

  const hasMore = useCallback(
    () => {
      if (!isReady || !timelineWindowRef.current) {
        return false
      }

      const canPaginate = timelineWindowRef.current.canPaginate(
        EventTimeline.BACKWARDS
      )

      if (!canPaginate) {
        return false
      }

      let canLoadOlderEvents = true
      const oldestEvent = oldestLoadedRoomEvent()

      if (oldestEvent) {
        canLoadOlderEvents = oldestEvent.getTs() > oldestEventTsCapRef.current
      }

      return canLoadOlderEvents
    },
    [isReady, oldestLoadedRoomEvent]
  )

  const loadMore = useCallback(
    async () => {
      if (!isReady || !timelineWindowRef.current) return

      setIsLoadingTimeline(true)
      // paginate until full page's worth of events are found for eventTypeFilter (or cannot paginate)
      // because there is no guarantee that the next page will have a type == eventTypes
      // e.g. rating event type could be scattered across pages 3, 7, 10
      while (hasMore()) {
        // eslint-disable-next-line no-await-in-loop
        await timelineWindowRef.current.paginate(
          EventTimeline.BACKWARDS,
          TIMELINE_PAGINATION_SIZE
        )

        const newEventsCount = getEvents().length
        const newEventsLoadedWithPage = newEventsCount > eventIds.length
        const isNewEventsFullPage = newEventsCount >= TIMELINE_PAGINATION_SIZE
        if (newEventsLoadedWithPage && isNewEventsFullPage) break
      }

      refreshState()
      setIsLoadingTimeline(false)
    },
    [isReady, refreshState, getEvents, eventIds, hasMore]
  )

  useEffect(
    () => {
      async function initialiseTimeline() {
        setIsLoadingTimeline(true)
        await timelineWindowRef.current.load(null, TIMELINE_PAGINATION_SIZE)

        refreshState()
        if (hasMore() && getEvents().length < TIMELINE_PAGINATION_SIZE) {
          loadMore()
        }
        setIsLoadingTimeline(false)
      }

      if (!isReady || !isMounted()) return

      if (!timelineWindowRef.current) {
        // https://matrix-org.github.io/matrix-js-sdk/stable/classes/TimelineWindow.html#constructor
        // keep in mind the default max amount of events in TimelineWindow is 1000, let's keep it that way
        // if we do need to increase it, it's the 3rd argument (see docs)
        timelineWindowRef.current = new TimelineWindow(
          getMatrixClient(),
          room.getTimelineSets()[0]
        )

        initialiseTimeline()
      } else if (hasMore() && getEvents().length < TIMELINE_PAGINATION_SIZE) {
        loadMore()
      } else {
        refreshState()
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isReady, room, isMounted, eventTypes]
  )

  useEffect(
    () => {
      if (isReady) {
        room.addListener('Room.timeline', onTimelineUpdate)
        room.addListener('Room.receipt', onRoomReceipt)
      }

      return () => {
        if (room) {
          room.removeListener('Room.timeline', onTimelineUpdate)
          room.removeListener('Room.receipt', onRoomReceipt)
        }
      }
    },
    [onTimelineUpdate, onRoomReceipt, isReady, room]
  )

  const unreadCountByEventType = useMemo(
    () => {
      const events = isReady ? getEvents(NOTIFICATION_EVENT_TYPES) : []

      const state = NOTIFICATION_EVENT_TYPES.reduce(
        (accumulator, eventType) => {
          // eslint-disable-next-line no-param-reassign
          accumulator[eventType] = events.filter(
            e => e.getType() === eventType && !isEventRead(room, e.getId())
          ).length

          return accumulator
        },
        {}
      )

      return state
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isReady, getEvents, room, refreshStateMarker]
  )

  const allUnreadCount = useMemo(
    () => calculateUnreadCount(unreadCountByEventType),
    [unreadCountByEventType]
  )

  const unreadCount = useMemo(
    () => calculateUnreadCount(pick(eventTypes, unreadCountByEventType)),
    [unreadCountByEventType, eventTypes]
  )

  const calculateUnreadCountForEventTypes = useCallback(
    types => calculateUnreadCount(pick(types, unreadCountByEventType)),
    [unreadCountByEventType]
  )

  const hasNotifications = useMemo(
    () => {
      return eventIds.length > 0
    },
    [eventIds]
  )

  const markEventReadUpTo = useCallback(
    event => {
      if (!event || isEventRead(room, event.getId())) return

      getMatrixClient().setRoomReadMarkers(room.roomId, event.getId(), event)
    },
    [room]
  )

  const markAllEventsRead = useCallback(
    () => {
      if (!timelineWindowRef.current) return

      const lastRoomEvent = newestLoadedRoomEvent()
      if (!lastRoomEvent) return

      markEventReadUpTo(lastRoomEvent)
    },
    [newestLoadedRoomEvent, markEventReadUpTo]
  )

  return {
    hasNotifications,
    isReady,
    room,
    allUnreadCount,
    unreadCount,
    calculateUnreadCountForEventTypes,
    eventIds,
    markAllEventsRead,
    markEventReadUpTo,
    isLoading,
    loadMore,
    hasMore,
  }
}

export const useNotificationEvent = (eventId, room) => {
  const [isRead, setIsRead] = useState(true)

  const event = useMemo(() => room.findEventById(eventId), [room, eventId])

  const onRoomReceipt = useCallback(
    (matrixEvent, eventRoom) => {
      if (
        room.roomId === eventRoom.roomId &&
        isReadReceiptEventForCurrentUser(matrixEvent)
      ) {
        setIsRead(isEventRead(eventRoom, eventId))
      }
    },
    [room, setIsRead, eventId]
  )

  useEffect(
    () => {
      if (!room) return

      setIsRead(isEventRead(room, eventId))
    },
    [room, eventId]
  )

  useEffect(
    () => {
      let isListening = false
      if (room && !isEventRead(room, eventId)) {
        isListening = true
        room.addListener('Room.receipt', onRoomReceipt)
      }

      return () => {
        if (room && isListening) {
          room.removeListener('Room.receipt', onRoomReceipt)
        }
      }
    },
    [onRoomReceipt, room, eventId]
  )

  return {
    event,
    isRead,
  }
}
