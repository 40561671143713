import { all } from 'util/arrays'

function makeDetectDataSetType(detector) {
  return gauge => {
    const { queries } = gauge
    return all(({ interval, groupBy }) => {
      return detector(interval, groupBy)
    }, queries)
  }
}

export const isGrouped = makeDetectDataSetType((interval, groupBy) => {
  return !interval && groupBy
})

export const isGroupedIntervalled = makeDetectDataSetType(
  (interval, groupBy) => {
    return interval && groupBy
  }
)

export const isSingleDatum = makeDetectDataSetType((interval, groupBy) => {
  return !interval && !groupBy
})

export const isIntervalled = makeDetectDataSetType((interval, groupBy) => {
  return interval && !groupBy
})
