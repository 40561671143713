import React from 'react'
import { VictoryLabel, VictoryPie } from 'victory'

import Info from 'components/Info'
import { splitWordsIntoSizedParts } from 'util/strings'

import formatDataPointValueAs from '../../util/formatDataPointValueAs'
import grooveTheme from '../../grooveTheme'
import Change from '../Change'
import styles from './styles.css'

const { purple, gray, lightestGray, green, red, yellow } = grooveTheme.colors

const NUMBER_SIZES = {
  ',': 9.26,
  '.': 9.16,
  0: 22.06,
  1: 13.24,
  2: 18.2,
  3: 19.61,
  4: 20.18,
  5: 19.03,
  6: 20.18,
  7: 17.18,
  8: 19.75,
  9: 20.21,
  K: 20.74,
  M: 27.97,
  G: 24.38,
  T: 18.37,
  P: 19.18,
  E: 18.4,
  Z: 19.1,
  Y: 19.81,
}

function calculatePercentOffsetPosition(middle, text) {
  let calculatedSize = middle + 7
  text.split('').forEach(c => {
    calculatedSize += (NUMBER_SIZES[c] || 0) / 2
  })
  return calculatedSize
}

function getValueLabel(loading, loaded, errored, scale, value) {
  if (loading) return 'Loading...'
  if (loaded)
    return (
      formatDataPointValueAs(scale.formatYAs || scale.y, value, 10000)
        // We dont want the % to be pre-appended because it has a smaller font
        // that the value. We strip it here and create a seperate label to add it
        .replace('%', '')
    )
  if (errored) return 'Error!'
  return '-'
}

function noop() {
  return null
}

export default function PercentGaugeIndividual({
  datum: { change, y: value } = {},
  description,
  includePreviousPeriod,
  loaded,
  loading,
  errored,
  scale,
  title,
  timeframe,
  trafficLight = [],
}) {
  const size = 184
  const innerRadius = size / 2 - 12
  const [firstThreshold = 0.25, secondThreshold = 0.5] = trafficLight
  const inverseValue = 1 - value
  const dataSet = [
    { x: 1, y: inverseValue < 0 ? 0 : inverseValue },
    { x: 2, y: value },
  ]
  const valueLabel = getValueLabel(loading, loaded, errored, scale, value)
  const yMiddle = size / 2 - 4
  const xMiddle = size / 2 - 3
  return (
    <div className={styles.PercentGauge}>
      {description && (
        <Info className={styles.Info} description={description} />
      )}
      <svg viewBox={`0 0 ${size} ${size}`} width="100%" height="100%">
        <VictoryPie
          standalone={false}
          width={size}
          height={size}
          data={dataSet}
          innerRadius={innerRadius}
          cornerRadius={25}
          padding={0}
          labels={noop}
          style={{
            labels: { padding: 0 },
            data: {
              padding: 0,
              fill: data => {
                if (!value || data.x === 1) return 'transparent'
                else if (!trafficLight) return purple
                else if (value >= secondThreshold) return green
                else if (value >= firstThreshold) return yellow
                return red
              },
            },
          }}
          theme={grooveTheme}
        />
        <VictoryPie
          standalone={false}
          width={size}
          height={size}
          data={[{ x: 1, y: 1 }]}
          innerRadius={innerRadius}
          labels={noop}
          padding={0}
          style={{
            labels: { padding: 0 },
            data: {
              padding: 0,
              fill: () => {
                if (!value) return lightestGray
                else if (!trafficLight) return purple
                else if (loading) return gray
                else if (value >= secondThreshold) return green
                else if (value >= firstThreshold) return yellow
                return red
              },
              opacity: () => {
                return value ? 0.2 : 1
              },
            },
          }}
          theme={grooveTheme}
        />

        <VictoryLabel
          textAnchor="middle"
          verticalAnchor="middle"
          text={valueLabel}
          style={
            loading || errored
              ? { ...grooveTheme.largeLabel, fontSize: 18 }
              : { ...grooveTheme.largeLabel }
          }
          x={xMiddle}
          y={yMiddle - 10}
        />
        <VictoryLabel
          textAnchor="middle"
          verticalAnchor="middle"
          text={loaded ? '%' : ''}
          style={{ ...grooveTheme.label }}
          x={calculatePercentOffsetPosition(xMiddle, valueLabel)}
          y={yMiddle - 15}
        />
        <VictoryLabel
          lineHeight={1.5}
          style={{
            ...grooveTheme.label,
            fill: grooveTheme.colors.lightGray,
            textTransform: 'none',
          }}
          text={splitWordsIntoSizedParts(title, 18)}
          textAnchor="middle"
          verticalAnchor="middle"
          x={xMiddle}
          y={yMiddle + 30}
        />
      </svg>
      {includePreviousPeriod && (
        <div className={styles.previousPeriod}>
          <Change
            append={` from previous ${timeframe.durationLabel}`}
            change={change}
            percent
            scale={scale}
          />
        </div>
      )}
    </div>
  )
}
