import { combineReducers } from 'redux'
import { byQueryId } from './byQueryId'
import { lastUpdatedAt } from './lastUpdatedAt'
import { currentQueryId } from './currentQueryId'

export default combineReducers({
  byQueryId,
  lastUpdatedAt,
  currentQueryId,
})
