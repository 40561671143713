import React from 'react'
import Link from 'redux-first-router-link'

// TODO: Fix direct duck reference without creating circular dependancy
import { TEAM_DETAIL_PAGE } from 'ducks/reports/detail/pages'
import { Popup } from 'util/ui'

export default function TeamsCellView({ agent: { groups } = {} }) {
  const singleGroup = groups && groups.length === 1 && groups[0]
  return (
    <span>
      {groups &&
        groups.length > 1 && (
          <Popup
            content={groups.map(({ name }) => name).join(', ')}
            inverted
            position="bottom center"
            on="hover"
            trigger={<span>{app.t('n_teams', { count: groups.length })}</span>}
          />
        )}
      {singleGroup && (
        <Link
          key={singleGroup.id}
          to={{ type: TEAM_DETAIL_PAGE, payload: { id: singleGroup.id } }}
        >
          {singleGroup.name}
        </Link>
      )}
    </span>
  )
}
