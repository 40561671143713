import { combineReducers } from 'core/redux'
import merge from 'deepmerge'

import { UPDATE_COMPONENT_STATE } from './types'

const defaultInstance = {}

function byId(state, { type, payload: { componentId, componentState } = {} }) {
  if (type === UPDATE_COMPONENT_STATE) {
    return {
      ...state,
      [componentId]: merge(state[componentId] || {}, componentState || {}),
    }
  }
  return state || defaultInstance
}

export default combineReducers({
  byId,
})
