import { connect } from 'react-redux'
import { createSelector } from 'reselect'

import { BarChartVisualization } from 'ducks/reports/visualizations'
import {
  selectConversationsAssignedCustomersForEachDayGauge,
  selectConversationsAssignedForEachDayGauge,
  selectConversationsResolvedForEachDayGauge,
} from 'ducks/reports/gauges'
import { asArray } from 'util/selectors'

function labelFormatter(data) {
  return data.value
}

const selectGauges = createSelector(
  selectConversationsAssignedForEachDayGauge,
  selectConversationsAssignedCustomersForEachDayGauge,
  selectConversationsResolvedForEachDayGauge,
  asArray
)
const select = (state, props) => ({
  gauges: selectGauges(state, props),
  labelFormatter,
})
export default connect(select)(BarChartVisualization)
