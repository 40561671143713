export const FETCH_TAGS = 'tags/FETCH_TAGS'
export const FETCH_TAGS_STARTED = 'tags/FETCH_TAGS_STARTED'
export const FETCH_TAGS_SUCCESS = 'tags/FETCH_TAGS_SUCCESS'
export const FETCH_TAGS_FAILED = 'tags/FETCH_TAGS_FAILED'

export const FETCH_TAGS_BY_IDS_NAMES = 'tags/FETCH_TAGS_BY_IDS_NAMES'
export const FETCH_TAGS_BY_IDS_NAMES_STARTED =
  'tags/FETCH_TAGS_BY_IDS_NAMES_STARTED'
export const FETCH_TAGS_BY_IDS_NAMES_SUCCESS =
  'tags/FETCH_TAGS_BY_IDS_NAMES_SUCCESS'
export const FETCH_TAGS_BY_IDS_NAMES_FAILED =
  'tags/FETCH_TAGS_BY_IDS_NAMES_FAILED'

export const UPDATE_TAG = 'tags/UPDATE_TAG'
export const UPDATE_TAG_STARTED = 'tags/UPDATE_TAG_STARTED'
export const UPDATE_TAG_SUCCESS = 'tags/UPDATE_TAG_SUCCESS'
export const UPDATE_TAG_FAILED = 'tags/UPDATE_TAG_FAILED'

export const DELETE_TAGS = 'tags/DELETE_TAGS'
export const DELETE_TAGS_STARTED = 'tags/DELETE_TAGS_STARTED'
export const DELETE_TAGS_SUCCESS = 'tags/DELETE_TAGS_SUCCESS'
export const DELETE_TAGS_FAILED = 'tags/DELETE_TAGS_FAILED'

export const MERGE_TAGS = 'tags/MERGE_TAGS'
export const MERGE_TAGS_STARTED = 'tags/MERGE_TAGS_STARTED'
export const MERGE_TAGS_SUCCESS = 'tags/MERGE_TAGS_SUCCESS'
export const MERGE_TAGS_FAILED = 'tags/MERGE_TAGS_FAILED'
