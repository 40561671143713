import createCachedSelector from 're-reselect'

import fullPropsSetCacheKey from './cacheKeys/fullPropsSetCacheKey'
import selectDataSets from './selectDataSets'

export default createCachedSelector(selectDataSets, dataSets => {
  if (!dataSets[0] || dataSets[0].isSingleDataSetWithoutPrevious) return false
  return [].concat(
    dataSets.map(dataSet => ({
      name:
        dataSet.isSingleDataSet && !dataSet.isSingleDataSetWithoutPrevious
          ? 'Current'
          : dataSet.name,
      color: dataSet.color,
      isEmptyDataSet: dataSet.isEmptyDataSet,
      isSingleDatum: dataSet.isSingleDatum,
    })),
    dataSets
      .map(dataSet => {
        return (
          dataSet.previousData && {
            name: dataSet.isSingleDataSet
              ? 'Previous'
              : `Previous ${dataSet.name}`,
            previous: true,
            isEmptyDataSet: dataSet.isPreviousEmptyDataSet,
            isSingleDatum: dataSet.isPreviousSinglePoint,
            color: dataSet.previousColor || dataSet.color,
          }
        )
      })
      .filter(x => !!x)
  )
})(fullPropsSetCacheKey)
