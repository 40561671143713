import { connect } from 'react-redux'

import { selectGroupsById } from 'selectors/app'

import TeamCellView from './TeamCellView'

const select = (state, { value }) => ({
  team: selectGroupsById(state)[value],
})

export default connect(select)(TeamCellView)
