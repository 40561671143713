import { LAYOUT_MOUNTED } from './types'
import { MOUNTED, UNMOUNTED } from './constants'

export default function reduceLayout(state, { type }) {
  if (type === LAYOUT_MOUNTED) {
    return MOUNTED
  } else if (type && type.match(/_PAGE$/)) {
    // Any page changes cause the layout to unmount, even page changes between
    // two pages with the reports layout. One day that will be fixed and we'll
    // have to handle it here.
    return UNMOUNTED
  }
  return state || false
}
