import cn from 'classnames'
import React from 'react'
import PropTypes from 'prop-types'

import { isFunction } from 'util/functions'
import { Table } from 'util/ui'
import uuid from 'util/uuid'

import styles from './styles.less'

TableHeader.propTypes = {
  columns: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  queryBase: PropTypes.object,
  sort: PropTypes.object,
  key: PropTypes.string,
}

TableHeader.defaultProps = {
  key: uuid(),
  sort: {},
  queryBase: {},
}

function parseDirection(direction) {
  if (direction && direction === 'ASC') {
    return 'ascending'
  }
  return 'descending'
}

export function parseSort(sort) {
  if (sort) {
    const parts = sort.split(' ')
    return {
      column: parts[0],
      direction: parts.length > 1 ? parseDirection(parts[1]) : null,
    }
  }
  return { column: null, direction: null }
}

function defaultSort(order, props) {
  const { setComponentState } = props
  return () => {
    setComponentState({
      sort: {
        order,
      },
    })
  }
}

function createSortHandler(columnSort, direction, onSort, props) {
  const { setComponentState } = props

  let column = columnSort
  let type = 'string'
  if (typeof columnSort === 'object') {
    column = columnSort.accessor
    type = columnSort.type || 'string'
  }
  const oppositeDirection = direction === 'ascending' ? 'DESC' : 'ASC'

  const order = `${column} ${oppositeDirection} ${type}`

  if (isFunction(onSort)) {
    return evt => {
      setComponentState(onSort(evt, order, props))
    }
  }
  return defaultSort(order, props)
}

export default function TableHeader(props) {
  const { columns, key, queryBase, componentState, sort } = props

  const querySettings = {
    ...queryBase,
    ...{ order: sort.default },
    ...componentState.sort,
  }
  const columnHeaders = columns.map((column, index) => {
    const columnSort = parseSort(querySettings.order)
    const sortField =
      typeof column.sort === 'object' ? column.sort.accessor : column.sort
    return (
      <Table.HeaderCell
        key={`${key}-header-${index}`}
        onClick={
          column.sort &&
          createSortHandler(
            column.sort,
            columnSort.direction,
            sort.onSortChange,
            props
          )
        }
        sorted={columnSort.column === sortField ? columnSort.direction : null}
        className={cn(styles[column.header], {
          [`sorted ${styles.sortable}`]: column.sort,
        })}
      >
        {column.header}
      </Table.HeaderCell>
    )
  })

  return (
    <Table.Header>
      <Table.Row>{columnHeaders}</Table.Row>
    </Table.Header>
  )
}
