import { createSelector } from 'reselect'
import createCachedSelector from 're-reselect'
import { emptyObj } from 'util/objects'
import storage from 'util/storage'
import { selectAccountSubdomain } from 'selectors/app'
import { selectIds } from 'subapps/kb/selectors/knowledge_bases/selectIds'
import { selectBase } from './base'
import { selectCurrentWidgetId } from './widgets'
import { selectIsOnCreatePage, selectIsFinalCreateStep } from './page'
import { selectDraftWidgetSettings } from './draft'

export const selectSettings = createSelector(
  selectBase,
  base => base.settings || emptyObj
)

export const selectCurrentWidgetSettings = createSelector(
  selectSettings,
  selectCurrentWidgetId,
  (settings, id) => settings[id] || emptyObj
)

export const selectWidgetSettingsById = createCachedSelector(
  selectSettings,
  (_state, widgetId) => widgetId,
  (settings, widgetId) => settings[widgetId] || emptyObj
)((_state, widgetId) => widgetId)

export const selectCurrentWidgetSettingsIsPublished = createSelector(
  selectCurrentWidgetSettings,
  settings => !!settings.published
)

export const selectDemoUuid = createSelector(
  selectAccountSubdomain,
  subdomain => (subdomain ? `demo-${subdomain}` : null)
)

export const selectHidePreviewMessage = createSelector(
  selectBase,
  base =>
    base.hidePreviewMessage ||
    storage.get('hide-widget-preview-message') ||
    false
)

export const selectIsCurrentWidgetSettingsChatEnabled = createSelector(
  selectCurrentWidgetSettings,
  settings => settings && settings['chat.enabled']
)

export const selectIsCurrentWidgetSettingsKbEnabled = createSelector(
  selectCurrentWidgetSettings,
  selectIds,
  (settings, kbIds) =>
    settings && settings['kb.enabled'] && kbIds.includes(settings['kb.id'])
)

export const selectIsCurrentWidgetSettingsContactEnabled = createSelector(
  selectCurrentWidgetSettings,
  settings => settings && settings['contact.enabled']
)

export const selectPreviewSettings = createSelector(
  selectIsOnCreatePage,
  selectIsFinalCreateStep,
  selectCurrentWidgetSettings,
  selectDraftWidgetSettings,
  (isOnCreatePage, isFinalStep, currentWidgetSettings, draftWidgetSettings) => {
    if (isOnCreatePage && !isFinalStep) {
      return draftWidgetSettings
    }
    return currentWidgetSettings
  }
)
