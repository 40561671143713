import { daysInTimeframe } from 'ducks/reports/queries'

export function averagePerDay(results, gauge) {
  if (!gauge) gauge = results
  const { loaded, queries } = gauge
  if (!loaded) return null
  const { timeframe } = queries[0]
  return results.reduce((queriesSum, result) => {
    const days = daysInTimeframe(timeframe)
    if (Array.isArray(result)) {
      const querySumTotal = result.reduce((querySum, resultDay) => {
        return querySum + resultDay.value
      }, 0)
      return queriesSum + querySumTotal / days
    }
    return queriesSum + result / days
  }, 0)
}
