/* eslint-disable no-param-reassign */
import { createActionTypeReducer } from 'util/reducers'
import { emptyObj, isDeepEqual } from 'util/objects'
import { TICKET_PAGE, MAIN_PAGE, SEARCH_PAGE } from 'constants/pages'
import { filterQueryByTargetId } from 'ducks/searches/utils/query'

const byIdInitialState = {}

const CLEAR_SEARCH_ACTION_TYPES = [TICKET_PAGE, MAIN_PAGE, SEARCH_PAGE]

function clearFiltersReducer(draftState, action) {
  if (!action || !CLEAR_SEARCH_ACTION_TYPES.includes(action.type))
    return draftState

  const {
    type,
    payload,
    meta: {
      location: { prev: { type: prevType, payload: prevPayload } = {} } = {},
    } = {},
  } = action

  if (type !== prevType || !isDeepEqual(payload, prevPayload)) {
    Object.keys(draftState).forEach(tableId => {
      delete draftState[tableId].query
    })
  }

  return draftState
}

function saveFiltersReducer(draftState, action) {
  const {
    tables: { tableId, storeQueryKeys } = {},
    meta: { query: actionQuery = {} } = {},
  } = action
  if (!tableId || !storeQueryKeys) return draftState

  if (!draftState[tableId]) draftState[tableId] = {}

  const query = filterQueryByTargetId(tableId, actionQuery)

  storeQueryKeys.forEach(filterKey => {
    if (query[filterKey]) {
      if (!draftState[tableId].query) draftState[tableId].query = {}
      draftState[tableId].query[filterKey] = query[filterKey]
    }
  })

  if (draftState[tableId].query) {
    Object.keys(draftState[tableId].query).forEach(key => {
      if (query[key] === undefined) delete draftState[tableId].query[key]
    })
  }
  return draftState
}

function saveScrollState(draftState, action) {
  const { tables: { tableId, storeQueryKeys, scrollPosition } = {} } = action
  if (!tableId || !storeQueryKeys) return draftState

  if (!draftState[tableId]) draftState[tableId] = {}

  draftState[tableId].scrollPosition = storeQueryKeys.includes('scroll')
    ? scrollPosition
    : 0
  return draftState
}

export const byId = createActionTypeReducer(
  {
    '*': (draftState, action) => {
      clearFiltersReducer(draftState, action)

      const { tables = emptyObj } = action || {}
      if (tables === emptyObj) return draftState

      saveFiltersReducer(draftState, action)
      saveScrollState(draftState, action)

      return draftState
    },
  },
  byIdInitialState
)

export default byId
