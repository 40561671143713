import { connect } from 'react-redux'

import { averageTimeOnArticleGauge } from 'ducks/reports/gauges'
import { HeadlineVisualization } from 'ducks/reports/visualizations'

const staticProps = {
  gauges: [averageTimeOnArticleGauge],
  scale: { y: 'duration' },
  title: '$Avg. time on article',
}

export default function AverageKbTimeOnArticle(props) {
  return <HeadlineVisualization {...props} {...staticProps} />
}
