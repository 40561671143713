import React from 'react'
import { compose } from 'redux'
import LineChart from '../LineChart/LineChartView'

import withAutoFetchGauges from '../../enhancers/withAutoFetchGauges'
import withDimensions from '../../enhancers/withDimensions'
import withPagableGauges from '../../enhancers/withPagableGauges'
import withDecoratedGauges from '../../enhancers/withDecoratedGauges'
import withSortableGauges from '../../enhancers/withSortableGauges'
import withLoadingAndErroredStates from '../../enhancers/withLoadingAndErroredStates'

export default compose(
  withPagableGauges,
  withSortableGauges,
  withDecoratedGauges,
  withAutoFetchGauges,
  withLoadingAndErroredStates,
  withDimensions
)(LineChart)
