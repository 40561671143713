import cn from 'classnames'
import React from 'react'
import { pluralize } from 'util/strings'

import Label from '../Label'
import styles from './styles.less'

export default function ProgressBarIndividual({
  color,
  scale: { x },
  size,
  title,
  total = 0,
  value = 0,
}) {
  const percent = total === 0 ? 0 : Math.round(1000 * value / total) / 10
  return (
    <section className={styles.PercentBar}>
      <div className={styles.label}>
        <Label>{title}</Label>{' '}
        <span className={styles.count}>{`${Math.round(100 * value) / 100} ${x &&
          pluralize(value, x)}`}</span>
      </div>
      <div
        className={styles.progressText}
        title={`${Math.round(10 * percent) / 10}%`}
      >
        {percent}%
      </div>
      <div
        className={cn('ui small progress', styles.ProgressBar)}
        data-percent={percent}
      >
        <div
          className={styles.ProgressBar_background}
          style={{ background: color }}
        />
        <div
          className={cn('bar', styles.ProgressBar_bar)}
          style={{ background: color, width: `${percent}%` }}
        />
      </div>
    </section>
  )
}
