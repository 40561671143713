import { channel } from 'ducks/entities/schema'

export const mailboxGraphQlResponseSchema = {
  mailboxes: [channel],
}

export const mailboxUpdateGraphQlResponseSchema = {
  mailbox: channel,
}

export const mailboxDeleteGraphQlResponseSchema = {
  mailbox: channel,
}
