import { createSelector } from 'reselect'
import { createBasicSelector } from 'util/redux'
import {
  selectMergingSearchTerm,
  selectMergingIsMerging,
} from 'ducks/merging/selectors/base'
import {
  selectTicketSearchOperatorValueMap,
  selectTicketSearchResultsByQueryId,
  searchTicketIds,
} from 'selectors/search/base'
import { selectAgentsById } from 'selectors/agents/base'
import { selectCustomersById } from 'ducks/customers/selectors'
import { selectCurrentUser } from 'ducks/currentUser/selectors/selectCurrentUser'
import {
  isAuthenticatedSelector,
  fetchingStatusesSelector,
} from 'selectors/app'
import { selectCurrentTicket } from 'selectors/tickets/current/selectCurrentTicket'
import { selectTicketsById } from 'selectors/tickets/byId/selectTicketsById'
import { selectCurrentCustomer } from 'selectors/tickets/customer/selectCurrentCustomer'
import { selectCurrentTicketId } from 'selectors/tickets/current/selectCurrentTicketId'
import {
  constructSearchQueryObject,
  constructSearchQueryId,
  constructSearchQueryString,
  constructGraphQLSearchQueryObject,
} from 'util/search'
import { emptyObj } from 'util/objects'
import isQueryStringComplete from 'util/search/isQueryStringComplete'
import isSearchValid from 'util/search/isSearchValid'
import { mapTickets } from 'selectors/search'
import { emptyArr } from 'util/arrays'

export const selectCurrentMergeSearchQueryObject = createSelector(
  selectMergingSearchTerm,
  selectTicketSearchOperatorValueMap,
  (queryString, operatorValueMap) => {
    return constructSearchQueryObject(queryString, operatorValueMap)
  }
)

export const selectCurrentMergeSearchQueryObjectString = createSelector(
  selectMergingSearchTerm,
  selectTicketSearchOperatorValueMap,
  (queryString, operatorValueMap) => {
    return constructSearchQueryString(queryString, operatorValueMap)
  }
)

export const selectCurrentMergeSearchQueryID = createSelector(
  selectCurrentMergeSearchQueryObject,
  selectTicketSearchOperatorValueMap,
  (queryObject, valueMap) => {
    return constructSearchQueryId(queryObject, valueMap)
  }
)

export const selectCurrentMergeSearch = createBasicSelector(
  selectCurrentMergeSearchQueryID,
  selectTicketSearchResultsByQueryId,
  (queryID, byQueryId) => {
    return byQueryId[queryID]
  }
)

export const selectCurrentMergeSearchResultTicketIds = createSelector(
  selectCurrentMergeSearch,
  search => searchTicketIds(search)
)

export const selectCurrentMergeSearchQueryObjectForAPI = createSelector(
  selectCurrentMergeSearchQueryObject,
  selectMergingIsMerging,
  selectCurrentCustomer,
  selectTicketSearchOperatorValueMap,
  (queryObject, isMerging, currentCustomer, valueIdMap) => {
    if (!queryObject && !isMerging) return {}
    const apiQueryObject = constructGraphQLSearchQueryObject(
      Object.assign({}, queryObject),
      valueIdMap
    )

    if (!queryObject && isMerging && currentCustomer) {
      // TODO (jscheel): Add state to bisect search results for merges.
      apiQueryObject.customer = currentCustomer.id
    }

    return apiQueryObject
  }
)

export const selectMergeableForCurrentCustomer = createSelector(
  selectTicketSearchResultsByQueryId,
  selectCurrentCustomer,
  selectTicketSearchOperatorValueMap,
  (byQueryId, customer, valueMap) => {
    if (!customer) return emptyObj
    const searchQueryObject = {
      customer: customer.id,
    }
    const queryId = constructSearchQueryId(searchQueryObject, valueMap)
    return byQueryId[queryId] || emptyObj
  }
)

export const selectMergeableTickets = createSelector(
  selectCurrentTicket,
  selectMergeableForCurrentCustomer,
  selectTicketsById,
  (ticket, mergeable, byId) => {
    if (!ticket || !mergeable || !mergeable.pages) return emptyArr

    const ticketIds = Object.keys(mergeable.pages)
      .map(i => mergeable.pages[i])
      .reduce((a, b) => a.concat(b))
    const tickets = ticketIds.map(id => byId[id]).filter(t => t)

    if (!tickets) return emptyArr

    const currentTicketIdx = tickets.findIndex(t => t.id === ticket.id)

    if (currentTicketIdx > -1) {
      const filteredResults = tickets.slice()
      filteredResults.splice(currentTicketIdx, 1)
      return filteredResults
    }
    return tickets
  }
)

export const shouldFetchMergeableTickets = createBasicSelector(
  isAuthenticatedSelector,
  selectCurrentTicket,
  selectCurrentCustomer,
  fetchingStatusesSelector,
  selectMergeableForCurrentCustomer,
  selectMergingIsMerging,
  (auth, ticket, customer, statuses, mergeable, isMerging) => {
    if (!auth || !ticket || !customer || !customer.id) return false
    if (statuses.fetchMergeableTickets) return false
    if (mergeable && !isMerging) return false
    if (mergeable && isMerging) {
      if (mergeable.currentPage > 2) return false // stop fetching too many
      return (
        mergeable.currentPage < mergeable.totalPages || mergeable.resultsStale
      )
    }
    if (!mergeable && ticket) return true // preload on ticket page
    return false
  }
)

export const selectCurrentMergeSearchSummaries = createSelector(
  selectCurrentMergeSearch,
  searchObject => {
    if (!searchObject) return emptyObj
    const { highlights } = searchObject
    if (!highlights) return emptyObj
    return highlights
  }
)

const selectMergeSearchResultTickets = createSelector(
  selectTicketsById,
  selectCurrentMergeSearchResultTicketIds,
  selectCurrentMergeSearchSummaries,
  selectAgentsById,
  selectCustomersById,
  selectCurrentUser,
  (byId, results, summaries, agentsById, customersById, currentUser) => {
    return mapTickets(
      byId,
      results,
      summaries,
      agentsById,
      customersById,
      currentUser
    )
  }
)

// dont allow current ticket to be merged into itself.
export const selectCurrentMergeSearchResultTickets = createSelector(
  selectMergeSearchResultTickets,
  selectCurrentTicketId,
  (tickets, ticketId) => [].concat(tickets).filter(t => t && t.id !== ticketId)
)

export const selectSnippetTicketIds = createSelector(
  selectMergingSearchTerm,
  selectMergeableTickets,
  selectCurrentMergeSearchResultTickets,
  (queryString, mergeable, results) =>
    [].concat(queryString ? results : mergeable).map(t => t && t.id)
)

const selectCurrentMergeSearchResultsStale = createBasicSelector(
  selectCurrentMergeSearch,
  search => {
    if (!search) return null
    return !!search.resultsStale
  }
)

export const selectHasCurrentMergeSearchErrored = createBasicSelector(
  selectCurrentMergeSearch,
  search => {
    if (!search) return false
    return !!search.errored
  }
)

const selectIsCurrentMergeSearchQueryStringComplete = createSelector(
  selectMergingSearchTerm,
  queryString => isQueryStringComplete(queryString)
)

export const selectIsCurrentMergeSearchQueryValid = createSelector(
  selectMergingSearchTerm,
  queryString => isSearchValid(queryString)
)

export const shouldFetchMergeSearchByQueryString = createBasicSelector(
  selectCurrentMergeSearchQueryID,
  selectCurrentMergeSearchResultTicketIds,
  selectCurrentMergeSearchResultsStale,
  selectIsCurrentMergeSearchQueryStringComplete,
  selectHasCurrentMergeSearchErrored,
  selectIsCurrentMergeSearchQueryValid,
  (queryID, tickets, resultsStale, complete, errored, valid) => {
    if (tickets && !resultsStale) return false
    if (!complete) return false
    if (errored) return false
    if (!valid) return false
    return !!queryID
  }
)
