export const countOpenAssignmentsToAnAgent = {
  analysisType: 'count',
  eventCollection: 'conversation.assigned',
  filters: [
    {
      propertyName: 'action.change.agent.id',
      operator: 'exists',
      propertyValue: true,
    },
    {
      propertyName: 'conversation.state',
      operator: 'eq',
      propertyValue: 'opened',
    },
  ],
  key: 'countOpenAssignmentsToAnAgent',
}
