import { createSelector } from 'reselect'
import { template } from 'util/strings'
import { selectBase } from './base'
import { selectWidgetsById } from './widgets'

export const selectSnippet = createSelector(selectBase, base => base.snippet)

export const selectSnippetForInstallationGenerator = createSelector(
  selectSnippet,
  selectWidgetsById,
  (snippet, widgetsById) => {
    return function generateSnippet(widgetId) {
      const widget = widgetsById[widgetId]
      if (!widget) return ''
      const { uuid } = widget
      return template(/%{{(\w+)}}%/g, snippet, {
        WIDGET_UUID: uuid,
        WIDGET_OPTIONS: {},
      })
    }
  }
)
