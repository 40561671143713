import { connect } from 'react-redux'
import { createSelector } from 'reselect'

import {
  selectHappinessScoreRatioByTeamGauge,
  selectTeamNameByTeamGauge,
  selectTotalConversationsAssignedByTeamGauge,
  selectTotalConversationsResolvedByTeamGauge,
} from 'ducks/reports/gauges'
import { TableVisualization } from 'ducks/reports/visualizations'
import { asArray } from 'util/selectors'

function filterOutEmptyAndMissingTeams(data) {
  return data.filter(d => d.id !== 'null' && d.team_name_by_team)
}

const selectGauges = createSelector(
  selectTeamNameByTeamGauge,
  selectTotalConversationsAssignedByTeamGauge,
  selectTotalConversationsResolvedByTeamGauge,
  selectHappinessScoreRatioByTeamGauge,
  asArray
)
const staticSelect = {
  columns: [
    {
      header: app.t('Teams'),
      accessor: 'id',
      component: TableVisualization.TeamCell,
      sort: 'team_name_by_team',
    },
    {
      header: 'Conversations',
      accessor: 'conversations_by_team',
      component: TableVisualization.NumberCell,
      sort: {
        accessor: 'conversations_by_team',
        type: 'number',
      },
    },
    {
      header: 'Resolutions',
      accessor: 'resolutions_by_team',
      component: TableVisualization.NumberCell,
      sort: {
        accessor: 'resolutions_by_team',
        type: 'number',
      },
    },
    {
      header: 'CSAT score',
      accessor: 'happiness_by_team',
      component: TableVisualization.PercentCell,
      sort: {
        accessor: 'happiness_by_team',
        type: 'number',
      },
    },
  ],
  defaultPageSize: 1000,
  filter: filterOutEmptyAndMissingTeams,
  key: 'team-overview-table',
  sort: { type: 'inmemory', default: 'team_name_by_team ASC' },
  title: `${app.t('Team')} Overview`,
}
const select = (state, props) => {
  return {
    ...staticSelect,
    gauges: selectGauges(state, props),
  }
}

export default connect(select)(TableVisualization)
