import cn from 'classnames'
import React from 'react'
import { Button } from 'util/ui'

function SearchKbEmptyItem({ knowledgeBaseId, itemRef }) {
  // Note cant use the redux <Link> component here without
  // actually loading the kb routes which will load the reducers
  // etc etc. To keep it performant, we'll just hardcode the link
  return (
    <div className={cn('searchResultItem', 'empty')} ref={itemRef}>
      <div className={cn('body')}>
        No articles found...
        <Button
          as="a"
          primary
          target="_blank"
          rel="noopener noreferrer"
          href={`/kb/${knowledgeBaseId}/articles/create`}
        >
          Create article in new tab
        </Button>
      </div>
    </div>
  )
}

export default React.forwardRef((props, ref) => (
  <SearchKbEmptyItem itemRef={ref} {...props} />
))
