import { conversationsAssignedPerDayGauge } from 'ducks/reports/gauges'
import { HeadlineVisualization } from 'ducks/reports/visualizations'

const staticProps = {
  gauges: [conversationsAssignedPerDayGauge],
  title: 'Avg. conversations per day',
}

export default function ConversationsAssignedPerDayHeadline(props) {
  return <HeadlineVisualization {...props} {...staticProps} />
}
