import { emptyArr, uniq } from 'util/arrays'

function calculateResult(queries, gauge) {
  const { cap, calculate = results => results[0], expectedGroups } = gauge
  const results =
    expectedGroups ||
    queries.reduce((reduction, { result }) => {
      return reduction.concat(result)
    }, [])
  const calculated = calculate(results, gauge)
  return [
    {
      y: calculated > cap ? cap : calculated,
    },
  ]
}

export default function generateSingleDatumDataSets(gauge) {
  const {
    colorScale,
    description,
    empty,
    fill,
    id,
    includePrevious,
    isJoinedThrough,
    isNegativeChangeGood,
    isNegativeGood,
    loaded,
    nullIsValidX,
    previousLoaded,
    previousQueries = emptyArr,
    queries = emptyArr,
    title: name,
    totalCount,
    scale,
    sort,
    xValueTranslations,
  } = gauge
  const query = queries[0]
  return [
    {
      colorScale,
      data: loaded && calculateResult(queries, gauge),
      description,
      empty,
      fill,
      id,
      isJoinedThrough,
      isNegativeChangeGood,
      isNegativeGood,
      name,
      nullIsValidX,
      queries,
      page: query && query.page,
      perPage: query && query.perPage,
      previousPeriod: includePrevious && {
        queries: previousQueries,
        data: previousLoaded && calculateResult(previousQueries, gauge),
      },
      scale,
      sort,
      sortKey: id,
      totalCount,
      xValueTranslations,
    },
  ]
}
