import { doGraphqlRequest } from 'ducks/requests/operations'

import {
  selectIsKnowledgeBasesLoading,
  selectIsKnowledgeBasesLoaded,
} from '../../selectors/kbSearch'
import { FETCH_KBS } from '../../types'

const knowledgeBaseFields = `
  id
  title
  siteUrl
  primary
  enabled
`

export const doFetchKnowledgeBases = options => (dispatch, getState) => {
  const state = getState()
  const isLoading = selectIsKnowledgeBasesLoading(state)
  const isLoaded = selectIsKnowledgeBasesLoaded(state)

  // KnowledgeBases change so rarely that we only load this once per
  // pageload
  if (isLoading || isLoaded) return Promise.resolve()

  const query = `
      query KnowledgeBasesQuery {
        knowledgeBases {
          ${knowledgeBaseFields}
        }
      }
    `

  return dispatch(doGraphqlRequest(FETCH_KBS, query, {}, options))
}
