import React from 'react'
import { connect } from 'react-redux'
import { doFetchReportGauge } from 'ducks/reports/gauges'

const perform = {
  doFetchGauge: doFetchReportGauge,
}

export default function withAutoFetchGauges(ComposedComponent) {
  class AutoFetchGauges extends React.Component {
    constructor(...args) {
      super(...args)
      this.doFetchGauges = this.doFetchGauges.bind(this)
    }

    state = {}

    componentDidMount() {
      this.doFetchGauges()
    }

    componentDidUpdate() {
      this.doFetchGauges()
    }

    doFetchGauges() {
      const { doFetchGauge, gauge, gauges = [gauge], queryBatch } = this.props
      gauges.forEach(g => {
        doFetchGauge(g, queryBatch)
      })
    }

    render() {
      return (
        <ComposedComponent doFetchGauges={this.doFetchGauges} {...this.props} />
      )
    }
  }

  return connect(null, perform)(AutoFetchGauges)
}
