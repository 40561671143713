import single from '../calculators/single'
import { averageRepliesPerConversation } from '../queries/averageRepliesPerConversation'

export const averageRepliesPerResolutionForEachDayGauge = {
  calculate: single,
  queries: [
    {
      ...averageRepliesPerConversation,
      interval: 'daily',
      key: 'averageRepliesPerConversationForEachDay',
    },
  ],
  title: '$Average Replies',
}
