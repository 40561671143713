import { memoize } from 'util/memoization'

export default memoize(applyJoinThroughScopeToQuery)

function applyJoinThroughScopeToQuery(
  query,
  shouldServerPage,
  joinedXValues,
  loadable
) {
  const { groupBy: individualGroupBy, page, perPage } = query

  const from = (page - 1) * perPage
  const to = from + perPage
  const hasFromTo = (from || from === 0) && (to || to === 0)
  const xValues =
    joinedXValues && hasFromTo ? joinedXValues.slice(from, to) : joinedXValues
  return {
    ...query,
    filters: xValues
      ? [
          ...query.filters,
          {
            propertyName: Array.isArray(individualGroupBy)
              ? individualGroupBy[0]
              : individualGroupBy,
            operator: 'in',
            propertyValue: xValues,
          },
        ]
      : query.filters,
    loadable,
    page: shouldServerPage ? page : null,
    perPage: shouldServerPage ? perPage : null,
  }
}
