export const FETCH_RULES = 'rules/FETCH_RULES'
export const FETCH_RULES_REQUEST = 'rules/FETCH_RULES_STARTED'
export const FETCH_RULES_SUCCESS = 'rules/FETCH_RULES_SUCCESS'
export const FETCH_RULES_FAIL = 'rules/FETCH_RULES_FAILED'

export const FETCH_RULE = 'rules/FETCH_RULE'
export const FETCH_RULE_STARTED = 'rules/FETCH_RULE_STARTED'
export const FETCH_RULE_SUCCESS = 'rules/FETCH_RULE_SUCCESS'
export const FETCH_RULE_FAILED = 'rules/FETCH_RULE_FAILED'

export const DELETE_RULE = 'rules/DELETE_RULE'
export const DELETE_RULE_STARTED = 'rules/DELETE_RULE_STARTED'
export const DELETE_RULE_SUCCESS = 'rules/DELETE_RULE_SUCCESS'
export const DELETE_RULE_FAILED = 'rules/DELETE_RULE_FAILED'

export const UPDATE_RULE = 'rules/UPDATE_RULE'
export const UPDATE_RULE_STARTED = 'rules/UPDATE_RULE_STARTED'
export const UPDATE_RULE_SUCCESS = 'rules/UPDATE_RULE_SUCCESS'
export const UPDATE_RULE_FAILED = 'rules/UPDATE_RULE_FAILED'

export const CREATE_RULE = 'rules/CREATE_RULE'
export const CREATE_RULE_STARTED = 'rules/CREATE_RULE_STARTED'
export const CREATE_RULE_SUCCESS = 'rules/CREATE_RULE_SUCCESS'
export const CREATE_RULE_FAILED = 'rules/CREATE_RULE_FAILED'

export const SAVE_RULE_DRAFT = 'rules/SAVE_RULE_DRAFT'
export const CLEAR_ALL_RULE_DRAFTS = 'rules/CLEAR_ALL_RULE_DRAFTS'

export const UPLOAD_RULE_ACTION_ATTACHMENT =
  'cannedReplies/UPLOAD_RULE_ACTION_ATTACHMENT'
export const UPLOAD_RULE_ACTION_ATTACHMENT_STARTED =
  'cannedReplies/UPLOAD_RULE_ACTION_ATTACHMENT_STARTED'
export const UPLOAD_RULE_ACTION_ATTACHMENT_SUCCESS =
  'cannedReplies/UPLOAD_RULE_ACTION_ATTACHMENT_SUCCESS'
export const UPLOAD_RULE_ACTION_ATTACHMENT_FAILED =
  'cannedReplies/UPLOAD_RULE_ACTION_ATTACHMENT_FAILED'
