// Apply the provided sope to the query by means of filters.

import { memoize } from 'util/memoization'

export default memoize(applyScope)

function applyScope(query) {
  const {
    adjustScope,
    applyScope: querysApplyScope = defaultApplyScope,
    scope,
  } = query
  if (!scope && !adjustScope) return query
  const adjustedScope = adjustScope ? adjustScope(scope) : scope
  return querysApplyScope({
    ...query,
    scope: adjustedScope,
  })
}

function defaultApplyScope(query) {
  const { filters, scope } = query
  if (!scope) return query
  return {
    ...query,
    filters: [
      ...filters,
      ...Object.keys(scope).map(propertyName => ({
        propertyName,
        operator: Array.isArray(scope[propertyName]) ? 'in' : 'eq',
        propertyValue: scope[propertyName],
      })),
    ],
  }
}
