/* eslint-disable no-param-reassign */
import { createActionTypeReducer } from 'util/reducers'
import { FETCH_RULES_SUCCESS } from '../actionTypes'

const totalCountInitialState = null

const reducers = {}

// We only want to update the rules totalCount in state if we're loading rules
// for a completely unflitered state.
reducers[FETCH_RULES_SUCCESS] = (
  draftState,
  {
    payload: { meta: { pagination } = {} } = {},
    request: { parameters = {} } = {},
  }
) => {
  const { total_count: totalCount } = pagination || {}
  if (totalCount === undefined || parameters?.filter?.search) return draftState
  return totalCount
}

export default createActionTypeReducer(reducers, totalCountInitialState)
