/* eslint-disable camelcase */
import Bugsnag from '@bugsnag/js'
import { selectOptimisticMergeTicketById } from 'selectors/tickets'
import { selectTicketsById } from 'selectors/tickets/byId/selectTicketsById'
import { createPayload, diffProps } from 'optimistic/base'
import { statusAction } from 'optimistic/actions'
import { last } from 'util/arrays'
import * as TicketStateTypes from 'constants/ticket_state_types'
import { toServerString } from 'util/date'
import { selectCurrentRawActionsForTicket } from 'selectors/currentChangesets'

function buildMergerAction(ticket, actor, changesetId) {
  const { id, message_count: messageCount } = ticket
  return {
    actor,
    change_type: 'Ticket::Merger',
    change: {
      ticket: id,
      type: 'Ticket::Merger',
    },
    preview: [messageCount],
    changeset: changesetId || `optimistic-merger-${id}`,
  }
}

function buildMergedTicketActions(
  state,
  targetTicketId,
  childTicketId,
  folderId,
  changesetId
) {
  const optimisticMergeTicket = selectOptimisticMergeTicketById(state)[
    childTicketId
  ]
  const parentDiffProps = diffProps(state, targetTicketId, folderId)
  if (!optimisticMergeTicket) {
    return [
      buildMergerAction(
        { childTicketId, message_count: 0 },
        parentDiffProps.actor,
        changesetId
      ),
    ]
  }
  let mergeOptimisticActions = []
  mergeOptimisticActions.push(
    buildMergerAction(optimisticMergeTicket, parentDiffProps.actor, changesetId)
  )
  let actions =
    optimisticMergeTicket &&
    optimisticMergeTicket.actions &&
    optimisticMergeTicket.actions.records
  if (!actions) {
    actions = selectCurrentRawActionsForTicket(state, childTicketId)
  }
  if (!actions) return []
  const messageActions = actions
    .map(action => {
      const { change_type: changeType, change } = action
      const changeId = change?.id
      if (changeType === 'Message' && changeId) {
        // eslint-disable-next-line  no-param-reassign
        action.changeset = `optimistic-comment-${childTicketId}-${changeId}`
        return action
      }
      return null
    })
    .filter(Boolean)
  mergeOptimisticActions = mergeOptimisticActions.concat(messageActions)
  return mergeOptimisticActions
}

export const mergedTicketPayload = (
  state,
  targetTicketId,
  childTicketIds,
  folderId,
  search,
  changesetId
) => {
  const ticket = selectTicketsById(state)[targetTicketId]
  if (!ticket) return null
  const actions = selectCurrentRawActionsForTicket(state, targetTicketId)
  if (!actions) return null

  let actionRecords = actions
  const mergedTicketsPayload = []
  let newState =
    TicketStateTypes.STATE_INT_MAP[ticket.state] || TicketStateTypes.OPENED

  childTicketIds.forEach(childId => {
    const byId = selectOptimisticMergeTicketById(state)
    const optimisticMergeTicket = byId[childId]
    if (!optimisticMergeTicket) {
      // This is a real problem - log it so we can track down the root cause
      const error = new Error('optimisticMergeTicket not found')
      const metaData = {
        meta: {
          targetTicketId,
          byId,
          childId,
          childTicketIds,
          state: ticket.state,
          newState,
          fetchingStatuses: state.app.fetchingStatuses,
        },
      }
      // eslint-disable-next-line no-console
      console.error({ error, metaData })
      Bugsnag.notify(error, event => {
        event.addMetadata('metaData', metaData)
      })
      throw error
    }
    mergedTicketsPayload.push({
      id: childId,
    })
    const ticketActions = buildMergedTicketActions(
      state,
      targetTicketId,
      childId,
      folderId,
      changesetId
    )
    actionRecords = actionRecords.concat(ticketActions)
    const mergeTicketState =
      TicketStateTypes.STATE_INT_MAP[optimisticMergeTicket.state]
    if (mergeTicketState < newState) {
      newState = mergeTicketState
    }
  })

  const initialPayloadActions = []
  const changedTicketData = { updated_at: toServerString(new Date()) }
  if (newState !== TicketStateTypes.STATE_INT_MAP[ticket.state]) {
    const newStateName = TicketStateTypes.INT_STATE_MAP[newState]
    const newStateAction = statusAction(newStateName)
    newStateAction.changeset = last(actionRecords, {}).changeset
    initialPayloadActions.push(newStateAction)
    changedTicketData.state = newStateName
  }

  const payload = createPayload(
    state,
    targetTicketId,
    'merger',
    changedTicketData,
    initialPayloadActions,
    folderId,
    search
  )

  payload.actions = { records: actionRecords.concat(payload.actions) }

  payload.ticket = payload.ticketData
  delete payload.ticketData

  // eslint-disable-next-line consistent-return
  return {
    ticket: payload,
    mergedTickets: {
      tickets: mergedTicketsPayload,
    },
  }
}
