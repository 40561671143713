import { connect } from 'react-redux'
import { doToggleTicketState } from 'actions/tickets'

import { selectTicketFlagsByTicketId } from 'selectors/tickets'
import {
  selectTicketStateLabelByTicketId,
  selectTicketSnoozedUntilByTicketId,
  selectTicketInvertStateActionLabelByTicketId,
} from 'selectors/tickets/state'

import View from './view'

const select = () => {
  return (state, props) => {
    const {
      isDeleted,
      isSnoozed,
      isOpen,
      isClosed,
      isUnread,
    } = selectTicketFlagsByTicketId(state, props.ticketId)
    return {
      isDeleted,
      isSnoozed,
      isOpen,
      isClosed,
      isUnread,
      snoozedUntil: selectTicketSnoozedUntilByTicketId(state, props.ticketId),
      stateLabel: selectTicketStateLabelByTicketId(state, props.ticketId),
      invertStateActionLabel: selectTicketInvertStateActionLabelByTicketId(
        state,
        props.ticketId
      ),
    }
  }
}

const perform = {
  toggleTicketState: doToggleTicketState,
}

export default connect(select, perform)(View)
