import cn from 'classnames'
import React from 'react'
import PropTypes from 'prop-types'
import { Dropdown } from 'util/ui'
import styles from './styles.less'

TablePageSizeSelector.propTypes = {
  onChange: PropTypes.func.isRequired,
}

TablePageSizeSelector.defaultProps = {
  onChange: () => {},
}

const OPTIONS = [5, 10, 25, 50].map(x => ({ text: String(x), value: x }))

export default function TablePageSizeSelector(props) {
  const { onChange } = props

  return (
    <div
      className={cn(
        'ui inline dropdown TablePageSizeSelector',
        styles.TablePageSizeSelector
      )}
    >
      Rows per page:{' '}
      <Dropdown
        inline
        upward
        options={OPTIONS}
        defaultValue={10}
        onChange={onChange}
        className={styles.sizeSelector}
      />
      {'  '}
    </div>
  )
}
