export const TIMELINE_MAX_DAYS = 45

export const EVENT_TYPE_FOLLOWING = 'g.agent.notification.following'
export const EVENT_TYPE_MENTION = 'g.agent.notification.mention'
export const EVENT_TYPE_RATING = 'g.agent.notification.rating'
export const EVENT_TYPE_REACTION = 'g.agent.notification.reaction'

export const NOTIFICATION_EVENT_TYPES = [
  EVENT_TYPE_FOLLOWING,
  EVENT_TYPE_MENTION,
  EVENT_TYPE_RATING,
  EVENT_TYPE_REACTION,
]
