import dot from 'dot-object'
import * as types from './types'

const defaultState = {
  data: {},
  default: {},
  english: {},
  isLoading: false,
  isLoaded: false,
  persistedDraft: {},
  // Translations type
  type: null,
  id: null,
}

const reducers = {}

reducers[types.FETCH_TRANSLATIONS_REQUEST] = (_, action) => {
  const { translationType, id } = action
  return {
    ...defaultState,
    type: translationType,
    id,
    isLoading: true,
    isLoaded: false,
  }
}

reducers[types.FETCH_TRANSLATIONS_SUCCESS] = (state, action) => {
  const { data } = action

  return {
    ...state,
    data: data.user,
    default: data.default,
    english: data.english,
    isLoading: false,
    isLoaded: true,
  }
}

reducers[types.FETCH_TRANSLATIONS_FAILURE] = (state, action) => {
  const { err } = action

  return {
    ...state,
    error: err,
    isLoading: false,
    isLoaded: true,
  }
}

// Switched context, loading new KB so reset the state
reducers[types.SWITCH_KNOWLEDGE_BASE] = state => {
  return {
    ...state,
    isLoaded: false,
    data: {},
    default: {},
  }
}

reducers[types.UPDATE_TRANSLATIONS_STARTED] = state => {
  return {
    ...state,
    isLoading: true,
  }
}

reducers[types.UPDATE_TRANSLATIONS_SUCCESS] = (state, action) => {
  const { data } = action.payload

  return {
    ...state,
    data: data.user,
    isLoading: false,
  }
}

reducers[types.UPDATE_TRANSLATIONS_FAILED] = (state, action) => {
  const { error } = action.payload

  return {
    ...state,
    error,
    isLoading: false,
  }
}

reducers[types.RESET_TRANSLATIONS_REQUEST] = state => {
  return {
    ...state,
    isLoading: true,
  }
}

reducers[types.RESET_TRANSLATIONS_SUCCESS] = (state, action) => {
  const { data } = action

  return {
    ...state,
    data: data.user,
    isLoaded: false,
    isLoading: false,
  }
}

reducers[types.RESET_TRANSLATIONS_FAILURE] = (state, action) => {
  const { err } = action

  return {
    ...state,
    error: err,
    isLoading: false,
  }
}

reducers[types.DELETE_TRANSLATION_REQUEST] = (state, { keyPath } = {}) => {
  const newData = { ...state.data }
  dot.delete(keyPath, newData)
  return {
    ...state,
    isLoading: true,
    data: newData,
  }
}

reducers[types.DELETE_TRANSLATION_SUCCESS] = (state, action) => {
  const { data } = action

  return {
    ...state,
    data: data.user,
    isLoading: false,
  }
}

reducers[types.DELETE_TRANSLATION_FAILURE] = (state, action) => {
  const { err } = action

  return {
    ...state,
    error: err,
  }
}

reducers[types.UPDATE_TRANSLATION_PERSISTED_DRAFT] = (
  state,
  { payload: { draft } = {} } = {}
) => {
  // TODO (jscheel): Look into storing in localstorage or on server as a draft
  return {
    ...state,
    persistedDraft: draft,
  }
}

export default function reducer(state = defaultState, action) {
  const handler = reducers[action.type]
  if (handler) return handler(state, action)
  return state
}
