// Generate a set of data sets from a gauge with group by and interval set.
// This will produce a dataset for each group with the intervals as the x values.

import { emptyArr, uniq } from 'util/arrays'

import moment from 'moment-timezone-all'

function calculateResult(group, queries, gauge) {
  const { collapseInterval = x => x } = gauge
  const query = queries[0]
  if (!query) return []
  const { groupBy, result = [] } = query
  const calculatedResults = result.map(resultItem => {
    const groupItem = resultItem.value.find(x => x[groupBy] === group)
    const { start } = resultItem.timeframe
    const x = moment(start).toDate()
    if (!groupItem) return null
    const y =
      (groupItem.result || groupItem.result === 0) && Number(groupItem.result)
    return {
      id: `${group}-${x}`,
      x,
      y,
    }
  })
  return collapseInterval(calculatedResults, gauge)
}

function findGroups(query) {
  const { groupBy, result = [] } = query
  const allResults = [].concat(...result.map(x => x.value)).map(x => x[groupBy])
  return uniq(allResults)
}

function findGroupOrder(query) {
  const { filters, groupBy } = query
  const filter = filters.find(
    ({ operator, propertyName }) =>
      groupBy === propertyName && operator === 'in'
  )
  if (!filter) return null
  return filter.propertyValue
}

export default function generateGroupedIntervalledDataSets(gauge) {
  const {
    colorScale,
    description,
    queries = emptyArr,
    groups = findGroups(queries[0]),
    groupOrder = findGroupOrder(queries[0]),
    fill,
    id,
    includePrevious,
    isNegativeChangeGood,
    isNegativeGood,
    loaded,
    nullIsValidX,
    previousQueries = emptyArr,
    title: name,
    totalCount,
    scale,
    sort,
    xValueTranslations,
  } = gauge

  if (!loaded) return []
  if (queries.length > 1) {
    // @TODO Allow multiple grouped intervalled queries to be reduced down to a single
    // result set via the gauge's calculate function.
    throw new Error(
      "Grouped intervalled datasets don't support calculating the result of multiple queries."
    )
  }
  const query = queries[0]
  return groups
    .map(group => ({
      colorScale,
      data: calculateResult(group, queries, gauge),
      description,
      fill,
      group,
      id: `${id}-${group}`,
      isNegativeChangeGood,
      isNegativeGood,
      name: group,
      nullIsValidX,
      queries,
      page: query && query.page,
      perPage: query && query.perPage,
      previousPeriod: includePrevious && {
        queries: previousQueries,
        data: calculateResult(group, previousQueries, gauge),
      },
      scale,
      sort,
      sortKey: id,
      title: name,
      totalCount,
      xValueTranslations,
    }))
    .sort(({ group: a }, { group: b }) => {
      if (!groupOrder) return 0
      const aPosition = groupOrder.findIndex(x => a === x)
      const bPosition = groupOrder.findIndex(x => b === x)
      if (aPosition > bPosition) return 1
      if (aPosition < bPosition) return -1
      return 0
    })
}
