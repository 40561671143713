import React from 'react'
import PropTypes from 'prop-types'

import { isFunction } from 'util/functions'
import { Menu } from 'util/ui'
import uuid from 'util/uuid'

import Label from '../Label'

TableFilter.propTypes = {
  filter: PropTypes.object.isRequired,
  queryBase: PropTypes.object.isRequired,
  key: PropTypes.string,
}

TableFilter.defaultProps = {
  key: uuid(),
}

function createOnMenuClickHandler(value, onClick, props) {
  const { setComponentState } = props
  return evt => {
    if (isFunction(onClick)) {
      setComponentState(onClick(evt, value, props, this))
    }
  }
}

export default function TableFilter(props) {
  const { filter, queryBase, componentState, key } = props
  const allFilters = { ...queryBase, ...componentState.query }
  let currentFilter = allFilters[filter.accessor]
  if (typeof allFilters[filter.accessor] === 'undefined') {
    currentFilter = null
  }

  const filterOptions = filter.options.map(option => {
    return (
      <Menu.Item
        key={`${key}-${option.value}`}
        as="a"
        onClick={createOnMenuClickHandler(option.value, filter.onClick, props)}
        active={option.value === currentFilter}
      >
        <Label>{option.label}</Label>
      </Menu.Item>
    )
  })

  return <Menu compact>{filterOptions}</Menu>
}
