import cn from 'classnames'
import React from 'react'
import PropTypes from 'prop-types'
import { Progress } from 'util/ui'
import themeVars from 'ui_theme/site/globals/site.variables'

import styles from './styles.less'

PercentBarView.propTypes = {
  label: PropTypes.string.isRequired,
  percent: PropTypes.number.isRequired,
  success: PropTypes.bool,
  error: PropTypes.bool,
  warning: PropTypes.bool,
  size: PropTypes.string,
}

PercentBarView.defaultProps = {
  success: false,
  error: false,
  warning: false,
  size: 'small',
}

export default function PercentBarView({
  label,
  percent,
  success,
  error,
  warning,
  size,
}) {
  const progressProps = {
    percent,
    size,
  }
  if (success || warning || error) {
    progressProps.color = cn({
      [themeVars.bioColorName]: success,
      [themeVars.berryColorName]: error,
      [themeVars.skyColorName]: warning,
    })
  }
  return (
    <section className={styles.PercentBar}>
      <div className={styles.label}>{label}</div>
      <div className={styles.progressText} title={`${Math.round(percent, 2)}%`}>
        {Math.round(percent, 0)}%
      </div>
      <div className={styles.progressBar}>
        <Progress {...progressProps} />
      </div>
    </section>
  )
}
