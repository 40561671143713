import PropTypes from 'prop-types'
import React from 'react'

import { doFetchReportGauge } from 'ducks/reports/gauges'
import { any } from 'util/arrays'

import Loading from '../components/Loading'
import Errored from '../components/Errored'
import withLoadingAndErroredProps from './withLoadingAndErroredProps'

const relPositionHeigth100 = { position: 'relative', height: '100%' }
export default function withLoadingAndErroredStates(ComposedComponent) {
  LoadingAndErroredStates.propTypes = {
    loadingComponent: PropTypes.oneOfType([
      PropTypes.element,
      PropTypes.string,
    ]),
  }

  LoadingAndErroredStates.defaultProps = {
    loadingComponent: <Loading />,
    erroredComponent: <Errored />,
  }

  function LoadingAndErroredStates(props) {
    const { errored, erroredComponent, loaded, loadingComponent } = props
    // If the queries haven't loaded and haven't errored we'll taking it on good
    // faith that they will be loading shortly even if a request isn't in transit
    const loading = !loaded && !errored
    return (
      <div style={relPositionHeigth100}>
        {loading && loadingComponent}
        {errored && erroredComponent}
        <div
          style={{
            opacity: loading ? 0.3 : 1,
            ...relPositionHeigth100,
          }}
        >
          <ComposedComponent {...props} />
        </div>
      </div>
    )
  }

  return withLoadingAndErroredProps(LoadingAndErroredStates)
}
