import { compose } from 'redux'

import BarChartView from './BarChartView'
import withAutoFetchGauges from '../../enhancers/withAutoFetchGauges'
import withDimensions from '../../enhancers/withDimensions'
import withLoadingAndErroredStates from '../../enhancers/withLoadingAndErroredStates'
import withDataForVisualizations from '../../enhancers/withDataForVisualizations'

export default compose(
  withAutoFetchGauges,
  withDataForVisualizations,
  withLoadingAndErroredStates,
  withDimensions
)(BarChartView)
