// Extract all the data sets from an array of gauges and concat them into a
// single array.
import createCachedSelector from 're-reselect'

import fullPropsSetCacheKey from './cacheKeys/fullPropsSetCacheKey'
import selectDecoratedGauges from './selectDecoratedGauges'

export default createCachedSelector(selectDecoratedGauges, gauges => {
  return [].concat(...gauges.map(gauge => gauge.dataSets))
})(fullPropsSetCacheKey)
