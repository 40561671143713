import React from 'react'

import { Grid } from 'util/ui'

import { column50 } from './config'
import styles from './styles.less'

export default function Overview12({ title, components }) {
  return (
    <Grid stackable celled padded className={styles.overviewContainer}>
      <Grid.Row columns={1}>
        <h1 className={styles.reportHeader}>{title}</h1>
      </Grid.Row>
      <Grid.Row columns={1} className={`${styles.chartContainer}`}>
        {components[0]}
      </Grid.Row>
      <Grid.Row columns={2}>
        <Grid.Column
          {...column50}
          className={styles.noPadding}
          verticalAlign="middle"
        >
          <Grid columns={2}>
            <Grid.Column>{components[1]}</Grid.Column>
            <Grid.Column>{components[2]}</Grid.Column>
          </Grid>
        </Grid.Column>
        <Grid.Column
          {...column50}
          className={styles.borderTop}
          verticalAlign="top"
        >
          {components[3]}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}
