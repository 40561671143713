import { single } from '../calculators/single'
import { countUniquePageVisitsOnSessionId } from '../queries/countUniquePageVisitsOnSessionId'

export const uniquePageVisitsPerDayGauge = {
  calculate: single,
  queries: [
    {
      ...countUniquePageVisitsOnSessionId,
      interval: 'daily',
      key: 'countUniquePageVisitsOnSessionIdForEachDay',
    },
  ],
  title: 'Visits',
}
