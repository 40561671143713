import { createSelector } from 'reselect'

import { selectAgents } from 'selectors/agents/base'
import { selectGroups } from 'selectors/app'

const selectTeamsByAgentForReports = createSelector(
  selectAgents,
  selectGroups,
  (agents, groups) => {
    return agents.map(agent => {
      return {
        'agent.id': agent.id,
        result: groups
          .filter(group => group.agents.map(a => a.id).includes(agent.id))
          .map(group => ({ id: group.id, name: group.name })),
      }
    })
  }
)

export const teamNamesByAgentGauge = {
  id: 'team_names_by_agent',
  queries: [
    {
      id: 'team_names_selected_from_state',
      groupBy: 'agent.id',
      loader: () => {
        return (dispatch, getState) => {
          return { result: selectTeamsByAgentForReports(getState()) }
        }
      },
    },
  ],
  title: `${app.t('Teams')} by ${app.t('Agent')}`,
}
