export default function queriesCacheKey(state, props) {
  const {
    gauge: { queries: gaugeQueries },
    isSorted,
    joinedXValues,
    loadable,
    pagination,
    previous,
    queryBase,
    queries,
    scope,
    sort,
    xValues,
  } = props
  return JSON.stringify({
    gaugeQueries,
    isSorted,
    joinedXValues,
    loadable,
    pagination,
    previous,
    queries,
    queryBase,
    scope,
    sort,
    xValues,
  })
}
