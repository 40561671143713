import PropTypes from 'prop-types'
import React from 'react'
import Link from 'redux-first-router-link'
import { v4 as uuidV4 } from 'uuid'

import SpacedItems from 'shared/components/ui/SpacedItems'
import { Button, Icon } from 'util/ui'

import { TEAMS_PAGE } from 'ducks/reports/teams'
import {
  ConversationHistoryTable,
  TeamLeaderBoardTable,
} from 'ducks/reports/conversations'
import { ReportsLayout } from 'ducks/reports/layout'
import * as CustomPropTypes from 'ducks/reports/propTypes'

import BusiestTimes from '../BusiestTimes'
import ConversationsAssignedOverview from '../ConversationsAssignedOverview'
import ProductivityOverview from '../ProductivityOverview'
import Header from './Header'
import NetDiffOpenConversations from './NetDiffOpenConversations'
import styles from './styles.less'

const conversationHistoryTableId = uuidV4()
const teamLeaderboardId = uuidV4()

export default class TeamPageView extends React.PureComponent {
  static propTypes = {
    queryBase: CustomPropTypes.queryBase,
    loading: PropTypes.bool,
    onMount: PropTypes.func,
    // eslint-disable-next-line react/forbid-prop-types
    team: PropTypes.object,
  }

  static defaultProps = {
    queryBase: {},
    loading: null,
    onMount: null,
    team: null,
  }

  componentDidMount() {
    const { onMount } = this.props
    if (onMount) onMount()
  }

  componentDidUpdate() {
    const { team, loading, doNotFound } = this.props
    if (!team && loading === false) {
      doNotFound()
    }
  }

  render() {
    const {
      customersHelped,
      queryBase,
      loading,
      team,
      resolutionsQueryBase,
    } = this.props
    return (
      <ReportsLayout>
        <SpacedItems.Container direction="vertical" gap="ginormous">
          <SpacedItems.Item>
            <Link to={{ type: TEAMS_PAGE }}>
              <Button basic size="large" className={styles.button}>
                <Icon name="arrow left" className={styles.buttonIcon} />
                Back to {app.t('teams')} overview
              </Button>
            </Link>
          </SpacedItems.Item>
          {loading && <SpacedItems.Item>Loading...</SpacedItems.Item>}
          {!loading &&
            team && (
              <React.Fragment>
                <SpacedItems.Item gap="large">
                  <Header team={team} customersHelped={customersHelped} />
                </SpacedItems.Item>
                <SpacedItems.Item gap="large">
                  <ConversationsAssignedOverview
                    queryBase={queryBase}
                    netDiffComponent={
                      <NetDiffOpenConversations
                        includePreviousPeriod
                        queryBase={queryBase}
                        resolutionsQueryBase={resolutionsQueryBase}
                      />
                    }
                    resolutionsQueryBase={resolutionsQueryBase}
                  />
                </SpacedItems.Item>
                <SpacedItems.Item>
                  <ProductivityOverview queryBase={queryBase} />
                </SpacedItems.Item>
                <SpacedItems.Item>
                  <TeamLeaderBoardTable
                    id={teamLeaderboardId}
                    queryBase={queryBase}
                    resolutionsQueryBase={resolutionsQueryBase}
                  />
                </SpacedItems.Item>
                <SpacedItems.Item>
                  <ConversationHistoryTable
                    id={conversationHistoryTableId}
                    queryBase={queryBase}
                    title="Recent Ratings"
                  />
                </SpacedItems.Item>
                <SpacedItems.Item>
                  <BusiestTimes queryBatch="busiest" queryBase={queryBase} />
                </SpacedItems.Item>
              </React.Fragment>
            )}
        </SpacedItems.Container>
      </ReportsLayout>
    )
  }
}
