import { numberToSize } from 'util/numbers'
import calculateColor from './calculateColor'

export default function formatPercent(
  { filledY },
  { scale: { color } = {} } = {}
) {
  const formattedPercent = `${numberToSize(Math.round(filledY * 1000) / 10)}%`
  const colorValue = calculateColor(filledY, color)
  return <span style={{ color: colorValue }}>{formattedPercent}</span>
}
