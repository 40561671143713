import failAction from './failAction'
import requestAction from './requestAction'
import successAction from './successAction'
import loader from './loader'

export default {
  createFetchWidgetsRequestAction: requestAction,
  createFetchWidgetsSuccessAction: successAction,
  createFetchWidgetsFailAction: failAction,
  createFetchWidgetsLoader: loader,
}
