import React from 'react'
import { v4 as uuidV4 } from 'uuid'

import SpacedItems from 'shared/components/ui/SpacedItems'

import { ReportsLayout } from 'ducks/reports/layout'
import * as CustomPropTypes from 'ducks/reports/propTypes'

import PopularArticlesTable from '../PopularArticlesTable'
import KbOverview from '../KbOverview'
import MostPopularSearchesTable from '../MostPopularSearchesTable'
import FailedSearchesTable from '../FailedSearchesTable'
import ActivationScreen from '../ActivationScreen'

const popularArticleTableId = uuidV4()
const popularSearchesTableId = uuidV4()
const failedSearchesTableId = uuidV4()

Page.propTypes = {
  queryBase: CustomPropTypes.queryBase.isRequired,
}

export default function Page({ queryBase, isEmpty }) {
  return (
    <ReportsLayout hideTopContent={isEmpty}>
      {isEmpty && <ActivationScreen />}
      {!isEmpty && (
        <SpacedItems.Container direction="vertical" gap="ginormous">
          <SpacedItems.Item>
            <KbOverview queryBatch="kb-overview" queryBase={queryBase} />
          </SpacedItems.Item>
          <SpacedItems.Item>
            <PopularArticlesTable
              queryBatch="kb-popular-articles"
              queryBase={queryBase}
              id={popularArticleTableId}
            />
          </SpacedItems.Item>
          <SpacedItems.Item>
            <MostPopularSearchesTable
              queryBatch="kb-popular-searches"
              queryBase={queryBase}
              id={popularSearchesTableId}
            />
          </SpacedItems.Item>
          <SpacedItems.Item>
            <FailedSearchesTable
              queryBatch="kb-failed-searches"
              queryBase={queryBase}
              id={failedSearchesTableId}
              includePrevious
            />
          </SpacedItems.Item>
        </SpacedItems.Container>
      )}
    </ReportsLayout>
  )
}
