import { userResolution } from './filters'
import { adjustScopeConversationAssigneeToActionUser } from './scopes'

export const averageResolutionTime = {
  adjustScope: adjustScopeConversationAssigneeToActionUser,
  analysisType: 'average',
  eventCollection: 'conversation.resolved',
  filters: [userResolution],
  key: 'averageResolutionTime',
  targetProperty: 'time.conversation_open_for',
}
