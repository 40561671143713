import averageIntervals from '../intervalCollapsers/averageIntervals'
import { countConversationsCreated } from '../queries/countConversationsCreated'

export const conversationsCreatedPerDayGauge = {
  collapseInterval: averageIntervals,
  description: 'Average number of incoming conversations per day.',
  queries: [
    {
      ...countConversationsCreated,
      interval: 'daily',
      key: 'countConversationsCreatedForEachDay',
    },
  ],
  title: 'Avg. Conversations Per Day',
}
