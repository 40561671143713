import React from 'react'
import cn from 'classnames'
import { Grid } from 'util/ui'

import styles from './styles.less'

const OverviewColumn = ({ children, width = 4 }) => {
  return (
    <Grid.Column
      width={width}
      className={cn(styles.noPadding, styles.Overview_column)}
      verticalAlign="top"
    >
      {children}
    </Grid.Column>
  )
}

export default OverviewColumn
