import styled from '@emotion/styled'

function Empty({ className, text }) {
  return <div className={className}>{text}</div>
}

const StyledEmpty = styled(Empty)`
  color: ${props => props.theme.color.monochrome.mediumDark};
  font-style: italic;
`

export default function formatEmpty(_, { empty = '—' }) {
  if (!empty) return null
  return <StyledEmpty text={empty} />
}
