import {
  BUSINESS_HOURS_LOAD_REQUEST,
  BUSINESS_HOURS_LOAD_RESPONSE,
  BUSINESS_HOURS_LOAD_ERROR,
  BUSINESS_HOURS_SAVE_STARTED,
  BUSINESS_HOURS_SAVE_SUCCESS,
  BUSINESS_HOURS_SAVE_FAILED,
} from './types'
import { parseBusinessHours } from './util'

const defaultBusinessHoursState = {
  days: {
    1: { start: '09:00 AM', end: '05:00 PM' },
    2: { start: '09:00 AM', end: '05:00 PM' },
    3: { start: '09:00 AM', end: '05:00 PM' },
    4: { start: '09:00 AM', end: '05:00 PM' },
    5: { start: '09:00 AM', end: '05:00 PM' },
    6: { start: '09:00 AM', end: '05:00 PM' },
    7: { start: '09:00 AM', end: '05:00 PM' },
  },
  loadErrored: false,
  loaded: false,
  loading: false,
  saveErrored: false,
  saved: false,
  saving: false,
}

export default function businessHours(state, action) {
  const { type, payload: { result, days } = {} } = action
  if (type === BUSINESS_HOURS_LOAD_REQUEST) {
    return {
      ...state,
      loadErrored: false,
      loaded: false,
      loading: true,
    }
  } else if (type === BUSINESS_HOURS_LOAD_RESPONSE) {
    return {
      ...state,
      loadErrored: false,
      loading: false,
      loaded: true,
      days: parseBusinessHours(result.days),
      timezone: result.timezone,
      persisted: true,
    }
  } else if (type === BUSINESS_HOURS_LOAD_ERROR) {
    return {
      ...state,
      loadErrored: true,
      loading: false,
    }
  } else if (type === BUSINESS_HOURS_SAVE_STARTED) {
    return {
      ...state,
      saveErrored: false,
      saved: false,
      saving: true,
    }
  } else if (type === BUSINESS_HOURS_SAVE_SUCCESS) {
    return {
      ...state,
      saveErrored: false,
      saving: false,
      saved: true,
      days: parseBusinessHours(days),
      persisted: true,
    }
  } else if (type === BUSINESS_HOURS_SAVE_FAILED) {
    return {
      ...state,
      saveErrored: true,
      saving: false,
    }
  }
  return state || defaultBusinessHoursState
}
