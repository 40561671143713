import {
  FETCH_TICKET_COMMENTS_STARTED,
  FETCH_TICKET_COMMENTS_SUCCESS,
  FETCH_TICKET_COMMENTS_FAILED,
} from '../types'

const reducers = {}
export const defaultState = {
  isLoading: false,
  isLoaded: false,
  hasError: false,
  loadingTicketIds: [],
  data: [],
}

reducers[FETCH_TICKET_COMMENTS_STARTED] = (state, action) => {
  const { meta: { requestParameters: { ticketId = null } = {} } = {} } = action

  return {
    ...state,
    loadingTicketIds: [...state.loadingTicketIds, ticketId],
    isLoading: true,
  }
}

reducers[FETCH_TICKET_COMMENTS_SUCCESS] = (state, action) => {
  const {
    payload: { comments },
    meta: { requestParameters: { ticketId = null } = {} } = {},
  } = action

  // Merge comment templates
  const allComments = state.data.filter(
    t => !comments.some(nt => nt.id === t.id)
  )
  comments.forEach(t => allComments.push({ ...t, ticketId }))

  return {
    ...state,
    isLoading: false,
    isLoaded: true,
    hasError: false,
    loadingTicketIds: state.loadingTicketIds.filter(t => t !== ticketId),
    data: allComments,
  }
}

reducers[FETCH_TICKET_COMMENTS_FAILED] = (state, action) => {
  const { meta: { requestParameters: { ticketId = null } = {} } = {} } = action

  return {
    ...state,
    isLoading: false,
    hasError: true,
    loadingTicketIds: state.loadingTicketIds.filter(t => t !== ticketId),
    error: action.payload.error,
  }
}

export default function reducer(state = defaultState, action) {
  // this is here because a long reducer with many ifs is unreadable
  const handler = reducers[action.type]
  if (handler) return handler(state, action)
  return state
}
