import React from 'react'
import activation from 'assets/kb-reporting-activation.png'
import ActivationCardWithBackground from 'components/ActivationCardWithBackground'
import { LANDING_PAGE } from 'subapps/kb/pages'
import { knowledge as KnowledgeBaseIcon } from 'assets/icons/groove/v2'
import { styles } from './styles'

// Don't use CREATE_PAGE, the route won't be created until we're in kb app
// LANDING_PAGE works here
const toCreatePage = { type: LANDING_PAGE }

const ActivationScreen = () => {
  return (
    <div css={styles.container}>
      <ActivationCardWithBackground backgroundImg={activation}>
        <ActivationCardWithBackground.Card
          title="Lower support volume by 30%"
          buttonLabel="Activate your Knowledge Base"
          buttonActionTo={toCreatePage}
          css={styles.card}
          icon={KnowledgeBaseIcon}
          note="Setup time of 5 minutes or less"
        >
          Our fully customizable Knowledge Base lets customers find their own
          answers quickly, making them happier, and your team more productive.
        </ActivationCardWithBackground.Card>
      </ActivationCardWithBackground>
    </div>
  )
}

export default ActivationScreen
