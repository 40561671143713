import { createPayload } from 'optimistic/base'
import { toServerString } from 'util/date'

// builds mock data for an undo send 'action'
export const undoSendPayload = (
  state,
  ticketId,
  messageChangesetId,
  folderId,
  search,
  updatedAt = toServerString(new Date())
) =>
  createPayload(
    state,
    ticketId,
    'UndoSend',
    {
      updated_at: updatedAt,
    },
    [
      {
        change_type: 'UndoSend',
        change: {
          type: 'UndoSend',
          messageChangesetId,
        },
      },
    ],
    folderId,
    search,
    `undoSend-${messageChangesetId}`
  )
