const EMOJIS = {
  0: '😣',
  3: '😬',
  7: '😃',
  10: '😄',
}

export default function formatRatings(datum) {
  return [0, 3, 7, 10]
    .map(grade => {
      const value =
        datum.y &&
        datum.y.find(({ group }) => {
          return group && group[0].toString() === grade.toString()
        })
      return `${EMOJIS[grade]} ${(value && value.y) || 0}`
    })
    .join('\u00A0\u00A0\u00A0')
}
