/* eslint-disable no-param-reassign */
/* eslint-disable default-case */
import debug from 'util/debug'
import { invert } from 'util/objects'
import { buildId } from 'util/globalId'
import { uniq } from 'util/arrays'
import {
  isBridgeChannelType,
  isChatChannelType,
} from 'ducks/channels/channelTypes'

const order = [
  'channel:', // Replaces inbox. Expects mailbox or widget global id
  'folder:',
  'folderunread:',
  'team:', // Replaces group Should support unassigned
  'agent:', // Replaces assignee. Should support unassigned
  'is:assigned',
  'is:unassigned', // Should this be replaced with not:assigned?
  'draft:',
  'is:starred',
  'starred:',
  'is:unread',
  'is:open',
  'is:closed',
  'is:spam',
  'is:snoozed',
  'is:trash', // Replaces is:deleted (Deleted tickets dont exist in the system)
  'is:rated',
  'rating:',
  'tag:',
  'deleted:',
  'mentions:',
  'type:',
  'orderBy:',
  'entityType:',
  'pageSize:',
  'cursor:',
  'search:',
  'category:',
  'state:',
  'name:',
  'active:',
  'scope:',
]

// lookup table, for performance
const orderLookup = {}
order.forEach((key, index) => {
  orderLookup[key] = index
})

// normalize the query part to use the orderLookup table
function normalizePart(part) {
  const first3 = part.substr(0, 3)
  const startsWithIs = first3 === 'is:'
  if (startsWithIs) return part

  const firstPart = part.split(':')[0]
  return `${firstPart}:`
}

// this function normalizes the query string coming from realtime
// it DOES NOT support quoted strings, only ids
export function normalizeSearchQueryId(queryId) {
  // when there is nothing to re-sort, short circuit
  if (!queryId.match(/\s/)) return queryId

  const parts = extractQueryParts(queryId)
  const sorted = parts.sort((a, b) => {
    const orderA = orderLookup[normalizePart(a)]
    const orderB = orderLookup[normalizePart(b)]
    return orderA > orderB ? 1 : -1
  })

  return sorted.join(' ')
}

function mapToField(fieldName, { decodeUri } = {}) {
  return (_, value, filter) => {
    filter[fieldName] = decodeUri ? decodeURIComponent(value) : value
    return filter
  }
}

function mapTeamToAssigned(_, value, filter) {
  if (value === 'none') {
    filter.assigned = {
      noTeam: true,
    }
  } else {
    filter.assigned = {
      team: value,
    }
  }
  return filter
}

function mapAgentToAssigned(_, value, filter) {
  if (value === 'none') {
    filter.assigned = {
      noAgent: true,
    }
  } else {
    filter.assigned = {
      agent: value,
    }
  }
  return filter
}

function mapIsToField(_, value, filter) {
  switch (value) {
    case 'assigned':
      debug('Mapping is:assigned is not supported')
      break
    case 'unassigned':
      filter.assigned = {
        noTeam: true,
        noAgent: true,
      }
      break
    case 'starred':
      filter.starred = true
      break
    case 'unread':
      filter.state = 'UNREAD'
      break
    case 'open':
      filter.state = 'OPEN'
      break
    case 'closed':
      filter.state = 'CLOSED'
      break
    case 'spam':
      filter.state = 'SPAM'
      break
    case 'snoozed':
      filter.state = 'SNOOZED'
      break
  }
  return filter
}

function mapDraftToDraftAuthor(_, value, filter) {
  filter.draftAuthor = value
  // TODO: Implement logic to convert username values to an author id
  return filter
}

function mapFieldToBoolean(fieldName) {
  return (_, value, filter) => {
    let booleanValue = !!value
    if ([0, '0', false, 'false', 'no'].includes(value)) {
      booleanValue = false
    } else if ([1, '1', true, 'true', 'yes'].includes(value)) {
      booleanValue = true
    }
    filter[fieldName] = booleanValue
    return filter
  }
}

function hackRoomFilter(filter) {
  // Currently room filters only support state and folderId
  // They also dont "correctly" mimic the ConversationFilter, so
  // we need to do some hack.
  const roomFilter = {
    channelType: filter.type ? filter.type.toUpperCase() : null,
  }
  if (filter.channelId) roomFilter.channelId = filter.channelId
  if (filter.folderId) roomFilter.folderId = filter.folderId

  return roomFilter
}

function applyTypeFilterTransformations(filter) {
  const { type = 'widget', entityType } = filter
  // The concept of a "RoomFilter vs ConversationFilter shouldnt exists"
  // Once we normalize the api, we need to get rid of these hacks
  if (isChatChannelType(type) && !entityType) return hackRoomFilter(filter)
  delete filter.type
  return filter
}

const queryKeyToFilterMap = {
  channel: mapToField('channelId'),
  entityType: mapToField('entityType'),
  folder: mapToField('folderId'),
  folderunread: mapToField('unreadFolderId'),
  team: mapTeamToAssigned,
  agent: mapAgentToAssigned,
  is: mapIsToField,
  draft: mapDraftToDraftAuthor,
  starred: mapFieldToBoolean('starred'),
  tag: mapToField('tagName'),
  type: mapToField('type'),
  state: mapToField('state'),
  pageSize: mapToField('pageSize'),
  cursor: mapToField('cursor'),
  search: mapToField('search', { decodeUri: true }),
  category: mapToField('categoryId'),
  name: mapToField('name'),
  active: mapFieldToBoolean('active'),
  scope: mapToField('scope'),
}

const ignoreKeys = ['orderBy']

export function constructGraphQLFilterObject(queryId, state) {
  if (!queryId) return null

  const queryParts = extractQueryParts(queryId)
  const data = applyTypeFilterTransformations(
    queryParts.reduce((filter, queryPart) => {
      const [key, value] = queryPart.split(':')
      if (!queryKeyToFilterMap[key]) {
        if (!ignoreKeys.includes(key)) {
          debug(
            `CRITICAL: Unable to map query part to graphql filter input: ${queryPart}`
          )
        }
        return filter
      }
      queryKeyToFilterMap[key](key, value, filter, state)
      return filter
    }, {})
  )
  return data
}

export function constructGraphQLOrderByObject(queryId) {
  const { orderBy } = queryIdToQuery(queryId) || {}
  if (!orderBy) return null
  let orderField = null
  let orderDirection = 'ASC'
  ;['ASC', 'DESC'].forEach(direction => {
    const directionRegex = new RegExp(`(?<orderBy>.*)_${direction}$`)
    const match = orderBy.match(directionRegex)
    if (match) {
      orderField = match.groups.orderBy
      orderDirection = direction
    }
  })
  if (!orderField) return null
  return { field: orderField, direction: orderDirection }
}

export function constructApiV1SortBy(queryId) {
  const { orderBy } = queryIdToQuery(queryId) || {}
  return orderBy
}

const queryStringMap = {
  channelId: 'channel',
  folderId: 'folder',
  teamId: 'team',
  agentId: 'agent',
  draftAuthorId: 'draft',
  tagId: 'tag',
  stateId: 'state',
  type: 'type',
  starred: 'starred',
  orderBy: 'orderBy',
  entityType: 'entityType',
  pageSize: 'pageSize',
  cursor: 'cursor',
  categoryId: 'category',
  search: 'search',
  state: 'state',
  name: 'name',
  active: 'active',
  scope: 'scope',
  mailboxId: 'inbox',
}

function parseKey(key, { targetId }) {
  if (key === undefined) return undefined
  if (!targetId) return key
  const targetPart = `${targetId}-`
  if (!key.startsWith(targetPart)) return undefined
  return key.substr(targetPart.length)
}

export function filterQueryByTargetId(targetId, query, options) {
  const normalizedOptions = { ...options, targetId }
  return Object.keys(query).reduce((tQuery, rawKey) => {
    const key = parseKey(rawKey, normalizedOptions)
    if (key) {
      tQuery[key] = casePart(key, query[rawKey], normalizedOptions)
    }
    return tQuery
  }, {})
}

export function queryStringToQueryId(query = {}, options = {}) {
  const { targetId } = options
  const queryParts = []
  query = !targetId ? query : filterQueryByTargetId(targetId, query, options)
  Object.keys(queryStringMap).forEach(key => {
    if (query[key] === undefined) return
    queryParts.push(
      `${queryStringMap[key]}:${casePart(key, query[key], options)}`
    )
  })
  if (query.is !== undefined) {
    query.is.split(`_`).forEach(isPart => {
      queryParts.push(`is:${isPart}`)
    })
  }
  return normalizeSearchQueryId(queryParts.join(' '))
}

const queryIdMap = invert(queryStringMap)

function casePart(
  key,
  value,
  { parseToIntKeys = [], decodeUriKeys = [], encodeUriKeys = [] } = {}
) {
  if (['pageSize'].includes(key)) {
    return parseInt(value, 10)
  }
  if (parseToIntKeys.includes(key)) {
    return parseInt(value, 10)
  }
  if (decodeUriKeys.includes(key)) {
    return decodeURIComponent(value)
  }
  if (encodeUriKeys.includes(key)) {
    return encodeURIComponent(value)
  }
  return value
}

// Handles splitting edge case queryIds like the following
// Input: state:open search: yes this does work starred:false search2:hur hur hur
// Output: ["state:open","search: yes this does work","starred:false","search2:hur hur hur"]
function extractQueryParts(inputQueryId) {
  let queryId = inputQueryId
  const extractorRegex = /(^|\s)(\w*?)(?=:)/g

  const regMatches = queryId.match(extractorRegex) || []
  const keys = regMatches.reverse()
  const queryParts = []
  keys.forEach(key => {
    const partStart = queryId.lastIndexOf(`${key}:`)
    queryParts.push(queryId.substr(partStart).trim())
    queryId = queryId.substr(0, partStart)
  })
  return queryParts.reverse()
}

export function queryIdToQuery(queryId, options = {}) {
  const { targetId } = options
  if (!queryId) return null
  const targetPart = targetId ? `${targetId}-` : ''

  const query = {}
  const queryParts = extractQueryParts(queryId)
  queryParts.forEach(queryPart => {
    const [rawKey, value] = queryPart.split(':')
    const key = parseKey(rawKey, options)
    const mappedKey = queryIdMap[key]
    if (key === 'is') {
      if (!query.is) query.is = []
      query.is.push(value)
    } else if (mappedKey !== undefined) {
      query[`${targetPart}${mappedKey}`] = casePart(mappedKey, value, options)
    }
  })
  if (query.is) {
    query.is = query.is.join('_')
  }
  return query
}

export function targetQuery(targetId, query) {
  return Object.keys(query).reduce((tQuery, key) => {
    tQuery[`${targetId}-${key}`] = query[key]
    return tQuery
  }, {})
}

export function clearTargetQuery(targetId, query) {
  return Object.keys(query).reduce((tQuery, key) => {
    if (!key.startsWith(targetId)) {
      tQuery[key] = query[key]
    }
    return tQuery
  }, {})
}

// Adds the currentUser id to any query parts that requert context.
// Currently this is only folders, but in future we might have additional types
// Example
// queryId = channel:ch_100 folder:fol_100 type:widget
// currentUserId = 2000
// return = channel:ch_100 folder:fol_100[2000] type:widget
export function addContextToQueryId(queryId, currentUserId) {
  return queryId.replace(/(folder:.+?)(\s|$)/, `$1[ag_${currentUserId}]$2`)
}

export function removeContextFromQueryId(queryId) {
  return queryId.replace(/(\[.*?\])/g, '')
}

export function removeKeysFromQueryId(keys, queryId) {
  if (!queryId) return ''

  return extractQueryParts(queryId)
    .filter(part => !keys.some(key => part.startsWith(`${key}:`)))
    .join(' ')
}

export function isForCurrentUser(queryId, currentUserId) {
  // If the query id doesnt have context, then it'll always be for the
  // current user
  if (!queryId.includes('ag_')) return true
  // If the query does contain context, then make sure its for the current user
  return queryId.includes(`[ag_${currentUserId}]`)
}

export function constructFolderItemQueryId({
  widget,
  folder,
  channelType: pageChannelType,
  orderBy,
}) {
  const { id: rawWidgetId, channelType } = widget || {}
  const collectionQueryId = [`type:${channelType || pageChannelType}`]
  if (rawWidgetId) {
    const widgetId = buildId('Widget', rawWidgetId)
    collectionQueryId.unshift(`channel:${widgetId}`)
  }

  if (folder) {
    const { queryId } = folder
    collectionQueryId.push(queryId)
  }

  if (orderBy) {
    collectionQueryId.push(`orderBy:${orderBy}`)
  }

  return normalizeSearchQueryId(collectionQueryId.join(' '))
}

export function defaultFolderItemQueryId({
  widgets,
  folders,
  prefersAllMailboxesSectionVisible,
  prefersUnifiedInbox,
  pageChannelType,
  orderBy,
}) {
  // Future dev, make sure you test the following cases to make sure the code is working
  // 1. Only 1 widget with prefersUnifiedInbox enabled --> Only all widgets displayed in expanded state
  // 2. Only 1 widget with Seperate inboxes enabled AND prefersAllMailboxesSectionVisible is enabled --> Only display the widget in expanded state (All widgets not shown)
  // 3. Only 1 widget with Seperate inboxes enabled AND prefersAllMailboxesSectionVisible is disabled --> Only display the widget in expanded state
  // 4. More than 1 widget with prefersUnifiedInbox enabled --> Only all widgets display in expanded state
  // 5. More than 1 widget with Seperate inboxes enabled AND prefersAllMailboxesSectionVisible is enabled --> Display All widgets and each seperate widget with all widgets in expanded state
  // 6. More than 1 widget with Seperate inboxes enabled AND prefersAllMailboxesSectionVisible is disabled --> Only display the widget with first widget in expanded state
  const widgetsWithAccess = widgets.filter(w => w.hasAccess)
  if (widgetsWithAccess.length === 0 || folders.length === 0) return null
  const hasOnlyOneWidget =
    widgetsWithAccess.length === 1 && !prefersUnifiedInbox
  const requireWidget = !(
    prefersAllMailboxesSectionVisible || prefersUnifiedInbox
  )
  const widget =
    widgetsWithAccess.length > 0 && (hasOnlyOneWidget || requireWidget)
      ? widgetsWithAccess[0]
      : null

  const channelType = widget?.channelType || pageChannelType

  const allowedFolders = folders.filter(
    ({ name }) =>
      !['New messages', 'Ending soon'].includes(name) ||
      isBridgeChannelType(channelType)
  )
  return constructFolderItemQueryId({
    widget,
    folder: allowedFolders[0],
    channelType,
    orderBy,
  })
}

export function getAddedConversationIds(searches) {
  let conversationIds = []
  Object.keys(searches).forEach(rawSearchDiffQueryId => {
    const { plus } = searches[rawSearchDiffQueryId]
    conversationIds = conversationIds.concat(plus)
  })
  return uniq(conversationIds)
}
