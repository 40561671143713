import { css } from '@emotion/react'
import { rem } from '@groovehq/internal-design-system/lib/util/strings'

const container = css`
  padding: 0 ${rem(17)};
`

const card = css`
  margin-top: ${rem(125)};
`

export const styles = {
  container,
  card,
}
