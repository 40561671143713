export function first(gauge) {
  const { queries } = gauge
  const firstQuery = queries[0]
  const { page, pages, per_page, total } = firstQuery
  if (page) {
    return {
      page,
      pages,
      per_page,
      total,
    }
  }
}
