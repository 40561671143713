import moment from 'moment-timezone-all'
import React from 'react'
import { connect } from 'react-redux'

import {
  selectActiveKnowledgeBaseId,
  selectActiveMailboxId,
} from '../../selectors'
import LinkWithReportContextView from './LinkWithReportContextView'

const select = state => ({
  reportContextQueryParams: {
    inbox_id: selectActiveMailboxId(state),
  },
})

export default connect(select)(LinkWithReportContextView)
