export const countMessageClosedSentUniqueCustomer = {
  analysisType: 'count_unique',
  eventCollection: 'message.sent',
  filters: [
    {
      propertyName: 'conversation.state',
      operator: 'eq',
      propertyValue: 'closed',
    },
  ],
  key: 'countMessageClosedSentUniqueCustomer',
  targetProperty: 'conversation.customer.id',
}
