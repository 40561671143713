import AgentPage from './components/AgentPage'
import TeamPage from './components/TeamPage'
import { AGENT_DETAIL_PAGE, TEAM_DETAIL_PAGE } from './pages'

export default {
  [AGENT_DETAIL_PAGE]: {
    component: AgentPage,
    path: '/reports/inbox/agent/:id',
    fromPath: string => String(string),
  },
  [TEAM_DETAIL_PAGE]: {
    component: TeamPage,
    path: '/reports/inbox/team/:id',
    fromPath: string => String(string),
  },
}
