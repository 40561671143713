import {
  averageSearchResultsByTermGauge,
  searchesClickThroughRateRatioGauge,
  totalSearchesByTermGauge,
  topClickedArticleBySearchTermGauge,
} from 'ducks/reports/gauges'
import {
  sortPercentChange,
  sortX,
  TableV2Visualization,
} from 'ducks/reports/visualizations'

const staticProps = {
  includePrevious: true,
  gauges: [
    {
      ...totalSearchesByTermGauge,
      id: 'totalSearchTermByTermGauge',
      title: 'Search Term',
      scale: { y: 'x' },
      sort: { direction: 'ASC', method: sortX },
    },
    {
      ...totalSearchesByTermGauge,
      id: 'totalSearchCountByTermGauge',
      title: 'Count',
      scale: { y: 'percent_total' },
    },
    {
      ...totalSearchesByTermGauge,
      id: 'totalSearchChangeByTermGauge',
      title: '% Change',
      scale: { y: 'percent_change' },
      sort: { method: sortPercentChange },
    },
    {
      ...averageSearchResultsByTermGauge,
      title: 'Results',
      scale: { y: 'integer' },
      fill: 0,
    },
    {
      ...searchesClickThroughRateRatioGauge,
      cap: 1,
      id: 'searchesClickThroughRateRatioGauge',
      fill: 0,
      scale: { y: 'percent' },
      title: 'CTR',
    },
  ],
  pagination: {
    customizable: true,
    perPage: 10,
    minPerPage: true,
  },
  sort: { by: 'totalSearchCountByTermGauge' },
  title: 'Most searched terms',
}

export default function TagInsights(props) {
  return <TableV2Visualization {...props} {...staticProps} />
}
