import React from 'react'
import StartContent from 'components/StartContent'
import VideoContainer from 'components/VideoContainer'
import Button from '@groovehq/internal-design-system/lib/components/Button/Button'
import VideoButton from 'components/VideoContainer/VideoButton'

import { styles } from './styles'

const videoId = 'ral3fyjw8s'

const Empty = () => {
  return (
    <div className="grui py-16 text-center">
      <VideoContainer size="big" css={styles.videoWrapper}>
        <VideoContainer.Video videoId={videoId} />
      </VideoContainer>
      <StartContent
        title="Your account is gathering data!"
        subtitle="Your account needs a bit more data before reporting will be available.
        This may take a few days depending on your support volume."
        css={styles.content}
      />
      <VideoButton videoId={videoId}>
        <Button type="info" size="xBig" className="grui px-12">
          Watch a 1 minute demo of reports
        </Button>
      </VideoButton>
    </div>
  )
}

export default Empty
