export const FETCH_TICKET_COMMENTS = 'ticketing/FETCH_TICKET_COMMENTS'
export const FETCH_TICKET_COMMENTS_STARTED =
  'ticketing/FETCH_TICKET_COMMENTS_STARTED'
export const FETCH_TICKET_COMMENTS_SUCCESS =
  'ticketing/FETCH_TICKET_COMMENTS_SUCCESS'
export const FETCH_TICKET_COMMENTS_FAILED =
  'ticketing/FETCH_TICKET_COMMENTS_FAILED'

export const FETCH_KB_ARTICLES = 'ticketing/FETCH_KB_ARTICLES'
export const FETCH_KB_ARTICLES_STARTED = 'ticketing/FETCH_KB_ARTICLES_STARTED'
export const FETCH_KB_ARTICLES_SUCCESS = 'ticketing/FETCH_KB_ARTICLES_SUCCESS'
export const FETCH_KB_ARTICLES_FAILED = 'ticketing/FETCH_KB_ARTICLES_FAILED'

export const CLEAR_KB_ARTICLES = 'ticketing/CLEAR_KB_ARTICLES'

export const FETCH_KBS = 'ticketing/FETCH_KBS'
export const FETCH_KBS_STARTED = 'ticketing/FETCH_KBS_STARTED'
export const FETCH_KBS_SUCCESS = 'ticketing/FETCH_KBS_SUCCESS'
export const FETCH_KBS_FAILED = 'ticketing/FETCH_KBS_FAILED'

export const FETCH_KB_PREVIEW_ARTICLE = 'ticketing/FETCH_KB_PREVIEW_ARTICLE'
export const FETCH_KB_PREVIEW_ARTICLE_STARTED =
  'ticketing/FETCH_KB_PREVIEW_ARTICLE_STARTED'
export const FETCH_KB_PREVIEW_ARTICLE_SUCCESS =
  'ticketing/FETCH_KB_PREVIEW_ARTICLE_SUCCESS'
export const FETCH_KB_PREVIEW_ARTICLE_FAILED =
  'ticketing/FETCH_KB_PREVIEW_ARTICLE_FAILED'
