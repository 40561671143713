import { createPayload } from 'optimistic/base'
import { sortByKey } from 'util/arrays'
import { optimisticUpdatedAt } from 'util/optimistic'
import { toServerString } from 'util/date'

// builds mock data for ticket restore
export const restorePayload = (
  state,
  ticketId,
  folderId,
  search,
  updatedAt = toServerString(new Date())
) =>
  createPayload(
    state,
    ticketId,
    'restore',
    {
      deleted_at: null,
      updated_at: updatedAt,
      stateChangedAt: updatedAt,
      state: 'open',
    },
    [
      {
        change_type: 'Deleted',
        change: {
          type: 'Deleted',
          restored: true,
        },
      },
      {
        change_type: 'State',
        change: {
          type: 'State',
          state: 'opened',
        },
      },
    ],
    folderId,
    search
  )

// builds mock data for bulk ticket restore
export const bulkRestorePayload = (state, ticketIds, folderId, search) => {
  return sortByKey(
    ticketIds.map(id => {
      return restorePayload(state, id, folderId, search, optimisticUpdatedAt())
    }),
    'id'
  )
}
