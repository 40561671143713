import React from 'react'
import { memoize } from 'util/memoization'

import { sortByKeyType, sortByKeyTypeDesc } from 'util/arrays'
import { isFunction } from 'util/functions'

export default function withDatasetTabularized(ComposedComponent) {
  return class DatasetTabularized extends React.Component {
    state = {}
    gaugesLoadStatus = {}

    getTableData = memoize(props => {
      const { dataSets } = props
      const data = []

      const rows = {}
      dataSets.forEach(dataSet => {
        if (
          dataSet.data.length > 1 ||
          (dataSet.data.length === 1 && dataSet.data[0].x !== null)
        ) {
          dataSet.data.forEach((dataRow, index) => {
            if (!rows[dataRow.x]) {
              rows[dataRow.x] = {
                id: dataRow.x,
                [dataSets[0].scale.x]: dataRow.x,
                rowOrder: index,
              }
            }
          })
        }
      })
      dataSets.forEach(dataSet => {
        const indexedDataSet = dataSet
        indexedDataSet.indexedData = {}
        dataSet.data.forEach(dataRow => {
          indexedDataSet.indexedData[dataRow.x] = dataRow
        })
        if (dataSet.previousData) {
          indexedDataSet.indexedPreviousData = {}
          dataSet.data.forEach(dataRow => {
            indexedDataSet.indexedPreviousData[dataRow.x] = dataRow
          })
        }
      })

      Object.keys(rows).forEach(id => {
        const dataRow = rows[id]
        const idValue = dataRow.id
        dataSets.forEach(dataSet => {
          // Stanard join using the X
          if (
            dataSet.data.length > 1 ||
            (dataSet.data.length === 1 && dataSet.data[0].x !== null)
          ) {
            dataRow[dataSet.scale.y] = null
            const dataItem = dataSet.indexedData[idValue]
            if (dataItem) {
              dataRow[dataSet.scale.y] = dataItem.y
            }
            if (dataSet.previousData) {
              const previousDataItem = dataSet.indexedData[idValue]
              if (previousDataItem) {
                dataRow[`previous_${dataSet.scale.y}`] = previousDataItem.y
                dataRow[`change_${dataSet.id}`] = dataItem.change
              }
            }
            // Full outer join
          } else if (dataSet.data.length === 1 && dataSet.data[0].x === null) {
            dataRow[dataSet.id] = dataSet.data[0].y
            if (dataSet.previousData) {
              dataRow[`previous_${dataSet.id}`] = dataSet.previousData[0].y
            }
          }
        })
        data.push(dataRow)
      })
      return this.filterDataset(this.sortDataset(data))
    })

    get tableData() {
      return this.getTableData(this.props)
    }

    parseSort(sort) {
      if (sort) {
        const parts = sort.split(' ')
        return {
          column: parts[0],
          direction: parts.length > 1 ? this.parseDirection(parts[1]) : null,
          type: parts.length > 2 ? parts[2] : null,
        }
      }
      return { column: null, direction: null }
    }

    parseDirection(direction) {
      if (direction && direction === 'ASC') {
        return 'ascending'
      }
      return 'descending'
    }

    filterDataset(data) {
      const { filter } = this.props
      if (!isFunction(filter)) return data
      return filter(data)
    }

    sortDataset(data) {
      const { componentState, sort } = this.props
      if (!sort) return data
      const stateSort = componentState.sort || { order: sort.default } || {}
      const { column, direction, type } = this.parseSort(stateSort.order)
      let sortAccessor = 'rowOrder'
      let sortDirection = 'ascending'
      // If a sorting handler has been defined, sort by the rowOrder returned
      // by the source dataset
      if (!sort.onSortChange) {
        sortAccessor = column || sort.accessor || sortAccessor
        sortDirection = direction || sort.direction || sortDirection
      }
      let sortFunction = sortByKeyType(sortAccessor, type)
      if (sortDirection !== 'ascending') {
        sortFunction = sortByKeyTypeDesc(sortAccessor, type)
      }
      return data.sort(sortFunction)
    }

    render() {
      return <ComposedComponent {...this.props} tableData={this.tableData} />
    }
  }
}
