import { createActionTypeReducer } from 'util/reducers'
import { camelize } from 'util/strings'
import {
  FETCH_WALLETS_FAILED,
  FETCH_WALLETS_STARTED,
  FETCH_WALLETS_SUCCESS,
  UPDATE_WALLET_BY_REALTIME,
  UPDATE_WALLET_SUCCESS,
} from '../types'

export const defaultState = {
  byId: {},
  aiOverview: {
    isLoading: false,
    isLoaded: false,
    ids: [],
  },
  aiDraft: {
    isLoading: false,
    isLoaded: false,
    ids: [],
  },
}

export default createActionTypeReducer(
  {
    [FETCH_WALLETS_STARTED]: (draftState, { meta }) => {
      const {
        requestParameters: { type },
      } = meta

      draftState[type].isLoading = true

      return draftState
    },
    [FETCH_WALLETS_SUCCESS]: (draftState, { payload, meta }) => {
      const {
        requestParameters: { type },
      } = meta
      const { wallets = {} } = payload

      const newWalletsById = wallets.edges?.reduce((acc, edge) => {
        // eslint-disable-next-line no-param-reassign
        acc[edge.node.id] = edge.node
        return acc
      }, {})

      draftState[type].isLoading = false
      draftState[type].isLoaded = true
      draftState[type].ids = wallets.edges?.map(edge => edge.node.id) || []

      draftState.byId = {
        ...draftState.byId,
        ...newWalletsById,
      }

      return draftState
    },
    [FETCH_WALLETS_FAILED]: (draftState, { meta }) => {
      const {
        requestParameters: { type },
      } = meta

      draftState[type].isLoading = false
      draftState[type].isLoaded = false

      return draftState
    },
    [UPDATE_WALLET_BY_REALTIME]: (draftState, { payload }) => {
      const { data } = payload
      const {
        id,
        balance,
        can_use: canUse,
        limit,
        limit_type: limitType,
        feature,
      } =
        data || {}
      if (!draftState.byId[id]) {
        const featureKey = camelize(feature.replace('_main', ''))
        if (draftState[featureKey] && !draftState[featureKey].ids.length) {
          draftState[featureKey] = {
            ...draftState[featureKey],
            isLoaded: false,
          }
        }
        return draftState
      }

      draftState.byId[id] = {
        ...draftState.byId[id],
        balance,
        canUse,
        limit,
        limitType,
      }

      return draftState
    },
    [UPDATE_WALLET_SUCCESS]: (draftState, { payload }) => {
      const { wallet = {} } = payload

      draftState.byId[wallet.id] = {
        ...draftState.byId[wallet.id],
        ...wallet,
      }

      return draftState
    },
  },
  defaultState
)
