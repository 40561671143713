import React from 'react'
import { Line, Point } from 'victory'

export default function LegendLine(props) {
  const {
    datum: { color, previous, isSingleDatum },
    x,
    y,
  } = props
  if (!isSingleDatum) {
    return (
      <Line
        x1={x - 8}
        x2={x + 7}
        y1={y}
        y2={y}
        style={{
          stroke: color,
          strokeWidth: 2,
          strokeDasharray: previous ? '3,3' : null,
        }}
        key={x}
      />
    )
  }
  return (
    <Point
      {...props}
      style={{
        fill: color,
      }}
    />
  )
}
