import cn from 'classnames'
import React from 'react'

import { PERCENTAGE_BRACKETS } from './collapseData'
import styles from './styles.less'

export default function Legend() {
  return (
    <React.Fragment>
      {[...PERCENTAGE_BRACKETS, 0]
        .map((bottom, i) => {
          const color = `color_${i === 6 ? 'white' : 5 - i}`
          return <span className={cn(styles.key, styles[color])} key={color} />
        })
        .reverse()}
    </React.Fragment>
  )
}
