import averageIntervals from '../intervalCollapsers/averageIntervals'

import { countConversationsAssignedUniqueCustomer } from '../queries/countConversationsAssignedUniqueCustomer'

export const conversationsAssignedCustomersPerDayGauge = {
  collapseInterval: averageIntervals,
  description:
    'Total amount of customers that sent in a conversations (one customer may have multiple conversations) including spam and deleted conversations per day.',
  queries: [
    {
      ...countConversationsAssignedUniqueCustomer,
      interval: 'daily',
      key: 'countConversationsAssignedUniqueCustomerForEachDay',
    },
  ],
  title: 'Assigned Customers',
}
