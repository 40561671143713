const basicFields = `
  id
  createdAt
  name
  updatedAt
  uuid
  publishedSettings
  hasAccess
  userIds
  groupIds
  restrictionType
  channelColor
  kind
  status
  channelType
`

const extendedFields = `
${basicFields}
  bridge {
    externalId
    externalName
  }
`

export const settingsQuery = () => `
  query WidgetSettingsQuery($widgetId: ID!) {
    widgetSettings(widgetId: $widgetId)
  }
`

export const widgetsQuery = () => `
  query WidgetsQuery {
    widgets {
      data {
        ${extendedFields}
      }
    }
  }
`

export const widgetQuery = () => `
  query WidgetQuery($id: ID!) {
    widget(id: $id) {
      ${extendedFields}
    }
  }
`

export const publishSettingsMutation = () => `
  mutation PublishWidgetSettings(
    $widgetId: ID!
  ){
    publishWidgetSettings(
      widgetId: $widgetId
    )
  }
`

export const revertWidgetSettingsMutation = () => `
  mutation RevertWidgetSettings(
    $widgetId: ID!
  ){
    revertWidgetSettings(
      widgetId: $widgetId
    )
  }
`

export const createWidgetMutation = () => `
  mutation CreateWidget(
    $input: WidgetInput!
  ){
    createWidget(
      input: $input
    ) {
      ${extendedFields}
    }
  }
`

export const updateWidgetMutation = () => `
  mutation UpdateWidget(
    $id: ID!,
    $input: WidgetInput!
  ){
    updateWidget(
      id: $id,
      input: $input
    ) {
      ${extendedFields}
    }
  }
`

export const deleteWidgetMutation = () => `
  mutation DeleteWidget($id: ID!) {
    destroyWidget(id: $id)
  }
`

export const updateWidgetSettingsMutation = () => `
  mutation UpdateWidgetSettings(
    $widgetId: ID!,
    $input: JSON!,
    $commitType: WidgetSettingsCommitType,
  ){
    updateWidgetSettings(
      widgetId: $widgetId,
      input: $input,
      commitType: $commitType
    )
  }
`

export const demoWidgetQuery = () => `
  query Widget($id: ID!) {
    widget(id: $id) {
      publishedSettings
    }
  }
`
