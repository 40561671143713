import moment from 'moment-timezone-all'
import React from 'react'
import { memoize } from 'util/memoization'

import { findFirst } from 'util/arrays'

import grooveTheme from '../grooveTheme'

const DEFAULT_SCALE = { x: 'time', y: 'linear' }
const SCALE_TYPES = {
  duration: 'linear',
  percent: 'linear',
}

function getGroupKey(item) {
  const keys = Object.keys(item).filter(
    k => k !== 'value' && k !== 'result' && k !== 'timeframe'
  )
  return keys.length === 1 ? keys[0] : null
}

function createGroupedDatasets(gauge, result) {
  const { id, isNegativeGood = false, description } = gauge
  const dataSets = []
  const groups = result

  groups.forEach(groupL1 => {
    const groupL1Key = getGroupKey(groupL1)
    const groupL2Key = getGroupKey(groupL1.value[0])
    const dataSetId = `${id}:${groupL1[groupL1Key]}`
    const data = groupL1.value.map(item => {
      const x = item[groupL2Key]
      const y = item.result
      const isPositive = y > 0
      const isNegative = y < 0
      return {
        id: `${dataSetId}:${x}:${y}`,
        isBad: isNegativeGood ? isPositive : isNegative,
        isGood: isNegativeGood ? isNegative : isPositive,
        isPositive,
        isNegative,
        x,
        y,
      }
    })

    dataSets.push({
      id: dataSetId,
      name: groupL1[groupL1Key],
      description,
      scale: { x: groupL2Key, y: groupL1[groupL1Key] },
      type: 1,
      data,
      dataByX: data.reduce((result, datum) => {
        result[datum.x] = datum
        return result
      }, {}),
      previousData: false,
    })
  })
  return dataSets
}

function createFlatGroupedDataset(gauge) {
  const {
    expectedGroups,
    id,
    isNegativeChangeGood,
    isNegativeGood,
    query,
    queries = [query],
    result,
    previousPeriod: { result: prevResult, loaded: prevLoaded } = {},
    scale,
    description,
    title: name,
  } = gauge
  const groupKey = (queries[0] && queries[0].groupBy) || getGroupKey(result[0])
  if (!groupKey) return
  const resultLookup = {}

  const groups = expectedGroups
    ? expectedGroups.map(group => {
        const groupResult = findFirst(result, x => x[groupKey] === group) || {}
        return {
          [groupKey]: group,
          result: groupResult.result,
        }
      })
    : result

  const data = groups.map(item => {
    resultLookup[item[groupKey]] = item
    const x = item[groupKey] || item[groupKey] === 0 ? item[groupKey] : 'null'
    const y = item.result
    const isPositive = y > 0
    const isNegative = y < 0
    return {
      id: `${id}:${groupKey}:${x}:${y}`,
      isBad: isNegativeGood ? isPositive : isNegative,
      isGood: isNegativeGood ? isNegative : isPositive,
      isPositive,
      isNegative,
      x,
      y,
    }
  })

  const response = {
    id: `${gauge.id}`,
    name,
    description,
    scale: { x: groupKey, y: gauge.id, ...scale },
    type: 2,
    isNegativeChangeGood,
    data,
    xProperty: groupKey,
    dataByX: data.reduce((result, datum) => {
      result[datum.x] = datum
      return result
    }, {}),
    previousData:
      prevLoaded &&
      prevResult &&
      prevResult
        .map(item => {
          const equivalentItem = resultLookup[item[groupKey]]
          const x = item[groupKey] || 'null'
          const y = item.result
          const isPositive = y > 0
          const isNegative = y < 0
          return (
            equivalentItem && {
              id: `${gauge.id}:${groupKey}:${x}:${y}`,
              isBad: isNegativeGood ? isPositive : isNegative,
              isGood: isNegativeGood ? isNegative : isPositive,
              isPositive,
              isNegative,
              x,
              xActual: item[groupKey],
              y,
            }
          )
        })
        .filter(x => !!x),
  }

  return response
}

function createFlatTimeframeDataset(gauge) {
  const {
    result,
    previousPeriod: { result: prevResult, loaded: prevLoaded, offset } = {},
    isNegativeGood,
    isNegativeChangeGood,
    description,
    title: name,
  } = gauge

  const data = result.map((item, index) => {
    const { start } = item.timeframe
    const x = moment(start).toDate()
    const y = (item.value || item.value === 0) && Number(item.value)
    const isPositive = y > 0
    const isNegative = y < 0
    return {
      id: `${gauge.id}:${index}:${x}:${y}`,
      isBad: isNegativeGood ? isPositive : isNegative,
      isGood: isNegativeGood ? isNegative : isPositive,
      isPositive,
      isNegative,
      x,
      y,
    }
  })
  return {
    id: `${gauge.id}`,
    name,
    description,
    scale: DEFAULT_SCALE,
    type: 3,
    isNegativeChangeGood,
    data,
    dataByX: data.reduce((result, datum) => {
      result[datum.x] = datum
      return result
    }, {}),
    previousData:
      prevLoaded &&
      prevResult &&
      prevResult
        .map((item, index) => {
          const { timeframe: { start } = {}, value } = item
          const xActual = moment(start).toDate()
          const x = moment(start)
            .add(offset)
            .toDate()
          const y = (value || value === 0) && Number(value)
          const isPositive = y > 0
          const isNegative = y < 0
          return {
            id: `${gauge.id}:${index}:${x}:${y}`,
            isBad: isNegativeGood ? isPositive : isNegative,
            isGood: isNegativeGood ? isNegative : isPositive,
            isPositive,
            isNegative,
            x,
            xActual,
            y,
          }
          return false
        })
        .filter(x => !!x),
  }
}

export default function withDataForVisualizations(ComposedComponent) {
  return class DataForVisualizations extends React.Component {
    state = {}
    gaugesLoadStatus = {}

    getRawDataSets = props => {
      const { gauge: singleGauge, gauges = [singleGauge] } = props

      let dataSets = []
      gauges.forEach(gauge => {
        const {
          expectedGroups,
          isNegativeGood,
          isNegativeChangeGood,
          result,
          loaded,
          previousPeriod: { result: prevResult } = {},
          timeframe,
        } = gauge
        if (!loaded) {
          if (expectedGroups) {
            dataSets.push(createFlatGroupedDataset(gauge))
          }
          return null
        }

        if (Array.isArray(result)) {
          const testItem = result[0] // We make the assumption the shape of the first item is reflective of the rest
          if (!testItem) {
            // This is shit and repetative, but a refactor is coming
            if (expectedGroups) {
              dataSets.push(createFlatGroupedDataset(gauge))
            }
            return null
          }
          const { value } = testItem
          const groupKey = getGroupKey(testItem)
          if (Array.isArray(value) && groupKey) {
            dataSets = [...dataSets, ...createGroupedDatasets(gauge, result)]
          } else if (!Array.isArray(value) && groupKey) {
            dataSets.push(createFlatGroupedDataset(gauge))
          } else if (!Array.isArray(value) && !timeframe) {
            // Is grouped
          } else if (Array.isArray(value) && timeframe) {
            // Is grouped interval
          } else if (!Array.isArray(value) && timeframe) {
            // Is interval
            dataSets.push(createFlatTimeframeDataset(gauge))
          } else {
            // Shouldn't happen
          }
        } else {
          const y = (result || result === 0) && Number(result)
          const prevY = Number(prevResult)
          const isPositive = y > 0
          const isNegative = y < 0
          const isPrevPositive = prevY > 0
          const isPrevNegative = prevY < 0
          dataSets.push({
            id: `${gauge.id}`,
            isNegativeChangeGood,
            name: gauge.title,
            description: gauge.description,
            scale: props.scale || { x: 'linear', y: 'linear' },
            scatter: true,
            type: 4,
            data: [
              {
                id: `${gauge.id}:null:${y}`,
                isBad: isNegativeGood ? isPositive : isNegative,
                isGood: isNegativeGood ? isNegative : isPositive,
                isPositive,
                isNegative,
                x: null,
                y,
              },
            ],
            previousData: (prevResult || prevResult === 0) && [
              {
                id: `${gauge.id}:null:${prevY}`,
                isBad: isNegativeGood ? isPrevPositive : isPrevNegative,
                isGood: isNegativeGood ? isPrevNegative : isPrevPositive,
                isPositive: isPrevPositive,
                isNegative: isPrevNegative,
                x: null,
                y: prevY,
              },
            ],
            timeframe,
          })
        }
        return null
      })
      return dataSets
    }

    getDataSets = (rawDataSets, scale) => {
      const dataSets = rawDataSets
      const colorScale = grooveTheme.colors.scale
      const isSingleDataSet = dataSets.length === 1
      const isSingleDataSetWithoutPrevious =
        isSingleDataSet && !dataSets[0].previousData
      const response = dataSets.map((dataSet, index) => {
        const { data, isNegativeChangeGood, previousData, name, id } = dataSet
        const isEmptyDataSet = data.length === 0
        const isSingleDatum = data.length === 1
        const isPreviousSinglePoint = previousData && previousData.length === 1
        const isPreviousEmptyDataSet = previousData && previousData.length === 0
        const color =
          isSingleDataSet || isEmptyDataSet
            ? grooveTheme.colors.purple
            : colorScale[index % colorScale.length]
        const previousColor = isSingleDataSet ? grooveTheme.colors.sky : color
        const mappedData = data.map((dataPoint, dataIndex) => {
          const previousY =
            previousData && previousData[dataIndex] && previousData[dataIndex].y
          const difference =
            (previousY || previousY === 0) && dataPoint.y - previousY
          const ratio =
            previousY === 0 && difference === 0
              ? 0
              : (previousY || previousY === 0) && difference / previousY
          const isPositive = difference > 0
          const isNegative = difference < 0
          let change
          if (previousY || previousY === 0) {
            change = {
              difference,
              isBad: isNegativeChangeGood ? isPositive : isNegative,
              isGood: isNegativeChangeGood ? isNegative : isPositive,
              isInfinity: Math.abs(ratio) === Infinity,
              isNegativeChangeGood: !!isNegativeChangeGood,
              isNegative,
              isNeutral: difference === 0,
              isPositive,
              percent: ratio * 100,
              previous: previousY,
              previousData,
              ratio,
            }
          }
          return {
            ...dataPoint,
            change,
            color,
            dataSetName: name,
            isPrevious: false,
            isSingleDataSet,
            isSingleDataSetWithoutPrevious,
            isSingleDatum,
            scale,
          }
        })
        return {
          ...dataSet,
          isEmptyDataSet,
          isPreviousEmptyDataSet,
          isPreviousSinglePoint,
          isSingleDataSet,
          isSingleDataSetWithoutPrevious,
          isSingleDatum,
          color,
          previousColor,
          data: mappedData,
          dataByX: mappedData.reduce((result, datum) => {
            result[datum.x] = datum
            return result
          }, {}),
          previousData:
            previousData &&
            previousData.map(dataPoint => ({
              ...dataPoint,
              color: previousColor || color,
              dataSetName: name,
              isPrevious: true,
              isSingleDataSet,
              isSingleDataSetWithoutPrevious,
              isSingleDatum: isPreviousSinglePoint,
              scale,
            })),
        }
      })
      return response
    }

    getDataSetsWithPrevious = dataSets => {
      return dataSets.filter(dataSet => {
        return !!dataSet.previousData
      })
    }

    getCurrentDataSets = dataSets => {
      return dataSets.map(x => x.data).filter(x => !!x)
    }

    getLegendDataSets = dataSets => {
      if (!dataSets[0] || dataSets[0].isSingleDataSetWithoutPrevious)
        return null
      return [].concat(
        dataSets.map(dataSet => ({
          name:
            dataSet.isSingleDataSet && !dataSet.isSingleDataSetWithoutPrevious
              ? 'Current'
              : dataSet.name,
          color: dataSet.color,
          isEmptyDataSet: dataSet.isEmptyDataSet,
          isSingleDatum: dataSet.isSingleDatum,
        })),
        dataSets
          .map(dataSet => {
            return (
              dataSet.previousData && {
                name: dataSet.isSingleDataSet
                  ? 'Previous'
                  : `Previous ${dataSet.name}`,
                previous: true,
                isEmptyDataSet: dataSet.isPreviousEmptyDataSet,
                isSingleDatum: dataSet.isPreviousSinglePoint,
                color: dataSet.previousColor || dataSet.color,
              }
            )
          })
          .filter(x => !!x)
      )
    }

    getPreviousDataSets = dataSets => {
      return dataSets.map(x => x.previousData).filter(x => !!x)
    }

    getScale = rawDataSets => {
      const testDataSet = rawDataSets[0]
      if (!testDataSet) {
        // Currently you can't mix scales so we just use the first data set
        return DEFAULT_SCALE
      }
      return {
        ...testDataSet.scale,
        ...this.propScale,
      }
    }

    getDomain = (dataSets, gauges) => {
      const maxYValue = Math.max(
        ...dataSets.map(dataSet => {
          return Math.max(
            dataSet.data && Math.max(...dataSet.data.map(d => d.y)),
            (dataSet.previousData &&
              Math.max(...dataSet.previousData.map(d => d.y))) ||
              0
          )
        })
      )
      const minXValue = new Date(
        Math.min(
          ...gauges.map(gauge => {
            const { start } = gauge.timeframe
            return moment(start).toDate()
          })
        )
      )
      const maxXValue = new Date(
        Math.min(
          ...gauges.map(gauge => {
            const { end } = gauge.timeframe
            return moment(end)
              .startOf('day')
              .toDate()
          })
        )
      )
      // Math.max(...[]) produces -Infinity for some reason. Wat!?!
      const invalidMaxYValue =
        Math.abs(maxYValue) === Infinity || maxYValue === NaN
      return {
        x: [minXValue, maxXValue],
        y: [0, invalidMaxYValue ? 1 : maxYValue || 1],
      }
    }

    get gauges() {
      const { gauge, gauges = [gauge] } = this.props
      return gauges
    }

    get rawDataSets() {
      return this.getRawDataSets(this.props)
    }

    get dataSets() {
      return this.getDataSets(this.rawDataSets, this.scale)
    }

    get dataSetsWithPrevious() {
      return this.getDataSetsWithPrevious(this.dataSets)
    }

    get currentDataSets() {
      return this.getCurrentDataSets(this.dataSets)
    }

    get legendDataSets() {
      return this.getLegendDataSets(this.dataSets)
    }

    get previousDataSets() {
      return this.getPreviousDataSets(this.dataSets)
    }

    get scale() {
      return this.getScale(this.rawDataSets)
      // @TODO This cache will not clear on prop change
      // let scale = this.scaleCached
      // const testDataSet = this.rawDataSets[0]
      // if (scale) {
      //   return scale
      // } else if (!testDataSet) {
      //   // Currently you can't mix scales so we just use the first data set
      //   return DEFAULT_SCALE
      // }
      // scale = testDataSet.scale
      // this.scaleCached = {
      //   ...scale,
      //   ...this.propScale,
      // }
      // return this.scaleCached
    }

    get propScale() {
      const returnScale = {}
      const { scale: { x, y } = {} } = this.props
      if (!x && !y) return {}
      if (x) {
        returnScale.x = SCALE_TYPES[x] || x
        if (x === 'duration') returnScale.formatXAs = 'duration'
        if (x === 'percent') returnScale.formatXAs = 'percent'
      }
      if (y) {
        returnScale.y = SCALE_TYPES[y] || y
        if (y === 'duration') returnScale.formatYAs = 'duration'
        if (y === 'percent') returnScale.formatYAs = 'percent'
      }
      return returnScale
    }

    get domain() {
      return this.getDomain(this.dataSets, this.gauges)
    }

    render() {
      const { gauge, gauges = [gauge] } = this.props
      return (
        <ComposedComponent
          {...this.props}
          currentDataSets={this.currentDataSets}
          dataSets={this.dataSets}
          domain={this.domain}
          dataSetsWithPrevious={this.dataSetsWithPrevious}
          gauge={null}
          gauges={gauges}
          legendDataSets={this.legendDataSets}
          previousDataSets={this.previousDataSets}
          scale={this.scale}
        />
      )
    }
  }
}
