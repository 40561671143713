import { createPayload } from 'optimistic/base'

export const followPayload = (
  state,
  ticketId,
  folderId,
  search,
  follow,
  followerIds,
  currentUserId
) => {
  const amendedFollowerIds = follow
    ? [...followerIds, currentUserId]
    : [...followerIds]
  const index = amendedFollowerIds.indexOf(currentUserId)
  if (!follow && index > -1) {
    amendedFollowerIds.splice(index, 1)
  }
  return createPayload(
    state,
    ticketId,
    'follow',
    {
      follower_ids: amendedFollowerIds,
    },
    [],
    folderId,
    search
  )
}
