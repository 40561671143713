import config from './config'

export default function CountDownCell(props) {
  let { value: seconds } = props
  let color = config.defaultColor
  seconds = Math.floor(seconds)
  const days = Math.floor(seconds / (3600 * 24))
  seconds -= days * 3600 * 24
  const hours = Math.floor(seconds / 3600)
  seconds -= hours * 3600
  const minutes = Math.floor(seconds / 60)
  seconds -= minutes * 60
  const tmp = []
  if (days) {
    tmp.push(`${days}d`)
  }
  if (days || hours) {
    tmp.push(`${hours}h`)
  }
  if (days || hours || minutes) {
    tmp.push(`${minutes}m`)
  }
  if (days || hours || minutes || seconds) {
    tmp.push(`${seconds}s`)
  }
  if (tmp.length === 0) {
    tmp.push('TBD')
    color = 'grey'
  }
  return <span style={{ color }}>{tmp.join(' ')}</span>
}
