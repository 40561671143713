export const BOOTSTRAP_WIDGETS = 'widgets/BOOTSTRAP'
export const FETCH_WIDGETS = 'widgets/FETCH'
export const FETCH_WIDGETS_REQUEST = 'widgets/FETCH_REQUEST'
export const FETCH_WIDGETS_SUCCESS = 'widgets/FETCH_SUCCESS'
export const FETCH_WIDGETS_FAILED = 'widgets/FETCH_FAILED'
export const FETCH_WIDGET_BYID_STARTED = 'widgets/FETCH_BYID_STARTED'
export const FETCH_WIDGET_BYID_SUCCESS = 'widgets/FETCH_BYID_SUCCESS'
export const FETCH_WIDGET_BYID_FAILED = 'widgets/FETCH_BYID_FAILED'
export const FETCH_WIDGET_BYID = 'widgets/FETCH_BYID'
export const FETCH_WIDGET_SETTINGS_REQUEST = 'widgets/FETCH_SETTINGS_REQUEST'
export const FETCH_WIDGET_SETTINGS_SUCCESS = 'widgets/FETCH_SETTINGS_SUCCESS'
export const FETCH_WIDGET_SETTINGS_FAILURE = 'widgets/FETCH_SETTINGS_FAILURE'
export const FETCH_WIDGET_SNIPPET_REQUEST = 'widgets/FETCH_SNIPPET_REQUEST'
export const FETCH_WIDGET_SNIPPET_SUCCESS = 'widgets/FETCH_SNIPPET_SUCCESS'
export const FETCH_WIDGET_SNIPPET_FAILURE = 'widgets/FETCH_SNIPPET_FAILURE'
export const UPDATE_WIDGET_SETTINGS = 'widgets/UPDATE_SETTINGS'
export const UPDATE_WIDGET_SETTINGS_STARTED = 'widgets/UPDATE_SETTINGS_STARTED'
export const UPDATE_WIDGET_SETTINGS_SUCCESS = 'widgets/UPDATE_SETTINGS_SUCCESS'
export const UPDATE_WIDGET_SETTINGS_FAILED = 'widgets/UPDATE_SETTINGS_FAILED'
export const PUBLISH_WIDGET_SETTINGS = 'widgets/PUBLISH_SETTINGS'
export const PUBLISH_WIDGET_SETTINGS_STARTED =
  'widgets/PUBLISH_SETTINGS_STARTED'
export const PUBLISH_WIDGET_SETTINGS_SUCCESS =
  'widgets/PUBLISH_SETTINGS_SUCCESS'
export const PUBLISH_WIDGET_SETTINGS_FAILED = 'widgets/PUBLISH_SETTINGS_FAILED'
export const REVERT_WIDGET_SETTINGS_REQUEST = 'widgets/REVERT_SETTINGS_REQUEST'
export const REVERT_WIDGET_SETTINGS_SUCCESS = 'widgets/REVERT_SETTINGS_SUCCESS'
export const REVERT_WIDGET_SETTINGS_FAILURE = 'widgets/REVERT_SETTINGS_FAILURE'
export const CREATE_WIDGET_STARTED = 'widgets/CREATE_STARTED'
export const CREATE_WIDGET_SUCCESS = 'widgets/CREATE_SUCCESS'
export const CREATE_WIDGET_FAILED = 'widgets/CREATE_FAILED'
export const CREATE_WIDGET = 'widgets/CREATE'
export const UPDATE_WIDGET = 'widgets/UPDATE'
export const UPDATE_WIDGET_STARTED = 'widgets/UPDATE_STARTED'
export const UPDATE_WIDGET_SUCCESS = 'widgets/UPDATE_SUCCESS'
export const UPDATE_WIDGET_FAILED = 'widgets/UPDATE_FAILED'
export const DELETE_WIDGET = 'widgets/DELETE'
export const DELETE_WIDGET_STARTED = 'widgets/DELETE_STARTED'
export const DELETE_WIDGET_SUCCESS = 'widgets/DELETE_SUCCESS'
export const DELETE_WIDGET_FAILED = 'widgets/DELETE_WIDGET_FAILED'
export const UPDATE_WIDGET_PREVIEW_MESSAGE = 'widgets/UPDATE_PREVIEW_MESSAGE'
export const CREATE_DRAFT_WIDGET_REQUEST = 'widgets/CREATE_DRAFT_WIDGET_REQUEST'
export const CREATE_DRAFT_WIDGET_SUCCESS = 'widgets/CREATE_DRAFT_WIDGET_SUCCESS'
export const UPDATE_DRAFT_WIDGET = 'widgets/UPDATE_DRAFT_WIDGET'
export const DESTROY_DRAFT_WIDGET = 'widgets/DESTROY_DRAFT_WIDGET'
