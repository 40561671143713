import { compose } from 'redux'

import HeadlineView from './HeadlineView'
import withDecoratedGauges from '../../enhancers/withDecoratedGauges'
import withAutoFetchGauges from '../../enhancers/withAutoFetchGauges'
import withLoadingAndErroredProps from '../../enhancers/withLoadingAndErroredProps'

export default compose(
  withDecoratedGauges,
  withAutoFetchGauges,
  withLoadingAndErroredProps
)(HeadlineView)
