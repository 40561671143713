import { averageRepliesPerResolutionGauge } from 'ducks/reports/gauges'
import { HeadlineVisualization } from 'ducks/reports/visualizations'

const staticProps = {
  gauges: [averageRepliesPerResolutionGauge],
  title: '$Avg. replies to resolution',
  negativeChangeIsGood: true,
}
export default function AverageRepliesPerResolution(props) {
  return <HeadlineVisualization {...props} {...staticProps} />
}
