import { connect } from 'react-redux'

import { selectAccountPreferredReportsAverage } from 'selectors/app/base'
import { doActivateGauge } from '../../actions'
import { selectActiveGauge } from '../../selectors'
import HeadlineChartSelectorView from './HeadlineChartSelectorView'

const PRODUCTIVITY_GAUGE_SWITCH = 'PRODUCTIVITY_GAUGE_SWITCH'
const PRODUCTIVITY_GAUGE_SWITCH_PROPS = {
  gaugeSwitchInstance: PRODUCTIVITY_GAUGE_SWITCH,
}

const perform = {
  doActivateGauge: (event, { value }) => {
    return doActivateGauge(PRODUCTIVITY_GAUGE_SWITCH, value)
  },
}

const select = state => ({
  average: selectAccountPreferredReportsAverage(state),
  activeGauge: selectActiveGauge(state, PRODUCTIVITY_GAUGE_SWITCH_PROPS),
  ...PRODUCTIVITY_GAUGE_SWITCH_PROPS,
})

export default connect(select, perform)(HeadlineChartSelectorView)
