import { compose } from 'redux'

import PercentGaugeView from './PercentGaugeView'
import withAutoFetchGauges from '../../enhancers/withAutoFetchGauges'
import withDataForVisualizations from '../../enhancers/withDataForVisualizations'
import withLoadingAndErroredProps from '../../enhancers/withLoadingAndErroredProps'

export default compose(
  withAutoFetchGauges,
  withDataForVisualizations,
  withLoadingAndErroredProps
)(PercentGaugeView)
