import React from 'react'
import PropTypes from 'prop-types'

import { Popup } from 'util/ui'

import formatDataPointValueAs from '../../util/formatDataPointValueAs'

export function formatTooltipValue(value, scale = {}) {
  return `Previous period: ${formatDataPointValueAs(
    scale.formatYAs || scale.y,
    value,
    true
  )}`
}

Tooltip.propTypes = {
  children: PropTypes.any.isRequired,
  previous: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
}

export default function Tooltip({ children, previous, scale }) {
  return (
    <Popup
      content={formatTooltipValue(previous, scale)}
      inverted
      position="bottom center"
      on="hover"
      trigger={children}
    />
  )
}
