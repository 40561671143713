import single from '../calculators/single'
import { averageFirstResponseTime } from '../queries/averageFirstResponseTime'
import { averageFirstResponseTimeWithinBusinessHours } from '../queries/averageFirstResponseTimeWithinBusinessHours'

export const averageFirstResponseTimeGauge = {
  businessHoursQueries: [averageFirstResponseTimeWithinBusinessHours],
  calculate: single,
  description:
    "The $average amount of time it takes to reply to a conversation's initial message.",
  isNegativeChangeGood: true,
  queries: [averageFirstResponseTime],
  title: '$Average First Response Time',
}
