import { countMessagesSent } from '../queries/countMessagesSent'

export const totalMessagesSentByAgentGauge = {
  id: 'message_sent_by_agent',
  queries: [
    {
      ...countMessagesSent,
      groupBy: 'action.actor.user.id',
      key: 'countMessagesSentByActor',
    },
  ],
  title: `Emails Sent By ${app.t('Agent')}`,
}
