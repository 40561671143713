import PropTypes from 'prop-types'
import React from 'react'
import Link from 'redux-first-router-link'
import { v4 as uuidV4 } from 'uuid'

import SpacedItems from 'shared/components/ui/SpacedItems'
import { Button, Icon } from 'util/ui'

import { AGENTS_PAGE } from 'ducks/reports/agents'
import { ConversationHistoryTable } from 'ducks/reports/conversations'
import { ReportsLayout } from 'ducks/reports/layout'
import * as CustomPropTypes from 'ducks/reports/propTypes'

import BusiestTimes from '../BusiestTimes'
import ConversationsAssignedOverview from '../ConversationsAssignedOverview'
import ProductivityOverview from '../ProductivityOverview'
import Header from './Header'
import NetDiffOpenConversations from './NetDiffOpenConversations'
import styles from './styles.less'

const conversationHistoryTableId = uuidV4()

export default class AgentPageView extends React.PureComponent {
  static propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    agent: PropTypes.object,
    queryBase: CustomPropTypes.queryBase,
    loading: PropTypes.bool,
    onMount: PropTypes.func,
  }

  static defaultProps = {
    agent: null,
    queryBase: {},
    loading: null,
    onMount: null,
  }

  componentDidMount() {
    const { onMount } = this.props
    if (onMount) onMount()
  }

  componentDidUpdate() {
    const { agent, loading, doNotFound } = this.props
    if (!agent && loading === false) {
      doNotFound()
    }
  }

  render() {
    const {
      agent,
      customersHelped,
      loading,
      queryBase,
      resolutionsQueryBase,
    } = this.props

    return (
      <ReportsLayout>
        <SpacedItems.Container direction="vertical" gap="ginormous">
          <SpacedItems.Item>
            <Link to={{ type: AGENTS_PAGE }}>
              <Button basic size="large" className={styles.button}>
                <Icon name="arrow left" className={styles.buttonIcon} />
                Back to {app.t('agents')} overview
              </Button>
            </Link>
          </SpacedItems.Item>
          {loading && <SpacedItems.Item>Loading...</SpacedItems.Item>}
          {!loading &&
            agent && (
              <React.Fragment>
                <SpacedItems.Item gap="large">
                  <Header agent={agent} customersHelped={customersHelped} />
                </SpacedItems.Item>
                <SpacedItems.Item gap="large">
                  <ConversationsAssignedOverview
                    queryBase={queryBase}
                    resolutionsQueryBase={resolutionsQueryBase}
                    netDiffComponent={
                      <NetDiffOpenConversations
                        includePrevious
                        queryBase={queryBase}
                      />
                    }
                  />
                </SpacedItems.Item>
                <SpacedItems.Item>
                  <ProductivityOverview
                    queryBase={queryBase}
                    resolutionsQueryBase={resolutionsQueryBase}
                  />
                </SpacedItems.Item>
                <SpacedItems.Item>
                  <ConversationHistoryTable
                    excludeAgentColumn
                    id={conversationHistoryTableId}
                    queryBase={queryBase}
                    title="Recent Ratings"
                  />
                </SpacedItems.Item>
                <SpacedItems.Item>
                  <BusiestTimes queryBatch="busiest" queryBase={queryBase} />
                </SpacedItems.Item>
              </React.Fragment>
            )}
        </SpacedItems.Container>
      </ReportsLayout>
    )
  }
}
