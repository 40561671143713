export const messageAction = ({
  id,
  body,
  quotedText,
  from,
  cc,
  bcc,
  to = {},
  // eslint-disable-next-line camelcase
  assigned_group_id,
  assignee,
  state,
  isNote,
  isForwarding,
  deliverBy,
  conversationType,
}) => ({
  change_type: 'Message',
  change: {
    id,
    quotedText,
    from,
    cc,
    bcc,
    to,
    assigned_group_id,
    assignee,
    state,
    body,
    note: isNote,
    forward: isForwarding,
    agent_response: true,
    deliverBy,
    conversationType,
  },
  preview: [],
})

export const statusAction = newState => ({
  change_type: 'State',
  change: {
    state: newState,
    type: 'State',
  },
})

export const assignAgentAction = assignee => ({
  change_type: 'Agent',
  change: {
    type: 'Agent',
    email: assignee ? assignee.email : null,
    id: assignee ? assignee.id : null,
  },
})

export const assignGroupAction = (id = null) => ({
  change_type: 'Group',
  change: {
    id,
    type: 'Group',
  },
})

export const snoozeAction = (until = null) => ({
  change_type: 'Snooze::State',
  change: {
    until,
    type: 'Snooze::State',
  },
})

export const unsnoozeAction = () => ({
  change_type: 'Unsnooze::State',
  change: {
    type: 'Unsnooze::State',
  },
})

export const titleAction = (title = '') => ({
  change_type: 'Title',
  change: {
    title,
    type: 'Title',
  },
})

export const assigneesDiffer = (ticket, draft) => {
  if (!ticket.assignee || !draft.assignee) {
    return ticket.assignee !== draft.assignee // compare if both null
  }
  return ticket.assignee.id !== draft.assignee.id
}
