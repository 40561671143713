import { doApiPutRequest } from 'ducks/requests/operations'

import { BUSINESS_HOURS_SAVE } from '../types'
import { serializeBusinessHours } from '../util'

export const doBusinessHoursSave = (
  businessHours,
  options = {}
) => dispatch => {
  return dispatch(
    doApiPutRequest(
      BUSINESS_HOURS_SAVE,
      `/v2/reports/business_hours`,
      serializeBusinessHours(businessHours),
      {
        moduleOptions: {
          toasts: {
            enabled: true,
            started: {
              enabled: false,
            },
            success: {
              enabled: true,
              content: 'Business hours saved',
            },
            failed: {
              content: 'Business hours save failed',
              onClickAction: () =>
                dispatch(doBusinessHoursSave(businessHours, options)),
            },
          },
        },
        ...options,
      }
    )
  )
}
export default doBusinessHoursSave
