import { connect } from 'react-redux'

import { selectQueryBase } from 'ducks/reports/controls'
import { selectKnowledgeBaseTotal } from 'subapps/kb/selectors/knowledge_bases'

import PageView from './PageView'

const select = state => ({
  queryBase: selectQueryBase(state),
  isEmpty: selectKnowledgeBaseTotal(state) === 0,
})

export default connect(select)(PageView)
