import { connect } from 'react-redux'

import { selectAverageFirstResponseTimeForEachDayGauge } from 'ducks/reports/gauges'

import { AreaChart } from 'ducks/reports/visualizations'

const staticSelect = {
  height: 300,
  scale: { y: 'duration' },
}
const select = (state, props) => ({
  ...staticSelect,
  gauge: selectAverageFirstResponseTimeForEachDayGauge(state, props),
})
export default connect(select)(AreaChart)
