import { connect } from 'react-redux'
import { createSelector } from 'reselect'

import {
  BarChartVisualization,
  grooveTheme,
} from 'ducks/reports/visualizations'
import {
  selectHappinessAwesomeForEachDayGauge,
  selectHappinessOkForEachDayGauge,
  selectHappinessBadForEachDayGauge,
} from 'ducks/reports/gauges'
import { asArray } from 'util/selectors'
import { capitalize } from 'util/strings'

const { dust, green, red } = grooveTheme.colors

const staticSelect = {
  colorScale: [green, dust, red],
  labelFormatter: (item, itemGauge, gauges) => {
    const timeframeValues = []
    gauges.forEach(set => {
      if (set && set.result) {
        let timeValueItem = {
          value: 0,
        }
        if (set.result) {
          timeValueItem = set.result.find(
            setItem =>
              setItem.timeframe.start === item.timeframe.start &&
              setItem.timeframe.end === item.timeframe.end
          )
        }
        const title = set.title
          .split(' ')
          .map(t => capitalize(t.toLowerCase()))
          .join(' ')

        timeframeValues.push(
          `${title} = ${(timeValueItem && timeValueItem.value) || 0}`
        )
      }
    })
    return timeframeValues.join('\n')
  },
}
const selectGauges = createSelector(
  selectHappinessAwesomeForEachDayGauge,
  selectHappinessOkForEachDayGauge,
  selectHappinessBadForEachDayGauge,
  asArray
)
const select = (state, props) => ({
  ...staticSelect,
  gauges: selectGauges(state, props),
})
export default connect(select)(BarChartVisualization)
