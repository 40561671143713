import cn from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import { Header, SubHeader } from 'shared/components/ui/Typography'
import Pager from 'shared/components/ui/Pager'
import { propFunc } from 'util/functions'
import { Grid, Popup, Table } from 'util/ui'
import grooveTheme from '../../grooveTheme'
import replaceAverage from '../../util/replaceAverage'
import formatDatum from '../../util/formatDatum'
import TablePageSizeSelector from '../Table/TablePageSizeSelector'
import styles from './styles.less'

TableView.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
}

TableView.defaultProps = {
  subtitle: null,
  title: null,
}

export default function TableView(props) {
  const {
    average,
    columnWidths,
    chart: { component: ChartComponent, props: chartProps } = {},
    dataSets,
    doSetSort,
    queryBatch,
    queryBase,
    loaded,
    pagination,
    sort: { defaultSortBy, direction, by: currentSortBy },
    subtitle,
    title,
    xValues: xValuesProp,
    className,
  } = props
  const xValues = xValuesProp || []
  const {
    customizable,
    doSetPage,
    doSetPageSize,
    lite,
    minPerPage = true,
    page,
    perPage,
    totalCount,
  } =
    pagination || {}
  const singlePage = totalCount <= perPage
  const minPerPageOrPerPage = minPerPage === true ? perPage : minPerPage
  const minHeight = singlePage && totalCount ? totalCount : minPerPageOrPerPage
  const minRows = Math.max(minHeight || 0, 3) - xValues.length
  const emptyRows = minRows > 0
  return (
    <Grid className={className}>
      {(title || subtitle) && (
        <Grid.Row columns={1}>
          <Grid.Column>
            {title && (
              <Header as="h3" className={styles.TableView_Header} size="small">
                {replaceAverage(title, average)}
              </Header>
            )}
            {subtitle && (
              <SubHeader
                as="h4"
                className={styles.TableView_SubHeader}
                size="small"
              >
                {replaceAverage(subtitle, average)}
              </SubHeader>
            )}
          </Grid.Column>
        </Grid.Row>
      )}
      <Grid.Row columns={ChartComponent ? 2 : 1}>
        <Grid.Column width={ChartComponent ? 6 : 16}>
          <Table className={styles.Table} selectable sortable>
            <Table.Header>
              <Table.Row>
                {dataSets.map(
                  (
                    {
                      description,
                      id,
                      name: dataSetName,
                      sort,
                      sort: { direction: dataSetDirection } = {},
                      sortKey = id,
                    },
                    i
                  ) => {
                    const sorted =
                      // eslint-disable-next-line no-nested-ternary
                      (currentSortBy && sortKey === currentSortBy) ||
                      (!currentSortBy && defaultSortBy === sortKey)
                        ? (direction || dataSetDirection) === 'ASC'
                          ? 'ascending'
                          : 'descending'
                        : null
                    const cell = (
                      <Table.HeaderCell
                        className={cn(
                          { [styles.sortable]: sort !== false },
                          styles.Table_HeaderCell,
                          'sorted'
                        )}
                        direction={direction}
                        key={[id, dataSetName]}
                        onClick={
                          sort !== false &&
                          propFunc(
                            doSetSort,
                            sortKey,
                            dataSetDirection === 'ASC'
                          )
                        }
                        sorted={sorted}
                        width={columnWidths && columnWidths[i]}
                      >
                        {replaceAverage(dataSetName, average)}
                      </Table.HeaderCell>
                    )
                    return description ? (
                      <Popup
                        content={description}
                        horizontalOffset={-10}
                        inverted
                        position="top left"
                        trigger={cell}
                      />
                    ) : (
                      cell
                    )
                  }
                )}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {xValues.length > 0 &&
                xValues.map((x, rowI) => {
                  return (
                    <Table.Row key={x}>
                      {dataSets.map(
                        ({ dataByX, empty, fill, id, scale }, cellI) => {
                          const datum = dataByX[x]
                          return (
                            <Table.Cell
                              className={cn({
                                [styles.firstCell]: cellI === 0,
                              })}
                              key={[cellI, id, x]}
                              width={columnWidths && columnWidths[cellI]}
                              style={{
                                borderLeftColor:
                                  ChartComponent &&
                                  grooveTheme.colors.scale[
                                    rowI % grooveTheme.colors.scale.length
                                  ],
                              }}
                            >
                              {formatDatum(datum, {
                                empty,
                                fill,
                                scale,
                                x,
                                dataByX,
                              })}
                            </Table.Cell>
                          )
                        }
                      )}
                    </Table.Row>
                  )
                })}
              {emptyRows &&
                Array(minRows)
                  .fill(minRows)
                  .map((x, i) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <Table.Row key={i}>
                      <Table.Cell
                        className={cn('filler', {
                          [styles.noData]: !xValues.length && i === 0,
                        })}
                        colSpan={dataSets.length}
                      >
                        {!xValues.length && i === 0
                          ? 'No Data to Show'
                          : '\u00A0'}
                      </Table.Cell>
                    </Table.Row>
                  ))}
            </Table.Body>
            {perPage &&
              totalCount > perPage && (
                <Table.Footer>
                  <Table.Row>
                    <Table.HeaderCell colSpan={dataSets.length}>
                      {loaded && !singlePage ? (
                        <Pager
                          activePage={page}
                          lite={lite}
                          onPageChange={doSetPage}
                          perPage={perPage}
                          totalCount={totalCount}
                        />
                      ) : (
                        '\u00A0'
                      )}
                      {customizable && (
                        <TablePageSizeSelector onChange={doSetPageSize} />
                      )}
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Footer>
              )}
          </Table>
        </Grid.Column>
        {ChartComponent && (
          <Grid.Column width={10}>
            <div className={styles.Chart}>
              <ChartComponent
                {...chartProps}
                loadable={loaded}
                queryBatch={queryBatch}
                queryBase={queryBase}
                xValues={xValues}
                xValueTranslations={xValues.reduce((reduction, x) => {
                  const { dataByX } = dataSets[0] // Huge assumption that the first data set is an entity data set
                  // eslint-disable-next-line no-param-reassign
                  reduction[x] = (dataByX && dataByX[x] && dataByX[x].y) || x
                  return reduction
                }, {})}
              />
            </div>
          </Grid.Column>
        )}
      </Grid.Row>
    </Grid>
  )
}
