import { daysInTimeframe } from 'ducks/reports/queries'

export default function averageIntervals(results, { queries }) {
  const { timeframe } = queries[0]
  const days = daysInTimeframe(timeframe)
  return [
    results.reduce(
      (reduction, result) => {
        reduction.y += Number.parseFloat(result.y) / days
        return reduction
      },
      { y: 0 }
    ),
  ]
}
