import { compose } from 'redux'
import TableView from './TableView'

import withAutoFetchGauges from '../../enhancers/withAutoFetchGauges'
import withPagableGauges from '../../enhancers/withPagableGauges'
import withDecoratedGauges from '../../enhancers/withDecoratedGauges'
import withSortableGauges from '../../enhancers/withSortableGauges'
import withLoadingAndErroredStates from '../../enhancers/withLoadingAndErroredStates'

export default compose(
  withPagableGauges,
  withSortableGauges,
  withDecoratedGauges,
  withAutoFetchGauges,
  withLoadingAndErroredStates
)(TableView)
