import { createPayload } from 'optimistic/base'
import { selectAgentsById } from 'selectors/agents/base'
import { selectTicketsById } from 'selectors/tickets/byId/selectTicketsById'
import { sortByKey } from 'util/arrays'
import { optimisticUpdatedAt } from 'util/optimistic'
import { toServerString } from 'util/date'

// builds an agent/group assignment action payload
export const assignmentPayload = (
  state,
  ticketId,
  groupId,
  agentId,
  folderId,
  search,
  updatedAt = toServerString(new Date())
) => {
  const ticket = selectTicketsById(state)[ticketId]
  const currentAgentId = ticket.assignee && ticket.assignee.id
  const currentGroupId = ticket.assigned_group_id
  const agent = agentId ? selectAgentsById(state)[agentId] : null
  const data = {
    assigned_group_id: groupId,
    updated_at: updatedAt,
  }
  const actions = []
  if (agentId !== currentAgentId) {
    actions.push({
      change_type: 'Agent',
      change: {
        type: 'Agent',
        email: agent && agent.email,
        id: agent && agent.id,
      },
    })
  }
  if (groupId !== currentGroupId) {
    actions.push({
      change_type: 'Group',
      change: {
        type: 'Group',
        id: groupId,
      },
    })
  }
  data.assignee = agent
  return createPayload(
    state,
    ticketId,
    'assign',
    data,
    actions,
    folderId,
    search
  )
}

// Returns the optimistic data for a bulk assignment action
export const bulkAssignmentPayload = (
  state,
  ticketIds,
  groupId,
  agentId,
  folderId,
  search
) =>
  sortByKey(
    // responses come back ordered by id
    ticketIds.map(id => {
      return assignmentPayload(
        state,
        id,
        groupId,
        agentId,
        folderId,
        search,
        optimisticUpdatedAt()
      )
    }),
    'id'
  )
