import { firstInBurst, notResponseToClosed } from './filters'

export const averageResponseTimeWithinBusinessHours = {
  analysisType: 'average',
  eventCollection: 'message.sent',
  filters: [firstInBurst, notResponseToClosed],
  key: 'averageResponseTimeWithinBusinessHours',
  targetProperty:
    'time.since_first_last_message_received_within_business_hours',
}
