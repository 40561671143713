import React from 'react'
import TicketDateTime from 'components/TicketDateTime'
import { Tooltip } from 'shared/ui'
import styles from './styles.less'

export default function TicketPreviewCommentHeader({
  authorName,
  authorEmail,
  createdAt,
  cc,
  bcc,
}) {
  return (
    <div className={styles.header}>
      <Tooltip tooltip={authorEmail} position="right" className={styles.author}>
        {authorName}
      </Tooltip>
      <div className={styles.time}>
        <TicketDateTime date={createdAt} />
      </div>
      {cc &&
        bcc && (
          <div className={styles.ccAndBcc}>
            {cc && <p>cc: {cc}</p>}
            {bcc && <p>bcc: {bcc}</p>}
          </div>
        )}
    </div>
  )
}
