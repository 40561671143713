import { createSelector } from 'reselect'

import { selectAgents } from 'selectors/agents/base'

const selectAgentsForReports = createSelector(selectAgents, agents => {
  return agents.map(agent => ({
    'agent.id': agent.id,
    result: agent.name,
  }))
})

export const agentNameByAgentGauge = {
  id: 'agent_name_by_agent',
  queries: [
    {
      id: 'agent_names_selected_from_state',
      filterScope: (result, query) => {
        const { scope: { agentIds = false } = {} } = query
        if (!agentIds.includes) return result
        return result.filter(x => agentIds.includes(x['agent.id']))
      },
      groupBy: 'agent.id',
      loader: () => {
        return (dispatch, getState) => {
          return { result: selectAgentsForReports(getState()) }
        }
      },
    },
  ],
  sort: { direction: 'ASC' },
  title: app.t('Agents'),
}
