import {
  FETCH_KB_PREVIEW_ARTICLE_STARTED,
  FETCH_KB_PREVIEW_ARTICLE_SUCCESS,
  FETCH_KB_PREVIEW_ARTICLE_FAILED,
} from '../../types'

const reducers = {}
export const defaultState = {
  isLoading: false,
  isLoaded: false,
  hasError: false,
  id: null,
  data: {},
}

reducers[FETCH_KB_PREVIEW_ARTICLE_STARTED] = (state, action) => {
  const { meta: { requestParameters: { articleId = null } = {} } = {} } = action

  return {
    ...state,
    isLoading: true,
    id: articleId,
  }
}

reducers[FETCH_KB_PREVIEW_ARTICLE_SUCCESS] = (state, action) => {
  const {
    payload: { article },
  } = action

  return {
    ...state,
    isLoading: false,
    isLoaded: true,
    hasError: false,
    data: article,
  }
}

reducers[FETCH_KB_PREVIEW_ARTICLE_FAILED] = (state, action) => {
  return {
    ...state,
    isLoading: false,
    hasError: true,
    error: action.payload.error,
  }
}

export default function reducer(state = defaultState, action) {
  // this is here because a long reducer with many ifs is unreadable
  const handler = reducers[action.type]
  if (handler) return handler(state, action)
  return state
}
