import { netDiffOpenAssignedAgentConversationsGauge } from 'ducks/reports/gauges'
import { HeadlineVisualization } from 'ducks/reports/visualizations'

const staticProps = {
  diff: true,
  gauges: [netDiffOpenAssignedAgentConversationsGauge],
  isNegativeGood: true,
  massive: true,
  suffix: 'change in opened conversations',
  title: 'Assigned vs Closed',
}

export default function NetDiffOpenConversations(props) {
  return <HeadlineVisualization {...props} {...staticProps} />
}
