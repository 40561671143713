export const countUniqueFailedSearchesOnSearchId = {
  analysisType: 'count_unique',
  eventCollection: 'kb.search.view',
  filters: [
    {
      propertyName: 'search.result_count',
      operator: 'eq',
      propertyValue: 0,
    },
  ],
  key: 'countUniqueFailedSearchesOnSearchId',
  targetProperty: 'search.id',
}
