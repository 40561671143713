import { ratio } from '../calculators/ratio'
import { countConversationsRatedUniqueCustomer } from '../queries/countConversationsRatedUniqueCustomer'
import { countMessageClosedSentUniqueCustomer } from '../queries/countMessageClosedSentUniqueCustomer'

export const customerLeftRatingRatioGauge = {
  calculate: ratio,
  description: `The percentage of ${app.t(
    'agent'
  )} responses that received a rating.`,
  queries: [
    countConversationsRatedUniqueCustomer,
    countMessageClosedSentUniqueCustomer,
  ],
  title: 'Customers who\n left a rating',
}
