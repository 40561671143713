import { connect } from 'react-redux'
import { NOT_FOUND } from 'redux-first-router'

import { selectScopedQueryBase } from 'ducks/reports/controls'
import {
  doFetchCustomersHelped,
  selectCustomersHelpedForTeam,
  selectCustomersHelpedLoading,
} from 'ducks/reports/customersHelped'
import { fetchingStatusesSelector, selectGroupsById } from 'selectors/app'

import TeamPageView from './TeamPageView'

const select = (state, { id }) => {
  return {
    customersHelped: selectCustomersHelpedForTeam(state, { id }),
    loading:
      fetchingStatusesSelector(state).fetchAppData ||
      selectCustomersHelpedLoading(state),
    queryBase: selectScopedQueryBase(state, {
      id,
      propertyName: 'conversation.assignee_team.id',
    }),
    team: selectGroupsById(state)[id],
  }
}

const perform = {
  doNotFound: () => ({
    type: NOT_FOUND,
  }),
  onMount: doFetchCustomersHelped,
}

export default connect(select, perform)(TeamPageView)
