import { connect } from 'react-redux'

import { doMarkAsUnread, doMarkAsRead } from 'actions/tickets'
import { selectTicketIsUnreadByTicketId } from 'selectors/tickets/state'

import View from './view'

const select = () => {
  return (state, props) => {
    return {
      isUnread: selectTicketIsUnreadByTicketId(state, props.ticketId),
    }
  }
}

const perform = {
  markAsRead: doMarkAsRead,
  markAsUnread: doMarkAsUnread,
}

export default connect(select, perform)(View)
