import React from 'react'

import { DAYS, TIMES } from './collapseData'

export default function BusiestCellAsString({ busiest }) {
  const timeValues = Object.values(TIMES)
  const dayValues = Object.values(DAYS)
  const cellTime = busiest % timeValues.length
  const busiestTime = Object.keys(TIMES)[
    timeValues.findIndex(([a, b]) => {
      return a <= cellTime * 3 && b >= cellTime * 3
    })
  ]
  const cellDay = Math.floor(busiest / timeValues.length)
  const busiestDay = Object.keys(DAYS)[
    dayValues.findIndex(x => {
      return x === cellDay + 1
    })
  ]
  if (!busiestTime || !busiestDay) return ''
  return (
    <React.Fragment>
      The busiest time is <strong>{busiestDay}</strong> between{' '}
      <strong>{busiestTime.replace('-', 'and')}</strong>.
    </React.Fragment>
  )
}
