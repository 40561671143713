import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { css } from '@emotion/react'
import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryContainer,
  VictoryGroup,
  VictoryLabel,
  VictoryLegend,
  VictoryPortal,
  VictoryTooltip,
} from 'victory'

import { all, any, uniqByProp } from 'util/arrays'

import grooveTheme from '../../grooveTheme'

const { lightPurple, dust, green } = grooveTheme.colors

const containerStyle = css`
  height: 100%;
  width: 100%;
`

// TODO Refactor this component to use all the HOC component properties
// instead of the local properties
export default class BarChartView extends React.PureComponent {
  static propTypes = {
    gauges: PropTypes.arrayOf(PropTypes.object).isRequired,
    labelFormatter: PropTypes.func,
    colorScale: PropTypes.arrayOf(PropTypes.object),
  }

  static defaultProps = {
    labelFormatter: (data, gauge) =>
      `${gauge.title} - ${data.timeframe.start}:  ${data.value}`,
    colorScale: [lightPurple, dust, green],
  }

  static domainPadding = 26

  componentDidUpdate() {
    this._categories = null
    this._datas = null
    this._showEveryTick = null
  }

  get datas() {
    if (!this._datas) {
      const { gauges, labelFormatter, timezone } = this.props
      this._datas = gauges
        .map((set, index) => {
          return (
            set &&
            set.result &&
            set.result
              .map(data => {
                const { timeframe, value } = data
                const mStart = moment.tz(timeframe.start, timezone)
                const dataPoint = {
                  x: mStart.format("D MMM 'YY"),
                  y: value,
                  index,
                  timestamp: mStart.unix(),
                }
                const label = labelFormatter(data, set, gauges)
                if (label) {
                  dataPoint.label = label
                }
                return dataPoint
              })
              .filter(x => !!x)
          )
        })
        .filter(x => x && x.length > 0)
    }
    return this._datas
  }

  get categories() {
    if (!this._categories) {
      const rawCats = uniqByProp([].concat(...this.datas), 'x')
      this._categories = {
        x: rawCats
          .sort((a, b) => a.timestamp - b.timestamp)
          .map(item => item.x),
      }
    }
    return this._categories
  }

  get legendData() {
    return this.props.gauges.map(gauge => {
      return {
        name: gauge.title,
        labels: grooveTheme.smallHeader,
      }
    })
  }

  get width() {
    return this.props.width
  }

  get padding() {
    return { top: 55, left: 26.5, right: 30, bottom: 30 }
  }

  get domainPaddingForBars() {
    return BarChartView.domainPadding + this.datas.length * this.barWidth / 2
  }

  get barWidth() {
    const sets = this.datas.length
    const maxSetSize = Math.max(...this.datas.map(x => x && x.length))
    const numberOfBars = sets * maxSetSize
    const totalBarsPlusSpaceBetween = (numberOfBars - sets) * 1.5 + sets
    const totalBarsPlusSpaceBetweenAndAtEnds = totalBarsPlusSpaceBetween + sets
    const maxWidth = this.widthLessPadding / totalBarsPlusSpaceBetweenAndAtEnds
    const minWidth = 24
    return maxWidth > minWidth ? minWidth : maxWidth
  }

  get widthLessPadding() {
    return (
      this.width -
      this.padding.left -
      this.padding.right -
      BarChartView.domainPadding * 2
    )
  }

  get loaded() {
    return all(x => x.loaded, this.props.gauges)
  }

  get loading() {
    return any(x => x.loading, this.props.gauges)
  }

  get errored() {
    return any(x => x.errored, this.props.gauges)
  }

  get showEveryTick() {
    if (!this._showEveryTick) {
      const numberOfCategories = this.categories.x.length
      const chartWidth = this.widthLessPadding
      const categoriesThatFit = chartWidth / 80
      this._showEveryTick = Math.round(numberOfCategories / categoriesThatFit)
    }
    return this._showEveryTick
  }

  tickFormat = (t, index) => {
    if (!this.categories.x) return t
    return index % this.showEveryTick === 0 || this.showEveryTick === 0 ? t : ''
  }

  render() {
    const { datas, loaded } = this
    // targetRef is from react-resize-detector (withResizeDetector)
    const { colorScale, domain, targetRef } = this.props

    return (
      <div css={containerStyle} ref={targetRef}>
        {loaded && (
          <VictoryChart
            containerComponent={<VictoryContainer responsive={false} />}
            domain={{ y: domain.y }}
            domainPadding={{ x: this.domainPaddingForBars }}
            height={this.props.height}
            padding={this.padding}
            theme={grooveTheme}
            scale={{ y: 'linear' }}
            width={this.width}
            style={{ parent: { overflow: 'visible !important' } }}
          >
            <VictoryPortal>
              <VictoryLabel text="a" y={-500} x={-500} style={{ opacity: 0 }} />
              <VictoryLabel text="a" y={3000} x={3000} style={{ opacity: 0 }} />
            </VictoryPortal>
            <VictoryLegend
              colorScale={colorScale}
              data={this.legendData}
              gutter={30}
              orientation="horizontal"
              padding={0}
              x={0}
              y={0}
            />
            <VictoryAxis offsetY={30.5} tickFormat={this.tickFormat} />
            <VictoryAxis crossAxis dependentAxis />
            <VictoryGroup
              labelComponent={
                <VictoryTooltip
                  theme={grooveTheme}
                  style={grooveTheme.tooltip.style}
                  labelComponent={<VictoryLabel lineHeight={1.3} />}
                />
              }
              offset={this.barWidth}
              style={{
                data: {
                  width: this.barWidth,
                  fill: item => {
                    if (item.y === 0) return 'transparent'
                    return colorScale[item.index % colorScale.length]
                  },
                },
              }}
            >
              {datas.map((data, index) => {
                return (
                  <VictoryBar
                    alignment="middle"
                    categories={this.categories}
                    cornerRadius={2}
                    data={data}
                    // no stable id for rendered items. ok as per https://reactjs.org/docs/lists-and-keys.html#keys
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                  />
                )
              })}
            </VictoryGroup>
          </VictoryChart>
        )}
      </div>
    )
  }
}
