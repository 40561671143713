import { createSelector } from 'reselect'
import { selectAgentsById } from 'selectors/agents/base'
import { selectGroupsById } from 'selectors/app'
import { AGENT_DETAIL_PAGE, TEAM_DETAIL_PAGE } from './pages'

export const selectIsOnAgentOverviewPage = state =>
  state.ducksPage.page === AGENT_DETAIL_PAGE

export const selectIsOnTeamOverviewPage = state =>
  state.ducksPage.page === TEAM_DETAIL_PAGE

function selectDuckPageProps(state) {
  if (state.ducksPage) return {}
  return state.ducksPage.props || {}
}

export const selectAgentOverviewName = createSelector(
  selectIsOnAgentOverviewPage,
  selectDuckPageProps,
  selectAgentsById,
  (isOnAgentOverview, { id: agentId }, agents) => {
    if (isOnAgentOverview && agents[agentId]) {
      return agents[agentId].name
    }
    return ''
  }
)

export const selectTeamOverviewName = createSelector(
  selectIsOnTeamOverviewPage,
  selectDuckPageProps,
  selectGroupsById,
  (isOnTeamOverview, { id: teamId }, teams) => {
    if (isOnTeamOverview && teams[teamId]) {
      return teams[teamId].name
    }
    return ''
  }
)
