import single from '../calculators/single'
import { averageResponseTime } from '../queries/averageResponseTime'
import { averageResponseTimeWithinBusinessHours } from '../queries/averageResponseTimeWithinBusinessHours'

export const averageResponseTimeGauge = {
  businessHoursQueries: [averageResponseTimeWithinBusinessHours],
  calculate: single,
  description: 'The $average amount of time it takes to reply to messages.',
  isNegativeChangeGood: true,
  queries: [averageResponseTime],
  title: '$Average Response Time',
}
