import { countArticleViews } from '../queries/countArticleViews'
import single from '../calculators/single'

export const articleViewsPerDayGauge = {
  calculate: single,
  queries: [
    {
      ...countArticleViews,
      interval: 'daily',
      key: 'countArticleViewsForEachDay',
    },
  ],
  title: 'Viewed Article',
}
