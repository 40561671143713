export function getTooltipDescription(state) {
  if (state === 'draft') return 'You cannot link articles in draft state'
  if (state === 'kb_disabled')
    return 'You cannot link articles in unpublished knowledge bases'
  return ''
}

export function getStateTitle(state) {
  if (state === 'kb_disabled') return 'Unpublished KB'
  if (state === 'wip') return 'modified'
  return state
}

export function isLinkDisabled(isAddingNote, state) {
  if (isAddingNote) return false
  return !['published', 'wip'].includes(state)
}

export function getArticleState(article = {}) {
  const { state, knowledgeBase: { enabled } = {} } = article
  return enabled ? state : 'kb_disabled'
}
