import React from 'react'

import { Overview12 } from 'ducks/reports/overviewLayouts'

import CustomerLeftRatingRatio from './CustomerLeftRatingRatio'
import HappinessForEachDay from './HappinessForEachDay'
import HappinessScoreRatio from '../HappinessScoreRatio'
import RatingByGradeProgressBar from './RatingByGradeProgressBar'

export default function HappinessOverview({ queryBatch, queryBase }) {
  return (
    <Overview12
      title="Happiness overview"
      components={[
        <HappinessForEachDay
          queryBatch={queryBatch}
          queryBatch={queryBatch}
          queryBase={queryBase}
        />,
        <HappinessScoreRatio
          queryBatch={queryBatch}
          queryBase={queryBase}
          includePreviousPeriod
        />,
        <CustomerLeftRatingRatio
          queryBatch={queryBatch}
          queryBase={queryBase}
          includePreviousPeriod
        />,
        <RatingByGradeProgressBar
          queryBatch={queryBatch}
          queryBase={queryBase}
        />,
      ]}
    />
  )
}
