import { connect } from 'react-redux'

import { selectQueryBase } from 'ducks/reports/controls'
import { selectQueryBaseExclFilterProperties } from 'ducks/reports/controls/selectors'

import PageView from './PageView'

const select = state => ({
  queryBase: selectQueryBase(state),
  queryBaseExclConversationOpenFor: selectQueryBaseExclFilterProperties(state, [
    'time.conversation_open_for',
  ]),
})

export default connect(select)(PageView)
