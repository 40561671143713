import { connect } from 'react-redux'

import { totalKbVisitsGauge } from 'ducks/reports/gauges'
import { HeadlineVisualization } from 'ducks/reports/visualizations'

const staticProps = {
  gauges: [totalKbVisitsGauge],
  title: 'Total Visits',
}

export default function TotalKbVisitsHeadline(props) {
  return <HeadlineVisualization {...props} {...staticProps} />
}
