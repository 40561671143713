/* eslint-disable no-param-reassign */
import { createActionTypeReducer } from 'util/reducers'
import {
  SEARCH_SYNC,
  FETCH_CONVERSATION_COUNTS_FOR_CHANNEL_SUCCESS,
} from '../actionTypes'

const lastUpdatedAtInitialState = null

const reducers = {}

reducers[FETCH_CONVERSATION_COUNTS_FOR_CHANNEL_SUCCESS] = (
  draftState,
  {
    payload: {
      data: { node },
    },
  }
) => {
  const {
    folders: { lastUpdatedAt },
  } = node
  if (draftState < lastUpdatedAt) draftState = lastUpdatedAt
  return draftState
}

reducers[SEARCH_SYNC] = (draftState, { payload: { lastUpdatedAt } }) => {
  // If search sync doesnt have a last updated at, then do nothing
  if (!lastUpdatedAt) return draftState
  // Last updated first needs to be set by FETCH_CONVERSATION_COUNTS_FOR_CHANNEL before
  // sync can start updating it
  if (!draftState) return draftState
  if (draftState < lastUpdatedAt) draftState = lastUpdatedAt
  return draftState
}

export const lastUpdatedAt = createActionTypeReducer(
  reducers,
  lastUpdatedAtInitialState
)
