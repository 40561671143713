import { doApiReadRequest, doApiWriteRequest } from 'ducks/requests/operations'

import {
  queryIdToQuery,
  constructApiV1SortBy,
} from 'ducks/searches/utils/query'

import { compact } from 'util/objects'

import { CREATE_SPAMMER, DELETE_SPAMMER, FETCH_SPAMMERS } from './types'

import {
  spammerApiV1ResponseSchema,
  spammersApiV1ResponseSchema,
} from './schema'
import { selectSpammerById } from './selectors'

export const doFetchSpammerV1 = id => dispatch => {
  return dispatch(
    doApiReadRequest(
      FETCH_SPAMMERS,
      `v1/incoming/spammers/${id}`,
      {},
      {
        normalizationSchema: spammerApiV1ResponseSchema,
      }
    )
  )
}

const ENTITY_TYPE = 'spammer'

export const doFetchSpammersV1 = ({ queryId }) => dispatch => {
  const { cursor = 1, pageSize = 5, search } = queryIdToQuery(queryId) || {}
  const sortBy = constructApiV1SortBy(queryId)

  const params = compact({
    keywords: search,
    page: cursor,
    per_page: pageSize,
    sort_by: sortBy,
  })

  return dispatch(
    doApiReadRequest(FETCH_SPAMMERS, 'v1/incoming/spammers', params, {
      normalizationSchema: spammersApiV1ResponseSchema,
      searches: {
        queryId,
        cursor,
        extractPagination: 'apiv1',
      },
    })
  )
}

export const doCreateSpammer = (email, options = {}) => dispatch => {
  const id = 'new'
  const entity = { id, email }
  const saveUrl = 'v1/incoming/spammers'
  const saveMethod = 'POST'
  const targetStores = ['pending', 'current']

  return dispatch(
    doApiWriteRequest(
      CREATE_SPAMMER,
      saveUrl,
      { email },
      {
        method: saveMethod,
        optimist: {
          entities: {
            [ENTITY_TYPE]: {
              [id]: entity,
            },
          },
        },
        normalizationSchema: spammerApiV1ResponseSchema,
        searches: {
          additionalActions: [
            {
              type: 'INVALIDATE_ENTITIES',
              entityTypes: [ENTITY_TYPE],
              phases: ['SUCCESS'],
            },
          ],
        },
        moduleOptions: {
          toasts: {
            enabled: true,
            started: {
              enabled: false,
            },
            success: {
              enabled: true,
              content: `Blocked ${email}`,
            },
            failed: {
              content: `Blocking ${email} failed`,
              onClickAction: () => {
                dispatch(doCreateSpammer(email, options))
              },
            },
          },
          entities: {
            additionalActions: [
              {
                entityType: ENTITY_TYPE,
                entityId: id,
                stores: targetStores,
                operation: 'remove',
                phases: ['SUCCESS'],
              },
            ],
          },
        },
      }
    )
  )
}

export const doDeleteSpammer = (id, options = {}) => (dispatch, getState) => {
  const state = getState()
  const spammer = selectSpammerById(state, id, 'current')

  return dispatch(
    doApiWriteRequest(
      DELETE_SPAMMER,
      `v1/incoming/spammers/${id}`,
      {},
      {
        method: 'DELETE',
        optimist: {},
        searches: {
          additionalActions: [
            {
              type: 'INVALIDATE_ENTITIES',
              entityTypes: [ENTITY_TYPE],
              phases: ['SUCCESS'],
            },
          ],
        },
        moduleOptions: {
          toasts: {
            enabled: true,
            started: {
              enabled: true,
              content: `${spammer.email} unblocked`,
            },
            success: {
              enabled: false,
            },
            failed: {
              content: 'Blacklist deletion failed',
              onClickAction: () => {
                dispatch(doDeleteSpammer(id, options))
              },
            },
          },
          entities: {
            targetOperation: 'remove',
            additionalActions: [
              {
                entityType: ENTITY_TYPE,
                entityId: id,
                stores: ['pending', 'current'],
                operation: 'remove',
                phases: ['STARTED'],
              },
            ],
          },
        },
      }
    )
  )
}
