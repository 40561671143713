import { all, emptyArr } from 'util/arrays'

function calculateGroups(queries) {
  if (
    all(
      ({ groupBy }) => !Array.isArray(groupBy) && !groupBy.match(/,/),
      queries
    )
  ) {
    return [emptyArr]
  }
  const groups = queries.reduce(
    (reduction, { result: results = [], groupBy }) => {
      const groupByAsArray = Array.isArray(groupBy)
        ? [...groupBy]
        : groupBy.split(',')
      const xGroup = groupByAsArray.shift()
      results.forEach(({ result, ...resultItem }) => {
        const group = { ...resultItem }
        delete group[xGroup]
        const groupValue = Object.keys(group).reduce((groupReduction, key) => {
          // eslint-disable-next-line no-param-reassign
          groupReduction[groupByAsArray.indexOf(key)] = group[key]
          return groupReduction
        }, [])
        // eslint-disable-next-line no-param-reassign
        reduction[JSON.stringify(groupValue)] = groupValue
      })
      return reduction
    },
    {}
  )
  return Object.values(groups)
}

function calculateXValues(queries) {
  return queries.reduce((reduction, { groupBy, result }) => {
    const groupByAsArray = Array.isArray(groupBy) ? groupBy : groupBy.split(',')
    const xGroup = groupByAsArray[0]
    if (result)
      result.forEach(resultItem => {
        const x = resultItem[xGroup]
        if (reduction.indexOf(x) === -1) reduction.push(x)
      })
    return reduction
  }, [])
}

function calculateData(
  queries,
  group,
  calculate = results => results && results[0],
  cap
) {
  const xValues = calculateXValues(queries)
  return xValues.map(x => {
    const resultItems = queries
      .map(({ groupBy, result }) => {
        const groupByAsArray = Array.isArray(groupBy)
          ? [...groupBy]
          : groupBy.split(',')
        const xGroup = groupByAsArray.shift()
        const foundResult = result.find(resultItem => {
          if (resultItem[xGroup] !== x) return false
          return all((groupKey, index) => {
            return resultItem[groupKey] === group[index]
          }, groupByAsArray)
        })
        return foundResult
      })
      .filter(result => !!result)
    const results = resultItems.map(({ result } = {}) => result)
    const calculatedResult = calculate(results)
    // Dear god this bug was hard to find. Object assigns merges all n+1 objects
    // into n. In our case this results in the "result" property of resultItems[0]
    // to be replaced with the result from the last item in resultItems.
    const otherValues =
      resultItems.length > 0 ? Object.assign(...[{}, ...resultItems]) : {}
    const y = cap ? Math.min(cap, calculatedResult) : calculatedResult
    return {
      meta: otherValues,
      x,
      y,
    }
  })
}

export default function generateGroupedDataSets(gauge) {
  const {
    calculate,
    cap,
    colorScale,
    description,
    empty,
    fill,
    id: gaugeId,
    includePrevious,
    isJoinedThrough,
    isNegativeChangeGood,
    isNegativeGood,
    loaded,
    nullIsValidX,
    previousLoaded,
    previousQueries = emptyArr,
    queries = emptyArr,
    title: name,
    totalCount,
    scale,
    sort,
    xValueTranslations,
  } = gauge
  const query = queries[0]

  if (!loaded) {
    return [
      {
        colorScale,
        data: false,
        description,
        empty,
        fill,
        id: gaugeId,
        isJoinedThrough,
        isNegativeChangeGood,
        isNegativeGood,
        name,
        nullIsValidX,
        queries,
        page: query && query.page,
        perPage: query && query.perPage,
        previousPeriod: includePrevious && {
          queries: previousQueries,
          data: false,
        },
        scale,
        sort,
        sortKey: gaugeId,
        totalCount,
        xValueTranslations,
      },
    ]
  }

  const groups = calculateGroups(queries)
  const isSingleGroup = groups.length === 1

  return groups.map(group => {
    const groupName = group.join(', ')
    const dataSetName = isSingleGroup ? name : groupName
    const id = `${gaugeId}${!isSingleGroup ? '-' : ''}${group.join(',')}`
    return {
      colorScale,
      data: calculateData(queries, group, calculate, cap),
      description,
      empty,
      fill,
      group,
      id,
      isJoinedThrough,
      isNegativeChangeGood,
      isNegativeGood,
      isSingleGroup,
      name: dataSetName,
      nullIsValidX,
      queries,
      page: query && query.page,
      perPage: query && query.perPage,
      previousPeriod: includePrevious && {
        queries: previousQueries,
        data:
          previousLoaded &&
          calculateData(previousQueries, group, calculate, cap),
      },
      scale,
      sort,
      sortKey: id,
      totalCount,
      xValueTranslations,
    }
  })
}
