import React from 'react'
import { css } from '@emotion/react'
import KbIcon from '@groovehq/internal-design-system/lib/assets/icons/KB'

import { SVGIcon, Tooltip } from 'shared/ui'
import NavItem from 'components/App/DesktopView/shared/Composer/Email/Header/NavItem'

import ToggleKbSearchDropdown from './Dropdown'

const iconStyle = css`
  height: 20px;
  color: inherit;
`

const idsIconStyle = theme => css`
  font-size: ${theme.fontSize.medium};
  margin-right: -1px;
`

const buttonStyle = () => theme => css`
  font-weight: ${theme.fontWeight.medium};
  font-size: ${theme.fontSize.small};
`

export default function ToggleKbSearchView({
  className,
  toggleKnowledgebaseToolbar,
  hasKnowledgebases,
  isOpen,
  size = 13,
  iconOnly = false,
  forceActive = true,
}) {
  const active = forceActive ? true : isOpen
  return (
    <ToggleKbSearchDropdown
      key="toggle-kb-search"
      onClick={toggleKnowledgebaseToolbar}
      hasKnowledgebases={hasKnowledgebases}
      isOpen={isOpen}
      trigger={
        <Tooltip
          tooltip={`${isOpen ? 'Hide' : 'Show'} knowledge base toolbar`}
          position="top"
        >
          <NavItem className={className} active={active}>
            {iconOnly ? (
              <SVGIcon name="knowledgeBase" size={size} css={[iconStyle]} />
            ) : (
              <div
                className="grui flex flex-1-0-auto flex-center"
                data-test-id="articles-button"
              >
                <KbIcon css={idsIconStyle} />
                <div className="grui ml-5" css={buttonStyle}>
                  Articles
                </div>
              </div>
            )}
          </NavItem>
        </Tooltip>
      }
    />
  )
}
