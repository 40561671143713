import moment from 'moment'
import React from 'react'

import Avatar from 'components/Avatar'
import { Segment } from 'util/ui'
import { Header, SubHeader } from 'shared/components/ui/Typography'

import styles from './styles.less'

export default function HeaderView({
  agent,
  agent: { created_at: createdAt, first_name: firstName, name } = {},
  customersHelped,
}) {
  return (
    <Segment className={styles.Header}>
      <div className={styles.avatar}>
        <Avatar width={86} height={83} borderRadius={4} actor={agent} />
      </div>
      <div className={styles.label}>
        <Header as="h1" size="medium" className={styles.avatarHeader}>
          {name}
        </Header>
        <SubHeader as="div" color="light" size="tiny">
          {firstName} joined on the{' '}
          <strong className={styles.strong}>
            {moment(createdAt).format('Do MMM, YYYY')}
          </strong>
          {customersHelped && [
            ' & has helped ',
            <strong className={styles.strong}>{customersHelped}</strong>,
            ' customers to date',
          ]}.
        </SubHeader>
      </div>
    </Segment>
  )
}
