import moment from 'moment'

import { formatNumberExact, numberToSize } from 'util/numbers'

export default function formatDataPointValueAs(as, value, exact) {
  if (as === 'duration') return formatDurationDataPoint(value, exact)
  if (as === 'percent') return formatPercentDataPoint(value, exact)
  if (as === 'date' || value instanceof Date)
    return formatDateDataPoint(value, exact)
  if (as === 'number' || as === 'linear' || typeof value === 'number')
    return formatNumberDataPoint(value, exact)
  if (value === null) return 'No Data'
  return value
}

function formatDurationDataPoint(value = 0) {
  return moment
    .duration(Number.parseFloat(value), 'seconds')
    .format('h[h] m[m] s[s]', {
      trim: 'both',
    })
}

function formatPercentDataPoint(value = 0, exact = false) {
  const exactThreshold = typeof exact === 'number' ? exact : 0
  const displayExact = value < exactThreshold / 100 || exact === true
  return displayExact
    ? `${formatNumberExact(Math.round(value * 10000) / 100)}%`
    : `${numberToSize(Math.round(value * 1000) / 10)}%`
}

function formatDateDataPoint(value) {
  if (value === null) return ''
  return moment(value).format("D MMM 'YY")
}

function formatNumberDataPoint(value = 0, exact = false) {
  const exactThreshold = typeof exact === 'number' ? exact : 0
  const displayExact = value < exactThreshold || exact === true
  return displayExact
    ? formatNumberExact(Math.round(value * 100) / 100)
    : numberToSize(value, 1)
}
