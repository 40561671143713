import React, { useEffect } from 'react'
import { v4 as uuidV4 } from 'uuid'
import { useDispatch, useSelector } from 'react-redux'
import { doFetchCustomerRatingSettings } from 'ducks/customerRatingSettings/operations'
import { selectCustomerRatingSettings } from 'ducks/customerRatingSettings/selectors/selectCustomerRatingSettings'

import SpacedItems from 'shared/components/ui/SpacedItems'

import { ReportsLayout } from 'ducks/reports/layout'
import * as CustomPropTypes from 'ducks/reports/propTypes'

import BusiestTimes from '../BusiestTimes'
import ConversationsOverview from '../ConversationsOverview'
import TagInsights from '../TagInsights'
import TeamLeaderBoardTable from '../TeamLeaderBoardTable'

const teamLeaderboardId = uuidV4()

Page.propTypes = {
  queryBase: CustomPropTypes.queryBase.isRequired,
}

export default function Page({ queryBase, queryBaseExclConversationOpenFor }) {
  const dispatch = useDispatch()
  const customerRatingSettings = useSelector(selectCustomerRatingSettings)

  useEffect(
    () => {
      dispatch(doFetchCustomerRatingSettings())
    },
    [dispatch]
  )

  return (
    <ReportsLayout>
      <SpacedItems.Container direction="vertical" gap="ginormous">
        <SpacedItems.Item>
          <ConversationsOverview
            queryBatch="conversation-overview"
            queryBase={queryBase}
          />
        </SpacedItems.Item>
        <SpacedItems.Item>
          <BusiestTimes queryBatch="busiest" queryBase={queryBase} />
        </SpacedItems.Item>
        <SpacedItems.Item>
          <TagInsights
            queryBatch="tag-insights"
            queryBase={queryBaseExclConversationOpenFor}
          />
        </SpacedItems.Item>
        <SpacedItems.Item>
          <TeamLeaderBoardTable
            queryBatch="leaderboard"
            id={teamLeaderboardId}
            queryBase={queryBase}
            customerRatingsInstalled={customerRatingSettings.length > 0}
          />
        </SpacedItems.Item>
      </SpacedItems.Container>
    </ReportsLayout>
  )
}
