import React from 'react'
import { capitalize } from 'util/strings'
import themeVars from 'ui_theme/site/globals/site.variables'

export const INT_STATE_COLOR_MAP = {
  unread: themeVars.berry,
  opened: themeVars.bio,
  closed: themeVars.sky,
  spam: themeVars.sea,
  DEFAULT: themeVars.dust,
}

export default function ColorStatusCell(props) {
  const { value } = props
  const color = INT_STATE_COLOR_MAP[value] || INT_STATE_COLOR_MAP.DEFAULT
  return <span style={{ color }}>{capitalize(value)}</span>
}
