export const FETCH_WEBHOOKS = 'webhooks/FETCH_WEBHOOKS'
export const FETCH_WEBHOOKS_REQUEST = 'webhooks/FETCH_WEBHOOKS_STARTED'
export const FETCH_WEBHOOKS_SUCCESS = 'webhooks/FETCH_WEBHOOKS_SUCCESS'
export const FETCH_WEBHOOKS_FAIL = 'webhooks/FETCH_WEBHOOKS_FAILED'

export const FETCH_WEBHOOK_BYID = 'webhooks/FETCH_WEBHOOK_BYID'
export const FETCH_WEBHOOK_BYID_REQUEST = 'webhooks/FETCH_WEBHOOK_BYID_STARTED'
export const FETCH_WEBHOOK_BYID_SUCCESS = 'webhooks/FETCH_WEBHOOK_BYID_SUCCESS'
export const FETCH_WEBHOOK_BYID_FAIL = 'webhooks/FETCH_WEBHOOK_BYID_FAILED'

export const DELETE_WEBHOOK = 'webhooks/DELETE_WEBHOOK'
export const DELETE_WEBHOOK_STARTED = 'webhooks/DELETE_WEBHOOK_STARTED'
export const DELETE_WEBHOOK_SUCCESS = 'webhooks/DELETE_WEBHOOK_SUCCESS'
export const DELETE_WEBHOOK_FAILED = 'webhooks/DELETE_WEBHOOK_FAILED'

export const SAVE_WEBHOOK = 'webhooks/SAVE_WEBHOOK'
export const SAVE_WEBHOOK_STARTED = 'webhooks/SAVE_WEBHOOK_STARTED'
export const SAVE_WEBHOOK_SUCCESS = 'webhooks/SAVE_WEBHOOK_SUCCESS'
export const SAVE_WEBHOOK_FAILED = 'webhooks/SAVE_WEBHOOK_FAILED'
