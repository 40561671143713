import { selectShowKbSearchOnInboxEditor } from 'ducks/currentUser/selectors/preferences/selectShowKbSearchOnInboxEditor'
import { doUpdateUserPreferences } from 'ducks/currentUser/operations'
import { selectUsageForFeature } from 'ducks/billing/selectors/usage/selectUsageForFeature'
import { FEATURE_KB_MAX_KBS } from 'ducks/billing/featureTypes'

export function doToggleShowKbSearchOnInboxEditor() {
  return (dispatch, getState) => {
    const state = getState()
    const showKbSearchOnInboxEditor = selectShowKbSearchOnInboxEditor(state)
    const currentKbs = selectUsageForFeature(state, FEATURE_KB_MAX_KBS)
    if (currentKbs > 0) {
      return doUpdateUserPreferences({
        show_kb_search_on_inbox_editor: !showKbSearchOnInboxEditor,
      })(dispatch, getState)
    }
    return Promise.resolve(null)
  }
}
