import React from 'react'
import PropTypes from 'prop-types'
import { propFunc } from 'util/functions'
import { Button } from 'shared/ui'

export default function TicketStateButtonView({
  ticketId,
  stateLabel,
  isSnoozed,
  isDeleted,
  isClosed,
  toggleTicketState,
  invertStateActionLabel,
  className,
}) {
  return (
    <Button
      primary
      basic={isClosed || isDeleted}
      className={className}
      onClick={propFunc(
        toggleTicketState,
        ticketId,
        stateLabel,
        isSnoozed,
        isDeleted
      )}
    >
      {invertStateActionLabel}
    </Button>
  )
}

TicketStateButtonView.propTypes = {
  ticketId: PropTypes.string.isRequired,
  stateLabel: PropTypes.string.isRequired,
  isSnoozed: PropTypes.bool.isRequired,
  isDeleted: PropTypes.bool.isRequired,
  isClosed: PropTypes.bool.isRequired,
  toggleTicketState: PropTypes.func.isRequired,
}
