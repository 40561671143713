// withSortableGauges wraps a component in an HOC that tracks
// an identifier to sort on and a direction to sort in.
// The wrapped component is passed the following props:
//  * sort
//    * sortBy (String) the identifier to sort by
//    * direction (ASC|DESC) the direction to sort in
//  * doSetSort (Function) a function to set the sort identifier

import React from 'react'

export default function withSortableGauges(ComposedComponent) {
  class SortableGauges extends React.Component {
    static getDerivedStateFromProps(
      { customerRatingsInstalled: ratingsInstalledProps, sort: { by } = {} },
      { ratingsInstalled: ratingsInstalledState }
    ) {
      if (ratingsInstalledProps !== ratingsInstalledState)
        return { ratingsInstalled: ratingsInstalledProps, sortBy: by }
      return null
    }

    constructor(...args) {
      super(...args)
      this.setSort = this.setSort.bind(this)
    }

    state = {
      direction: (this.props.sort && this.props.direction) || 'DESC',
      sortBy: this.props.sort && this.props.sort.by,
    }

    setSort(dataSet, defaultAsc = false) {
      const { direction, sortBy } = this.state
      const { pagination } = this.props
      if (pagination && pagination.doSetPage) pagination.doSetPage(1)
      this.setState({
        direction:
          (sortBy === dataSet && direction === 'DESC') ||
          (sortBy !== dataSet && defaultAsc)
            ? 'ASC'
            : 'DESC',
        sortBy: dataSet,
      })
    }

    render() {
      const {
        sort: { direction: propsDirection = null, by: propsSortBy } = {},
      } = this.props
      const { direction = propsDirection, sortBy = propsSortBy } = this.state
      return (
        <ComposedComponent
          {...this.props}
          doSetSort={this.setSort}
          sort={{
            direction,
            by: sortBy,
          }}
        />
      )
    }
  }

  return SortableGauges
}
