import cn from 'classnames'
import React from 'react'

import Info from 'components/Info'
import { Label, SubHeader } from 'shared/components/ui/Typography'
import { Popup } from 'util/ui'

import replaceAverage from '../../util/replaceAverage'
import formatDataPointValueAs from '../../util/formatDataPointValueAs'
import Change from '../Change'
import styles from './styles.less'

HeadlineIndividual.defaultProps = {
  suffix: null,
}

export default function HeadlineIndividual({
  average,
  datum: { change, isBad, isGood, isInfinity, isPositive, y: value } = {},
  diff,
  errored,
  fullstackId,
  includePrevious,
  loaded,
  loading,
  massive,
  scale = {},
  suffix,
  title,
  description,
}) {
  const formated = formatDataPointValueAs(
    scale.formatYAs || scale.y,
    value,
    10000
  )
  const formatedExact = formatDataPointValueAs(
    scale.formatYAs || scale.y,
    value,
    true
  )
  const sign = isPositive ? '+' : ''
  const formatedWithSign = diff ? `${sign}${formated}` : formated
  const formatedExactWithSign = diff ? `${sign}${formatedExact}` : formatedExact
  const isExactTheSame = formated === formatedExact
  return (
    <div className={styles.Headline} data-test-id={fullstackId}>
      <Label as="div" size="large" textAlign="center" className={styles.label}>
        {replaceAverage(title, average)}{' '}
        {description && (
          <Info corner description={replaceAverage(description, average)} />
        )}
      </Label>

      {errored && (
        <Label
          as="div"
          className={cn(styles.Headline_errored, {
            [styles.Headline_massive]: massive,
          })}
          size={'huge'}
          textAlign="center"
        >
          ERROR!
        </Label>
      )}
      {!loaded && (
        <SubHeader
          as="div"
          className={cn(styles.Headline_loading, {
            [styles.Headline_massive]: massive,
          })}
          size="small"
          textAlign="center"
        >
          Loading...
        </SubHeader>
      )}
      {loaded && (
        <Label
          as="div"
          color={
            cn({
              bio: diff && isGood && !isInfinity,
              berry: diff && isBad && !isInfinity,
            }) || 'dark'
          }
          data-test-id="value"
          size={'huge'}
          className={cn(styles.thisPeriod, {
            [styles.Headline_massive]: massive,
          })}
          textAlign="center"
        >
          {isExactTheSame ? (
            <span>{formatedWithSign}</span>
          ) : (
            <Popup
              content={formatedExactWithSign}
              inverted
              on="hover"
              position="bottom center"
              trigger={<span>{formatedWithSign}</span>}
            />
          )}
        </Label>
      )}
      {suffix !== null && (
        <div className={styles.Headline_suffix}>{suffix}</div>
      )}
      {includePrevious && (
        <Change
          loading={loading}
          change={change}
          textAlign="center"
          percent
          scale={scale}
          showPercentageTooltip
          showIcon={false}
        />
      )}
    </div>
  )
}
