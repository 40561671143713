import { connect } from 'react-redux'

import { totalArticleViewsGauge } from 'ducks/reports/gauges'
import { HeadlineVisualization } from 'ducks/reports/visualizations'

const staticProps = {
  gauges: [totalArticleViewsGauge],
  title: 'Article views',
}

export default function TotalKbArticleViewsHeadline(props) {
  return <HeadlineVisualization {...props} {...staticProps} />
}
