import React from 'react'
import PropTypes from 'prop-types'

import formatAxisValueAs from '../../util/formatAxisValueAs'
import grooveTheme from '../../grooveTheme'
import PercentBar from '../PercentBar'
import ProgressBarIndividual from './ProgressBarIndividual'
import styles from './styles.less'

export default function ProgressBarView({
  colorScale = grooveTheme.colors.scale,
  dataSets,
  scale,
  total: propTotal,
}) {
  let colorIndex = 0
  return (
    <section className={styles.ProgressBars}>
      {dataSets.map(({ data, name, timeframe }, dsIndex) => {
        const total =
          propTotal ||
          data.reduce((runningTotal, datum) => {
            if (typeof datum.y === 'number') return runningTotal + datum.y
            return runningTotal
          }, 0)
        return data.map((datum, index) => {
          const color = colorScale[colorIndex]
          colorIndex += 1
          if (!colorScale[colorIndex]) colorIndex = 0
          return (
            <ProgressBarIndividual
              color={color}
              key={datum.id || `pb-${dsIndex}-${index}`}
              scale={scale}
              timeframe={timeframe}
              title={`${
                datum.x || datum.x === 0
                  ? `${formatAxisValueAs(scale.formatXAs || scale.x, datum.x)}`
                  : ''
              }`}
              total={total}
              value={datum.y}
            />
          )
        })
      })}
    </section>
  )
}
