import { createSelector } from 'reselect'
import { emptyObj, isEmpty } from 'util/objects'
import { selectBase } from './base'

export const selectDraftWidget = createSelector(
  selectBase,
  base => base.draftWidget
)

// TODO (jscheel): It's possible to rewrite selectCurrentWidgetSettings to figure
// return the draft widget, if we are in the create wizard, but for now it was
// clearer to have two completely separate selectors.
export const selectDraftWidgetSettings = createSelector(
  selectDraftWidget,
  draft => draft?.settings || emptyObj
)

export const selectIsCreatingDraftWidget = createSelector(
  selectBase,
  base => base.isCreatingDraftWidget
)

export const selectDraftWidgetErrors = createSelector(
  selectBase,
  base => base.draftWidgetErrors
)

export const selectDraftWidgetValid = createSelector(
  selectDraftWidgetErrors,
  errors => {
    return isEmpty(errors)
  }
)
