import React, { Fragment, PureComponent } from 'react'
import { debounce } from 'util/functions'
import TicketPreview from '../TicketPreview'

const previewWaitShow = 500
const previewWaitHide = 500

let previewHandle = null

export default function withTicketPreview(
  WrappedComponent,
  eventsOnly = false
) {
  return class extends PureComponent {
    hide = () => {
      const { hideTicketPreviewModal } = this.props
      hideTicketPreviewModal()
    }

    delayedHide = debounce(this.hide, previewWaitHide)

    cancelHide = () => {
      this.delayedHide.cancel()
    }

    delayedShowTicketPreviewModal = e => {
      const {
        showTicketPreviewModal,
        ticketId,
        eventOffsetRight,
        eventOffsetLeft,
        disabled,
      } = this.props
      if (disabled) return
      if (previewHandle) clearTimeout(previewHandle)
      const offset = {
        left: e.pageX,
        top: e.pageY,
        eventOffsetRight,
        eventOffsetLeft,
      }
      previewHandle = setTimeout(() => {
        showTicketPreviewModal(ticketId, offset)
      }, previewWaitShow)
      this.cancelHide()
    }

    cancelDelayedShowTicketPreviewModal = () => {
      const { disabled, isModalOpen } = this.props
      if (disabled) return
      if (previewHandle) clearTimeout(previewHandle)
      if (isModalOpen) {
        this.delayedHide()
      }
    }

    render() {
      const { ticketId, disabled } = this.props
      return (
        <Fragment>
          <WrappedComponent
            {...this.props}
            delayedShowTicketPreviewModal={this.delayedShowTicketPreviewModal}
            cancelDelayedShowTicketPreviewModal={
              this.cancelDelayedShowTicketPreviewModal
            }
          />
          {!eventsOnly &&
            !disabled && (
              <TicketPreview
                key={`ticket-preview-${ticketId}`}
                showState
                showMark
                ticketId={ticketId}
                delayedHide={this.delayedHide}
                cancelHide={this.cancelHide}
              />
            )}
        </Fragment>
      )
    }
  }
}
