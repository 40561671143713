import { v4 as uuidV4 } from 'uuid'
import {
  NOTIFICATION_CLEAR,
  NOTIFICATION_DISMISS,
  NOTIFICATION_SHOW,
} from './types'

export function showErrorNotification(options = {}) {
  return showNotification({ ...options, type: 'negative' })
}

export function showWarningNotification(options = {}) {
  return showNotification({ ...options, type: 'warning' })
}

export function showSuccessNotification(options = {}) {
  return showNotification({ ...options, type: 'positive' })
}

export function showNotification(options = {}) {
  return dispatch => {
    const {
      message = '',
      header = '',
      type = 'success',
      id = uuidV4(),
      dismissAfter = -1, // permanent by default (unlike a Snackbar)
    } = options
    const payload = { message, header, type, id }

    dispatch({ type: NOTIFICATION_SHOW, payload })

    if (Number.isInteger(dismissAfter) && dismissAfter > 0) {
      setTimeout(() => {
        dispatch(dismissNotification(id))
      }, dismissAfter)
    }
  }
}

export function dismissNotification(id) {
  return {
    type: NOTIFICATION_DISMISS,
    payload: { id },
  }
}

export function clearAllNotifications() {
  return {
    type: NOTIFICATION_CLEAR,
  }
}
