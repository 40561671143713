import createCachedSelector from 're-reselect'
import selectGroups from './selectGroups'

const selectGroupForId = createCachedSelector(
  selectGroups,
  (_state, groupId) => groupId,
  (groups, groupId) => groups.find(g => g.id === groupId)
)((_state, groupId) => groupId || 'UNKNOWN')

export default selectGroupForId
