import { createSelector } from 'reselect'
import { emptyObj } from 'util/objects'
import { emptyArr } from 'util/arrays'
import { createBasicSelector } from 'util/redux'
import { selectUsageForFeature } from 'ducks/billing/selectors/usage/selectUsageForFeature'
import { FEATURE_KB_MAX_KBS } from 'ducks/billing/featureTypes'
import { selectBase } from './base'

const selectKbSearch = createSelector(
  selectBase,
  base => base.kbSearch || emptyObj
)

const selectKbSearchArticles = createSelector(
  selectKbSearch,
  kbSearch => kbSearch.articles || emptyObj
)

const selectKbSearchArticlePreview = createSelector(
  selectKbSearch,
  kbSearch => kbSearch.articlePreview || emptyObj
)

export const selectLastRequestedSearchTerm = createSelector(
  selectKbSearchArticles,
  articles => articles.lastRequestedSearchTerm
)

export const selectArticles = createSelector(
  selectKbSearchArticles,
  articles => articles.data || emptyArr
)

export const selectIsArticlesLoaded = createSelector(
  selectKbSearchArticles,
  articles => !!articles.isLoaded
)

const selectKbSearchKnowledgeBases = createSelector(
  selectKbSearch,
  kbSearch => kbSearch.knowledgeBases || emptyObj
)

export const selectKnowledgeBases = createSelector(
  selectKbSearchKnowledgeBases,
  knowledgeBases => knowledgeBases.data || emptyArr
)

export const selectArticlesWithKnowledgebase = createSelector(
  selectArticles,
  selectKnowledgeBases,
  (articles, knowledgeBases) =>
    articles.map(a => ({
      ...a,
      knowledgeBase:
        knowledgeBases.find(k => k.id === a.knowledgeBaseId) || emptyObj,
    }))
)

export const selectKnowledgeBaseOptions = createSelector(
  selectKnowledgeBases,
  knowledgeBases => {
    const kbOptions = knowledgeBases.map(({ id, title }) => ({
      key: `kb-${id}`,
      text: title,
      value: id,
    }))

    return [
      { key: 'kb-*', text: 'All knowledge bases', value: '*' },
      ...kbOptions,
    ]
  }
)

export const selectIsKnowledgeBasesLoading = createSelector(
  selectKbSearchKnowledgeBases,
  knowledgeBases => !!knowledgeBases.isLoading
)

export const selectIsKnowledgeBasesLoaded = createSelector(
  selectKbSearchKnowledgeBases,
  knowledgeBases => !!knowledgeBases.isLoaded
)

export const selectPrimaryKnowledgeBaseId = createSelector(
  selectKnowledgeBases,
  knowledgeBases => {
    if (knowledgeBases.length === 0) return null
    return (knowledgeBases.find(kb => kb.primary) || knowledgeBases[0]).id
  }
)

export const selectIsArticlePreviewLoading = createSelector(
  selectKbSearchArticlePreview,
  articlePreview => !!articlePreview.isLoading
)

export const selectIsArticlePreviewLoaded = createSelector(
  selectKbSearchArticlePreview,
  articlePreview => !!articlePreview.isLoaded
)

export const selectIsArticlePreviewBroken = createSelector(
  selectKbSearchArticlePreview,
  articlePreview => !!articlePreview.hasError
)

export const selectArticlePreviewArticle = createSelector(
  selectKbSearchArticlePreview,
  articlePreview => articlePreview.data
)

export const selectIsArticlePreviewArticleId = createSelector(
  selectKbSearchArticlePreview,
  articlePreview => articlePreview.id
)

export const selectArticlePreviewUrl = createSelector(
  selectKnowledgeBases,
  selectArticles,
  selectIsArticlePreviewArticleId,
  (knowledgeBases, articles, previewArticleId) => {
    const article = articles.find(a => a.id === previewArticleId)
    if (!article) return ''
    const knowledgeBase = knowledgeBases.find(
      kb => kb.id === article.knowledgeBaseId
    )
    if (!knowledgeBase) return ''
    return `${knowledgeBase.siteUrl}/${article.slug}`
  }
)

export const selectHasKnowledgebases = createBasicSelector(state => {
  return selectUsageForFeature(state, FEATURE_KB_MAX_KBS) > 0
})

export const selectHasMultipleKnowledgebases = createBasicSelector(state => {
  return selectUsageForFeature(state, FEATURE_KB_MAX_KBS) > 1
})
