import createCachedSelector from 're-reselect'

import fullPropsSetCacheKey from './cacheKeys/fullPropsSetCacheKey'
import colorizeDataSetsOnGauges from './colorizeDataSetsOnGauges'
import selectDecoratedGaugesWithoutColorizedDataSets from './selectDecoratedGaugesWithoutColorizedDataSets'

export default createCachedSelector(
  selectDecoratedGaugesWithoutColorizedDataSets,
  gauges => {
    return colorizeDataSetsOnGauges(gauges)
  }
)(fullPropsSetCacheKey)
