/* eslint-disable no-param-reassign */
import {
  oauthTokenSelector,
  selectAccountSubdomain,
  selectMailboxIds,
} from 'selectors/app/base'

import config from 'config'
import { batchActions } from 'util/redux'
import storage from 'util/storage'
import { redirect } from 'redux-first-router'
import graphql from 'api/graphql'
import { doGraphqlRequest } from 'ducks/requests/operations'
import { statusStates } from 'constants/status_states'
import { doBuildMenuFromWidgets } from 'ducks/folders/operations/collections'
import {
  selectKnowledgeBases,
  selectPrimaryKnowledgeBase,
} from 'subapps/kb/selectors/knowledge_bases'
import { doFetchKnowledgeBases } from 'subapps/kb/actions/knowledge_bases'
import { SETTINGS_WIDGETS_PAGE } from 'subapps/settings/types'
import { isEmpty } from 'util/objects'
import { getRawId, buildId } from 'util/globalId'
import {
  channel as channelEntity,
  widget as widgetSchema,
} from 'ducks/entities/schema'
import { createDoFetchInMemoryByQueryId } from 'ducks/searches/operations/createDoFetchInMemoryByQueryId'
import { selectSearchByQueryId } from 'ducks/searches/selectors'
import { isBridgeChannelType } from 'ducks/channels/channelTypes'
import { doTryFetchAccountUsageOnboardingForOnboarding } from 'ducks/accountPreferences/operations'
import { selectFeatureBasedOnboardingWorkflowData } from 'subapps/onboarding/selectors'
import { FEATURE_INBOX_LIVE_CHAT } from 'ducks/billing/featureTypes'
import { selectFeatureUsage } from 'ducks/billing/selectors/features'
import { createWidgetGraphQlResponseSchema } from '../schema'
import {
  widgetsQuery,
  widgetQuery,
  settingsQuery,
  publishSettingsMutation,
  revertWidgetSettingsMutation,
  updateWidgetSettingsMutation,
  createWidgetMutation,
  updateWidgetMutation,
  deleteWidgetMutation,
  demoWidgetQuery,
} from '../api'

import {
  BOOTSTRAP_WIDGETS,
  FETCH_WIDGETS,
  FETCH_WIDGET_SETTINGS_REQUEST,
  FETCH_WIDGET_SETTINGS_SUCCESS,
  FETCH_WIDGET_SETTINGS_FAILURE,
  FETCH_WIDGET_SNIPPET_REQUEST,
  FETCH_WIDGET_SNIPPET_SUCCESS,
  FETCH_WIDGET_SNIPPET_FAILURE,
  FETCH_WIDGET_BYID,
  UPDATE_WIDGET_SETTINGS,
  PUBLISH_WIDGET_SETTINGS,
  REVERT_WIDGET_SETTINGS_REQUEST,
  REVERT_WIDGET_SETTINGS_SUCCESS,
  REVERT_WIDGET_SETTINGS_FAILURE,
  UPDATE_WIDGET,
  DELETE_WIDGET,
  UPDATE_WIDGET_PREVIEW_MESSAGE,
  CREATE_DRAFT_WIDGET_REQUEST,
  CREATE_DRAFT_WIDGET_SUCCESS,
  UPDATE_DRAFT_WIDGET,
  DESTROY_DRAFT_WIDGET,
  CREATE_WIDGET,
} from '../types'

import {
  selectSettings,
  selectCurrentWidgetSettings,
} from '../selectors/settings'
import { selectIsOnCreatePage } from '../selectors/page'
import { selectWidgetsBootstrapped } from '../selectors/status'
import { selectWidgetById, selectWidgetsIds } from '../selectors/widgets'
import {
  selectDraftWidget,
  selectDraftWidgetSettings,
} from '../selectors/draft'
import { selectSnippet } from '../selectors/snippet'

const normalizeWidgetErrors = e => {
  const errorMessage = {}

  if (e && e.errors && e.errors[0] && e.errors[0].originalError) {
    const error = e.errors[0].originalError

    if (error.name) {
      errorMessage.name = error.name[0]
    }
  }
  return errorMessage
}

export const doFetchSettings = widgetId => {
  return (dispatch, getState) => {
    const state = getState()
    const token = oauthTokenSelector(state)

    dispatch({
      type: FETCH_WIDGET_SETTINGS_REQUEST,
      payload: { widgetId },
    })

    const variables = {
      widgetId,
    }

    return graphql(token, settingsQuery(), variables)
      .then(res => {
        const data = res.json.data
        dispatch({
          type: FETCH_WIDGET_SETTINGS_SUCCESS,
          payload: {
            settings: data.widgetSettings,
            widgetId,
          },
        })
      })
      .catch(err => {
        dispatch({
          type: FETCH_WIDGET_SETTINGS_FAILURE,
          payload: { error: err },
          error: true,
        })
      })
  }
}

export const doFetchSnippet = () => {
  return dispatch => {
    dispatch({
      type: FETCH_WIDGET_SNIPPET_REQUEST,
    })

    // use template variables to replace later for the widget
    fetch(`${config.widgetUrl}/api/snippet`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/javascript',
      },
    })
      .then(response => response.text())
      .then(body => {
        dispatch({
          type: FETCH_WIDGET_SNIPPET_SUCCESS,
          payload: {
            snippet: body,
          },
        })
      })
      .catch(err => {
        dispatch({
          type: FETCH_WIDGET_SNIPPET_FAILURE,
          payload: { error: err },
          error: true,
        })
      })
  }
}

export const doUpdateSettings = (_prefs = {}, id, configOptions) => {
  const {
    shouldShowToasts = false,
    toastsConfig,
    toastsKeyWord = 'Widget',
    ...otherOptions
  } =
    configOptions || {}

  return (dispatch, getState) => {
    const options = {
      commitType: 'BRANCH',
      ...otherOptions,
    }
    const state = getState()
    const widgetId = id || state.location.payload.id
    const originalSettings = selectSettings(state)[widgetId] || {}
    // We need other data to calculate the new settings
    const settingsToUpdate =
      options.commitType === 'BRANCH'
        ? { ...originalSettings, ..._prefs }
        : _prefs

    const prefs = calculateSettings(settingsToUpdate)

    const variables = {
      widgetId,
      options,
      settings: prefs,
    }

    const transformedVariables = {
      widgetId,
      input: Object.keys(prefs).reduce((acc, k) => {
        // eslint-disable-next-line no-unused-expressions
        typeof prefs[k] === 'object' && prefs[k] !== null
          ? (acc[k] = JSON.stringify(prefs[k]))
          : (acc[k] = prefs[k])
        return acc
      }, {}),
      commitType: options.commitType,
    }

    return dispatch(
      doGraphqlRequest(
        UPDATE_WIDGET_SETTINGS,
        updateWidgetSettingsMutation(),
        variables,
        {
          optimist: {},
          transformVariables: () => transformedVariables,
          transformResponse: data => ({
            settings: data?.updateWidgetSettings,
            widgetId,
            options,
          }),
          searches: {
            additionalActions: [
              {
                type: 'INVALIDATE_ENTITIES',
                entityTypes: ['widget', 'channel'],
                phases: ['SUCCESS'],
              },
            ],
          },
          moduleOptions: {
            toasts: {
              enabled: shouldShowToasts,
              started: {
                enabled: false,
              },
              success: {
                enabled: true,
                content: `${toastsKeyWord} updated`,
                ...toastsConfig?.success,
              },
              failed: {
                content: `${toastsKeyWord} update failed`,
                onClickAction: () => {
                  dispatch(doUpdateSettings(_prefs, id, configOptions))
                },
                ...toastsConfig?.failed,
              },
            },
          },
        }
      )
    )
  }
}

const LOAD_ALL_WIDGETS_QUERYID = 'entityType:widget pageSize:10000'

export const doFetchWidgets = ({ skipLoaded } = {}) => (dispatch, getState) => {
  const queryId = LOAD_ALL_WIDGETS_QUERYID
  const cursor = 'all'
  const state = getState()
  const { loaded = null, cursors = {} } = selectSearchByQueryId(state, queryId)
  const hasCurrentPage = !!cursors[cursor]
  const isStale = hasCurrentPage && cursors[cursor].isStale

  const hasLoaded = loaded && hasCurrentPage && !isStale

  if (hasLoaded && skipLoaded) {
    // Note we might need to change this in future to return the results
    // from the previous query
    return Promise.resolve({})
  }

  return dispatch(
    doGraphqlRequest(
      FETCH_WIDGETS,
      widgetsQuery(),
      {},
      {
        transformResponse: async res => {
          const widgets = res.widgets.data
          const promises = []
          widgets.forEach(({ id }) => {
            promises.push(dispatch(doFetchSettings(id)))
          })
          await Promise.all(promises)
          return {
            widgets,
            // FIXME: Hiding facebook widgets as in selectWidgets from showing in the table for settings
            widgetsWithSocialChat: widgets.filter(
              widget => !isBridgeChannelType(widget.channelType)
            ),
          }
        },
        normalizationSchema: { widgetsWithSocialChat: [widgetSchema] },
        searches: {
          queryId,
          cursor,
          extractPagination: ({ widgetsWithSocialChat: data }) => {
            return {
              type: 'widgets',
              nodes: data,
              startCursor: 1,
              endCursor: 1,
              hasNextPage: false,
              hasPreviousPage: false,
              totalCount: data.length,
              totalPageCount: 1,
            }
          },
        },
      }
    )
  )
}

export const doFetchWidgetById = id => dispatch => {
  return dispatch(
    doGraphqlRequest(
      FETCH_WIDGET_BYID,
      widgetQuery(),
      { id: getRawId(id) },
      {
        normalizationSchema: channelEntity,
        transformResponse: data => {
          if (!data) return null
          return {
            ...data.widget,
            id: buildId('Widget', data.widget.id),
          }
        },
      }
    )
  )
}

export function doPublishSettings(id = null, { shouldShowToasts = true } = {}) {
  return async (dispatch, getState) => {
    const state = getState()
    let widgetId = id
    if (!widgetId) {
      const { id: currentWidgetId } = state.location.payload
      widgetId = currentWidgetId
    }

    const variables = {
      widgetId,
    }

    const res = await dispatch(
      doGraphqlRequest(
        PUBLISH_WIDGET_SETTINGS,
        publishSettingsMutation(),
        variables,
        {
          optimist: {},
          transformResponse: data => ({
            settings: data.publishWidgetSettings,
            widgetId,
          }),
          searches: {
            additionalActions: [
              {
                type: 'INVALIDATE_ENTITIES',
                entityTypes: ['widget', 'channel'],
                phases: ['SUCCESS'],
              },
            ],
          },
          moduleOptions: {
            toasts: {
              enabled: shouldShowToasts,
              started: {
                enabled: false,
              },
              success: {
                enabled: true,
                content: 'Widget published',
              },
              failed: {
                content: 'Widget publish failed',
                onClickAction: () => {
                  dispatch(doPublishSettings(widgetId))
                },
              },
            },
          },
        }
      )
    )
    dispatch(doBuildMenuFromWidgets({ autoRedirect: false }))
    return res
  }
}

export function doRevertSettings() {
  return (dispatch, getState) => {
    const state = getState()
    const token = oauthTokenSelector(state)
    const { id } = state.location.payload

    dispatch({
      type: REVERT_WIDGET_SETTINGS_REQUEST,
    })

    const variables = {
      widgetId: id,
    }

    return graphql(token, revertWidgetSettingsMutation(), variables)
      .then(res => {
        const data = res.json.data

        dispatch({
          type: REVERT_WIDGET_SETTINGS_SUCCESS,
          payload: {
            settings: data.revertWidgetSettings,
            widgetId: id,
          },
        })
        return data.revertWidgetSettings
      })
      .catch(err => {
        dispatch({
          type: REVERT_WIDGET_SETTINGS_FAILURE,
          payload: { error: err },
          error: true,
        })
      })
  }
}

export const doCreateWidget = (
  name,
  settings,
  params = {},
  options = {}
) => async (dispatch, getState) => {
  const variables = {
    input: {
      name,
      ...params,
    },
  }

  const response = await dispatch(
    doGraphqlRequest(CREATE_WIDGET, createWidgetMutation(), variables, {
      normalizationSchema: createWidgetGraphQlResponseSchema,
      transformResponse: data => ({
        widget: data.createWidget
          ? {
              ...data.createWidget,
              id: buildId('Widget', data.createWidget.id),
            }
          : null,
        errors: data.errors || null,
      }),
      transformError: normalizeWidgetErrors,
      ...options,
    })
  )
  const {
    widget,
    widget: { id },
  } = response
  const rawId = getRawId(id)
  await dispatch(doUpdateSettings(settings, getRawId(rawId)))
  await dispatch(
    doPublishSettings(rawId, {
      shouldShowToasts: false,
    })
  )
  const onboardingWorkflowData = selectFeatureBasedOnboardingWorkflowData(
    getState()
  )
  dispatch(
    doTryFetchAccountUsageOnboardingForOnboarding(
      onboardingWorkflowData.widget?.usageKey
    )
  )
  return widget
}

export function doUpdateWidget(widgetId, widget, options) {
  const { shouldShowToasts = false, toastsKeyWord = 'Widget', ...rest } =
    options || {}
  return dispatch => {
    const variables = {
      id: widgetId,
      input: widget,
    }

    return dispatch(
      doGraphqlRequest(UPDATE_WIDGET, updateWidgetMutation(), variables, {
        transformResponse: data => ({ widget: data.updateWidget }),
        transformError: normalizeWidgetErrors,
        searches: {
          additionalActions: [
            {
              type: 'INVALIDATE_ENTITIES',
              entityTypes: ['widget', 'channel'],
              phases: ['SUCCESS'],
            },
          ],
        },
        moduleOptions: {
          toasts: {
            enabled: shouldShowToasts,
            started: {
              enabled: false,
            },
            success: {
              enabled: true,
              content: `${toastsKeyWord} updated`,
            },
            failed: {
              content: `${toastsKeyWord} update failed`,
              onClickAction: () => {
                dispatch(doUpdateWidget(widgetId, widget, options))
              },
            },
          },
        },
        ...rest,
      })
    )
  }
}

export function doDeleteWidget(inputWidgetId) {
  return (dispatch, getState) => {
    const state = getState()
    const widgetId = getRawId(inputWidgetId)
    const variables = {
      id: widgetId,
    }
    const widget = selectWidgetById(state, widgetId)
    const channelType = isBridgeChannelType(widget.channelType)
      ? 'Channel'
      : 'Widget'

    return dispatch(
      doGraphqlRequest(DELETE_WIDGET, deleteWidgetMutation(), variables, {
        method: 'DELETE',
        optimist: {},
        transformResponse: () => ({
          widget,
        }),
        searches: {
          additionalActions: [
            {
              type: 'INVALIDATE_ENTITIES',
              entityTypes: ['widget', 'channel'],
              phases: ['SUCCESS'],
            },
          ],
        },
        moduleOptions: {
          toasts: {
            enabled: true,
            started: {
              enabled: true,
              content: `${channelType} deleted`,
            },
            success: {
              enabled: false,
            },
            failed: {
              content: `${channelType} deletion failed`,
              onClickAction: () => {
                dispatch(doDeleteWidget(widgetId))
              },
            },
          },
          entities: {
            targetOperation: 'remove',
            additionalActions: [
              {
                // Because we're passing through a global id here, we need to manually delete
                // the entity from the store
                entityType: 'widget',
                entityId: widgetId,
                stores: ['pending', 'current'],
                operation: 'remove',
                phases: ['STARTED'],
              },
            ],
          },
        },
      })
    )
  }
}

export function doBootstrapWidgetsAndSettings() {
  // eslint-disable-next-line func-names
  return function(dispatch, getState) {
    const state = getState()
    const isBootstrapped = selectWidgetsBootstrapped(state)
    // HACK (jscheel): Circular dependency that's not worth dealing with in the
    // locations selectors. Just grab the dumb values here.
    const { id: currentId } = state.location.payload
    const { id: prevId } = (state.location.prev || {}).payload
    if (isBootstrapped && currentId !== prevId) {
      // NOTE (jscheel): Refetch the settings if we are switching widgets.
      const { id } = state.location.payload
      if (id) {
        dispatch(doFetchSettings(id))
      }
    } else if (isBootstrapped) {
      // NOTE (jscheel): It is possible that widgets have been "sort of" bootstrapped
      // by the chat subapp. However, that bootstrapping does not ensure that the
      // settings are fetched. Here we do some cleanup to ensure that the settings
      // and the snippet are also loaded.
      const widgetIds = selectWidgetsIds(state)
      const settings = selectSettings(state)
      widgetIds.forEach(id => {
        if (!settings[id] || settings[id] === statusStates.ERROR) {
          dispatch(doFetchSettings(id))
        }
      })
      const snippet = selectSnippet(state)
      if (!snippet) {
        dispatch(doFetchSnippet())
      }
    } else if (!isBootstrapped) {
      dispatch(
        batchActions(
          { type: BOOTSTRAP_WIDGETS },
          doFetchWidgets(),
          doFetchSnippet()
        )
      )
    }
  }
}

export function doHidePreviewMessage() {
  storage.set('hide-widget-preview-message', true)
  return {
    type: UPDATE_WIDGET_PREVIEW_MESSAGE,
    payload: {
      hide: true,
    },
  }
}

function calculateSettings(settings, options = {}) {
  const newSettings = { ...settings }

  if (isEmpty(newSettings)) return newSettings

  const checks = ['contact', 'kb', 'chat']
  const hasMultiple =
    checks.map(c => settings[`${c}.enabled`]).filter(Boolean).length > 1

  if (options.attemptStartScreenUpgrade && hasMultiple) {
    newSettings.startScreen = 'home'
  } else if (
    (newSettings.startScreen === 'home' && !hasMultiple) ||
    (newSettings.startScreen !== 'home' &&
      !newSettings[`${newSettings.startScreen}.enabled`])
  ) {
    const firstEnabled = checks.find(c => newSettings[`${c}.enabled`])
    newSettings.startScreen = firstEnabled || 'home'
  }

  const offlineAgentEnabledKey = 'chat.skills.offlineAgents.enabled'
  const offlineAgentsEnabled = settings[offlineAgentEnabledKey]

  if (offlineAgentsEnabled) {
    const offlineAgentDisplayOptionKey =
      'chat.skills.offlineAgents.newConversation.displayOption'
    const offlineAgentsDisplayOption = settings[offlineAgentDisplayOptionKey]
    const contactEnabled = settings['contact.enabled']
    const kbEnabled = settings['kb.enabled']

    if (
      (offlineAgentsDisplayOption === 'contact_home' && !contactEnabled) ||
      (offlineAgentsDisplayOption === 'kb_home' && !kbEnabled)
    ) {
      newSettings[offlineAgentEnabledKey] = false
      newSettings[offlineAgentDisplayOptionKey] = 'user_choice_without_chat'
    }
  }

  return newSettings
}

export function doCreateDraftWidget() {
  return (dispatch, getState) => {
    // NOTE (jscheel): We have to ensure that knowledgebases are fetched, so
    // we break this up even though it doesn't make a request anywhere.
    dispatch({
      type: CREATE_DRAFT_WIDGET_REQUEST,
    })

    let state = getState()
    const token = oauthTokenSelector(state)
    const subdomain = selectAccountSubdomain(state)
    const { canUseFeature: canUseLiveChat } = selectFeatureUsage(
      state,
      FEATURE_INBOX_LIVE_CHAT
    )

    const demoPromise = graphql(token, demoWidgetQuery(), {
      id: `demo-${subdomain}`,
    }).then(res => {
      return res.json.data.widget
    })
    const kbPromise = dispatch(doFetchKnowledgeBases())

    return Promise.all([demoPromise, kbPromise]).then(([demoWidget]) => {
      state = getState()
      const mailboxIds = selectMailboxIds(state)
      const primaryKb = selectPrimaryKnowledgeBase(state)
      const knowledgeBases = selectKnowledgeBases(state)
      const settings = { ...demoWidget.publishedSettings }

      if (mailboxIds && mailboxIds.length > 0) {
        settings['contact.id'] = mailboxIds[0]
        settings['contact.enabled'] = true
      }

      if (knowledgeBases.length) {
        settings['kb.id'] = primaryKb?.id || knowledgeBases[0].id
        settings['kb.enabled'] = true
      }

      if (!canUseLiveChat) {
        settings['chat.enabled'] = false
      }

      dispatch({
        type: CREATE_DRAFT_WIDGET_SUCCESS,
        payload: {
          settings: calculateSettings(settings),
        },
      })
    })
  }
}

export function doUpdateDraftWidget(
  { widgetFields, settings },
  calculateOptions = {}
) {
  return {
    type: UPDATE_DRAFT_WIDGET,
    payload: {
      widgetFields,
      settings: calculateSettings(settings, calculateOptions),
    },
  }
}

export function doCancelWidgetCreate() {
  return dispatch => {
    // NOTE (jscheel): Can't batch actions here, because batchActions kills the
    // location middleware.
    dispatch({
      type: DESTROY_DRAFT_WIDGET,
    })
    dispatch(
      redirect({
        type: SETTINGS_WIDGETS_PAGE,
      })
    )
  }
}

export function doCreateWidgetFromDraft() {
  return (dispatch, getState) => {
    const state = getState()
    const draftWidget = selectDraftWidget(state)
    if (!draftWidget) throw new Error('No draft widget in store')
    const draftWidgetSettings = selectDraftWidgetSettings(state)
    return dispatch(doCreateWidget(draftWidget.name, draftWidgetSettings))
  }
}

export function doPreCalculateStartScreen() {
  return (dispatch, getState) => {
    const state = getState()
    const isOnCreate = selectIsOnCreatePage(state)
    if (isOnCreate) {
      const settings = selectDraftWidgetSettings(state)
      if (settings.startScreen === undefined) {
        dispatch(doUpdateDraftWidget({ settings: { startScreen: 'home' } }))
      }
    } else {
      const settings = selectCurrentWidgetSettings(state)
      if (settings.startScreen === undefined) {
        dispatch(doUpdateSettings({ startScreen: 'home' }))
      }
    }
  }
}

const allChatSkillsDisabled = {
  'chat.skills.welcome.enabled': false,
  'chat.skills.identity.enabled': false,
  'chat.skills.offline_agents.enabled': false,
  'chat.skills.offline_agents.new_conversation.identity.enabled': false,
  'chat.skills.offline_agents.existing_conversation.identity.enabled': false,
  'chat.skills.unresponsive_agents.enabled': false,
  'chat.skills.unresponsive_agents.identity.enabled': false,
}

export const doCreateFacebookWidget = (name, channelColor, settings = {}) => {
  return doCreateWidget(
    name,
    {
      ...settings,
      ...allChatSkillsDisabled,
      'chat.enabled': true,
    },
    {
      kind: 'facebook',
      channelColor,
    },
    {
      throwGraphQlValidationError: true,
    }
  )
}

export function doCreateInstagramWidget(name, channelColor, settings = {}) {
  return doCreateWidget(
    name,
    {
      ...settings,
      ...allChatSkillsDisabled,
      'chat.enabled': true,
    },
    {
      kind: 'instagram',
      channelColor,
    }
  )
}
export const doFetchWidgetsInMemory = createDoFetchInMemoryByQueryId({
  fromQueryId: LOAD_ALL_WIDGETS_QUERYID,
  entityType: 'widget',
  doLoadAllFn: doFetchWidgets,
})
