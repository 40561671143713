import { createSelector } from 'reselect'
import { emptyObj } from 'util/objects'
import routesMap from './routesMap'

const selectBase = state => state.ducksPage

const selectPage = createSelector(selectBase, base => base.page)

const selectProps = createSelector(selectBase, base => base.props)

const selectRoute = createSelector(selectPage, page => {
  return routesMap[page] || emptyObj
})

const selectRouteProps = createSelector(selectRoute, route => {
  return route.props
})

export const selectDuckPageProps = createSelector(
  selectProps,
  selectRouteProps,
  (props, routeProps) => {
    return {
      ...props,
      ...routeProps,
    }
  }
)

export const selectDuckPageComponent = createSelector(selectRoute, route => {
  return route.component
})
