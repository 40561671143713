function formatCount(count) {
  return Number(count).toLocaleString()
}

export function setTitle(title, count) {
  if (title && count) {
    const positiveCount = count < 0 ? 0 : count
    window.document.title = `${title} (${formatCount(positiveCount)}) | Groove`
  } else if (title) {
    window.document.title = `${title} | Groove`
  } else {
    window.document.title = 'Groove'
  }
  return null
}
