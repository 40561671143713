import createCachedSelector from 're-reselect'
import { createSelector } from 'reselect'
import {
  selectCurrentEntitiesById,
  selectCurrentEntityById,
  selectPendingEntityById,
  selectCurrentEntities,
} from 'ducks/entities/selectors'
import { emptyArr } from 'util/arrays'
import { emptyObj } from 'util/objects'

export const selectTagsBase = state => state.tags || emptyObj

export const selectCurrentTagsById = state =>
  selectCurrentEntitiesById(state, 'tag') || null

export const selectCurrentTagById = (state, tagId, isLoadedFn) =>
  selectCurrentEntityById(state, 'tag', tagId, isLoadedFn) || null

export const selectPendingTagById = (state, id) =>
  selectPendingEntityById(state, 'tag', id) || null

export const selectCurrentTags = state =>
  selectCurrentEntities(state, 'tag') || emptyArr

// exact match
export const selectCurrentTagByName = createCachedSelector(
  selectCurrentTags,
  (_, name) => name,
  (tags, name) => {
    if (!name) return null
    return tags.find(t => t.name === name) || null
  }
)((_, name) => name || 'unknown')

export const selectTagsTotalCount = createSelector(
  selectTagsBase,
  tags => tags.totalCount
)
