import { createPayload } from 'optimistic/base'
import { sortByKey } from 'util/arrays'
import { optimisticUpdatedAt } from 'util/optimistic'
import { toServerString } from 'util/date'

// builds a mock GraphQL response of a createChangeset mutation for a
// ticket state change
export const snoozePayload = (
  state,
  ticketId,
  snoozedUntil,
  folderId,
  search,
  updatedAt = toServerString(new Date())
) => {
  return createPayload(
    state,
    ticketId,
    'snooze',
    {
      state: 'closed',
      snoozedUntil,
      updated_at: updatedAt,
      // snoozedById excluded intentionally
    },
    [
      {
        change_type: 'Snooze::State',
        change: {
          until: snoozedUntil,
          type: 'Snooze::State',
        },
      },
      {
        change_type: 'State',
        change: {
          state: 'closed',
          type: 'State',
        },
      },
    ],
    folderId,
    search
  )
}

// To unsnooze a ticket, open+unsnooze
export const unSnoozePayload = (
  state,
  ticketId,
  search,
  updatedAt = toServerString(new Date())
) => {
  return createPayload(
    state,
    ticketId,
    'unsnooze',
    {
      state: 'opened',
      snoozedById: null,
      snoozedUntil: null,
      updated_at: updatedAt,
    },
    [
      {
        change_type: 'Unsnooze::State',
        change: {
          type: 'Unsnooze::State',
        },
      },
      {
        change_type: 'State',
        change: {
          state: 'opened',
          type: 'State',
        },
      },
    ],
    null,
    search
  )
}

// To really close a snoozed ticket, just unsnooze. The selector should build a
// dummy close action for us to show.
export const unSnoozeAndClosePayload = (
  state,
  ticketId,
  search,
  updatedAt = toServerString(new Date())
) => {
  return createPayload(
    state,
    ticketId,
    'unsnooze',
    {
      // no state change as should already be closed
      state: 'closed',
      snoozedById: null,
      snoozedUntil: null,
      updated_at: updatedAt,
    },
    [
      {
        change_type: 'Unsnooze::State',
        change: {
          type: 'Unsnooze::State',
        },
      },
    ],
    null,
    search
  )
}

// Returns the optimistic data for a bulk starring action.
export const bulkSnoozePayload = (
  state,
  ticketIds,
  snoozeOption,
  folderId,
  search
) =>
  sortByKey(
    ticketIds.map(id => {
      return snoozePayload(
        state,
        id,
        snoozeOption,
        folderId,
        search,
        optimisticUpdatedAt()
      )
    }),
    'id'
  )

// Returns the optimistic data for a bulk starring action.
export const bulkUnsnoozePayload = (state, ticketIds, search) =>
  sortByKey(
    ticketIds.map(id => {
      return unSnoozeAndClosePayload(state, id, search, optimisticUpdatedAt())
    }),
    'id'
  )
