import createCachedSelector from 're-reselect'

import { uniq } from 'util/arrays'
import { createArraySelector } from 'util/selectors'

import fullPropsSetCacheKey from './cacheKeys/fullPropsSetCacheKey'
import selectDecoratedGauge from './selectDecoratedGauge'
import selectDecoratedJoinedThroughGauge from './selectDecoratedJoinedThroughGauge'
import selectDecoratedSortedGauge from './selectDecoratedSortedGauge'
import selectReleventState from './selectReleventState'

const selectDecoratedGaugesUncachedWithoutColorizedDataSets = createCachedSelector(
  selectDecoratedJoinedThroughGauge,
  selectDecoratedSortedGauge,
  selectReleventState,
  (state, props) => props,
  (decoratedJoinedThroughGauge, decoratedSortedGauge, state, props) => {
    const {
      includePrevious,
      join,
      gauges,
      nullIsValidX = false,
      offset,
      pagination,
      previous,
      queryBase,
      scope,
      sort,
      xValues,
      xValueTranslations,
    } = props
    return gauges.map(gauge => {
      if (
        decoratedJoinedThroughGauge &&
        decoratedJoinedThroughGauge.undecoratedGauge === gauge
      )
        return decoratedJoinedThroughGauge
      if (
        decoratedSortedGauge &&
        decoratedSortedGauge.undecoratedGauge === gauge
      )
        return decoratedSortedGauge
      const joinedThroughDataSet =
        decoratedJoinedThroughGauge &&
        decoratedJoinedThroughGauge.dataSets.find(({ isJoinedThrough: x }) => x)
      const sortedDataSet =
        decoratedSortedGauge &&
        decoratedSortedGauge.dataSets.find(({ isSorted: x }) => x)
      const { unpagedXValues: joinedXValues } = joinedThroughDataSet || {}
      const { unpagedXValues: sortedXValues } = sortedDataSet || {}
      const joinedSortedXValues =
        (joinedThroughDataSet || sortedDataSet) &&
        uniq(sortedXValues.concat(joinedXValues || []))
      return selectDecoratedGauge(state, {
        decoratedJoinedThroughGauge,
        decoratedSortedGauge,
        includePrevious,
        join,
        joinedXValues: joinedSortedXValues,
        gauge,
        gauges,
        nullIsValidX,
        offset,
        pagination,
        previous,
        queryBase,
        scope,
        sort,
        xValues,
        xValueTranslations,
      })
    })
  }
)(fullPropsSetCacheKey)

// This second layer of cached selector with createArraySelector, ensures the
// same array is returned if every item in the array is the same.
export default createCachedSelector(
  selectDecoratedGaugesUncachedWithoutColorizedDataSets,
  gauges => {
    return gauges
  }
)(fullPropsSetCacheKey, { selectorCreator: createArraySelector })
