import { fullOuterJoin } from 'util/arrays'

import { countUniqueArticleViewsOnSearchId } from '../queries/countUniqueArticleViewsOnSearchId'
import { countUniqueSearchesOnSearchId } from '../queries/countUniqueSearchesOnSearchId'
import { ratio } from '../calculators/ratio'

export const searchesClickThroughRateRatioGauge = {
  calculate: ratio,
  description: 'Click-Through Rate from search results to an article.',
  id: 'search_click_through_rate',
  queries: [
    {
      ...countUniqueArticleViewsOnSearchId,
      groupBy: 'kb_referrer.search.term',
    },
    {
      ...countUniqueSearchesOnSearchId,
      groupBy: 'search.term',
    },
  ],
  title: '# of results',
}
