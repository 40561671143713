import { SHOW_HOTKEYS_MODAL, HIDE_HOTKEYS_MODAL } from './types'

const defaultState = {
  visible: false,
}

export default function recentConversations(
  state = defaultState,
  { type, payload }
) {
  switch (type) {
    case SHOW_HOTKEYS_MODAL: {
      return { ...state, visible: true }
    }

    case HIDE_HOTKEYS_MODAL: {
      return { ...state, visible: false }
    }
  }

  return state
}
