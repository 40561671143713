export const walletsQuery = `
  query Wallets($features: [String!]) {
    wallets(features: $features, options: { userOnly: true }) {
      edges {
        node {
          balance
          canUse
          createdAt
          feature
          id
          updatedAt
          ownerId
          limitType
          limit
          ownerType
          autorenew
          autorenewThreshold
          autorenewAmount
          autorenewUses
          autorenewAt
        }
      }
    }
  }
`
