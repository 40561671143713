import { single } from '../calculators/single'
import { countConversationsAssigned } from '../queries/countConversationsAssigned'

export const conversationsAssignedForEachDayGauge = {
  calculate: single,
  queries: [
    {
      ...countConversationsAssigned,
      interval: 'daily',
      key: 'countConversationsAssignedForEachDay',
    },
  ],
  title: 'Assignments',
}
