import React from 'react'

import formatAxisValueAs from '../../util/formatAxisValueAs'
import HeadlineIndividual from './HeadlineIndividual'

HeadlineView.propTypes = {}

export default function HeadlineView({
  average,
  dataSets,
  diff,
  errored,
  fullstackId,
  gauges,
  includePrevious,
  loaded,
  loading,
  scale: propScale,
  suffix,
  massive,
  title,
  description,
}) {
  const isSuffixFunction = typeof suffix === 'function'
  if (errored || !loaded) {
    if (!gauges) {
      return (
        <HeadlineIndividual
          average={average}
          errored={errored}
          includePrevious={includePrevious}
          loaded={false}
          loading={loading}
          massive={massive}
          title={title}
          description={description}
          scale={propScale}
          suffix={isSuffixFunction ? suffix({ errored, loading }) : suffix}
        />
      )
    }
    return gauges.map(
      (
        {
          id,
          description: gaugeDescription,
          scale: gaugeScale,
          title: gaugeTitle,
        },
        i
      ) => (
        <HeadlineIndividual
          average={average}
          errored={errored}
          includePrevious={includePrevious}
          key={id || i}
          loaded={false}
          loading={loading}
          massive={massive}
          scale={gaugeScale || propScale}
          suffix={isSuffixFunction ? suffix({ errored, loading }) : suffix}
          title={title || gaugeTitle}
          description={description || gaugeDescription}
        />
      )
    )
  }
  return dataSets.map(
    (
      {
        data,
        description: datasetDescription,
        name,
        scale,
        timeframe,
        title: dataSetTitle,
      },
      dsIndex
    ) => {
      return (
        data &&
        data.map((datum, index) => {
          return (
            <HeadlineIndividual
              key={datum.id || `hv-${dsIndex}-${index}`}
              average={average}
              datum={datum}
              diff={diff}
              fullstackId={fullstackId}
              errored={errored}
              includePrevious={includePrevious}
              loaded={loaded}
              loading={loading}
              massive={massive}
              scale={scale || propScale}
              suffix={isSuffixFunction ? suffix(datum) : suffix}
              timeframe={timeframe}
              title={`${dataSetTitle || title || name}${
                datum.x
                  ? ` ${formatAxisValueAs(scale.formatXAs || scale.x, datum.x)}`
                  : ''
              }`}
              description={description || datasetDescription}
            />
          )
        })
      )
    }
  )
}
