import { createSelector } from 'reselect'
import { createBasicSelector } from 'util/redux'

export function selectBase(state) {
  return state.starredCannedReplies
}

export const selectStarredCannedRepliesById = createBasicSelector(
  selectBase,
  base => base.byId
)

export const selectStarredCannedReplies = createSelector(
  selectStarredCannedRepliesById,
  byId => Object.values(byId)
)
