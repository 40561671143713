import React from 'react'

import { Icon } from 'util/ui'
import themeVars from 'ui_theme/site/globals/site.variables'
import globalConfig from './config'

export default function ChangeCell(props) {
  const { isNegative, isPositive, isBad, isGood, percent } = props.value || {}
  let color = globalConfig.defaultColor
  let indicator = ''
  if (isNegative) {
    indicator = <Icon name="caret down" />
  } else if (isPositive) {
    indicator = <Icon name="caret up" />
  } else {
    indicator = <Icon name="window minimize" />
  }

  if (isBad) {
    color = themeVars.berryColorName
  } else if (isGood) {
    color = themeVars.bioColorName
  }

  return (
    <span style={{ color }}>
      {indicator}
      {typeof percent !== 'undefined' && `${Math.round(Math.abs(percent))}%`}
      {typeof percent === 'undefined' && `-`}
    </span>
  )
}
