import React from 'react'

import { Overview2 } from 'ducks/reports/overviewLayouts'

import * as CustomPropTypes from 'ducks/reports/propTypes'
import {
  AverageFirstResponseTime,
  AverageRepliesPerResolution,
  AverageResponseTime,
  AverageResolutionTime,
  HeadlineChartSelector,
  ProductivityOverviewHeadlineLine,
} from 'ducks/reports/productivity'

ProductivityOverview.propTypes = {
  queryBase: CustomPropTypes.queryBase.isRequired,
}

export default function ProductivityOverview({
  queryBase,
  resolutionsQueryBase,
}) {
  return (
    <Overview2
      title={<HeadlineChartSelector />}
      components={[
        <ProductivityOverviewHeadlineLine
          queryBase={queryBase}
          resolutionsQueryBase={resolutionsQueryBase}
        />,
        <AverageFirstResponseTime queryBase={queryBase} includePrevious />,
        <AverageResponseTime
          queryBase={queryBase}
          includePrevious
          resolutionsQueryBase={resolutionsQueryBase}
        />,
        <AverageResolutionTime
          includePrevious
          queryBase={queryBase}
          resolutionsQueryBase={resolutionsQueryBase}
        />,
        <AverageRepliesPerResolution
          queryBase={queryBase}
          includePrevious
          resolutionsQueryBase={resolutionsQueryBase}
        />,
      ]}
    />
  )
}
