import {
  FETCH_KB_ARTICLES_STARTED,
  FETCH_KB_ARTICLES_SUCCESS,
  FETCH_KB_ARTICLES_FAILED,
  CLEAR_KB_ARTICLES,
} from '../../types'

const reducers = {}
export const defaultState = {
  isLoading: false,
  isLoaded: false,
  hasError: false,
  lastRequestedSearchTerm: [],
  data: [],
}

reducers[FETCH_KB_ARTICLES_STARTED] = (state, action) => {
  const { meta: { requestParameters: { keyword = null } = {} } = {} } = action

  return {
    ...state,
    isLoading: true,
    lastRequestedSearchTerm: keyword,
  }
}

reducers[FETCH_KB_ARTICLES_SUCCESS] = (state, action) => {
  const {
    payload: {
      articles: { data },
    },
  } = action

  return {
    ...state,
    isLoading: false,
    isLoaded: true,
    hasError: false,
    data,
  }
}

reducers[FETCH_KB_ARTICLES_FAILED] = (state, action) => {
  return {
    ...state,
    isLoading: false,
    hasError: true,
    error: action.payload.error,
  }
}

reducers[CLEAR_KB_ARTICLES] = state => {
  return {
    ...state,
    lastRequestedSearchTerm: '',
    data: defaultState.data,
  }
}

export default function reducer(state = defaultState, action) {
  // this is here because a long reducer with many ifs is unreadable
  const handler = reducers[action.type]
  if (handler) return handler(state, action)
  return state
}
