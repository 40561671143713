import averageIntervals from '../intervalCollapsers/averageIntervals'
import { countConversationsResolved } from '../queries/countConversationsResolved'

export const conversationsResolvedPerDayGauge = {
  collapseInterval: averageIntervals,
  description: 'Average number of conversations marked as closed per day.',
  queries: [
    {
      ...countConversationsResolved,
      interval: 'daily',
      key: 'countConversationsResolvedForEachDay',
    },
  ],
  title: 'Avg. Resolutions per Day',
}
