import { ratio } from '../calculators/ratio'
import { countMessageRatingsCreated } from '../queries/countMessageRatingsCreated'
import { countMessageRatingAwesome } from '../queries/countMessageRatingAwesome'

export const happinessScoreRatioByAgentGauge = {
  calculate: ratio,
  description: 'The percentage of ratings graded "awesome".',
  id: 'happiness_by_agent',
  queries: [
    {
      ...countMessageRatingAwesome,
      groupBy: 'action.change.agent.id',
      key: 'countMessageRatingAwesomeByAssignee',
    },
    {
      ...countMessageRatingsCreated,
      groupBy: 'action.change.agent.id',
      key: 'countMessageRatingsCreatedByAssignee',
    },
  ],
  scale: {
    y: 'percent',
    color: {
      good: 0.7,
      bad: 0.5,
    },
  },

  title: 'CSAT score',
}
