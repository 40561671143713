import cn from 'classnames'
import React from 'react'

import DatePicker from 'shared/components/ui/DatePicker'

import DatePickerDateRange from './DatePickerDateRange'
import DatePickerFooter from './DatePickerFooter'
import styles from './styles.less'

export default function DatePickerDropdownContent(props) {
  const {
    disabledDays,
    isOpen,
    focusedInput,
    month,
    onApply,
    onCancel,
    onDayClick,
    onDatesChange,
    onFocusChange,
    onMonthChange,
    pendingOrEndDate,
    pendingOrStartDate,
    earliestStartDate,
    today,
    presets,
    topOffset, // HACK (jscheel): Not worth rewriting this with popper right now
  } = props
  return (
    <div
      data-test-id="datePickerDropdownContent"
      className={cn(styles.DatePickerDropdownContentWrapper, {
        [styles.DatePickerDropdownContentWrapperActive]: isOpen,
      })}
      style={{ marginTop: topOffset }}
    >
      <div
        className={cn(styles.DatePickerDropdownContent, {
          [styles.DatePickerDropdownContent_open]: isOpen,
        })}
      >
        <DatePickerDateRange
          focusedInput={focusedInput}
          onFocusChange={onFocusChange}
          pendingOrEndDate={pendingOrEndDate}
          pendingOrStartDate={pendingOrStartDate}
        />
        <DatePicker
          className={styles.DatePicker}
          disabledDays={disabledDays}
          displayNumberOfMonths={3}
          month={month}
          onDayClick={onDayClick}
          onMonthChange={onMonthChange}
          selectedRangeEnd={pendingOrEndDate}
          selectedRangeStart={pendingOrStartDate}
        />
        <DatePickerFooter
          onApply={onApply}
          onCancel={onCancel}
          onDatesChange={onDatesChange}
          earliestStartDate={earliestStartDate}
          today={today}
          presets={presets}
        />
      </div>
    </div>
  )
}
