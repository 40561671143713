export const BASIC_FIELDS = `
  id
  name
  snippet
  subject
  automaticActions {
    value
    type
  }
`

export const getAllQuery = `
  query StarredCannedReplies {
    starredCannedReplies {
      nodes {
        ${BASIC_FIELDS}
      }
    }
  }
`

export const createQuery = `
  mutation StarredCannedReplyCreate(
    $cannedReplyId: String,
  ) {
    starredCannedReplyCreate(input: {
      cannedReplyId: $cannedReplyId
    }) {
      starredCannedReply {
        ${BASIC_FIELDS}
      }
    }
  }
`

export const deleteQuery = `
  mutation StarredCannedReplyDelete(
    $cannedReplyId: String,
  ) {
    starredCannedReplyDelete(input: {
      cannedReplyId: $cannedReplyId
    }) {
      messageTemplateId
      errors {
        message
        path
      }
    }
  }
`
