export const atLeastOneReassignment = {
  propertyName: 'conversation.reassignment_count',
  operator: 'gt',
  propertyValue: 0,
}

export const atLeastOneReply = {
  propertyName: 'conversation.response_count',
  operator: 'gt',
  propertyValue: 0,
}

export const firstToCustomer = {
  propertyName: 'action.change.first_to_customer',
  operator: 'eq',
  propertyValue: true,
}

export const firstInBurst = {
  propertyName: 'action.change.first_in_burst',
  operator: 'eq',
  propertyValue: true,
}

export const notResponseToClosed = {
  propertyName: 'action.change.response_to_closed_ticket',
  operator: 'ne', // We do != true rather than == false because we don't want to exclude events prior to Apr 2019 when this wasn't set
  propertyValue: true,
}

export const oneReply = {
  propertyName: 'conversation.response_count',
  operator: 'eq',
  propertyValue: 1,
}

export const userResolution = {
  propertyName: 'action.actor.user.id',
  operator: 'exists',
  propertyValue: true,
}

export const excludeImmediatelyClosedConversations = {
  propertyName: 'time.conversation_open_for',
  operator: 'gt',
  propertyValue: 0,
}
