import { groupBy, last, sortByKey } from 'util/arrays'

import { countArticleViews } from '../queries/countArticleViews'

export const topClickedArticleBySearchTermGauge = {
  id: 'top_click_article_by_search_term',
  title: 'Top clicked result',
  calculate: gauge => {
    const { loaded, queries } = gauge
    if (!loaded) return null
    const results = Array.from(
      groupBy(queries[0].result, item => item['article.id']),
      mapArrayItem => mapArrayItem[1] // Position 1 contains the value from the map
    ).map(items => {
      const winningItem = last(sortByKey(items, 'result'))
      return {
        search_term: winningItem['kb_referrer.search.term'],
        result: winningItem['article.id'],
      }
    })
    return results
  },
  queries: [
    {
      ...countArticleViews,
      groupBy: ['kb_referrer.search.term', 'article.id'],
      key: 'countArticleViewsByTermAndArticle',
    },
  ],
}
