import { createPayload } from 'optimistic/base'
import { toServerString } from 'util/date'
import { TITLE } from 'constants/changesetActionChangeTypes'

// builds a mock GraphQL response of a createChangeset mutation for a
// ticket title change
export const titleChangePayload = (
  state,
  ticketId,
  newTitle,
  previousTitle,
  folderId,
  search,
  updatedAt = toServerString(new Date())
) => {
  return createPayload(
    state,
    ticketId,
    'status',
    { title: newTitle, updated_at: updatedAt },
    [
      {
        change_type: TITLE,
        change: {
          title: newTitle,
          previousTitle,
          type: 'Title',
        },
      },
    ],
    folderId,
    search
  )
}
