import { ratio } from '../calculators/ratio'
import { countConversationsAssignedFirstTime } from '../queries/countConversationsAssignedFirstTime'
import { countConversationsCreated } from '../queries/countConversationsCreated'

export const conversationsAssignedCreatedRatioGauge = {
  calculate: ratio,
  description: 'The ratio of conversations assigned and conversations created.',
  queries: [countConversationsAssignedFirstTime, countConversationsCreated],
  title: 'Assigned/Created Conversations',
}
