import createCachedSelector from 're-reselect'

import { selectAreBusinessHoursEnabled } from 'ducks/reports/controls'
import { emptyArr } from 'util/arrays'

import previousQueriesCacheKey from './cacheKeys/previousQueriesCacheKey'
import selectDecoratedQueries from './selectDecoratedQueries'

export default createCachedSelector(
  selectAreBusinessHoursEnabled,
  state => state,
  (state, props) => props,
  (areBusinessHoursEnabled, state, props) => {
    const {
      includePrevious,
      gauge: { queries, businessHoursQueries = queries },
    } = props
    if (!includePrevious) return emptyArr
    return selectDecoratedQueries(state, {
      ...props,
      previous: true,
      queries: areBusinessHoursEnabled ? businessHoursQueries : queries,
    })
  }
)(previousQueriesCacheKey)
