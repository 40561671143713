export const BUSINESS_HOURS_LOAD_REQUEST =
  'reports/businessHours/BUSINESS_HOURS_LOAD_REQUEST'
export const BUSINESS_HOURS_LOAD_RESPONSE =
  'reports/businessHours/BUSINESS_HOURS_LOAD_RESPONSE'
export const BUSINESS_HOURS_LOAD_ERROR =
  'reports/businessHours/BUSINESS_HOURS_LOAD_ERROR'

export const BUSINESS_HOURS_SAVE = 'reports/businessHours/BUSINESS_HOURS_SAVE'
export const BUSINESS_HOURS_SAVE_STARTED =
  'reports/businessHours/BUSINESS_HOURS_SAVE_STARTED'
export const BUSINESS_HOURS_SAVE_SUCCESS =
  'reports/businessHours/BUSINESS_HOURS_SAVE_SUCCESS'
export const BUSINESS_HOURS_SAVE_FAILED =
  'reports/businessHours/BUSINESS_HOURS_SAVE_FAILED'
