import React from 'react'
import { useSelector } from 'react-redux'
import cn from 'classnames'
import { selectMaxReportingDays } from 'ducks/billing/selectors/features'

import { propFunc } from 'util/functions'

import styles from './styles.less'

export default function DatePickerPreset({ onApply, text, timeframe, limit }) {
  const maxReportingDays = Number(useSelector(selectMaxReportingDays))
  const disabled = isNaN(maxReportingDays) ? false : limit >= maxReportingDays

  return (
    <span
      className={cn(styles.DatePickerDateRangeItem, disabled && 'disabled')}
      data-test-id={`preset-${timeframe}`}
      onClick={propFunc(onApply, timeframe)}
    >
      {text}
    </span>
  )
}
