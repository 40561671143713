import { countUniqueSearchesOnSearchId } from '../queries/countUniqueSearchesOnSearchId'

export const totalSearchesByTermGauge = {
  id: 'total_search_by_term',
  queries: [
    {
      ...countUniqueSearchesOnSearchId,
      groupBy: 'search.term',
      key: 'countUniqueSearchesOnSearchIdByTerm',
    },
  ],
  title: 'Failed searches by term',
}
