import { single } from '../calculators/single'
import { countConversationsAssignedUniqueCustomer } from '../queries/countConversationsAssignedUniqueCustomer'

export const conversationsAssignedCustomersForEachDayGauge = {
  calculate: single,
  queries: [
    {
      ...countConversationsAssignedUniqueCustomer,
      interval: 'daily',
      key: 'countConversationsAssignedUniqueCustomerForEachDay',
    },
  ],
  title: 'Assigned Customers',
}
