import React from 'react'
import { v4 as uuidV4 } from 'uuid'

import SpacedItems from 'shared/components/ui/SpacedItems'

import { ReportsLayout } from 'ducks/reports/layout'
import * as CustomPropTypes from 'ducks/reports/propTypes'

import TeamsOverviewTable from '../TeamsOverviewTable'

const teamsOverviewTableId = uuidV4()

Page.propTypes = {
  queryBase: CustomPropTypes.queryBase.isRequired,
}

export default function Page({ queryBase }) {
  return (
    <ReportsLayout>
      <SpacedItems.Container direction="vertical" gap="ginormous">
        <SpacedItems.Item>
          <TeamsOverviewTable id={teamsOverviewTableId} queryBase={queryBase} />
        </SpacedItems.Item>
      </SpacedItems.Container>
    </ReportsLayout>
  )
}
