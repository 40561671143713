import { createActionTypeReducer } from 'util/reducers'
import { CHAT_PAGE, SOCIAL_PAGE } from 'subapps/chat/pages'
import { queryStringToQueryId } from 'ducks/searches/utils/query'

const currentQueryIdFromQueryReducer = (_, action) => {
  // https://github.com/faceyspacey/redux-first-router/blob/master/docs/query-strings.md#where-query-lives-on-your-actions
  const { meta: { location: { current: { query = {} } = {} } = {} } = {} } =
    action || {}
  return queryStringToQueryId(query)
}

export const currentQueryId = createActionTypeReducer(
  {
    [CHAT_PAGE]: currentQueryIdFromQueryReducer,
    [SOCIAL_PAGE]: currentQueryIdFromQueryReducer,
  },
  null
)
