import cn from 'classnames'
import React from 'react'

import { propFunc } from 'util/functions'
import { BoxHeadline, Header, Label } from 'shared/components/ui/Typography'
import styles from './styles.less'

export default function DatePickerDateRangeItem({
  alignRight,
  date,
  focusedInput,
  onClick,
  type,
}) {
  const isFocused = focusedInput === type
  return (
    <div
      className={cn(styles.DatePickerDateRangeItem, {
        [styles.DatePickerDateRangeItem_alignRight]: alignRight,
        [styles.DatePickerDateRangeItem_focus]: isFocused,
      })}
      onClick={propFunc(onClick, type)}
    >
      <Header color={isFocused ? 'lightSky' : 'sky'}>
        {date.toLocaleDateString('en-US', {
          day: '2-digit',
        })}
      </Header>
      <BoxHeadline color={isFocused ? 'sky' : 'ultraLight'} as="div">
        {date.toLocaleDateString('en-US', {
          month: 'long',
        })}{' '}
        {date.getFullYear()}
      </BoxHeadline>
      <Label
        color={isFocused ? 'lightSky' : 'ultraLight'}
        as="div"
        size="small"
      >
        {date.toLocaleDateString('en-US', {
          weekday: 'long',
        })}
      </Label>
    </div>
  )
}
