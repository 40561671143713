export default function calculateChange(datum, previousDatum, decorations) {
  const { y } = datum
  const { y: previousY = 0 } = previousDatum || {}
  const { isNegativeGood, isNegativeChangeGood = isNegativeGood } = decorations
  const difference = y - previousY
  const isPositive = difference > 0
  const isNegative = difference < 0
  const ratio =
    previousY === 0 && difference === 0
      ? 0
      : (previousY || previousY === 0) && difference / previousY
  return {
    difference,
    isBad: isNegativeChangeGood ? isPositive : isNegative,
    isGood: isNegativeChangeGood ? isNegative : isPositive,
    isInfinity: Math.abs(ratio) === Infinity,
    isNegative,
    isNegativeGood: isNegativeChangeGood,
    isNeutral: difference === 0,
    isPositive,
    percent: ratio * 100,
    previous: previousY,
    ratio,
  }
}
