export const BASIC_TEMPLATE_FIELDS = `
id
name
color
conversationCount
conversationCountDeleted: conversationCount(filter: { states: [DELETED] }),
creator {
  id
}
lastUsedAt
createdAt
updatedAt
`

export const getAllQuery = () => `
query TagsQuery(
  $filter: TagFilter,
  $cursor: String,
  $size: Int,
  $orderBy: TagOrder
) {
  tags(filter: $filter, after: $cursor, first: $size, orderBy: $orderBy) {
    totalCount
    totalPageCount
    nodes {
      ${BASIC_TEMPLATE_FIELDS}
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      endCursor
      startCursor
    }
  }
}
`
