import cn from 'classnames'
import React from 'react'

import styles from './styles.less'

export default function KbPreviewCategoryStatus({
  knowledgeBase: { title } = {},
  categories,
  state,
}) {
  // This component supports passing in a single category or an array of categories
  let normalizedArray = !Array.isArray(categories) ? [categories] : categories
  // when an article has no categories, it returns null. we want to remove that
  normalizedArray = normalizedArray.filter(item => !!item)
  return (
    <div className={styles.breadcrumbs}>
      <span className={styles.categories}>
        <span className={styles.category}>{title}</span>
        {normalizedArray.map(({ id, title: categoryTitle }) => (
          <span key={`category-breadcrumb-${id}`} className={styles.category}>
            {categoryTitle}
          </span>
        ))}
      </span>
      <span className={cn(styles.status, styles[`status-${state}`])}>
        {state === 'wip' ? 'modified' : state}
      </span>
    </div>
  )
}
