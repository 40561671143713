import React, { Fragment } from 'react'
import { SUI, BoxHeadline, Header } from 'shared/ui'
import Modal from 'components/Modal'
import styles from './styles.less'

export default function NoKnowledgebases({ offset, onClose, open }) {
  if (!open) return null
  return (
    <Modal offset={offset} onBackgroundClick={onClose}>
      <div className={styles.noKnowledgebases}>
        <SUI>
          <Fragment>
            <Header as="div" size="small" className="header" fontWeight="bold">
              Knowledge Base
            </Header>
            <BoxHeadline as="div" color="light" className="body">
              Groove’s Knowledge Base let’s you create accessible articles for
              common questions, FAQ’s, and help guides.<br />
              <br />
              Creating a Knowledge Base takes just a few minutes, and can save
              you hours in future.
            </BoxHeadline>
          </Fragment>
          <a
            className="ui button primary small"
            target="_blank"
            rel="noopener noreferrer"
            href="/kb/create"
          >
            Get started!
          </a>
        </SUI>
      </div>
    </Modal>
  )
}
