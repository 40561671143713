import { emptyArr, uniq } from 'util/arrays'

import moment from 'moment-timezone-all'

function calculateResult(queries, gauge) {
  const { collapseInterval = x => x } = gauge
  const query = queries[0]
  if (!query) return []
  const { result = [] } = query
  const calculatedResult = result.map(resultItem => {
    const { start } = resultItem.timeframe
    const x = moment(start).toDate()
    const y =
      (resultItem.value || resultItem.value === 0) && Number(resultItem.value)
    return {
      id: x,
      x,
      y,
    }
  })
  return collapseInterval(calculatedResult, gauge)
}

export default function generateIntervalledDataSets(gauge) {
  const {
    colorScale,
    description,
    fill,
    id,
    includePrevious,
    isJoinedThrough,
    isNegativeChangeGood,
    isNegativeGood,
    loaded,
    previousQueries = emptyArr,
    queries = emptyArr,
    nullIsValidX,
    title: name,
    totalCount,
    scale,
    sort,
    xValueTranslations,
  } = gauge

  if (!loaded) return []
  if (queries.length > 1) {
    // @TODO Allow multiple grouped intervalled queries to be reduced down to a single
    // result set via the gauge's calculate function.
    throw new Error(
      "Intervalled datasets don't support calculating the result of multiple queries."
    )
  }
  const query = queries[0]
  const r = [
    {
      colorScale,
      data: calculateResult(queries, gauge),
      description,
      fill,
      id,
      isJoinedThrough,
      isNegativeChangeGood,
      isNegativeGood,
      nullIsValidX,
      queries,
      page: query && query.page,
      perPage: query && query.perPage,
      previousPeriod: includePrevious && {
        queries: previousQueries,
        data: calculateResult(previousQueries, gauge),
      },
      scale,
      sort,
      sortKey: id,
      title: name,
      totalCount,
      xValueTranslations,
    },
  ]
  return r
}
