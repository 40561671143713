import { v4 as uuidV4 } from 'uuid'
import { doGraphqlRequest, doRequest } from 'ducks/requests/operations'
import { MERGE_SEARCH } from 'constants/action_types'
import { DEFAULT_SORT_ORDER, NEWEST } from 'constants/defaults'
import { doFetchTicketSearch } from 'actions/search/doFetchTicketSearch'
import { searchQuery } from 'api/search'
import { mergedTicketPayload } from 'optimistic/merger'
import { selectCurrentFolderId, fetchingStatusesSelector } from 'selectors/app'
import {
  selectMergeableForCurrentCustomer,
  selectCurrentMergeSearchQueryID,
  selectCurrentMergeSearchQueryObjectForAPI,
  selectCurrentMergeSearchQueryObject,
  selectCurrentMergeSearch,
  shouldFetchMergeSearchByQueryString,
} from 'ducks/merging/selectors/search'
import { selectTicketSearchOperatorValueMap } from 'selectors/search/base'
import { selectCurrentTicketSearchQueryObject } from 'selectors/search'
import { selectCurrentCustomer } from 'selectors/tickets/customer/selectCurrentCustomer'
import { selectAccountPreferenceSortByCollaboratorCommentEnabled } from 'selectors/app/selectAccountPreferences'
import { selectFoldersById } from 'ducks/folders/selectors/folders'
import { constructSearchQueryId } from 'util/search'
import { toGraphQL } from 'util/search/sorting'

import { mergeQuery } from './queries'
import {
  MERGE_TICKET,
  FETCH_MERGEABLE_TICKETS,
  CHANGE_MERGE_DIRECTION,
  UPDATE_MERGING_SEARCH_TERM,
  UPDATE_MERGING_MERGE_TICKET,
  RESET_TO_DEFAULTS,
} from './types'

export const doUpdateSearchTerm = term => dispatch => {
  return dispatch({
    type: UPDATE_MERGING_SEARCH_TERM,
    data: { term },
  })
}

export const doUpdateMergeTicket = mergeTicket => dispatch => {
  return dispatch({
    type: UPDATE_MERGING_MERGE_TICKET,
    data: { mergeTicket },
  })
}

export const doResetToDefaults = () => dispatch => {
  return dispatch({
    type: RESET_TO_DEFAULTS,
  })
}

export const doChangeMergeDirection = direction => dispatch => {
  return dispatch({
    type: CHANGE_MERGE_DIRECTION,
    data: { direction },
  })
}

export const doFetchMergeableTickets = (requestedPage = 1) => (
  dispatch,
  getState
) => {
  const state = getState()
  const customer = selectCurrentCustomer(state)
  if (!customer) return false

  const customerId = customer.id
  const searchQueryObject = {
    customer: customerId,
  }
  const sortByCollaboratorCommentAtEnabled = selectAccountPreferenceSortByCollaboratorCommentEnabled(
    state
  )

  let page = requestedPage
  if (!page) {
    const currentMergeable = selectMergeableForCurrentCustomer(state) || {
      currentPage: 0,
    }

    if (!currentMergeable.resultsStale) {
      page = currentMergeable.currentPage + 1
    } else {
      page = 1
    }
  }

  return dispatch(
    doGraphqlRequest(
      FETCH_MERGEABLE_TICKETS,
      searchQuery(),
      {
        searchQuery: searchQueryObject,
        sortOrder: toGraphQL(NEWEST, { sortByCollaboratorCommentAtEnabled }),
        page,
        perPage: null,
      },
      {
        transformResponse: data => {
          const search = data.search
          const {
            currentPage,
            totalPages,
            totalCount,
          } = search.tickets.metadata
          const tickets = search.tickets.records
          const valueMap = selectTicketSearchOperatorValueMap(state)
          const queryId = constructSearchQueryId(searchQueryObject, valueMap)
          const foldersById = selectFoldersById(state)
          const ticketSearchOperatorValueMap = selectTicketSearchOperatorValueMap(
            state
          )
          return {
            page,
            queryId,
            queryObject: searchQueryObject,
            currentPage,
            totalPages,
            totalCount,
            tickets,
            foldersById,
            ticketSearchOperatorValueMap,
          }
        },
      }
    )
  )
}

export const doMerge = ({ targetTicketId, childTicketIds }) => (
  dispatch,
  getState
) => {
  const state = getState()

  const transactionId = uuidV4()
  const optimisticData = mergedTicketPayload(
    state,
    targetTicketId,
    childTicketIds,
    selectCurrentFolderId(state),
    selectCurrentTicketSearchQueryObject(state),
    transactionId
  )

  return dispatch(
    doGraphqlRequest(
      MERGE_TICKET,
      mergeQuery,
      {
        ticketId: targetTicketId,
        childTicketIds,
        changesetId: transactionId,
      },
      {
        optimist: optimisticData
          ? {
              id: transactionId,
            }
          : {},
        meta: {
          requestId: transactionId,
        },
        transformResponse: data => {
          const { ticket, mergedTickets } = data.mergeTickets

          if (ticket.diff) {
            ticket.diff = JSON.parse(ticket.diff) // eslint-disable-line no-param-reassign
          }

          if (mergedTickets && mergedTickets.tickets) {
            mergedTickets.tickets.map(t => {
              if (t.diff) {
                t.diff = JSON.parse(t.diff) // eslint-disable-line no-param-reassign
              }
              return t
            })
          }

          return { ticket, mergedTickets }
        },
        moduleOptions: {
          toasts: {
            enabled: true,
            started: {
              enabled: false,
            },
            success: {
              enabled: true,
              content: 'Ticket merged',
            },
            failed: {
              enabled: true,
              content: 'Ticket merge failed',
            },
          },
        },
      }
    )
  )
}

export const doFetchMergeSearchByQueryString = (page = 1) => (
  dispatch,
  getState
) => {
  const state = getState()
  const statuses = fetchingStatusesSelector(state)
  if (statuses.fetchTicketSearch) return Promise.resolve({})

  const searchQueryId = selectCurrentMergeSearchQueryID(state)
  const searchQueryObject = selectCurrentMergeSearchQueryObject(state)
  const searchQueryObjectForAPI = selectCurrentMergeSearchQueryObjectForAPI(
    state
  )
  const sortOrder = DEFAULT_SORT_ORDER
  const previousPages = selectCurrentMergeSearch(state)
  const previous = previousPages && previousPages[page]

  if (previous && previous.length > 1) return Promise.resolve({})

  return dispatch(
    doRequest(
      MERGE_SEARCH,
      async ({ variables }) => {
        return dispatch(
          doFetchTicketSearch(
            variables.searchQueryId,
            variables.searchQueryObject,
            variables.searchQueryObjectForAPI,
            variables.sortOrder,
            variables.page
          )
        )
      },
      {
        searchQueryId,
        searchQueryObject,
        searchQueryObjectForAPI,
        sortOrder,
        page,
      }
    )
  )
}

export function doLoadMergeSearchByQueryString(page = 1) {
  return (dispatch, getState) => {
    if (!shouldFetchMergeSearchByQueryString(getState())) return false
    return dispatch(doFetchMergeSearchByQueryString(page))
  }
}
