import React from 'react'
import { connect } from 'react-redux'

import { doHideBusinessHoursNotice } from '../../actions'
import {
  selectAreBusinessHoursEnabled,
  selectShowBusinessHoursNotice,
} from '../../selectors'
import BusinessHoursNoticeView from './BusinessHoursNoticeView'

const select = state => ({
  areBusinessHoursEnabled: selectAreBusinessHoursEnabled(state),
  showBusinessHoursNotice: selectShowBusinessHoursNotice(state),
})

const perform = {
  doHideBusinessHoursNotice,
}

export default connect(select, perform)(BusinessHoursNoticeView)
