import {
  isGrouped,
  isGroupedIntervalled,
  isSingleDatum,
  isIntervalled,
} from './dataSetTypeDetectors'

import generateGroupedDataSets from './generateGroupedDataSets'
import generateGroupedIntervalledDataSets from './generateGroupedIntervalledDataSets'
import generateIntervalledDataSets from './generateIntervalledDataSets'
import generateSingleDatumDataSets from './generateSingleDatumDataSets'

export default function generateDataSetsForGauge(gauge) {
  if (isSingleDatum(gauge)) return generateSingleDatumDataSets(gauge)
  if (isGrouped(gauge)) return generateGroupedDataSets(gauge)
  if (isIntervalled(gauge)) return generateIntervalledDataSets(gauge)
  if (isGroupedIntervalled(gauge))
    return generateGroupedIntervalledDataSets(gauge)
  throw new Error('Gauges with mixed result types are not supported.')
}
