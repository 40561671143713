import { isDefined } from 'util/nullOrUndefinedChecks'
import fillY from './fillY'
import formatAgent from './formatAgent'
import formatArticle from './formatArticle'
import formatChange from './formatChange'
import formatDuration from './formatDuration'
import formatEmpty from './formatEmpty'
import formatInteger from './formatInteger'
import formatPercent from './formatPercent'
import formatPercentChange from './formatPercentChange'
import formatPercentTotal from './formatPercentTotal'
import formatRatings from './formatRatings'
import formatRatingsThumbs from './formatRatingsThumbs'
import formatTeams from './formatTeams'

export default function formatDatum(datum = {}, options = {}) {
  const { x, xActual = x } = datum
  const {
    scale: { y: yScale, formatYAs = yScale } = {},
    dataByX = {},
  } = options
  const filledY = fillY(datum, options)
  const filledDatum = {
    ...datum,
    filledY,
  }

  if (filledY === null) {
    // no data returned from keen for query for this particular cell
    // instead of returning blank string which is confusing users, try to:
    // 1. return 0 if other row cells have data and they are integers
    // 2. Fallback to returning formatEmpty if a non-integer cell/data point
    const { y: sampleY } =
      Object.values(dataByX).find(({ y }) => isDefined(y) && !isNaN(y)) || {}

    if (isDefined(sampleY) && !formatYAs) {
      if (typeof sampleY === 'number') return 0
      // add more cases here as you see fit
    }

    return formatEmpty(filledDatum, options)
  }
  if (formatYAs === 'agent') return formatAgent(filledDatum, options)
  if (formatYAs === 'article') return formatArticle(filledDatum, options)
  if (formatYAs === 'teams') return formatTeams(filledDatum, options)
  if (formatYAs === 'integer') return formatInteger(filledDatum, options)
  if (formatYAs === 'duration') return formatDuration(filledDatum, options)
  if (formatYAs === 'x') return xActual
  if (formatYAs === 'percent') return formatPercent(filledDatum, options)
  if (formatYAs === 'change') return formatChange(filledDatum, options)
  if (formatYAs === 'ratings') return formatRatings(filledDatum, options)
  if (formatYAs === 'ratings-thumbs')
    return formatRatingsThumbs(filledDatum, options)
  if (formatYAs === 'percent_change')
    return formatPercentChange(filledDatum, options)
  if (formatYAs === 'percent_total')
    return formatPercentTotal(filledDatum, options)
  return filledY
}
