import cn from 'classnames'
import React, { Fragment } from 'react'
import { times } from 'util/arrays'
import styles from './styles.less'

export default function TicketPreviewPlaceholder({ count }) {
  return (
    <Fragment>
      {times(count, index => (
        <div
          key={`ticket-preview-placeholder-${index}`}
          className={cn(styles.placeholder, 'placeholder-collapsed-changeset')}
        >
          <div className="placeholder-byline" />
          <div className="placeholder-date" />
          <div className="placeholder-cc" />
          <div className="placeholder-content" />
          <div className="placeholder-content" />
          <div className="placeholder-content" />
        </div>
      ))}
    </Fragment>
  )
}
