const AVERAGE_REPLACEMENTS = {
  average: {
    average: 'average',
    Average: 'Average',
    AVERAGE: 'AVERAGE',
    avg: 'avg',
    Avg: 'Avg',
    AVG: 'AVG',
    'avg.': 'avg.',
    'Avg.': 'Avg.',
    'AVG.': 'AVG.',
    "avg'": "avg'",
    "Avg'": "Avg'",
    "AVG'": "AVG'",
  },

  mean: {
    average: 'mean',
    Average: 'Mean',
    AVERAGE: 'MEAN',
    avg: 'mean',
    Avg: 'Mean',
    AVG: 'MEAN',
    'avg.': 'mean',
    'Avg.': 'Mean',
    'AVG.': 'MEAN',
    "avg'": 'mean',
    "Avg'": 'Mean',
    "AVG'": 'MEAN',
  },

  median: {
    average: 'median',
    Average: 'Median',
    AVERAGE: 'MEDIAN',
    avg: 'med',
    Avg: 'Med',
    AVG: 'MED',
    'avg.': 'med.',
    'Avg.': 'Med.',
    'AVG.': 'MED.',
    "avg'": "med'",
    "Avg'": "Med'",
    "AVG'": "MED'",
  },

  percentile: {
    average: 'percentile',
    Average: 'Percentile',
    AVERAGE: 'PERCENTILE',
    avg: 'pctl',
    Avg: 'Pctl',
    AVG: 'PCTL',
    'avg.': 'pctl.',
    'Avg.': 'Pctl.',
    'AVG.': 'PCTL.',
    "avg'": "pctl'",
    "Avg'": "Pctl'",
    "AVG'": "PCTL'",
  },
}

const REPLACEMENT_LOOK_FOR = Object.values(AVERAGE_REPLACEMENTS).map(x =>
  Object.keys(x)
)

const REPLACEMENT_REGEX = new RegExp(
  `(?:\\$)(${[].concat(...REPLACEMENT_LOOK_FOR).join('|')})`,
  'g'
)

const AVG_REGEX = /^(average|percentile|mean|median)/

export default function replaceAverage(string, fullAverage) {
  const average = (fullAverage || 'average').match(AVG_REGEX)[1]
  const replacements = AVERAGE_REPLACEMENTS[average]
  return string.replace(REPLACEMENT_REGEX, (a, x) => replacements[x])
}
