import React from 'react'

import { Overview14 } from 'ducks/reports/overviewLayouts'

import ArticlePerformanceForEachDay from '../ArticlePerformanceForEachDay'

import AverageKbTimeOnArticle from './AverageKbTimeOnArticle'
import ViewedArticleRatio from './ViewedArticleRatio'
import PerformedSearchRatio from './PerformedSearchRatio'
import TotalKbVisitorsHeadline from './TotalKbVisitorsHeadline'
import TotalKbVisitsHeadline from './TotalKbVisitsHeadline'
import TotalKbArticleViewsHeadline from './TotalKbArticleViewsHeadline'

export default function KbOverview({ queryBatch, queryBase }) {
  return (
    <Overview14
      title="Knowledge Base performance"
      components={[
        <ArticlePerformanceForEachDay
          queryBatch={queryBatch}
          queryBase={queryBase}
        />,
        <PerformedSearchRatio
          queryBatch={queryBatch}
          queryBase={queryBase}
          includePreviousPeriod
        />,
        <ViewedArticleRatio
          queryBatch={queryBatch}
          queryBase={queryBase}
          includePreviousPeriod
        />,
        <TotalKbVisitorsHeadline
          queryBatch={queryBatch}
          queryBase={queryBase}
          includePrevious
        />,
        <TotalKbVisitsHeadline
          queryBatch={queryBatch}
          queryBase={queryBase}
          includePrevious
        />,
        <TotalKbArticleViewsHeadline
          queryBatch={queryBatch}
          queryBase={queryBase}
          includePrevious
        />,
        <AverageKbTimeOnArticle
          queryBatch={queryBatch}
          queryBase={queryBase}
          includePrevious
        />,
      ]}
    />
  )
}
