import React from 'react'
import activation from 'assets/csat-reporting-activation.png'
import ActivationCardWithBackground from 'components/ActivationCardWithBackground'
import { SETTINGS_SATISFACTION_RATINGS_PAGE } from 'subapps/settings/types'
import { happiness as HappinessIcon } from 'assets/icons/groove/v2'
import { styles } from './styles'

const toSettingsSatisfactionRatingsPage = {
  type: SETTINGS_SATISFACTION_RATINGS_PAGE,
}

const ActivationScreen = () => {
  return (
    <div css={styles.container}>
      <ActivationCardWithBackground backgroundImg={activation}>
        <ActivationCardWithBackground.Card
          title="Know how your customers are feeling with CSAT "
          buttonLabel="Enable CSAT for your account"
          buttonActionTo={toSettingsSatisfactionRatingsPage}
          icon={HappinessIcon}
          css={styles.card}
          note="Setup time of 1 minute"
        >
          Customer Satisfaction Ratings are a great way to get feedback from
          your customers on how you&apos;re team is doing by asking customers to
          rate the service they&apos;ve received on closed conversations.
        </ActivationCardWithBackground.Card>
      </ActivationCardWithBackground>
    </div>
  )
}

export default ActivationScreen
