import {
  selectPendingEntityById,
  selectCurrentEntitiesById,
  selectPendingEntitiesById,
} from 'ducks/entities/selectors'
import { emptyArr } from 'util/arrays'

export const selectPendingMessageTemplateById = (state, entityType, id) =>
  selectPendingEntityById(state, entityType, id) || null

export const selectPendingMessageTemplateBodyById = (state, entityType, id) =>
  selectPendingEntityById(state, entityType, id)?.body || null

export const selectPendingMessageTemplateSubjectById = (
  state,
  entityType,
  id
) => selectPendingEntityById(state, entityType, id)?.subject || null

export const selectPendingMessageTemplateAttachmentIdsById = (
  state,
  entityType,
  id
) => selectPendingEntityById(state, entityType, id)?.attachments || emptyArr

export const selectCurrentAttachmentsById = state =>
  selectCurrentEntitiesById(state, 'attachment')

export const selectPendingAttachmentsById = state =>
  selectPendingEntitiesById(state, 'attachment')
