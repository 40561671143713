import { createSelector } from 'reselect'

import { selectAreBusinessHoursEnabled } from 'ducks/reports/controls'
import { selectQueriesByIdRaw } from 'ducks/reports/queries'

export default createSelector(
  selectAreBusinessHoursEnabled,
  selectQueriesByIdRaw,
  (areBusinessHoursEnabled, queriesById) => {
    return {
      reports: {
        controls: {
          areBusinessHoursEnabled,
        },
        queries: {
          byId: queriesById,
        },
      },
    }
  }
)
