import { countOpenAssignmentsToAnAgent } from '../queries/countOpenAssignmentsToAnAgent'
import { countConversationOpened } from '../queries/countConversationOpened'
import { countConversationClosed } from '../queries/countConversationClosed'
import { countOpenUnassignmentsFromScopedTeamOrAgent } from '../queries/countOpenUnassignmentsFromScopedTeamOrAgent'

export const netDiffOpenAssignedAgentConversationsGauge = {
  calculate: results => {
    return results[0] + results[1] - results[2] - results[3]
  },
  description:
    'Difference in opened conversations between the start and end of this period. Negative numbers are better and indicate that you are resolving more conversations than are being opened.',
  isNegativeChangeGood: true,
  isNegativeGood: true,
  queries: [
    countOpenAssignmentsToAnAgent,
    countConversationOpened,
    countConversationClosed,
    countOpenUnassignmentsFromScopedTeamOrAgent,
  ],
  title: 'Net Difference in Opened Conversations',
}
