import React from 'react'

import { Button } from 'util/ui'

import styles from './styles.less'

export default function DatePickerButtons({ onApply, onCancel }) {
  return (
    <div className={styles.DatePickerButtons}>
      <Button onClick={onCancel}>Cancel</Button>
      <Button data-test-id="apply" onClick={onApply} primary>
        Apply
      </Button>
    </div>
  )
}
