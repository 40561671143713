import { normalize } from 'normalizr'
import { v4 as uuidV4, validate as validateUUID } from 'uuid'
import { selectSearchByQueryId } from 'ducks/searches/selectors'
import { createDoFetchInMemoryByQueryId } from 'ducks/searches/operations/createDoFetchInMemoryByQueryId'
import {
  doApiReadRequest,
  doApiWriteRequest,
  doAttachementRequest,
} from 'ducks/requests/operations'
import {
  selectCurrentRules,
  selectCurrentRuleForId,
} from 'ducks/rules/selectors'
import { buildId, isGid } from 'util/globalId'
import { isEmpty, omit } from 'util/objects'
import { rule as ruleEntity } from 'ducks/entities/schema'
import {
  changeEntity,
  clearEntities,
  mergeEntityChanges,
} from 'ducks/entities/actionUtils'
import { reverseHashInt } from 'util/scatterSwap'
import { stringToColor } from 'util/colors'
import { isDefined, isNullOrUndefined } from 'util/nullOrUndefinedChecks'
import { doCreateUpdateTagV2 } from 'ducks/tags/actions'
import { doTryFetchAccountUsageOnboardingForOnboarding } from 'ducks/accountPreferences/operations'
import { selectFeatureBasedOnboardingWorkflowData } from 'subapps/onboarding/selectors'

import {
  FETCH_RULES,
  FETCH_RULE,
  DELETE_RULE,
  UPDATE_RULE,
  UPLOAD_RULE_ACTION_ATTACHMENT,
  SAVE_RULE_DRAFT,
  CLEAR_ALL_RULE_DRAFTS,
} from './actionTypes'
import { rulesApiV1ResponseSchema, ruleApiV1ResponseSchema } from './schema'

const LOAD_ALL_RULES_QUERYID = 'entityType:rule pageSize:10000'

export const doFetchRulesV1 = ({ skipLoaded } = {}) => (dispatch, getState) => {
  const queryId = LOAD_ALL_RULES_QUERYID
  const cursor = 'all'
  const state = getState()
  const { loaded = null, cursors = {} } = selectSearchByQueryId(state, queryId)
  const hasCurrentPage = !!cursors[cursor]
  const isStale = hasCurrentPage && cursors[cursor].isStale

  const hasLoaded = loaded && hasCurrentPage && !isStale

  if (hasLoaded && skipLoaded) {
    // Note we might need to change this in future to return the results
    // from the previous query
    return Promise.resolve({})
  }

  return dispatch(
    doApiReadRequest(
      FETCH_RULES,
      'v1/automation_rules',
      {
        per_page: 10000,
      },
      {
        normalizationSchema: rulesApiV1ResponseSchema,
        app: true,
        searches: {
          queryId,
          cursor,
          extractPagination: 'apiv1',
        },
      }
    )
  )
}

export const doFetchRulesV1ById = (id, options = {}) => dispatch => {
  // targetStore: optional, defaults to changeEntity()'s default store otherwise
  const { targetStore } = options

  return dispatch(
    doApiReadRequest(
      FETCH_RULE,
      `v1/automation_rules/${id}`,
      {},
      {
        normalizationSchema: ruleApiV1ResponseSchema,
        moduleOptions: {
          entities: {
            targetStore,
            missingEntityActions: [
              {
                entityType: 'rule',
                // convert from gid to scatter swapped that's used in entity store
                entityId: id,
                phases: ['SUCCESS'],
              },
            ],
          },
        },
      }
    )
  )
}

export const doFetchRulesInMemory = createDoFetchInMemoryByQueryId({
  fromQueryId: LOAD_ALL_RULES_QUERYID,
  entityType: 'rule',
  doLoadAllFn: doFetchRulesV1,
})

// eslint-disable-next-line no-unused-vars
export const doDeleteSingleRule = (id, options = {}) => dispatch => {
  return dispatch(
    doApiWriteRequest(
      DELETE_RULE,
      `api/settings/rules/${buildId('Rule', id)}`,
      {},
      {
        method: 'DELETE',
        optimist: {},
        normalizationSchema: rulesApiV1ResponseSchema,
        searches: {
          additionalActions: [
            {
              type: 'INVALIDATE_ENTITIES',
              entityTypes: ['rule'],
              phases: ['SUCCESS'],
            },
          ],
        },
        moduleOptions: {
          toasts: {
            enabled: true,
            started: {
              enabled: true,
              content: 'Rule deleted',
            },
            success: {
              enabled: false,
            },
            failed: {
              content: 'Rule deletion failed',
              onClickAction: () => {
                dispatch(doDeleteSingleRule(id, options))
              },
            },
          },
          entities: {
            targetOperation: 'remove',
            additionalActions: [
              {
                // Because we're passing through a global id here, we need to manually delete
                // the entity from the store
                entityType: 'rule',
                entityId: id,
                stores: ['pending', 'current'],
                operation: 'remove',
                phases: ['STARTED'],
              },
            ],
          },
        },
      }
    )
  )
}

function calculateRulePosition(rule, currentOrderedRules) {
  if (currentOrderedRules[rule.row_order + 1]) {
    return currentOrderedRules[rule.row_order + 1].row_order - 10
  } else if (currentOrderedRules[rule.row_order - 1]) {
    return currentOrderedRules[rule.row_order - 1].row_order + 10
  }
  return rule.row_order
}

export const doUpdateSingleRule = (rule, options = {}) => async (
  dispatch,
  getState
) => {
  const id = rule.id
  const { updatePosition, queryId, orderedIds = null } = options

  const state = getState()

  const orderedRules = selectCurrentRules(state)
  const currentEntity = selectCurrentRuleForId(state, id)

  const currentTriggerIds = {}
  const currentConditionsIds = {}
  const currentActionsIds = {}
  if (currentEntity) {
    currentEntity.triggers.forEach(tId => {
      currentTriggerIds[tId] = true
    })
    // eslint-disable-next-line no-underscore-dangle
    currentEntity.conditions.forEach(cId => {
      currentConditionsIds[cId] = true
    })
    currentEntity.actions.forEach(aId => {
      currentActionsIds[aId] = true
    })
  }

  const triggers = []
  const conditions = []
  const actions = []
  const uploadAttachments = []

  rule.triggers.forEach(trig => {
    const trigger = trig
    delete currentTriggerIds[trig.id]

    if (isDefined(trig.id) && validateUUID(trig.id)) {
      // is a temp id created from saving draft
      delete trigger.id
    }

    triggers.push(trigger)
  })

  rule.conditions.forEach(cond => {
    const condition = cond
    delete currentConditionsIds[cond.id]

    if (['assignee', 'mailbox'].includes(condition.param)) {
      condition.value = cond.value
        .split(',')
        .map(gid => reverseHashInt(gid))
        .join(',')
    }

    if (isDefined(cond.id) && validateUUID(cond.id)) {
      // is a temp id created from saving draft
      delete condition.id
    }

    conditions.push(condition)
  })

  rule.actions.forEach((action, actionIndex) => {
    delete currentActionsIds[action.id]
    const messageTemplate = action.messageTemplate
    const ruleReplyTemplateId = action.rule_reply_template
    const sanitizedAction = omit(
      ['messageTemplate', 'rule_reply_template'],
      action
    )

    if (messageTemplate) {
      const { attachments, body, subject } = messageTemplate
      let { pendingUploads } = messageTemplate

      if (pendingUploads) {
        pendingUploads = pendingUploads.map(pendingUpload => ({
          ...pendingUpload,
          actionIndex,
        }))
      }

      const actionMessageTemplate = {
        body,
        subject,
      }

      if (ruleReplyTemplateId && !validateUUID(ruleReplyTemplateId)) {
        // we're updating an existing rule message template
        actionMessageTemplate.id = reverseHashInt(ruleReplyTemplateId)
      }

      if (Array.isArray(attachments)) {
        actionMessageTemplate.attachment_ids = attachments
          .filter(attachmentId => {
            if (!pendingUploads) {
              return true
            }

            const pendingUpload = pendingUploads.find(
              p => p.attachmentId === attachmentId
            )
            return !pendingUpload
          })
          .map(attachmentId => reverseHashInt(attachmentId))
      }

      if (Array.isArray(pendingUploads) && pendingUploads.length > 0) {
        uploadAttachments.push(...pendingUploads)
      }

      sanitizedAction.rule_reply_template_attributes = actionMessageTemplate
      // keep action.value to be the same as message template body so it's backwards compatible with old settings screen
      sanitizedAction.value = body
    }

    if (isDefined(action.id) && validateUUID(action.id)) {
      // is a temp id created from saving draft
      delete sanitizedAction.id
    }

    actions.push(sanitizedAction)
  })

  // API V0 expects deletes to be sent a an array of objects with
  // id and _delete=1
  Object.keys(currentTriggerIds).forEach(triggerId => {
    if (triggerId && triggerId !== 'undefined') {
      triggers.push({
        // eslint-disable-next-line no-underscore-dangle
        _destroy: 'true',
        id: triggerId,
      })
    }
  })
  Object.keys(currentConditionsIds).forEach(conditionId => {
    if (conditionId && conditionId !== 'undefined') {
      conditions.push({
        // eslint-disable-next-line no-underscore-dangle
        _destroy: 'true',
        id: conditionId,
      })
    }
  })
  Object.keys(currentActionsIds).forEach(actionId => {
    if (actionId && actionId !== 'undefined') {
      actions.push({
        // eslint-disable-next-line no-underscore-dangle
        _destroy: 'true',
        id: actionId,
      })
    }
  })

  const payload = {
    id,
    active: rule.active,
    match_type: rule.match_type,
    name: rule.name,
    stop_upcoming: rule.stop_upcoming,
    rule_actions_attributes: actions,
    rule_conditions_attributes: conditions,
    rule_triggers_attributes: triggers,
    schedule_type: rule.schedule_type,
    schedule_settings: rule.schedule_settings,
  }
  if (updatePosition) {
    payload.row_order_position = rule.position
  }

  const optimisticRule = {
    ...rule,
  }
  if (updatePosition) {
    optimisticRule.position = calculateRulePosition(rule, orderedRules)
  }

  const normalizedEntities = normalize(rule, ruleEntity)

  const optimist = {
    entities: normalizedEntities.entities,
  }

  const response = await dispatch(
    doApiWriteRequest(
      UPDATE_RULE,
      `v1/automation_rules/${rule.id}`,
      { rule: payload },
      {
        method: 'PUT',
        optimist,
        normalizationSchema: ruleApiV1ResponseSchema,
        searches: {
          additionalActions: [
            {
              type: 'INVALIDATE_ENTITIES',
              entityTypes: Object.keys(optimist.entities),
              phases: ['SUCCESS'],
            },
            {
              type: 'UPDATE_CURSOR',
              queryId,
              entityIds: orderedIds,
              phases: ['STARTED'],
            },
          ],
        },
        moduleOptions: {
          entities: {
            additionalActions: Object.keys(optimist.entities).map(
              entityType => {
                return {
                  entityType,
                  stores: ['pending'],
                  phases: ['SUCCESS'],
                  type: 'clear',
                }
              }
            ),
          },
          toasts: {
            enabled: true,
            started: {
              enabled: false,
            },
            success: {
              enabled: true,
              content: 'Rule updated',
            },
            failed: {
              content: 'Rule update failed',
              onClickAction: () => {
                dispatch(doUpdateSingleRule(rule, options))
              },
            },
          },
        },
      }
    )
  )

  if (response && response.automation_rule && uploadAttachments.length > 0) {
    await Promise.all(
      response.automation_rule.actions.map(async (action, actionIndex) => {
        if (!action.rule_reply_template) {
          return null
        }

        const ruleTemplateAttachmentsToUpload = uploadAttachments.filter(
          u => u.actionIndex === actionIndex
        )
        const ruleReplyTemplateId = action.rule_reply_template.id
        return dispatch(
          doAttachementRequest(
            UPLOAD_RULE_ACTION_ATTACHMENT,
            `/rule_reply_templates/${ruleReplyTemplateId}/attachments`,
            ruleTemplateAttachmentsToUpload.map(upload => upload.editorFile),
            options
          )
        )
      })
    )
  }

  return response
}

export const doCreateSingleRule = (rule, options = {}) => async (
  dispatch,
  getState
) => {
  const id = rule.id

  const actions = []
  const conditions = []
  const triggers = []
  const uploadAttachments = []
  const ruleActionTagsToCreate = []

  rule.actions.forEach((action, actionIndex) => {
    const messageTemplate = action.messageTemplate
    const ruleReplyTemplateId = action.rule_reply_template
    const sanitizedAction = omit(
      ['id', 'messageTemplate', 'rule_reply_template'],
      action
    )

    if (messageTemplate) {
      const { body, subject } = messageTemplate
      let { pendingUploads } = messageTemplate

      if (pendingUploads) {
        pendingUploads = pendingUploads.map(pendingUpload => ({
          ...pendingUpload,
          actionIndex,
        }))
      }

      const actionMessageTemplate = {
        body,
        subject,
      }

      if (ruleReplyTemplateId && !validateUUID(ruleReplyTemplateId)) {
        // we're updating an existing rule message template
        actionMessageTemplate.id = ruleReplyTemplateId
      }

      if (Array.isArray(pendingUploads) && pendingUploads.length > 0) {
        uploadAttachments.push(...pendingUploads)
      }

      sanitizedAction.rule_reply_template_attributes = actionMessageTemplate
      // keep action.value to be the same as message template body so it's backwards compatible with old settings screen
      sanitizedAction.value = body
    }

    const { type, value } = action

    if (type === 'labels' && !!value && !!value.trim()) {
      const tags = (value || '').split(',')
      tags.forEach((tagName, tagIndex) => {
        if (tagName && !isGid(tagName)) {
          ruleActionTagsToCreate.push({
            actionIndex,
            tagIndex,
            tagName,
          })
        }
      })
    }

    actions.push(sanitizedAction)
  })

  if (ruleActionTagsToCreate.length) {
    const tagsCreated = await Promise.all(
      ruleActionTagsToCreate.map(async ({ tagName }) => {
        return dispatch(
          doCreateUpdateTagV2(
            'new',
            {
              name: tagName,
              color: stringToColor(tagName).toUpperCase(),
            },
            {
              toastsEnabled: false,
            }
          )
        )
      })
    )

    const tagsCreatedByName = tagsCreated.reduce((acc, obj) => {
      const { tagCreate: { tag } = {} } = obj || {}
      if (!tag) return acc

      // eslint-disable-next-line no-param-reassign
      acc[tag.name] = tag

      return acc
    }, {})

    ruleActionTagsToCreate.forEach(({ actionIndex, tagIndex, tagName }) => {
      const action = actions[actionIndex]
      if (action && !!action.value) {
        const tags = (action.value || '').split(',')
        if (tags.length >= tagIndex) {
          const createdTag = tagsCreatedByName[tagName]

          if (createdTag) {
            tags[tagIndex] = createdTag.id

            actions[actionIndex].value = tags.join(',')
          }
        }
      }
    })
  }

  rule.conditions.forEach(cond => {
    const condition = cond

    if (isDefined(condition.id)) {
      delete condition.id
    }

    if (['assignee', 'mailbox'].includes(condition.param)) {
      condition.value = cond.value
        .split(',')
        .map(gid => reverseHashInt(gid))
        .join(',')
    }

    conditions.push(condition)
  })

  rule.triggers.forEach(trig => {
    const trigger = trig

    if (isDefined(trigger.id)) {
      delete trigger.id
    }

    triggers.push(trigger)
  })

  const payload = {
    id,
    active: rule.active,
    match_type: rule.match_type,
    name: rule.name,
    stop_upcoming: rule.stop_upcoming,
    rule_actions_attributes: actions,
    rule_conditions_attributes: conditions,
    rule_triggers_attributes: triggers,
    schedule_type: rule.schedule_type,
    schedule_settings: rule.schedule_settings,
  }

  const normalizedEntities = normalize(rule, ruleEntity)

  const optimist = {
    entities: normalizedEntities.entities,
  }

  const response = await dispatch(
    doApiWriteRequest(
      UPDATE_RULE,
      `v1/automation_rules`,
      { rule: payload },
      {
        method: 'POST',
        optimist,
        normalizationSchema: ruleApiV1ResponseSchema,
        searches: {
          additionalActions: [
            {
              type: 'INVALIDATE_ENTITIES',
              entityTypes: ['rule'],
              phases: ['SUCCESS'],
            },
          ],
        },
        moduleOptions: {
          entities: {
            additionalActions: Object.keys(optimist.entities).map(
              entityType => {
                return {
                  entityType,
                  stores: ['pending'],
                  phases: ['SUCCESS'],
                  type: 'clear',
                }
              }
            ),
          },
          toasts: {
            enabled: true,
            started: {
              enabled: false,
            },
            success: {
              enabled: true,
              content: 'Rule created',
            },
            failed: {
              content: 'Rule create failed',
              onClickAction: () => {
                dispatch(doCreateSingleRule(rule, options))
              },
            },
          },
        },
        ...options,
      }
    )
  )

  const ruleId = response?.id || response?.automation_rule?.id
  if (ruleId && uploadAttachments.length > 0) {
    await Promise.all(
      response.automation_rule.actions.map(async (action, actionIndex) => {
        if (!action.rule_reply_template) {
          return null
        }

        const ruleTemplateAttachmentsToUpload = uploadAttachments.filter(
          u => u.actionIndex === actionIndex
        )
        const ruleReplyTemplateId = action.rule_reply_template.id
        return dispatch(
          doAttachementRequest(
            UPLOAD_RULE_ACTION_ATTACHMENT,
            `/rule_reply_templates/${ruleReplyTemplateId}/attachments`,
            ruleTemplateAttachmentsToUpload.map(upload => upload.editorFile),
            options
          )
        )
      })
    )
  }
  const onboardingWorkflowData = selectFeatureBasedOnboardingWorkflowData(
    getState()
  )
  dispatch(
    doTryFetchAccountUsageOnboardingForOnboarding(
      onboardingWorkflowData.rule?.usageKey
    )
  )
  return dispatch(doFetchRulesV1())
}

export const doDeleteRules = (ids, options = {}) => dispatch => {
  // Promise.all is not technically required in the current implementation because
  // we'll only step into this block if the ids array has a single id, but I do
  // want to leave the door open for us to say that deleting less than X rows uses
  // single deletes instead of batch deletes
  return Promise.all(ids.map(id => dispatch(doDeleteSingleRule(id, options))))
}

export const doClearAllRuleDrafts = () => {
  return {
    type: CLEAR_ALL_RULE_DRAFTS,
    ...mergeEntityChanges([
      clearEntities('rule', 'pending'),
      clearEntities('ruleAction', 'pending'),
      clearEntities('ruleActionMessageTemplate', 'pending'),
      clearEntities('ruleCondition', 'pending'),
      clearEntities('ruleTrigger', 'pending'),
    ]),
  }
}

export const doSaveRuleDraft = (id, fields) => dispatch => {
  if (isEmpty(fields)) return null

  const ruleFields = omit(['actions', 'conditions', 'triggers'], fields)
  const actionsIds = []
  const conditionIds = []
  const triggerIds = []

  const entityChanges = []

  if (fields?.actions?.length) {
    fields.actions.forEach(action => {
      const sanitizedAction = omit(['messageTemplate'], action)

      if (isNullOrUndefined(action.id)) {
        sanitizedAction.id = uuidV4()
      }
      actionsIds.push(sanitizedAction.id)

      const messageTemplate = action.messageTemplate

      if (messageTemplate) {
        if (isNullOrUndefined(action.rule_reply_template)) {
          const templateId = uuidV4()
          sanitizedAction.rule_reply_template = templateId
        }

        if (isNullOrUndefined(messageTemplate.id)) {
          messageTemplate.id = sanitizedAction.rule_reply_template
        }

        sanitizedAction.value = messageTemplate?.body

        entityChanges.push(
          changeEntity(
            'ruleActionMessageTemplate',
            messageTemplate.id,
            messageTemplate,
            'update',
            'pending'
          )
        )
      }

      entityChanges.push(
        changeEntity(
          'ruleAction',
          sanitizedAction.id,
          sanitizedAction,
          'update',
          'pending'
        )
      )
    })
  }

  if (fields?.conditions?.length) {
    fields.conditions.forEach(condition => {
      const sanitizedCondition = condition

      if (isNullOrUndefined(condition.id)) {
        sanitizedCondition.id = uuidV4()
      }
      conditionIds.push(sanitizedCondition.id)

      entityChanges.push(
        changeEntity(
          'ruleCondition',
          sanitizedCondition.id,
          sanitizedCondition,
          'update',
          'pending'
        )
      )
    })
  }

  if (fields?.triggers?.length) {
    fields.triggers.forEach(trigger => {
      const sanitizedTrigger = trigger

      if (isNullOrUndefined(trigger.id)) {
        sanitizedTrigger.id = uuidV4()
      }
      triggerIds.push(sanitizedTrigger.id)

      entityChanges.push(
        changeEntity(
          'ruleTrigger',
          sanitizedTrigger.id,
          sanitizedTrigger,
          'update',
          'pending'
        )
      )
    })
  }

  ruleFields.actions = actionsIds
  ruleFields.conditions = conditionIds
  ruleFields.triggers = triggerIds
  // to allow us keep default values
  ruleFields.isDraft = true

  entityChanges.push(changeEntity('rule', id, ruleFields, 'update', 'pending'))

  return dispatch({
    type: SAVE_RULE_DRAFT,
    ...mergeEntityChanges(entityChanges),
  })
}
