import { atLeastOneReply, userResolution } from './filters'
import { adjustScopeConversationAssigneeToActionUser } from './scopes'

export const averageRepliesPerConversation = {
  adjustScope: adjustScopeConversationAssigneeToActionUser,
  analysisType: 'average',
  eventCollection: 'conversation.resolved',
  filters: [atLeastOneReply, userResolution],
  key: 'averageRepliesPerConversation',
  targetProperty: 'conversation.response_count',
}
