import cn from 'classnames'
import React, { PureComponent } from 'react'
import { Button, Icon, Popup } from 'util/ui'
import { propFunc } from 'util/functions'
import { isBlank } from 'util/strings'
import {
  isLinkDisabled,
  getTooltipDescription,
  getStateTitle,
} from '../../utils/kbSearch'

class SearchKbItemView extends PureComponent {
  getDescription = () => {
    const { description } = this.props
    if (isBlank(description)) return 'No description provided'
    return description
  }

  getTooltipDescription = () => {
    const { state } = this.props
    return getTooltipDescription(state)
  }

  getStateTitle = () => {
    const { state } = this.props
    return getStateTitle(state)
  }

  handleLinkClick = (articleId, knowledgeBaseId, evt) => {
    const { onLinkClick } = this.props
    evt.stopPropagation()
    onLinkClick(articleId, knowledgeBaseId, evt)
  }

  isLinkDisabled = () => {
    const { state, isAddingNote } = this.props
    return isLinkDisabled(isAddingNote, state)
  }

  render() {
    const {
      id,
      title,
      state,
      onClick,
      isFocused,
      onMouseMove,
      itemRef,
      knowledgeBaseId,
      knowledgeBaseTitle,
      description,
      hasMultipleKnowledgebases,
    } = this.props

    return (
      <div
        className={cn('searchResultItem', {
          active: isFocused,
          empty: isBlank(description),
        })}
        onClick={propFunc(onClick, id, knowledgeBaseId)}
        onMouseMove={onMouseMove}
        ref={itemRef}
      >
        <div className="header">
          {hasMultipleKnowledgebases &&
            knowledgeBaseTitle && (
              <span className="kbTitle">{knowledgeBaseTitle}</span>
            )}
          {title && <span className="body">{title}</span>}
          {state && (
            <span className={cn('status', `status-${state}`)}>
              {this.getStateTitle(state)}
            </span>
          )}
        </div>
        <div className={cn('body')}>{this.getDescription()}</div>
        {knowledgeBaseId && (
          <Popup
            content={this.getTooltipDescription()}
            position="top center"
            inverted
            className="tooltip"
            disabled={!this.isLinkDisabled()}
            trigger={
              <Button
                className={cn('link', {
                  disabled: this.isLinkDisabled(),
                })}
                basic
                icon
                onClick={propFunc(this.handleLinkClick, id, knowledgeBaseId)}
              >
                <Icon name="linkify" />
              </Button>
            }
          />
        )}
      </div>
    )
  }
}

export default React.forwardRef((props, ref) => (
  <SearchKbItemView itemRef={ref} {...props} />
))
