import grooveTheme from '../../../grooveTheme'
import colorizeDatum from './colorizeDatum'

export default function colorizeDataSet(
  {
    data,
    previousPeriod,
    previousPeriod: { data: previousData } = {},
    ...dataSet
  },
  index,
  isSingleDataSet
) {
  const { colorScale = grooveTheme.colors.scale, scale } = dataSet
  const isEmptyDataSet = data && data.length === 0
  const color =
    isSingleDataSet || isEmptyDataSet
      ? grooveTheme.colors.purple
      : colorScale[index % colorScale.length]
  const isDataSetWithSingleDatum = isSingleDataSet && data.length === 1
  const isSingleDataSetWithoutPrevious =
    isSingleDataSet && !dataSet.previousData
  const previousColor = isSingleDataSet ? grooveTheme.colors.sky : color
  const decorations = {
    color,
    previousColor,
  }
  const decoratedPreviousData =
    previousData &&
    previousData.map(datum =>
      colorizeDatum(datum, null, { ...decorations, isPrevious: true })
    )
  const decoratedData =
    data &&
    data
      .map(datum => {
        const previousDatum =
          decoratedPreviousData &&
          decoratedPreviousData.find(pd => pd.x === datum.x)
        return colorizeDatum(datum, previousDatum, decorations)
      })
      .filter(datum => !!datum)
  const dataByX =
    decoratedData &&
    decoratedData.reduce((result, datum) => {
      if (!datum) return null
      // eslint-disable-next-line no-param-reassign
      result[datum.x] = datum
      return result
    }, {})
  return {
    ...dataSet,
    ...decorations,
    data: decoratedData,
    dataByX,
    isSingleDataSet,
    isSingleDataSetWithoutPrevious,
    isSingleDatum: isDataSetWithSingleDatum,
    previousPeriod: previousPeriod && {
      ...previousPeriod,
      data: decoratedPreviousData,
    },
    scale,
  }
}
