import { all } from 'util/arrays'

export function findFirst(group, queries) {
  return queries.map(({ result: findResult, groupMap = {} }) => {
    const foundResult = findResult.find(resultItem =>
      all(
        groupKey =>
          resultItem[groupMap[groupKey] || groupKey] === group[groupKey] ||
          groupKey === 'result',
        Object.keys(resultItem)
      )
    )
    return foundResult && foundResult.result
  })
}
