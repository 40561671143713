/* eslint-disable no-param-reassign */
import { createActionTypeReducer } from 'util/reducers'
import {
  FETCH_RULE_STARTED,
  FETCH_RULE_SUCCESS,
  FETCH_RULE_FAILED,
} from '../actionTypes'

export const defaultState = {
  isLoading: false,
  isLoaded: false,
  hasError: false,
}

export default createActionTypeReducer(
  {
    [FETCH_RULE_STARTED]: draftState => {
      draftState.isLoading = true
      return draftState
    },
    [FETCH_RULE_SUCCESS]: draftState => {
      draftState.isLoading = false
      draftState.isLoaded = true
      draftState.hasError = false
      return draftState
    },
    [FETCH_RULE_FAILED]: draftState => {
      draftState.isLoading = false
      draftState.hasError = true
      return draftState
    },
  },
  defaultState
)
