import { createPayload } from 'optimistic/base'
import { selectCurrentTicket } from 'selectors/tickets/current/selectCurrentTicket'
import { selectCurrentUser } from 'ducks/currentUser/selectors/selectCurrentUser'
import { selectPreferences } from 'ducks/currentUser/selectors/preferences/selectPreferences'

import { calculateConversationType } from 'util/draft'
import {
  messageAction,
  statusAction,
  assignAgentAction,
  assignGroupAction,
  snoozeAction,
  unsnoozeAction,
  assigneesDiffer,
} from './actions'

// builds mock data for a reply action.
export const notePayload = (
  state,
  ticketId,
  draft,
  changesetId,
  folderId,
  search
) => {
  const { undo_send_delay: delaySeconds } = selectPreferences(state)
  const deliverBy = delaySeconds
    ? new Date(Date.now() + delaySeconds * 1000)
    : undefined
  const draftWithDeliverBy = delaySeconds ? { ...draft, deliverBy } : draft
  const actions = [messageAction(draftWithDeliverBy)]
  const ticket = selectCurrentTicket(state)
  const agent = selectCurrentUser(state)

  if (draft.snoozeUntil) {
    actions.push(snoozeAction(draft.snoozeUntil))
  } else if (draft.state && ticket.state !== draft.state) {
    actions.push(statusAction(draft.state))
  }
  if (!draft.snoozeUntil && ticket.snoozeUntil) {
    actions.push(unsnoozeAction(draft.snoozeUntil))
  }

  if (assigneesDiffer(ticket, draft)) {
    actions.push(assignAgentAction(draft.assignee))
  }

  if (ticket.assigned_group_id !== draft.assigned_group_id) {
    actions.push(assignGroupAction(draft.assigned_group_id))
  }
  const params = {
    id: ticketId,
    assigned_group_id: draft.assigned_group_id,
    assignee: draft.assignee,
    state: draft.state,
    to: draft.to,
    cc: draft.cc,
    bcc: draft.bcc,
    note: draft.isNote,
    mailboxId: draft.mailboxId,
    snoozedUntil: draft.snoozedUntil || draft.snoozeUntil,
    snoozedById: draft.snoozedById,
    bodyType: calculateConversationType(draft),
    bodyAuthor: {
      id: agent.id,
      type: 'Agent',
    },
  }
  if (draft.title) params.title = draft.title

  return createPayload(
    state,
    ticketId,
    'message',
    params,
    actions,
    folderId,
    search,
    changesetId ? `optimistic-${changesetId}` : null
  )
}
