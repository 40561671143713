import cn from 'classnames'
import React, { PureComponent } from 'react'
import { createPopper } from '@popperjs/core'
import maxSize from 'popper-max-size-modifier'
import ButtonVariant from '@groovehq/internal-design-system/lib/components/Button/Button'
import { SUI, Button } from 'shared/ui'
import SpacedItems from 'shared/components/ui/SpacedItems'
import { Input, Dropdown, Icon, Popup, Ref } from 'util/ui'
import { debounce } from 'util/functions'
import { isBlank } from 'util/strings'
import Sidebar from 'shared/components/ui/Sidebar'
import {
  isLinkDisabled,
  getTooltipDescription,
  getArticleState,
} from '../../utils/kbSearch'
import KbPreview from '../KbPreview'
import SearchKbList from './List'
import styles from './styles.less'

export default class SearchKbView extends PureComponent {
  constructor(props) {
    super(props)
    // create a ref to store the textInput DOM element
    this.inputRef = React.createRef()
    this.inputWrapperRef = React.createRef()
    this.listRef = React.createRef()
  }

  state = {
    keyword: '',
    knowledgeBaseId: null,
    showResults: false,
    isSearchFocused: false,
    isKbPreviewOpen: false,
    previewKnowledgeBaseId: null,
  }

  componentDidMount = () => {
    const { fetchKnowledgeBases } = this.props
    if (fetchKnowledgeBases) fetchKnowledgeBases()
  }

  componentDidUpdate(prevProps) {
    const { current: inputWrapper } = this.inputWrapperRef
    const { current: list } = this.listRef
    const shouldCreatePopper = this.state.showResults && !this.popperInstance
    const canCreatePopper = inputWrapper && list
    const { dropdownItemsPosition, items } = this.props
    const isItemsLengthChanged = prevProps.items.length !== items.length
    if (shouldCreatePopper && canCreatePopper) {
      this.popperInstance = createPopper(inputWrapper, list, {
        placement: dropdownItemsPosition || 'top-start',
        modifiers: [
          maxSize,
          {
            name: 'offset',
            options: {
              offset: [0, 8],
            },
          },
          {
            name: 'flip',
            options: {
              flipVariations: false, // true by default
              padding: 64,
            },
          },
          {
            name: 'applyMaxSize',
            enabled: true,
            phase: 'beforeWrite',
            requires: ['maxSize'],
            fn: ({ state }) => {
              const { height } = state.modifiersData.maxSize
              // eslint-disable-next-line no-param-reassign
              state.styles.popper.maxHeight = `${Math.min(height - 8, 284)}px`
            },
          },
        ],
      })
    } else if (!this.state.showResults && this.popperInstance) {
      this.popperInstance.destroy()
      this.popperInstance = null
    } else if (
      this.state.showResults &&
      this.popperInstance &&
      // Prevent updating popper instance if items length isn't changed
      // to fix click events for items aren't working
      isItemsLengthChanged
    ) {
      this.popperInstance.update()
    }
  }

  componentWillUnmount() {
    if (this.popperInstance) {
      this.popperInstance.destroy()
      this.popperInstance = null
    }
  }

  onInputFocus = () => {
    const { lastRequestedSearchTerm, onFocus } = this.props
    if (!isBlank(lastRequestedSearchTerm)) this.showResults()
    this.setState({ isSearchFocused: true })
    if (onFocus) onFocus()
  }

  onInputBlur = () => {
    const { onBlur } = this.props
    this.hideResults(true)
    this.setState({ isSearchFocused: false })
    if (onBlur) onBlur()
  }
  getActions = () => {
    const {
      articlePreviewUrl,
      isAddingNote,
      articlePreviewArticle,
    } = this.props
    const articleState = getArticleState(articlePreviewArticle)
    return (
      <SpacedItems.Container direction="vertical">
        <SpacedItems.Item>
          <Popup
            content={getTooltipDescription(articleState)}
            position="top center"
            inverted
            className="tooltip"
            disabled={!isLinkDisabled(isAddingNote, articleState)}
            trigger={
              <Button
                className={cn({
                  visbleDisable: isLinkDisabled(isAddingNote, articleState),
                })}
                size="large"
                fluid
                primary
                onClick={this.handleInsertLinkAndClose}
              >
                Insert Article Link
              </Button>
            }
          />
        </SpacedItems.Item>
        <SpacedItems.Item>
          <SpacedItems.Container>
            <SpacedItems.Item>
              <ButtonVariant
                type="secondary"
                as="a"
                target="_blank"
                rel="noopener noreferrer"
                href={articlePreviewUrl}
              >
                <Icon name="external alternate" />
                Open in new tab
              </ButtonVariant>
            </SpacedItems.Item>
            <SpacedItems.Item grow={1}>
              <Button size="large" fluid onClick={this.hideKb} basic>
                Cancel
              </Button>
            </SpacedItems.Item>
          </SpacedItems.Container>
        </SpacedItems.Item>
      </SpacedItems.Container>
    )
  }

  setResultsVisibility = debounce(visible => {
    this.setState({ showResults: visible })
  }, 500)

  handleInsertLinkAndClear = (articleId, knowledgeBaseId, evt) => {
    const { insertKbLink } = this.props
    insertKbLink(articleId, knowledgeBaseId, evt)
      .then(() => {
        if (this.inputRef.current) {
          this.inputRef.current.inputRef.current.value = ''
        }
        this.hideKb()
      })
      .catch(e => e)
  }

  handleInsertLinkAndClose = evt => {
    const { previewKnowledgeBaseId, previewArticleId } = this.state
    this.handleInsertLinkAndClear(previewArticleId, previewKnowledgeBaseId, evt)
  }

  showResults = () => {
    this.setResultsVisibility(true)
  }

  hideResults = (ignoreInputFocus = false) => {
    const { isSearchFocused } = this.state
    if (!isSearchFocused || ignoreInputFocus) {
      this.setResultsVisibility(false)
    }
  }

  handleDropdownChange = (evt, { value }) => {
    // For the ui we need to use a actualy value, but for the backend we need to pass
    // null to search all kbs
    const translatedValue = value === '*' ? null : value
    this.setState({ knowledgeBaseId: translatedValue }, this.searchArticles)
    if (this.inputRef.current) {
      this.inputRef.current.inputRef.current.focus()
    }
  }

  handleInputChange = (evt, { value }) => {
    this.setState({ keyword: value }, this.searchArticles)
  }

  searchArticles = debounce(() => {
    const { searchForArticles } = this.props
    const { keyword, knowledgeBaseId, showResults } = this.state
    if (searchForArticles) {
      searchForArticles({ keyword, knowledgeBaseId }).then(() => {
        if (isBlank(keyword)) {
          this.setState({ showResults: false })
        } else if (!showResults) {
          this.showResults()
        }
      })
    }
  }, 250)

  shouldShowResults = () => {
    const { items } = this.props
    const { showResults } = this.state

    return items && showResults
  }

  showKb = (articleId, knowledgeBaseId) => {
    this.setState({
      isKbPreviewOpen: true,
      previewKnowledgeBaseId: knowledgeBaseId,
      previewArticleId: articleId,
    })
  }

  hideKb = () => {
    this.setState({
      isKbPreviewOpen: false,
      previewKnowledgeBaseId: null,
      previewArticleId: null,
    })
  }

  render() {
    const {
      className,
      focusedItem,
      items,
      knowledgeBaseOptions,
      primaryKnowledgeBaseId,
      isAddingNote,
      hasMultipleKnowledgebases,
      menuUpward = false,
      hasKnowledgebases,
    } = this.props

    if (!hasKnowledgebases) return null

    const {
      previewKnowledgeBaseId,
      previewArticleId,
      isSearchFocused,
      knowledgeBaseId,
    } = this.state

    return (
      <SUI
        className={cn(styles.container, 'container', className, {
          focused: isSearchFocused,
        })}
      >
        <Ref innerRef={this.inputWrapperRef}>
          <Input
            action={
              <Dropdown
                button
                floating
                upward={menuUpward}
                options={knowledgeBaseOptions}
                defaultValue="*"
                additionPosition="top"
                onChange={this.handleDropdownChange}
                className="searchDropdown"
                onOpen={this.onInputFocus}
                onBlur={this.onInputBlur}
              />
            }
            placeholder="Search Knowledge Base…"
            className="searchInput"
            fluid
            onChange={this.handleInputChange}
            onFocus={this.onInputFocus}
            onBlur={this.onInputBlur}
            ref={this.inputRef}
          />
        </Ref>

        {this.shouldShowResults() && (
          <SearchKbList
            scrollerProps={{ innerRef: this.listRef }}
            // ref={this.listRef}
            scrollerClassName="searchResults"
            insertKbLink={this.handleInsertLinkAndClear}
            openKbArticlePopout={this.showKb}
            hideKbArticlePopout={this.hideKb}
            focusedItem={focusedItem}
            onBlur={this.hideResults}
            onFocus={this.showResults}
            items={items}
            autoFocusFirst
            primaryKnowledgeBaseId={primaryKnowledgeBaseId}
            isAddingNote={isAddingNote}
            hasMultipleKnowledgebases={
              hasMultipleKnowledgebases && !knowledgeBaseId
            }
          />
        )}
        <Sidebar
          open={this.state.isKbPreviewOpen}
          onClose={this.hideKb}
          headerChildren={'Knowledge Base article'}
          footerChildren={this.getActions()}
        >
          <KbPreview
            articleId={previewArticleId}
            knowledgeBaseId={previewKnowledgeBaseId}
          />
        </Sidebar>
      </SUI>
    )
  }
}
