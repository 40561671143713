import { doAppGraphqlRequest } from 'ducks/requests/operations'
import Bugsnag from '@bugsnag/js'
import { walletsQuery } from './queries'
import {
  FETCH_WALLETS,
  UPDATE_WALLET_BY_REALTIME,
  UPDATE_WALLET,
  CHARGE_CREDIT_FROM_WALLET,
} from './types'
import { walletChargeCreditMutation, walletMutation } from './mutations'

export const doRealtimeWalletTransactionEvent = message => dispatch => {
  const {
    data,
    meta: { action },
  } = message

  if (action === 'update') {
    dispatch({
      type: UPDATE_WALLET_BY_REALTIME,
      payload: { data },
    })
  }
}

export const doFetchWalletsForFeature = (feature, type) => dispatch => {
  return dispatch(
    doAppGraphqlRequest(FETCH_WALLETS, walletsQuery, {
      features: [`${feature}_main`],
      type,
    })
  )
}

export const doUpdateWalletForAutorenew = (...args) => dispatch => {
  const {
    id,
    autorenew,
    autorenewAmount,
    autorenewUses,
    autorenewThreshold,
  } = args[0]
  const {
    successMessage = 'Auto-refills updated',
    errorMessage = 'Auto-refills update failed',
  } =
    args[1] || {}
  return dispatch(
    doAppGraphqlRequest(
      UPDATE_WALLET,
      walletMutation,
      {
        walletId: id,
        autorenew,
        autorenewAmount,
        autorenewUses,
        autorenewThreshold,
      },
      {
        throwOnError: true,
        moduleOptions: {
          toasts: {
            enabled: true,
            started: {
              enabled: false,
            },
            success: {
              enabled: true,
              content: successMessage,
            },
            failed: {
              enabled: true,
              content: errorMessage,
              onClickAction: () =>
                dispatch(doUpdateWalletForAutorenew(...args)),
            },
          },
        },
      }
    )
  )
}

const doChargeCreditFromFeatureWallet = feature => dispatch => {
  return dispatch(
    doAppGraphqlRequest(
      CHARGE_CREDIT_FROM_WALLET,
      walletChargeCreditMutation,
      {
        feature,
      },
      {
        throwOnError: true,
      }
    )
  )
}

export const doChargeCreditFromAiDraftWallet = () => async dispatch => {
  try {
    const response = await dispatch(doChargeCreditFromFeatureWallet('ai_draft'))
    if (!response.walletTransactionCreate?.success) {
      throw new Error(
        'Error in wallet charging: ',
        response.walletTransactionCreate?.errors[0].message
      )
    }
  } catch (error) {
    Bugsnag.notify(error)
  }
}
