import { connect } from 'react-redux'
import { doHideTicketPreviewModal } from 'actions/modals'
import { doToggleStarred } from 'actions/tickets'
import { selectTicketFlagsByTicketId } from 'selectors/tickets'
import { selectRawTicketById } from 'selectors/tickets/byId/selectRawTicketById'
import { makeIsTicketPreviewOpen, selectModalOffset } from 'selectors/modals'

import {
  makeSelectCommentsByTicketId,
  makeSelectIsLoadingByTicketId,
} from '../../selectors/comments'
import { doFetchTicketComments } from '../../operations'

import TicketPreviewView from './view'

const select = () => {
  const selectIsModalOpen = makeIsTicketPreviewOpen()
  const selectCommentsByTicketId = makeSelectCommentsByTicketId()
  const selectIsLoadingByTicketId = makeSelectIsLoadingByTicketId()
  return (state, props) => {
    return {
      comments: selectCommentsByTicketId(state, props),
      flags: selectTicketFlagsByTicketId(state, props.ticketId),
      isModalOpen: selectIsModalOpen(state, props),
      ticket: selectRawTicketById(state, props.ticketId),
      modalOffset: selectModalOffset(state),
      isLoading: selectIsLoadingByTicketId(state, props),
    }
  }
}

const perform = {
  hideTicketPreviewModal: doHideTicketPreviewModal,
  fetchTicketComments: doFetchTicketComments,
  toggleStarred: doToggleStarred,
}

export default connect(select, perform)(TicketPreviewView)
