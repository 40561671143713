import businessHours from './businessHours'
import components from './visualizations/enhancers/withComponentState/reducers'
import controls from './controls'
import customersHelped from './customersHelped'
import layout from './layout'
import kb from './kb'
import productivity from './productivity'
import queries from './queries'

export default {
  businessHours,
  components,
  controls,
  customersHelped,
  kb,
  layout,
  productivity,
  queries,
}

export * as agents from './agents'
export * as businessHours from './businessHours'
export * as conversations from './conversations'
export * as detail from './detail'
export * as happiness from './happiness'
export * as kb from './kb'
export * as productivity from './productivity'
export * as teams from './teams'
