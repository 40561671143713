import { connect } from 'react-redux'

import { totalKbVisitorsGauge } from 'ducks/reports/gauges'
import { HeadlineVisualization } from 'ducks/reports/visualizations'

const staticProps = {
  gauges: [totalKbVisitorsGauge],
  title: 'Total Visitors',
}

export default function TotalKbVisitorsHeadline(props) {
  return <HeadlineVisualization {...props} {...staticProps} />
}
