export default function metaActionModule(_phase, action, _payload, options) {
  const { requestType, requestId, requestParameters, meta } = options

  Object.assign(action, {
    meta: {
      requestId,
      requestType,
      requestParameters,
      ...meta,
    },
  })
  return action
}
