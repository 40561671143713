import { totalConversationsAssignedCustomersGauge } from 'ducks/reports/gauges'
import { HeadlineVisualization } from 'ducks/reports/visualizations'

const staticProps = {
  gauges: [totalConversationsAssignedCustomersGauge],
  title: 'Customers Helped',
}

export default function TotalConversationsAssignedHeadline(props) {
  return <HeadlineVisualization {...props} {...staticProps} />
}
