export const CREATE_TEAMS_DRAFT = 'teams/CREATE_TEAMS_DRAFT'

export const SAVE_TEAM = 'teams/SAVE_TEAM'
export const SAVE_TEAM_STARTED = 'teams/SAVE_TEAM_STARTED'
export const SAVE_TEAM_SUCCESS = 'teams/SAVE_TEAM_SUCCESS'
export const SAVE_TEAM_FAILED = 'teams/SAVE_TEAM_FAILED'

export const DELETE_TEAM = 'teams/DELETE_TEAM'
export const DELETE_TEAM_STARTED = 'teams/DELETE_TEAM_STARTED'
export const DELETE_TEAM_SUCCESS = 'teams/DELETE_TEAM_SUCCESS'
export const DELETE_TEAM_FAILED = 'teams/DELETE_TEAM_FAILED'
