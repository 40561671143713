import { Fragment } from 'react'
import { Popup } from 'util/ui'

export default function Label({ children }) {
  let renderMode = 'passthrough'
  let tooltipText
  switch (children) {
    case '😁':
      renderMode = 'popup'
      tooltipText = 'Awesome'
      break
    case '😕':
      renderMode = 'popup'
      tooltipText = 'Ok'
      break
    case '😩':
      renderMode = 'popup'
      tooltipText = 'Bad'
      break
    default:
      break
  }
  if (renderMode === 'popup') {
    return (
      <Popup
        content={tooltipText}
        inverted
        position="top center"
        on="hover"
        trigger={<span>{children}</span>}
      />
    )
  }
  return <Fragment>{children}</Fragment>
}
