export default function extractGraphqlPagination(payload) {
  return Object.keys(payload).reduce((paginations, type) => {
    const {
      nodes,
      pageInfo: { startCursor, endCursor, hasNextPage, hasPreviousPage } = {},
      totalCount,
      totalPageCount,
    } = payload[type]
    paginations.push({
      type,
      nodes,
      startCursor,
      endCursor,
      hasNextPage,
      hasPreviousPage,
      totalCount,
      totalPageCount,
    })
    return paginations
  }, [])[0]
}
