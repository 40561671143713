import { selectCurrentEntitiesById } from 'ducks/entities/selectors'
import { selectSearchByQueryId } from '../selectors'
import {
  sortEntities as defaultSortEntities,
  filterEntities as defaultFilterEntities,
} from '../utils/memory'
import {
  queryIdToQuery,
  constructGraphQLOrderByObject,
  constructGraphQLFilterObject,
} from '../utils/query'
import { searchStarted, searchSuccess } from '../utils/action'

const DEFAULT_STARTED_ACTION_TYPE = 'searches/IN_MEMORY'

export const createDoFetchInMemoryByQueryId = ({
  fromQueryId,
  fromCursorKey = 'all',
  entityType,
  STARTED_ACTION_TYPE = DEFAULT_STARTED_ACTION_TYPE,
  SUCCESS_ACTION_TYPE = DEFAULT_STARTED_ACTION_TYPE,
  doLoadAllFn,
  sortEntities = defaultSortEntities,
  filterEntities = defaultFilterEntities,
}) => ({ queryId }) => async (dispatch, getState) => {
  dispatch({
    type: STARTED_ACTION_TYPE,
    ...searchStarted(queryId),
  })
  // Phase 1: Ensure that the data we're paginating over in memory
  // is loaded
  let state = getState()
  const {
    loaded: fromLoaded = null,
    cursors: fromCursors = {},
  } = selectSearchByQueryId(state, fromQueryId)
  const fromHasCurrentPage = !!fromCursors[fromCursorKey]
  const fromIsStale = fromHasCurrentPage && fromCursors[fromCursorKey].isStale
  const fromHasLoaded = fromLoaded && fromHasCurrentPage && !fromIsStale

  if (!fromHasLoaded) {
    // Note we might need to change this in future to return the results
    // from the previous query
    await dispatch(doLoadAllFn({ queryId: fromQueryId, skipLoaded: true }))
  }
  // Reload state after the async function finishes loading the data
  state = getState()

  // //////////////////////////////////////////
  // Phase 2: Apply in memory sorting and filtering and return results
  const { cursor: currentCursor, pageSize = 20 } = queryIdToQuery(queryId) || {}
  const orderBy = constructGraphQLOrderByObject(queryId)
  const filterBy = constructGraphQLFilterObject(queryId)

  const { cursors = {} } = selectSearchByQueryId(state, fromQueryId)

  const { entityIds = [] } = cursors.all || {}
  const entitiesById = selectCurrentEntitiesById(state, entityType)
  const entities = entityIds.map(id => entitiesById[id]).filter(e => !!e)

  const filteredEntities = filterEntities(filterBy, entities)
  const sortedEntities = sortEntities(orderBy, filteredEntities)
  const from = pageSize * currentCursor - pageSize
  const to = pageSize * currentCursor
  const nodes = sortedEntities.slice(from, to)

  const hasNextPage = currentCursor * pageSize < filteredEntities.length
  const hasPreviousPage = currentCursor - 1 > 0
  const startCursor = hasPreviousPage ? currentCursor - 1 : null
  const endCursor = hasNextPage ? currentCursor + 1 : null
  const totalCount = filteredEntities.length
  const totalPageCount = Math.round(totalCount / pageSize)

  dispatch({
    type: SUCCESS_ACTION_TYPE,
    ...searchSuccess({
      queryId,
      nodes,
      currentCursor,
      startCursor,
      endCursor,
      hasNextPage,
      hasPreviousPage,
      totalCount,
      totalPageCount,
    }),
  })
}
