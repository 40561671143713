import { createSelector } from 'reselect'
import { selectTicketIdFromProps } from 'selectors/tickets/props'
import { selectCustomersByEmail } from 'ducks/customers/selectors'
import { emptyArr } from 'util/arrays'
import { emptyObj } from 'util/objects'
import { selectBase } from './base'

const selectComments = createSelector(
  selectBase,
  base => base.comments || emptyObj
)

const selectDecoratedComments = createSelector(
  selectComments,
  selectCustomersByEmail,
  (comments, customersByEmail) => {
    if (!comments.data || comments.data.length === 0) return emptyArr
    return comments.data.map(c => ({
      ...c,
      author: customersByEmail[c.authorEmail],
    }))
  }
)

export const makeSelectCommentsByTicketId = () => {
  return createSelector(
    selectDecoratedComments,
    selectTicketIdFromProps,
    (comments, ticketId) => {
      return comments.filter(c => c.ticketId === ticketId)
    }
  )
}

export const makeSelectIsLoadingByTicketId = () => {
  return createSelector(
    selectComments,
    selectTicketIdFromProps,
    (comments, ticketId) => {
      return comments.loadingTicketIds.includes(ticketId)
    }
  )
}
