import React from 'react'
import { START_DATE, END_DATE } from './constants'

import DatePickerDateRangeItem from './DatePickerDateRangeItem'
import styles from './styles.less'

export default function DatePickerDateRange({
  focusedInput,
  onFocusChange,
  pendingOrEndDate,
  pendingOrStartDate,
}) {
  return (
    <div className={styles.DatePickerDateRange}>
      <DatePickerDateRangeItem
        date={pendingOrStartDate}
        focusedInput={focusedInput}
        onClick={onFocusChange}
        type={START_DATE}
      />
      <DatePickerDateRangeItem
        date={pendingOrEndDate}
        focusedInput={focusedInput}
        onClick={onFocusChange}
        type={END_DATE}
        alignRight
      />
    </div>
  )
}
