import { css } from '@emotion/react'
import { rem } from '@groovehq/internal-design-system/lib/util/strings'

const content = theme => css`
  h2 {
    font-size: ${theme.fontSize.x6large};
  }
  & > p {
    font-size: ${theme.fontSize.x5large};
    max-width: ${rem(821)};
  }
`

const videoWrapper = css`
  margin: ${rem(68)} auto ${rem(32)};
  max-width: ${rem(596)};
`

export const styles = {
  content,
  videoWrapper,
}
