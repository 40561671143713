import React from 'react'
import Link from 'redux-first-router-link'

import { TEAM_DETAIL_PAGE } from 'ducks/reports/detail/pages'
import { Popup } from 'util/ui'

export default function formatTeams({ y: teams }) {
  const singleTeam = teams && teams.length === 1 && teams[0]
  return (
    <span>
      {teams &&
        teams.length > 1 && (
          <Popup
            content={teams.map(({ name }) => name).join(', ')}
            inverted
            position="bottom center"
            on="hover"
            trigger={<span>{app.t('n_teams', { count: teams.length })}</span>}
          />
        )}
      {singleTeam && (
        <Link
          key={singleTeam.id}
          to={{ type: TEAM_DETAIL_PAGE, payload: { id: singleTeam.id } }}
        >
          {singleTeam.name}
        </Link>
      )}
    </span>
  )
}
