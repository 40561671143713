import { averageResponseTimeWithinBusinessHours } from '../queries/averageResponseTimeWithinBusinessHours'
import { averageResponseTime } from '../queries/averageResponseTime'

export const averageResponseTimeByAgentGauge = {
  businessHoursQueries: [
    {
      ...averageResponseTimeWithinBusinessHours,
      groupBy: 'conversation.assignee.id',
    },
  ],
  id: 'avg_response_time_by_agent',
  isNegativeChangeGood: true,
  queries: [
    {
      ...averageResponseTime,
      groupBy: 'conversation.assignee.id',
      key: 'averageResponseTimeByAgent',
    },
  ],
  scale: { y: 'duration' },
  title: `$Average Response Time By ${app.t('Agent')}`,
}
