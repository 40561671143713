function byMappingPropertyKeys(KEY_MAP) {
  return scope => {
    if (!scope) return scope
    return Object.keys(scope).reduce((reduction, key) => {
      reduction[KEY_MAP[key] || key] = scope[key] // eslint-disable-line no-param-reassign
      return reduction
    }, {})
  }
}

export const adjustScopeConversationAssigneeToActionUser = byMappingPropertyKeys(
  {
    'conversation.assignee.id': 'action.actor.user.id',
  }
)

export const adjustScopeConversationAssigneeToActionChange = byMappingPropertyKeys(
  {
    'conversation.assignee.id': 'action.change.previous_agent.id',
    'conversation.assignee_team.id': 'action.change.previous_team.id',
  }
)
