import React from 'react'

import { propFunc } from 'util/functions'
import { ordinal } from 'util/ordinal'

import styles from './styles.less'

export default function DatePickerInput({ date, onClick, type }) {
  return (
    <span className={styles.DatePickerInput} onClick={propFunc(onClick, type)}>
      {date &&
        `${ordinal(date.getDate())} ${date.toLocaleDateString('en-US', {
          month: 'short',
        })} ${date.getFullYear()}`}
    </span>
  )
}
