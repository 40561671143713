import { selectSearchByQueryId } from 'ducks/searches/selectors'
import { createDoFetchInMemoryByQueryId } from 'ducks/searches/operations/createDoFetchInMemoryByQueryId'

import { doApiReadRequest, doApiWriteRequest } from 'ducks/requests/operations'
import { convertScatterIdToRaw } from 'util/scatterSwap'

import {
  FETCH_WEBHOOKS,
  FETCH_WEBHOOK_BYID,
  SAVE_WEBHOOK,
  DELETE_WEBHOOK,
} from './actionTypes'

import {
  webhooksApiV0ResponseSchema,
  webhookApiV0ResponseSchema,
} from './schema'

const LOAD_ALL_WEBHOOKS_QUERYID = 'entityType:webhook pageSize:10000'

export const doFetchWebhooksV0 = ({ skipLoaded } = {}) => (
  dispatch,
  getState
) => {
  const queryId = LOAD_ALL_WEBHOOKS_QUERYID
  const cursor = 'all'
  const state = getState()
  const { loaded = null, cursors = {} } = selectSearchByQueryId(state, queryId)
  const hasCurrentPage = !!cursors[cursor]
  const isStale = hasCurrentPage && cursors[cursor].isStale

  const hasLoaded = loaded && hasCurrentPage && !isStale

  if (hasLoaded && skipLoaded) {
    // Note we might need to change this in future to return the results
    // from the previous query
    return Promise.resolve({})
  }

  return dispatch(
    doApiReadRequest(
      FETCH_WEBHOOKS,
      'api/settings/webhooks.json',
      {},
      {
        normalizationSchema: webhooksApiV0ResponseSchema,
        app: true,
        searches: {
          queryId,
          cursor,
          extractPagination: (
            _,
            {
              action: {
                transformedEntities: { webhook: webhookById = {} } = {},
              },
            }
          ) => {
            const nodes = Object.values(webhookById)
            return {
              type: 'webhook',
              nodes,
              startCursor: 1,
              endCursor: 1,
              hasNextPage: false,
              hasPreviousPage: false,
              totalCount: nodes.length,
              totalPageCount: 1,
            }
          },
        },
      }
    )
  )
}

export const doFetchWebhooksInMemory = createDoFetchInMemoryByQueryId({
  fromQueryId: LOAD_ALL_WEBHOOKS_QUERYID,
  entityType: 'webhook',
  doLoadAllFn: doFetchWebhooksV0,
})

export const doFetchWebhookV0ById = (inputId, options = {}) => dispatch => {
  // targetStore: optional, defaults to changeEntity()'s default store otherwise
  const { targetStore, convertScatterToRaw } = options
  const id = convertScatterToRaw ? convertScatterIdToRaw(inputId) : inputId

  return dispatch(
    doApiReadRequest(
      FETCH_WEBHOOK_BYID,
      `api/settings/webhooks/${id}`,
      {},
      {
        normalizationSchema: webhookApiV0ResponseSchema,
        moduleOptions: {
          entities: {
            targetStore,
            missingEntityActions: [
              {
                entityType: 'webhook',
                // convert from gid to scatter swapped that's used in entity store
                entityId: id,
                phases: ['SUCCESS'],
              },
            ],
          },
        },
      }
    )
  )
}

export const doDeleteWebhook = (inputId, options = {}) => dispatch => {
  const { convertScatterToRaw } = options
  const id = convertScatterToRaw ? convertScatterIdToRaw(inputId) : inputId
  return dispatch(
    doApiWriteRequest(
      DELETE_WEBHOOK,
      `api/settings/webhooks/${id}`,
      {},
      {
        method: 'DELETE',
        optimist: {},
        searches: {
          additionalActions: [
            {
              type: 'INVALIDATE_ENTITIES',
              entityTypes: ['webhook'],
              phases: ['SUCCESS'],
            },
          ],
        },
        moduleOptions: {
          toasts: {
            enabled: true,
            started: {
              enabled: true,
              content: 'Webhook deleted',
            },
            success: {
              enabled: false,
            },
            failed: {
              content: 'Webhook deletion failed',
              onClickAction: () => {
                dispatch(doDeleteWebhook(inputId, options))
              },
            },
          },
          entities: {
            targetOperation: 'remove',
            additionalActions: [
              {
                // Because we're passing through a global id here, we need to manually delete
                // the entity from the store
                entityType: 'webhook',
                entityId: id,
                stores: ['pending', 'current'],
                operation: 'remove',
                phases: ['STARTED'],
              },
            ],
          },
        },
      }
    )
  )
}

export const doDeleteWebhooks = (ids, options = {}) => dispatch => {
  // Promise.all is not technically required in the current implementation because
  // we'll only step into this block if the ids array has a single id, but I do
  // want to leave the door open for us to say that deleting less than X rows uses
  // single deletes instead of batch deletes
  return Promise.all(ids.map(id => dispatch(doDeleteWebhook(id, options))))
}

export const doSaveWebhook = (inputId, fields, options = {}) => dispatch => {
  // and on here  (i.e. on save button click) you just copy the entity from pending to current store
  const { convertScatterToRaw } = options
  const isUpdate = inputId !== 'new'
  if (isUpdate) throw new Error('Webhooks do not support updating atm')
  const id = convertScatterToRaw ? convertScatterIdToRaw(inputId) : inputId

  const entityType = 'webhook'
  const entity = { ...fields, id }

  let saveUrl = 'api/settings/webhooks'
  let saveMethod = 'POST'
  let targetStores = ['pending', 'current']
  if (isUpdate) {
    saveUrl += `/${id}`
    saveMethod = 'PUT'
    targetStores = ['pending']
  }

  // api call
  return dispatch(
    doApiWriteRequest(SAVE_WEBHOOK, saveUrl, fields, {
      method: saveMethod,
      optimist: {
        entities: {
          [entityType]: {
            [id]: entity,
          },
        },
      },
      normalizationSchema: webhookApiV0ResponseSchema,
      searches: {
        additionalActions: [
          {
            type: 'INVALIDATE_ENTITIES',
            entityTypes: ['webhook'],
            phases: ['SUCCESS'],
          },
        ],
      },
      moduleOptions: {
        toasts: {
          enabled: true,
          started: {
            enabled: false,
          },
          success: {
            enabled: true,
            content: isUpdate ? 'Webhook changes saved' : 'New webhook created',
          },
          failed: {
            content: isUpdate
              ? 'Webhook changes failed'
              : 'Webhook creation failed',
            onClickAction: () => {
              dispatch(doSaveWebhook(inputId, fields, options))
            },
          },
        },
        entities: {
          additionalActions: [
            {
              entityType,
              entityId: id,
              stores: targetStores,
              operation: 'remove',
              phases: ['SUCCESS'],
            },
          ],
        },
      },
    })
  )
}
