import { connect } from 'react-redux'

import DatePicker from '../DatePicker'
import { doChangeTimeframe } from '../../actions'
import {
  selectToday,
  selectTimeframeEnd,
  selectTimeframeStart,
  selectTimezone,
} from '../../selectors'

const select = state => ({
  endDate: selectTimeframeEnd(state).toDate(),
  today: selectToday(state),
  timezone: selectTimezone(state),
  startDate: selectTimeframeStart(state).toDate(),
})
const perform = {
  onApply: doChangeTimeframe,
}

export default connect(select, perform)(DatePicker)
