import { memoize } from 'util/memoization'

import generateChange from './generateChange'

export default memoize(decorateDatum)

function decorateDatum(datum, previousDatum, decorations) {
  if (!datum) return null
  const {
    color,
    group,
    groupId,
    isNegativeGood,
    isPrevious,
    previousColor,
    ...rest
  } = decorations
  const { y } = datum
  const isNegative = y < 0
  const isPositive = y > 0
  return {
    ...datum,
    ...rest,
    color: isPrevious ? previousColor : color,
    group,
    groupId,
    isBad: isNegativeGood ? isPositive : isNegative,
    isGood: isNegativeGood ? isNegative : isPositive,
    isNegative,
    isNegativeGood,
    isNeutral: y === 0,
    isPositive,
    isPrevious: !!isPrevious,
    isInfinity: Math.abs(y) === Infinity,
    previous: previousDatum,
    change: isPrevious
      ? null
      : generateChange(datum, previousDatum, decorations),
  }
}
