import React, { useEffect } from 'react'
import { v4 as uuidV4 } from 'uuid'
import { useDispatch, useSelector } from 'react-redux'
import { doFetchCustomerRatingSettings } from 'ducks/customerRatingSettings/operations'
import { selectCustomerRatingSettings } from 'ducks/customerRatingSettings/selectors/selectCustomerRatingSettings'

import SpacedItems from 'shared/components/ui/SpacedItems'

// TODO: Fix direct duck reference without creating circular dependancy
import ConversationHistoryTable from 'ducks/reports/conversations/components/ConversationHistoryTable'
import { ReportsLayout } from 'ducks/reports/layout'
import * as CustomPropTypes from 'ducks/reports/propTypes'
import HappinessOverview from '../HappinessOverview'
import ActivationScreen from '../ActivationScreen'

const conversationHistoryTableId = uuidV4()

Page.propTypes = {
  queryBase: CustomPropTypes.queryBase.isRequired,
}

export default function Page({ queryBase }) {
  const dispatch = useDispatch()
  const customerRatingSettings = useSelector(selectCustomerRatingSettings)
  const isEmpty = !customerRatingSettings.length

  useEffect(
    () => {
      dispatch(doFetchCustomerRatingSettings())
    },
    [dispatch]
  )

  return (
    <ReportsLayout hideTopContent={isEmpty}>
      {isEmpty && <ActivationScreen />}
      {!isEmpty && (
        <SpacedItems.Container direction="vertical" gap="ginormous">
          <SpacedItems.Item>
            <HappinessOverview
              queryBatch="happiness-overview"
              queryBase={queryBase}
            />
          </SpacedItems.Item>
          <SpacedItems.Item>
            <ConversationHistoryTable
              filter="rating"
              id={conversationHistoryTableId}
              queryBatch="ratings"
              queryBase={queryBase}
              title="Recent Ratings"
              subtitle={'A list of recent ratings over the selected period'}
            />
          </SpacedItems.Item>
        </SpacedItems.Container>
      )}
    </ReportsLayout>
  )
}
