import averageIntervals from '../intervalCollapsers/averageIntervals'
import { countMessagesSent } from '../queries/countMessagesSent'

export const messagesSentPerDayGauge = {
  collapseInterval: averageIntervals,
  description:
    'Average number of outbound emails sent, excluding auto-replies. Includes outbound mails sent by rule actions.',
  queries: [
    {
      ...countMessagesSent,
      interval: 'daily',
      key: 'countMessagesSentForEachDay',
    },
  ],
  title: 'Avg. Emails Sent Per Day',
}
