import { connect } from 'react-redux'
import { NOT_FOUND } from 'redux-first-router'

import { selectScopedQueryBase } from 'ducks/reports/controls'
import {
  doFetchCustomersHelped,
  selectCustomersHelpedForAgent,
  selectCustomersHelpedLoading,
} from 'ducks/reports/customersHelped'
import { selectAgentsById } from 'selectors/agents/base'
import { fetchingStatusesSelector } from 'selectors/app'

import AgentPageView from './AgentPageView'

const select = (state, { id }) => {
  return {
    agent: selectAgentsById(state)[id],
    customersHelped: selectCustomersHelpedForAgent(state, { id }),
    loading:
      fetchingStatusesSelector(state).fetchAppData ||
      selectCustomersHelpedLoading(state),
    queryBase: selectScopedQueryBase(state, {
      id,
      propertyName: 'conversation.assignee.id',
    }),
    resolutionsQueryBase: selectScopedQueryBase(state, {
      id,
      propertyName: 'action.actor.user.id',
    }),
  }
}

const perform = {
  doNotFound: () => ({
    type: NOT_FOUND,
  }),
  onMount: doFetchCustomersHelped,
}

export default connect(select, perform)(AgentPageView)
