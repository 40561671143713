export const countTagByTag = {
  analysisType: 'count_unique',
  eventCollection: 'conversation.tagged',
  filters: [
    {
      operator: 'eq',
      propertyName: 'action.change.added',
      propertyValue: true,
    },
  ],
  groupBy: 'action.change.tag.id',
  key: 'countTagByTag',
  minPerPage: 50,
  maxPerPage: 200,
  targetProperty: 'conversation.id',
}
