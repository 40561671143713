import { connect } from 'react-redux'

import { selectTimezoneOffset } from 'ducks/reports/controls'
import {
  messagesSentByHourOfWeekGauge,
  messagesSentByQuarterHourOfWeekGauge,
} from 'ducks/reports/gauges'

import { BusiestTimeVisualization } from 'ducks/reports/visualizations'

function selectProps(state, { timezone }) {
  const isTimezoneOffsetInteger = Number.isInteger(
    timezone || selectTimezoneOffset(state)
  )
  return {
    gauges: [
      isTimezoneOffsetInteger
        ? messagesSentByHourOfWeekGauge
        : messagesSentByQuarterHourOfWeekGauge,
    ],
    quarterHours: !isTimezoneOffsetInteger,
    timezoneOffset: timezone,
  }
}

export default connect(selectProps)(BusiestTimes)

function BusiestTimes(props) {
  return (
    <BusiestTimeVisualization
      {...props}
      measuring="messages sent"
      measuringSingle="message sent"
    />
  )
}
