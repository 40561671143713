export default function queryCacheKey(state, props) {
  const {
    gauge: { id },
    isSorted,
    joinedXValues,
    loadable,
    pagination,
    previous,
    queryBase,
    query,
    scope,
    sort,
    xValues,
  } = props
  return JSON.stringify({
    id,
    isSorted,
    joinedXValues,
    loadable,
    pagination,
    previous,
    queryBase,
    query,
    scope,
    sort,
    xValues,
  })
}
