import decorateDataSet from './decorateDataSet'
import generateDataSetsForGauge from './generateDataSetsForGauge'

export default function generateAndDecorateDataSetsForGauge(gauge, sortBy) {
  const { collapseDataSets } = gauge
  const dataSets = generateDataSetsForGauge(gauge)
  const collapsedDatasets = collapseDataSets
    ? collapseDataSets(gauge, dataSets)
    : dataSets
  return collapsedDatasets.map(g => decorateDataSet(g, sortBy))
}
