import { connect } from 'react-redux'

import { selectAverageRepliesPerResolutionForEachDayGauge } from 'ducks/reports/gauges'

import { AreaChart } from 'ducks/reports/visualizations'

const select = (state, props) => ({
  gauge: selectAverageRepliesPerResolutionForEachDayGauge(state, props),
  height: 300,
})
export default connect(select)(AreaChart)
