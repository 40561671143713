// Shift the query's timeframe to cover the previous period.

import moment from 'moment-timezone-all'

export default function adjustTimeframeToPreviousPeriod(query) {
  const { timeframe: { start, end } = {} } = query
  const mStart = moment(start)
  const mEnd = moment(end)
  const diff = moment.duration(mEnd.diff(start))
  return {
    ...query,
    isPrevious: true,
    timeframe: {
      end: mStart.format(),
      start: mStart.subtract(diff).format(),
    },
  }
}
