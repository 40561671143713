import { doAppGraphqlRequest } from 'ducks/requests/operations'
import { buildId } from 'util/globalId'
import {
  FETCH_STARRED_CANNED_REPLIES,
  CREATE_STARRED_CANNED_REPLY,
  DELETE_STARRED_CANNED_REPLY,
} from './types'
import {
  starredCannedRepliesNormalizationSchema,
  starredCannedReplyNormalizationSchema,
  starredCannedReplyDeleteNormalizationSchema,
} from './schema'
import { getAllQuery, createQuery, deleteQuery } from './queries'

export const doFetchStarredCannedReplies = () => dispatch => {
  return dispatch(
    doAppGraphqlRequest(
      FETCH_STARRED_CANNED_REPLIES,
      getAllQuery,
      {},
      {
        normalizationSchema: starredCannedRepliesNormalizationSchema,
      }
    )
  )
}

export const doCreateStarredCannedReply = input => dispatch => {
  const { cannedReplyId } = input
  const gid = buildId('CannedReply', cannedReplyId)

  return dispatch(
    doAppGraphqlRequest(
      CREATE_STARRED_CANNED_REPLY,
      createQuery,
      { cannedReplyId: gid },
      {
        normalizationSchema: starredCannedReplyNormalizationSchema,
      }
    )
  )
}

export const doDeleteStarredCannedReply = input => dispatch => {
  const { cannedReplyId } = input
  const gid = buildId('CannedReply', cannedReplyId)

  return dispatch(
    doAppGraphqlRequest(
      DELETE_STARRED_CANNED_REPLY,
      deleteQuery,
      { cannedReplyId: gid },
      {
        meta: { removeEntities: true },
        normalizationSchema: {
          starredCannedReplyDelete: starredCannedReplyDeleteNormalizationSchema,
        },
        moduleOptions: {
          entities: {
            targetOperation: 'remove',
            additionalActions: [
              {
                entityType: 'starredCannedReply',
                entityId: gid,
                stores: ['pending', 'current'],
                operation: 'remove',
                phases: ['SUCCESS'],
              },
            ],
          },
        },
      }
    )
  )
}
