import { createPayload } from 'optimistic/base'
import { selectTicketsById } from 'selectors/tickets/byId/selectTicketsById'
import { toServerString } from 'util/date'

// builds mock data for a integration action.
export default function integationPayload(
  state,
  ticketId,
  integrationData,
  folderId,
  search,
  updatedAt = toServerString(new Date())
) {
  const ticket = selectTicketsById(state)[ticketId] || {}
  const { linkedExternalResources } = ticket
  const newLinkedExternalResources = [
    ...(linkedExternalResources.records || []),
  ]
  const existingIndex = newLinkedExternalResources.findIndex(
    ({ externalId }) => externalId === integrationData.external_id
  )
  if (existingIndex >= 0) delete newLinkedExternalResources[existingIndex]
  if (integrationData.removed !== true) {
    newLinkedExternalResources.push({
      externalId: integrationData.external_id,
      linkedAt: updatedAt,
      provider: integrationData.provider,
      title: integrationData.title,
      url: integrationData.url,
    })
  }
  return createPayload(
    state,
    ticketId,
    'integration',
    {
      linkedExternalResources: newLinkedExternalResources,
      updated_at: updatedAt,
    },
    [
      {
        change_type: 'Ticket::Integration',
        change: {
          ...integrationData,
          type: 'Ticket::Integration',
        },
      },
    ],
    folderId,
    search
  )
}
