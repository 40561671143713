export const column50 = {
  mobile: 16,
  tablet: 16,
  computer: 16,
  largeScreen: 8,
  widescreen: 8,
}

export const column25 = {
  mobile: 4,
  tablet: 4,
  computer: 4,
  largeScreen: 8,
  widescreen: 8,
}
