import { compose } from 'redux'

import ProgressBarView from './ProgressBarView'
import withAutoFetchGauges from '../../enhancers/withAutoFetchGauges'
import withDataForVisualizations from '../../enhancers/withDataForVisualizations'
import withLoadingAndErroredStates from '../../enhancers/withLoadingAndErroredStates'

export default compose(
  withAutoFetchGauges,
  withDataForVisualizations,
  withLoadingAndErroredStates
)(ProgressBarView)
