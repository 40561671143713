import React, { useCallback, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Checkbox, Popup } from 'util/ui'
import Link from 'redux-first-router-link'

import {
  selectAreAnyBusinessHoursSet,
  doBusinessHoursLoad,
} from 'ducks/reports/businessHours'
import { SETTINGS_BUSINESS_HOURS_PAGE } from 'subapps/settings/types'
import { selectCurrentUserIsAdminOrOwnerOrAgent } from 'ducks/currentUser/selectors'
import { doToggleBusinessHours } from '../../actions'
import { selectAreBusinessHoursEnabled } from '../../selectors'
import styles from './styles.less'

const BusinessHoursControl = () => {
  const dispatch = useDispatch()

  // Selectors
  const areAnyBusinessHoursSet = useSelector(selectAreAnyBusinessHoursSet)
  const areBusinessHoursEnabled = useSelector(selectAreBusinessHoursEnabled)
  const isAdminOrOwnerOrAgent = useSelector(
    selectCurrentUserIsAdminOrOwnerOrAgent
  )

  // Dispatch onLoad
  useEffect(
    () => {
      dispatch(doBusinessHoursLoad())
    },
    [dispatch]
  )

  // Handlers
  const toggleBusinessHours = useCallback(
    () => dispatch(doToggleBusinessHours()),
    [dispatch]
  )
  return (
    <div>
      {areAnyBusinessHoursSet ? (
        <Checkbox
          checked={areBusinessHoursEnabled}
          className={styles.DateControlView_Checkbox}
          label="Business Hours"
          onChange={toggleBusinessHours}
        />
      ) : (
        <Popup
          basic
          content={
            'You need to set your business hours before you can enable them.'
          }
          inverted
          on="hover"
          position="bottom center"
          trigger={
            <Checkbox
              checked={areBusinessHoursEnabled}
              className={styles.DateControlView_Checkbox}
              disabled
              label="Business Hours"
              onChange={toggleBusinessHours}
            />
          }
        />
      )}
      {isAdminOrOwnerOrAgent && (
        <Link
          className={styles.link}
          to={{ type: SETTINGS_BUSINESS_HOURS_PAGE }}
        >
          {`${areAnyBusinessHoursSet ? 'Edit' : 'Set'} hours`}
        </Link>
      )}
    </div>
  )
}

export default BusinessHoursControl
