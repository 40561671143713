import React from 'react'
import Link from 'redux-first-router-link'
import Menu from '@groovehq/internal-design-system/lib/components/Menu/Menu'
// TODO: Fix direct duck reference without creating circular dependancy
import { AGENTS_PAGE } from 'ducks/reports/agents/pages'
import { AGENT_DETAIL_PAGE, TEAM_DETAIL_PAGE } from 'ducks/reports/detail/pages'
import { CONVERSATIONS_PAGE } from 'ducks/reports/conversations/pages'
import { HAPPINESS_PAGE } from 'ducks/reports/happiness/pages'
import { PRODUCTIVITY_PAGE } from 'ducks/reports/productivity/pages'
import { TEAMS_PAGE } from 'ducks/reports/teams/pages'
import { KB_PAGE } from 'ducks/reports/kb/pages'

import ReportsSideBareKnowledgeBaseItem from './ReportsSideBareKnowledgeBaseItem'
import ReportsSideBarMailboxItem from './ReportsSideBarMailboxItem'

const ALL_MAILBOXES = { name: `All ${app.t('Mailboxes')}`, id: null }
const ALL_KNOWLEDGE_BASES = { title: 'All Knowledge Bases', id: null }

const toKb = {
  type: KB_PAGE,
}

export default class ReportsSideBar extends React.PureComponent {
  render() {
    const {
      activeKnowledgeBaseId,
      activeMailboxId,
      activePage,
      knowledgeBases,
      mailboxes,
      hasRealConfirmedMailboxes,
      hasKB,
    } = this.props
    const knowledgeBasesWithAll =
      knowledgeBases.length > 1
        ? [ALL_KNOWLEDGE_BASES].concat(knowledgeBases)
        : knowledgeBases
    const mailboxesWithAll =
      mailboxes.length > 1 ? [ALL_MAILBOXES].concat(mailboxes) : mailboxes
    const isInboxActive =
      [
        AGENT_DETAIL_PAGE,
        AGENTS_PAGE,
        CONVERSATIONS_PAGE,
        HAPPINESS_PAGE,
        PRODUCTIVITY_PAGE,
        TEAM_DETAIL_PAGE,
        TEAMS_PAGE,
      ].indexOf(activePage) > -1
    const isKnowledgeBaseActive = [KB_PAGE].indexOf(activePage) > -1

    const kbReportsMenuItems = (
      <>
        {knowledgeBasesWithAll.map(knowledgeBase => {
          return (
            <ReportsSideBareKnowledgeBaseItem
              activeKnowledgeBaseId={activeKnowledgeBaseId}
              activePage={activePage}
              key={knowledgeBase.id}
              knowledgeBase={knowledgeBase}
              totalItemsCount={knowledgeBasesWithAll.length}
              isSubMenuItem
            />
          )
        })}
      </>
    )

    return (
      <Menu title="Reporting" className="grui" tight animate={false}>
        {hasRealConfirmedMailboxes && (
          <Menu.Item
            defaultOpen={isInboxActive}
            subMenu={
              <>
                {mailboxesWithAll.map(mailbox => {
                  return (
                    <ReportsSideBarMailboxItem
                      activeMailboxId={activeMailboxId}
                      activePage={activePage}
                      key={mailbox.id}
                      mailbox={mailbox}
                      totalItemsCount={mailboxesWithAll.length}
                    />
                  )
                })}
              </>
            }
          >
            Inbox
          </Menu.Item>
        )}
        <Menu.Item
          defaultOpen={isKnowledgeBaseActive}
          // Don't provide subMenu when there is no kbs, so can show active state for this main item
          subMenu={hasKB ? kbReportsMenuItems : undefined}
          active={isKnowledgeBaseActive}
          // Click the main item will go to kb reporting too even if there're no kbs
          // will show activation screen there
          as={hasKB ? undefined : Link}
          to={hasKB ? undefined : toKb}
        >
          Knowledge Base
        </Menu.Item>
      </Menu>
    )
  }
}
