import { doGraphqlRequest } from 'ducks/requests/operations'
import {
  queryIdToQuery,
  constructGraphQLOrderByObject,
} from 'ducks/searches/utils/query'
import { pick } from 'util/objects'
import { selectSearchByQueryId } from 'ducks/searches/selectors'
import {
  MERGE_TAGS as MERGE_TAGS_JOB,
  DELETE_TAGS as DELETE_TAGS_JOB,
} from 'ducks/batchJobs/jobTypes'
import { isGid } from 'util/globalId'
import {
  FETCH_TAGS,
  FETCH_TAGS_BY_IDS_NAMES,
  MERGE_TAGS,
  DELETE_TAGS,
  UPDATE_TAG,
} from './actionTypes'
import {
  batchDeleteMutation,
  createMutation,
  singleDeleteMutation,
  updateMutation,
  mergeTagMutation,
} from './mutations'
import { getAllQuery } from './queries'
import {
  tagGraphQlResponseSchema,
  tagGraphQlCreateResponseSchema,
} from './schema'

const ENTITY_TYPE = 'tag'

export const doFetchTagsV2ByIdsOrNames = (
  filterValues,
  options = {}
) => dispatch => {
  const {
    cursor,
    pageSize: size = 20,
    requestType = FETCH_TAGS_BY_IDS_NAMES,
  } = options
  const filter = {
    state: 'ANY',
  }

  const idValues = []
  const nameValues = []

  if (filterValues) {
    filterValues.forEach(value => {
      if (isGid(value)) {
        idValues.push(value)
      } else {
        nameValues.push(value)
      }
    })
  }

  if (idValues.length) {
    filter.ids = idValues
  }

  if (nameValues.length) {
    filter.names = nameValues
  }

  return dispatch(
    doGraphqlRequest(
      requestType,
      getAllQuery(),
      {
        filter,
        cursor,
        size,
      },
      {
        normalizationSchema: tagGraphQlResponseSchema,
        app: true,
        searches: {
          cursor,
        },
      }
    )
  )
}

export const doFetchTagsV2ByIds = ({ ids, cursor, pageSize }) => dispatch => {
  return dispatch(doFetchTagsV2ByIdsOrNames(ids, { cursor, pageSize }))
}

export const doFetchTagsV2 = ({ queryId, skipLoaded }) => (
  dispatch,
  getState
) => {
  const { cursor, pageSize = 20, search, state: tagState } =
    queryIdToQuery(queryId, { decodeUriKeys: ['search'] }) || {}
  const state = getState()
  const orderBy = constructGraphQLOrderByObject(queryId)

  const { loaded = null, cursors = {} } = selectSearchByQueryId(state, queryId)
  const hasCurrentPage = !!cursors[cursor]
  const isStale = hasCurrentPage && cursors[cursor].isStale

  const hasLoaded = loaded && hasCurrentPage && !isStale

  if (hasLoaded && skipLoaded) {
    // Note we might need to change this in future to return the results
    // from the previous query
    return Promise.resolve({})
  }

  return dispatch(
    doGraphqlRequest(
      FETCH_TAGS,
      getAllQuery(),
      {
        filter: {
          search,
          state: tagState || 'ANY',
        },
        cursor,
        size: pageSize,
        orderBy,
      },
      {
        normalizationSchema: tagGraphQlResponseSchema,
        app: true,
        searches: {
          queryId,
          cursor,
        },
      }
    )
  )
}

export const doMergeTags = (mergeConfig, options = {}) => dispatch => {
  const { parentTagId, ids, queryId, mode = 'ids' } = mergeConfig

  if (!parentTagId) return null
  if (mode === 'ids' && ids.length === 0) return null

  const { search, state: tagState } = queryIdToQuery(queryId) || {}

  return dispatch(
    doGraphqlRequest(
      MERGE_TAGS,
      mergeTagMutation(),
      {
        parentTagId,
        filter:
          mode === 'query'
            ? {
                search,
                state: tagState || 'ANY',
              }
            : null,
        tagIds: mode === 'ids' ? ids : null,
      },
      {
        app: true,
        optimist: {
          entities: {
            batchJob: {
              new: {
                id: 'new',
                batch_type: MERGE_TAGS_JOB,
              },
            },
          },
        },
        searches: {
          additionalActions: [
            {
              type: 'INVALIDATE_ENTITIES',
              entityTypes: ['tag'],
              phases: ['SUCCESS'],
            },
          ],
        },
        moduleOptions: {
          toasts: {
            enabled: true,
            started: {
              enabled: true,
              content: 'Merge tag started',
            },
            success: {
              enabled: false,
            },
            failed: {
              content: 'Merge tag failed',
              onClickAction: () => {
                dispatch(doMergeTags(mergeConfig, options))
              },
            },
          },
        },
      }
    )
  )
}

export const doCreateUpdateTagV2 = (id, fields, options = {}) => dispatch => {
  const { addAsSelected } = options
  const isUpdate = id !== 'new'
  const variables = {
    ...fields,
  }
  let targetStores = ['pending', 'current']
  if (isUpdate) {
    targetStores = ['pending']
    variables.tagId = id
  }

  return dispatch(
    doGraphqlRequest(
      UPDATE_TAG,
      isUpdate ? updateMutation() : createMutation(),
      variables,
      {
        app: true,
        optimist: {
          entities: {
            tag: {
              [id]: { id, ...fields },
            },
          },
        },
        normalizationSchema: tagGraphQlCreateResponseSchema,
        onBeforeSuccessAction: () => {
          if (options.shouldFetchTags) {
            dispatch(doFetchTagsV2({}))
          }
        },
        searches: {
          additionalActions: [
            {
              type: 'INVALIDATE_ENTITIES',
              entityTypes: [ENTITY_TYPE],
              phases: ['SUCCESS'],
            },
          ],
        },
        moduleOptions: {
          toasts: {
            enabled: options?.toastsEnabled !== false,
            started: {
              enabled: true,
              content: isUpdate ? 'Tag changes saved' : 'New tag created',
            },
            success: {
              enabled: false,
            },
            failed: {
              content: isUpdate ? 'Tag changes failed' : 'Tag creation failed',
              onClickAction: () => {
                dispatch(doCreateUpdateTagV2(id, fields, options))
              },
            },
          },
        },
        entities: {
          additionalActions: [
            {
              entityType: ENTITY_TYPE,
              entityId: id,
              stores: targetStores,
              operation: 'remove',
              phases: ['SUCCESS'],
            },
          ],
        },
        meta: {
          addAsSelected,
        },
      }
    )
  )
}

const doDeleteSingleTag = (id, options = {}) => dispatch => {
  return dispatch(
    doGraphqlRequest(
      DELETE_TAGS,
      singleDeleteMutation(),
      {
        tagId: id,
      },
      {
        app: true,
        optimist: {},
        normalizationSchema: tagGraphQlResponseSchema,
        searches: {
          additionalActions: [
            {
              type: 'INVALIDATE_ENTITIES',
              entityTypes: [ENTITY_TYPE],
              phases: ['SUCCESS'],
            },
          ],
        },
        moduleOptions: {
          toasts: {
            enabled: true,
            started: {
              enabled: false,
            },
            success: {
              enabled: true,
              content: 'Tag deleted',
            },
            failed: {
              content: 'Tag deletion failed',
              onClickAction: () => {
                dispatch(doDeleteSingleTag(id, options))
              },
            },
          },
        },
        entities: {
          targetOperation: 'remove',
          additionalActions: [
            {
              entityType: ENTITY_TYPE,
              entityId: id,
              stores: ['pending', 'current'],
              operation: 'remove',
              phases: ['SUCCESS'],
            },
          ],
        },
      }
    )
  )
}

const doDeleteBatchTags = (deleteConfig, options = {}) => dispatch => {
  const { queryId, ids, mode = 'ids' } = deleteConfig
  const query = queryIdToQuery(queryId) || {}

  const variables = {}

  if (mode === 'ids') {
    variables.tagIds = ids
  } else {
    variables.filter = pick(
      ['search', 'conversationState', 'state', 'channelId'],
      query,
      false
    )
  }

  return dispatch(
    doGraphqlRequest(
      DELETE_TAGS,
      batchDeleteMutation(),
      { ...variables },
      {
        app: true,
        normalizationSchema: tagGraphQlResponseSchema,
        optimist: {
          entities: {
            batchJob: {
              new: {
                id: 'new',
                batch_type: DELETE_TAGS_JOB,
              },
            },
          },
        },
        searches: {
          additionalActions: [
            {
              type: 'INVALIDATE_ENTITIES',
              entityTypes: [ENTITY_TYPE],
              phases: ['SUCCESS'],
            },
          ],
        },
        moduleOptions: {
          toasts: {
            enabled: true,
            started: {
              enabled: true,
              content: 'Tags deleted',
            },
            success: {
              enabled: false,
            },
            failed: {
              content: 'Tag deletion failed',
              onClickAction: () => {
                dispatch(doDeleteBatchTags(deleteConfig, options))
              },
            },
          },
        },
        entities: {
          targetOperation: 'remove',
          additionalActions: ids.map(id => {
            return {
              entityType: ENTITY_TYPE,
              entityId: id,
              stores: ['pending', 'current'],
              operation: 'remove',
              phases: ['STARTED'],
            }
          }),
        },
      }
    )
  )
}

export const doDeleteTagsV2 = (deleteConfig, options = {}) => dispatch => {
  const { ids, mode = 'ids' } = deleteConfig
  const deletionMode = mode === 'ids' && ids.length === 1 ? 'single' : 'batch'

  if (deletionMode === 'single') {
    // NOTE (jscheel && kevinrademan): Door left open to singly-delete less than n ids (see comment in doDeleteCannedReplies)
    return Promise.all(ids.map(id => dispatch(doDeleteSingleTag(id, options))))
  }

  return dispatch(doDeleteBatchTags(deleteConfig, options))
}
