import { createPayload } from 'optimistic/base'
import { selectCurrentTicket } from 'selectors/tickets/current/selectCurrentTicket'
import { selectCurrentUser } from 'ducks/currentUser/selectors/selectCurrentUser'
import { selectPreferences } from 'ducks/currentUser/selectors/preferences/selectPreferences'
import {
  selectLastMessage,
  selectCurrentTicketMessages,
} from 'selectors/currentChangesets'
import { calculateConversationType } from 'util/draft'
import { toServerString } from 'util/date'
import { getUpdatedTicketBodyAttributes } from 'util/messages'
import { stripHTML } from 'util/strings'
import {
  COMMENT_DELETION,
  COMMENT_EDIT,
  COMMENT_REACTION,
} from 'constants/changesetActionChangeTypes'
import {
  messageAction,
  statusAction,
  assignAgentAction,
  assignGroupAction,
  snoozeAction,
  unsnoozeAction,
  titleAction,
  assigneesDiffer,
} from './actions'

// same as Ticket::ASC_SORT_ORDER_FUTURE_ANCHOR on api
const ASC_SORT_ORDER_FUTURE_ANCHOR = new Date('2100-01-01T00:00:00.000Z')

// builds mock data for a reply action.
export const messagePayload = (
  state,
  ticketId,
  draft,
  changesetId,
  folderId,
  search
) => {
  const { undo_send_delay: delaySeconds } = selectPreferences(state)
  const deliverBy = delaySeconds
    ? new Date(Date.now() + delaySeconds * 1000)
    : undefined
  const draftWithDeliverBy = delaySeconds ? { ...draft, deliverBy } : draft
  const actions = [messageAction(draftWithDeliverBy)]
  const ticket = selectCurrentTicket(state)
  const agent = selectCurrentUser(state)

  if (draft.snoozeUntil) {
    actions.push(snoozeAction(draft.snoozeUntil))
  } else if (ticket.state !== draft.state) {
    actions.push(statusAction(draft.state))
  }
  if (!draft.snoozeUntil && ticket.snoozeUntil) {
    actions.push(unsnoozeAction(draft.snoozeUntil))
  }

  if (assigneesDiffer(ticket, draft)) {
    actions.push(assignAgentAction(draft.assignee))
  }

  if (ticket.assigned_group_id !== draft.assigned_group_id) {
    actions.push(assignGroupAction(draft.assigned_group_id))
  }

  if (draft.title && ticket.title !== draft.title) {
    actions.push(titleAction(draft.title))
  }

  const ticketData = {
    id: ticketId,
    assigned_group_id: draft.assigned_group_id,
    assignee: draft.assignee,
    state: draft.state,
    to: draft.to,
    cc: draft.cc,
    bcc: draft.bcc,
    note: draft.isNote,
    mailboxId: draft.mailboxId,
    snoozedUntil: draft.snoozedUntil || draft.snoozeUntil,
    snoozedById: draft.snoozedById,
    updated_at: toServerString(new Date()),
    latest_collaborator_comment_at: toServerString(new Date()),
    last_unanswered_user_message_at: toServerString(
      new Date(ASC_SORT_ORDER_FUTURE_ANCHOR.getTime() + new Date().getTime())
    ),
    bodyType: calculateConversationType(draft),
    title: draft.title,
    bodyAuthor: {
      id: agent.id,
      type: 'Agent',
    },
  }

  // Remove undefined props. This prevents downstream
  // issues where we clear out legitimate values from
  // the store because there is an undefined field in
  // the ticketData payload
  Object.keys(ticketData).forEach(key => {
    if (ticketData[key] === undefined) {
      delete ticketData[key]
    }
  })

  return createPayload(
    state,
    ticketId,
    'message',
    ticketData,
    actions,
    folderId,
    search,
    changesetId ? `optimistic-${changesetId}` : null
  )
}

export const deleteMessagePayload = (
  state,
  ticketId,
  commentId,
  folderId,
  search,
  changesetId
) => {
  const ticketData = {
    id: ticketId,
  }

  const lastMessage = selectLastMessage(state)
  const messages = selectCurrentTicketMessages(state)

  const isDeletedCommentLastMessage = lastMessage.change.id === commentId
  if (isDeletedCommentLastMessage) {
    const filteredMessages = messages.filter(
      message => message.action.change.id !== commentId
    )

    if (filteredMessages.length) {
      const newLastMessage = filteredMessages[filteredMessages.length - 1]
      const newAction = newLastMessage.action

      const { body, bodyType, bodyAuthor } = getUpdatedTicketBodyAttributes(
        newAction
      )
      if (body) ticketData.body = body
      if (bodyType) ticketData.bodyType = bodyType
      if (bodyAuthor) ticketData.bodyAuthor = bodyAuthor
    }
  }

  return createPayload(
    state,
    ticketId,
    'comment-deletion',
    ticketData,
    [
      {
        change_type: COMMENT_DELETION,
        change: {
          comment_id: commentId,
        },
      },
    ],
    folderId,
    search,
    changesetId ? `optimistic-${changesetId}` : null,
    {
      [commentId]: {
        remove: true,
      },
    }
  )
}

export const updateMessagePayload = (
  state,
  ticketId,
  commentId,
  newBody,
  previousBody,
  folderId,
  search,
  changesetId
) => {
  const ticketData = {
    id: ticketId,
  }

  const lastMessage = selectLastMessage(state)

  const isUpdatedCommentLastMessage = lastMessage.change.id === commentId
  if (isUpdatedCommentLastMessage) {
    ticketData.body = stripHTML(newBody)
  }

  return createPayload(
    state,
    ticketId,
    'comment-edit',
    ticketData,
    [
      {
        change_type: COMMENT_EDIT,
        change: {
          comment_id: commentId,
          body: newBody,
          previousBody,
        },
      },
    ],
    folderId,
    search,
    changesetId ? `optimistic-${changesetId}` : null,
    {
      [commentId]: {
        body: newBody,
      },
    }
  )
}

export const reactionMessagePayload = (
  state,
  ticketId,
  commentId,
  reaction,
  isAdded,
  folderId,
  search,
  changesetId
) => {
  const agent = selectCurrentUser(state)

  return createPayload(
    state,
    ticketId,
    'comment-reaction',
    {},
    [
      {
        change_type: COMMENT_REACTION,
        change: {
          comment_id: commentId,
          reaction,
          is_added: isAdded,
        },
        actor: {
          id: agent.id,
          first_name: agent.first_name,
          last_name: agent.last_name,
          name: agent.name,
        },
      },
    ],
    folderId,
    search,
    changesetId ? `optimistic-${changesetId}` : null
  )
}
