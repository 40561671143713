import graphql from 'api/graphql'

import { oauthTokenSelector } from 'selectors/app'

const fields = `
metadata {
  totalCount
}
records {
  hashedId
  name
}
`
function doFetchSortedLabels(token, sortOrder, page, perPage) {
  const query = `
    query SortedLabels($page: Int, $perPage: Int, $sortBy: String, $sortOrder: String) {
      labels(page: $page, perPage: $perPage, sortBy: $sortBy, sortOrder: $sortOrder) {
        ${fields}
      }
    }
  `
  const variables = {
    page,
    perPage,
    sortBy: 'name',
    sortOrder,
  }
  return graphql(token, query, variables)
}

function doFetchLabelsByHashedIds(token, ids) {
  const query = `
    query LabelsByHashedIds($ids: [String]) {
      labels(hashedIds: $ids) {
        ${fields}
      }
    }
  `
  const variables = { ids }
  return graphql(token, query, variables)
}

const groupBy = 'action.change.tag.id'
export const tagNameByTagGauge = {
  fill: 'x',
  id: 'tagNameByTagGauge',
  queries: [
    {
      groupBy,
      key: 'tagNameByTagQuery',
      loader: query => {
        const { filters = [], page, perPage, direction } = query
        return (dispatch, getState) => {
          const idFilter = filters.find(f => f.propertyName === groupBy) || {}
          const { propertyValue: ids } = idFilter || {}
          const state = getState()
          const token = oauthTokenSelector(state)
          if (ids && ids.length === 0) return Promise.resolve()
          return (ids
            ? doFetchLabelsByHashedIds(token, ids)
            : doFetchSortedLabels(token, direction, page, perPage)
          ).then(res => {
            const { metadata, records } = res.json.data.labels
            const totalCount = metadata && metadata.totalCount
            return {
              totalCount,
              result: records.map(label => {
                return {
                  [groupBy]: label.hashedId,
                  result: label.name,
                }
              }),
            }
          })
        }
      },
      minPerPage: 50,
      maxPerPage: 150,
      queryToId: query => {
        const { filters = [], id, page, perPage, direction } = query
        if (id) return id
        return JSON.stringify({
          filters,
          page,
          perPage,
          direction,
        })
      },
    },
  ],
  scale: { x: 'id', y: 'tag' },
  sort: { direction: 'ASC' },
  title: 'Tag',
}
