import { averageSearchResults } from '../queries/averageSearchResults'
import { round } from '../calculators/round'

export const averageSearchResultsByTermGauge = {
  id: 'average_search_by_term',
  calculate: round,
  queries: [
    {
      ...averageSearchResults,
      groupBy: 'search.term',
      key: 'averageSearchResultsByTerm',
    },
  ],
  title: '# of results',
}
