export const popularArticleGauge = {
  queries: [
    {
      analysisType: 'articles',
      per_page: 10,
      page: 1,
    },
  ],
  title: 'Most viewed articles',
}
