import React from 'react'

import { equals } from 'util/objects'

export default function withPagableGauges(ComposedComponent) {
  return class PagableGauges extends React.PureComponent {
    constructor(props) {
      super(props)
      this.state = {
        page: props.page || 1,
      }
      this.setPage = this.setPage.bind(this)
      this.setPageSize = this.setPageSize.bind(this)
    }

    componentWillUpdate(
      { pagination: { customizable, perPage } = {}, queryBase },
      { page }
    ) {
      const {
        pagination: {
          customizable: existingCustomizable,
          perPage: existingPerPage,
        } = {},
      } = this.props
      if (
        page !== this.state.page ||
        perPage !== existingPerPage ||
        customizable === existingCustomizable
      ) {
        this.paginationCache = null
      }
      if (!equals(queryBase, this.props.queryBase)) {
        this.setPage(1)
      }
    }

    setPage(page) {
      this.setState({ page })
    }

    setPageSize(_, { value }) {
      this.setState({ page: 1, pageSize: value })
    }

    pagination() {
      if (!this.props.pagination) return undefined
      if (!this.paginationCache) {
        const {
          pagination: {
            customizable,
            lite,
            minHeight,
            minPerPage,
            perPage,
          } = {},
        } = this.props
        const { pageSize } = this.state
        this.paginationCache = {
          customizable,
          doSetPage: this.setPage,
          doSetPageSize: this.setPageSize,
          lite,
          minHeight,
          minPerPage,
          page: this.state.page,
          perPage: customizable && pageSize ? pageSize : perPage,
        }
      }
      return this.paginationCache
    }

    render() {
      return (
        <ComposedComponent {...this.props} pagination={this.pagination()} />
      )
    }
  }
}
