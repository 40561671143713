// Applies data from store state to a query
import { memoize } from 'util/memoization'

export default memoize(applyStateToQuery)

function applyStateToQuery(query, stateQuery) {
  const resultLength =
    stateQuery.result &&
    (Array.isArray(stateQuery.result) ? stateQuery.result.length : 1)
  const totalCount =
    stateQuery.totalCount || stateQuery.totalCount === 0
      ? stateQuery.totalCount
      : resultLength
  return {
    ...query,
    ...stateQuery,
    totalCount,
  }
}
