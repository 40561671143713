// NOTE (jscheel): Keep this *as lean as possible*. You should not be importing
// anything more than what is necessary for the very beginning of the app object
// to be available. You do not want this creating any deep import trees at all,
// as you may hit code that expects the global app object to already exist.

import { Instance as CounterpartInstance } from 'counterpart'
import enTranslations from 'locales/en-US.json'

const counterpart = new CounterpartInstance()
counterpart.setLocale('en')
counterpart.registerTranslations('en', enTranslations)

const app = {
  t: (key, options) => {
    return counterpart.translate(key, options)
  },
}

global.app = app
module.exports = app
