import React from 'react'
import themeVars from 'ui_theme/site/globals/site.variables'

export const defaultConfig = {
  colorScale: true,
  // TODO: WE should also allow the color scales to be configurable.
  // Perhaps a function which results the color based off the value?
}

export default function PercentCell(props) {
  const { value, column } = props
  const config = {
    ...defaultConfig,
    ...(column.componentConfig || {}),
  }

  let percentValue = '-'
  if (value) {
    percentValue = `${Math.round(value * 100)}`
  }
  let color = themeVars.berry
  if (percentValue > 70) {
    color = themeVars.bio
  } else if (percentValue > 50) {
    color = themeVars.golden
  }
  if (value) {
    percentValue += '%'
  }

  if (!config.colorScale) {
    color = themeVars.dust
  }

  return <div style={{ color }}>{percentValue}</div>
}
