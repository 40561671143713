import { createSelector } from 'reselect'

import {
  selectHappinessAwesomeForEachDayGauge,
  selectHappinessOkForEachDayGauge,
  selectHappinessBadForEachDayGauge,
} from 'ducks/reports/gauges'
import { HAPPINESS_PAGE } from './pages'

export const selectGaugesForHappinessForEachDay = createSelector(
  selectHappinessAwesomeForEachDayGauge,
  selectHappinessOkForEachDayGauge,
  selectHappinessBadForEachDayGauge,
  (
    happinessAwesomeRatingForEachDayGauge,
    happinessOkRatingForEachDayGauge,
    happinessBadRatingForEachDayGauge
  ) => {
    return [
      happinessAwesomeRatingForEachDayGauge,
      happinessOkRatingForEachDayGauge,
      happinessBadRatingForEachDayGauge,
    ]
  }
)

export const selectIsOnHappinessPage = state =>
  state.ducksPage.page === HAPPINESS_PAGE
