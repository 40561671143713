import React from 'react'
import {
  diff,
  getPreviousMonthFirstDayDate,
  getCurrentMonthFirstDayDate,
} from 'util/date'
import { isObject } from 'util/objects'

import DatePickerPreset from './DatePickerPreset'
import styles from './styles.less'

const now = new Date()

const DEFAULT_PRESETS = {
  this_1_day: 'Today',
  previous_1_day: 'Yesterday',
  previous_7_days: {
    title: 'Last 7 days',
    days: 7,
  },
  previous_4_weeks: { title: 'Last 4 weeks', days: 28 },
  this_1_month: {
    title: 'This month',
    days: diff('days', getCurrentMonthFirstDayDate(), now),
  },
  previous_1_month: {
    title: 'Last month',
    days: diff('days', getPreviousMonthFirstDayDate(), now),
  },
  all_time: {
    title: 'All time',
    days: 99999,
  },
}

export default function DatePickerPresets({
  onApply,
  presets = DEFAULT_PRESETS,
}) {
  return (
    <div className={styles.DatePickerPresets}>
      <span>Ranges:</span>
      {Object.keys(presets).map(key => {
        const preset = presets[key]
        const presetObject = isObject(preset)
          ? preset
          : {
              title: preset,
            }
        return preset ? (
          <DatePickerPreset
            key={key}
            onApply={onApply}
            text={presetObject.title}
            timeframe={key}
            limit={presetObject.days}
          />
        ) : null
      })}
    </div>
  )
}
