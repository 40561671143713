import { ratio } from '../calculators/ratio'
import { countMessageRatingsCreated } from '../queries/countMessageRatingsCreated'
import { countMessageRatingAwesome } from '../queries/countMessageRatingAwesome'

export const happinessScoreRatioByTeamGauge = {
  calculate: ratio,
  description: 'The percentage of ratings graded "awesome".',
  id: 'happiness_by_team',
  queries: [
    {
      ...countMessageRatingAwesome,
      groupBy: 'conversation.assignee_team.id',
      key: 'countMessageRatingAwesomeByTeam',
    },
    {
      ...countMessageRatingsCreated,
      groupBy: 'conversation.assignee_team.id',
      key: 'countMessageRatingsCreatedByTeam',
    },
  ],
  title: 'CSAT score',
}
