const defaultState = {}

export default function(state, action) {
  const {
    meta: { location: { current: { type, payload } = {} } = {} } = {},
  } = action
  if (!type) return state || defaultState
  return {
    page: type,
    props: payload,
  }
}
