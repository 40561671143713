import React from 'react'

import { Overview } from 'ducks/reports/overviewLayouts'

import ConversationsCreatedPerDayHeadline from './ConversationsCreatedPerDayHeadline'
import ConversationsResolvedPerDayHeadline from './ConversationsResolvedPerDayHeadline'
import ConversationsCustomersResolutionsForEachDay from './ConversationsCustomersResolutionsForEachDay'
import CustomersPerDayHeadline from './CustomersPerDayHeadline'
import NetDiffOpenConversations from './NetDiffOpenConversations'
import TotalConversationsCreatedHeadline from './TotalConversationsCreatedHeadline'
import TotalConversationsResolvedHeadline from './TotalConversationsResolvedHeadline'
import TotalCustomersHeadline from './TotalCustomersHeadline'
import TotalMessagesSentHeadline from './TotalMessagesSentHeadline'
import MessagesSentPerDayHeadline from './MessagesSentPerDayHeadline'

export default function ConversationsOverview({ queryBatch, queryBase }) {
  return (
    <Overview
      title="Conversations"
      main={
        <ConversationsCustomersResolutionsForEachDay
          queryBatch={queryBatch}
          queryBase={queryBase}
        />
      }
    >
      <Overview.Row>
        <Overview.Column>
          <Overview.Cell>
            <NetDiffOpenConversations
              queryBatch={queryBatch}
              queryBase={queryBase}
              includePrevious
            />
          </Overview.Cell>
        </Overview.Column>
        <Overview.Column>
          <Overview.Cell>
            <TotalConversationsCreatedHeadline
              queryBatch={queryBatch}
              queryBase={queryBase}
              includePrevious
            />
          </Overview.Cell>
          <Overview.Cell>
            <ConversationsCreatedPerDayHeadline
              queryBatch={queryBatch}
              queryBase={queryBase}
              includePrevious
            />
          </Overview.Cell>
        </Overview.Column>
        <Overview.Column>
          <Overview.Cell>
            <TotalConversationsResolvedHeadline
              queryBatch={queryBatch}
              queryBase={queryBase}
              includePrevious
            />
          </Overview.Cell>
          <Overview.Cell>
            <ConversationsResolvedPerDayHeadline
              queryBatch={queryBatch}
              queryBase={queryBase}
              includePrevious
            />
          </Overview.Cell>
        </Overview.Column>
        <Overview.Column>
          <Overview.Cell>
            <TotalCustomersHeadline
              queryBatch={queryBatch}
              queryBase={queryBase}
              includePrevious
            />
          </Overview.Cell>
          <Overview.Cell>
            <CustomersPerDayHeadline
              queryBatch={queryBatch}
              queryBase={queryBase}
              includePrevious
            />
          </Overview.Cell>
        </Overview.Column>
      </Overview.Row>
      <Overview.Row>
        <Overview.Column>
          <Overview.Cell>
            <TotalMessagesSentHeadline includePrevious queryBase={queryBase} />
          </Overview.Cell>
        </Overview.Column>
        <Overview.Column>
          <Overview.Cell>
            <MessagesSentPerDayHeadline includePrevious queryBase={queryBase} />
          </Overview.Cell>
        </Overview.Column>
        {/* empty column to force left border */}
        <Overview.Column />
      </Overview.Row>
    </Overview>
  )
}
