export const FETCH_TRANSLATIONS_REQUEST = 'translations/FETCH_REQUEST'
export const FETCH_TRANSLATIONS_SUCCESS = 'translations/FETCH_SUCCESS'
export const FETCH_TRANSLATIONS_FAILURE = 'translations/FETCH_FAILURE'

export const UPDATE_TRANSLATIONS = 'translations/UPDATE'
export const UPDATE_TRANSLATIONS_STARTED = 'translations/UPDATE_STARTED'
export const UPDATE_TRANSLATIONS_SUCCESS = 'translations/UPDATE_SUCCESS'
export const UPDATE_TRANSLATIONS_FAILED = 'translations/UPDATE_FAILED'

export const RESET_TRANSLATIONS_REQUEST = 'translations/RESET_REQUEST'
export const RESET_TRANSLATIONS_SUCCESS = 'translations/RESET_SUCCESS'
export const RESET_TRANSLATIONS_FAILURE = 'translations/RESET_FAILURE'

export const DELETE_TRANSLATION_REQUEST = 'translations/DELETE_REQUEST'
export const DELETE_TRANSLATION_SUCCESS = 'translations/DELETE_SUCCESS'
export const DELETE_TRANSLATION_FAILURE = 'translations/DELETE_FAILURE'

export const UPDATE_TRANSLATION_DIRTY_STATUS =
  'translations/UPDATE_DIRTY_STATUS'

export const UPDATE_TRANSLATION_PERSISTED_DRAFT =
  'translations/UPDATE_PERSISTED_DRAFT'
