import { firstToCustomer, notResponseToClosed } from './filters'

export const averageFirstResponseTimeWithinBusinessHours = {
  analysisType: 'average',
  eventCollection: 'message.sent',
  filters: [firstToCustomer, notResponseToClosed],
  key: 'averageFirstResponseTimeWithinBusinessHours',
  targetProperty:
    'time.since_first_last_message_received_within_business_hours',
}
