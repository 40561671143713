import calculateDaysInTimeframe from './calculateDaysInTimeframe'

export const TIMES = {
  '12 - 3am': [0, 2],
  '3 - 6am': [3, 5],
  '6 - 9am': [6, 8],
  '9 - 12pm': [9, 11],
  '12 - 3pm': [12, 14],
  '3 - 6pm': [15, 17],
  '6 - 9pm': [18, 20],
  '9 - 12am': [21, 23],
}

export const DAYS = {
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
  Sunday: 7,
}

const CELLS = [].concat(
  ...Object.values(DAYS).map((day, dayI) => {
    return Object.values(TIMES).map((hours, hoursI) => {
      return {
        day: Object.keys(DAYS)[dayI],
        dayI: dayI + 1,
        hour: Object.keys(TIMES)[hoursI],
        x: hours[0] + (day - 1) * 24,
      }
    })
  })
)

export const PERCENTAGE_BRACKETS = [0.08, 0.04, 0.03, 0.02, 0.01, 0]

export default function collapseData(
  data,
  quarterHours,
  timeframe,
  timezoneOffset
) {
  const daysInTimeframe = calculateDaysInTimeframe(timeframe)
  const totalDaysInTimeFrame = daysInTimeframe.reduce(
    (reduction, x) => reduction + x,
    0
  )
  const dataValues = Object.values(data)
  const sum = dataValues.reduce((reduction, { y }) => reduction + y, 0)
  const sumAdjustedForDaysInTimeframe = 7 * sum / totalDaysInTimeFrame
  const dataByCellFirst = CELLS.reduce((reduction, { day, dayI, hour, x }) => {
    const xAdjustedForTimezone = adjustXForTimezone(x, timezoneOffset)
    const y = calculateYForX(data, quarterHours, xAdjustedForTimezone)
    const daysInTimeframeForDay = daysInTimeframe[dayI - 1]
    const yAdjustedForDaysInTimeframe =
      daysInTimeframeForDay === 0 ? 0 : y / daysInTimeframeForDay
    // eslint-disable-next-line no-param-reassign
    reduction[x] = {
      day,
      daysInTimeframeForDay,
      hour,
      percentageOfTotal:
        yAdjustedForDaysInTimeframe / sumAdjustedForDaysInTimeframe,
      y: yAdjustedForDaysInTimeframe || 0,
      x,
    }
    return reduction
  }, {})
  const cellValues = Object.values(dataByCellFirst)
  const ys = cellValues.map(({ y }) => y).sort((a, b) => a - b)
  const ysReversed = [...ys].reverse()
  let busiestCell = null
  let busiestCellCount = 0
  const dataByCell = cellValues.reduce((reduction, cell) => {
    const { percentageOfTotal } = cell
    const firstPosition = ys.indexOf(cell.y)
    const lastPosition = ysReversed.length - ysReversed.indexOf(cell.y)
    const position = (lastPosition - firstPosition) / 2 + firstPosition
    const percentageOfTotalBracket =
      5 - PERCENTAGE_BRACKETS.findIndex(x => x < percentageOfTotal)
    const busiest = cell.y && cell.y === ysReversed[0]
    if (busiest && percentageOfTotalBracket >= 4) {
      busiestCellCount += 1
      busiestCell = cell.x
    }
    // eslint-disable-next-line no-param-reassign
    reduction[cell.x] = {
      ...cell,
      busiest,
      percentageOfTotal,
      percentageOfTotalBracket,
      position,
    }
    return reduction
  }, {})

  return {
    busiestCell,
    busiestCellCount,
    dataByCell,
    sum: sumAdjustedForDaysInTimeframe,
    totalDaysInTimeFrame,
  }
}

const HOURS_IN_WEEK = 24 * 7
function adjustXForTimezone(x, offset) {
  const xShiftedForTimezone = x - offset
  if (xShiftedForTimezone < 0) return xShiftedForTimezone + HOURS_IN_WEEK
  if (xShiftedForTimezone > HOURS_IN_WEEK)
    return xShiftedForTimezone - HOURS_IN_WEEK
  return xShiftedForTimezone
}

function calculateYForX(data, quarterHours, x) {
  const offsets = quarterHours
    ? [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]
    : [0, 1, 2]
  const xAdjustedForQuarterHours = quarterHours ? x * 4 : x
  return offsets.reduce((reduction, offset) => {
    return data[xAdjustedForQuarterHours + offset]
      ? reduction + data[xAdjustedForQuarterHours + offset].y
      : reduction
  }, 0)
}
