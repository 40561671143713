import { compose } from 'redux'

import withAutoFetchGauges from '../../enhancers/withAutoFetchGauges'
import withDataForVisualizations from '../../enhancers/withDataForVisualizations'
import withComponentState from '../../enhancers/withComponentState'
import withPagingForVisualizations from '../../enhancers/withPagingForVisualizations'
import withLoadingAndErroredStates from '../../enhancers/withLoadingAndErroredStates'
import withDatasetTabularized from '../../enhancers/withDatasetTabularized'
import TableView from './TableView'
import AgentCell from '../AgentLink'
import ColorStatusCell from './cells/ColorStatusCell'
import ConversationCell from './cells/ConversationCell'
import CountDownCell from './cells/CountDownCell'
import CustomerCell from './cells/CustomerCell'
import GradeToEmojiCell from './cells/GradeToEmojiCell'
import TextCell from './cells/TextCell'
import PercentCell from './cells/PercentCell'
import TeamsCell from './cells/TeamsCell'
import NumberCell from './cells/NumberCell'
import TeamCell from './cells/TeamCell'
import RatingsCell from './cells/RatingsCell'
import PageViewCell from './cells/PageViewCell'
import ChangeCell from './cells/ChangeCell'
import SearchCountCell from './cells/SearchCountCell'
import cellConfig from './cells/config'

const ComposedTable = compose(
  withAutoFetchGauges,
  withComponentState,
  withDataForVisualizations,
  withDatasetTabularized,
  withPagingForVisualizations,
  withLoadingAndErroredStates
)(TableView)

ComposedTable.AgentCell = AgentCell
ComposedTable.ColorStatusCell = ColorStatusCell
ComposedTable.ConversationCell = ConversationCell
ComposedTable.CountDownCell = CountDownCell
ComposedTable.CustomerCell = CustomerCell
ComposedTable.GradeToEmojiCell = GradeToEmojiCell
ComposedTable.TextCell = TextCell
ComposedTable.PercentCell = PercentCell
ComposedTable.TeamsCell = TeamsCell
ComposedTable.NumberCell = NumberCell
ComposedTable.TeamCell = TeamCell
ComposedTable.RatingsCell = RatingsCell
ComposedTable.PageViewCell = PageViewCell
ComposedTable.ChangeCell = ChangeCell
ComposedTable.SearchCountCell = SearchCountCell
ComposedTable.cellConfig = cellConfig

export default ComposedTable
