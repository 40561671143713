import { connect } from 'react-redux'

import { doFetchReportGauge } from 'ducks/reports/gauges'

import ReportsVisJSONView from './ReportsVisJSONView'

const perform = {
  doLoadGauge: doFetchReportGauge,
}

export default connect(null, perform)(ReportsVisJSONView)
