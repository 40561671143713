import React, { PureComponent, Fragment } from 'react'
import { timeInHuman } from 'util/date'
import { Loader } from 'util/ui'
import { getAgentAvatar, name as getAgentName } from 'util/agents'
import { isBlank } from 'util/strings'
import CategoryStatus from './CategoryStatus'

import styles from './styles.less'

export default class KbPreviewView extends PureComponent {
  componentDidMount = () => {
    this.loadArticle()
  }

  componentDidUpdate = () => {
    this.loadArticle()
  }

  getName = () => {
    const { article: { author } = {} } = this.props
    const name = getAgentName(author)
    return isBlank(name) ? 'Unknown' : name
  }

  loadArticle = () => {
    const { loadArticlePreview, articleId, knowledgeBaseId } = this.props
    loadArticlePreview({ articleId, knowledgeBaseId })
  }

  isReady = () => {
    const { isLoaded, isLoading } = this.props
    return isLoaded && !isLoading
  }

  render() {
    const {
      isLoaded,
      isLoading,
      article: {
        body,
        title,
        description,
        author,
        updatedAt,
        knowledgeBase = {},
        category = {},
        state,
      } = {},
    } = this.props
    return (
      <Fragment>
        {!this.isReady() && <Loader active={!isLoaded || isLoading} />}
        {this.isReady() && (
          <div className={styles.container}>
            <CategoryStatus
              categories={category}
              knowledgeBase={knowledgeBase}
              state={state}
            />
            <div className={styles.article}>
              <div className={styles.title}>{title}</div>
              <div className={styles.description}>{description}</div>
              <div className={styles.author}>
                <img
                  className={styles.authorImage}
                  src={getAgentAvatar(author)}
                  alt={this.getName()}
                  title={this.getName()}
                />
                <div className={styles.authorName}>{this.getName()}</div>
                <div className={styles.modifiedDate}>
                  Edited on {timeInHuman(updatedAt)}
                </div>
              </div>
              <div
                className={styles.body}
                dangerouslySetInnerHTML={{ __html: body }} // eslint-disable-line react/no-danger
              />
            </div>
          </div>
        )}
      </Fragment>
    )
  }
}
