import { connect } from 'react-redux'

import { selectAccountPreferredReportsAverage } from 'selectors/app/base'

import selectDecoratedGauges from './selectDecoratedGauges'
import selectDataSets from './selectDataSets'
import selectLegendDataSets from './selectLegendDataSets'
import selectPagination from './selectPagination'
import selectXValues from './selectXValues'
import selectScaleFromDataSets from './selectScaleFromDataSets'

function select(state, props) {
  return {
    average: selectAccountPreferredReportsAverage(state),
    dataSets: selectDataSets(state, props),
    gauges: selectDecoratedGauges(state, props),
    legendDataSets: selectLegendDataSets(state, props),
    pagination: selectPagination(state, props),
    scale: selectScaleFromDataSets(state, props),
    xValues: selectXValues(state, props),
  }
}

export default function withDecoratedGauges(ComposedComponent) {
  const connected = connect(select)(ComposedComponent)
  connected.displayName = 'DecoratedGauges'
  return connected
}
