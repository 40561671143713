import sortDataSet from './sortDataSet'
import pageXValues from './pageXValues'

// We could memoize this but there is so much data in dataSets at this point
// it is cheaper to recalculate than look up the cache.

export default function sortAndPageDataSets(
  dataSets,
  joinedXValues,
  page,
  perPage,
  sortBy,
  sortDirection,
  sortMethod,
  totalCount
) {
  return dataSets.map(dataSet => {
    if (dataSet.id === sortBy) {
      const sortedXValues = sortDataSet(
        dataSet,
        joinedXValues,
        sortDirection,
        sortMethod
      )
      const { page: dataSetPage, perPage: dataSetPerPage } = dataSet
      const r = {
        ...dataSet,
        xValues: pageXValues(
          sortedXValues,
          dataSetPage,
          dataSetPerPage,
          page,
          perPage,
          totalCount
        ),
        unpagedXValues: sortedXValues,
      }
      return r
    }
    return dataSet
  })
}
