import { connect } from 'react-redux'
import { runOnNextTick } from 'util/functions'
import { doFetchReportsBootstrapData } from 'actions/app'
import { selectKnowledgeBaseTotal } from 'subapps/kb/selectors/knowledge_bases'
import { selectIsKbsLoaded } from 'subapps/kb/selectors/knowledge_bases/selectIsKbsLoaded'
import { doFetchKnowledgeBases } from 'subapps/kb/actions/knowledge_bases'
import { selectIsAccountBootstrapped } from 'ducks/accounts/selectors/selectAccount'
import { selectMaxReportingDays } from 'ducks/billing/selectors/features'
import { selectCurrentPage } from 'selectors/location'
import { KB_PAGE } from 'ducks/reports/kb/pages'
import { selectCurrentPlanByType } from 'ducks/billing/selectors/selectCurrentPlanByType'
import { INBOX } from 'ducks/billing/productTypes'
import { doLayoutMounted } from '../../actions'
import {
  selectLayoutMounted,
  selectShowContent,
  selectShowEmptyState,
} from '../../selectors'

import ReportsLayout from './view'

const select = state => ({
  layoutMounted: selectLayoutMounted(state),
  showEmptyState: selectShowEmptyState(state),
  showContent: selectShowContent(state),
  hasKnowledgeBases: selectKnowledgeBaseTotal(state) > 0,
  isKbsLoaded: selectIsKbsLoaded(state),
  isAccountBootstrapped: selectIsAccountBootstrapped(state),
  currentPlan: selectCurrentPlanByType(state, INBOX),
  reportingDaysLimit: selectMaxReportingDays(state),
  isInKbReporting: selectCurrentPage(state) === KB_PAGE,
})

const perform = dispatch => ({
  onComponentDidMount: () => {
    runOnNextTick(() => {
      dispatch(doFetchReportsBootstrapData())
      dispatch(doLayoutMounted())
    })
  },
  onComponentDidUpdate: () => {
    runOnNextTick(() => {
      dispatch(doLayoutMounted())
    })
  },
  fetchKnowledgeBases: () => dispatch(doFetchKnowledgeBases()),
})

export default connect(select, perform)(ReportsLayout)
