import { single } from '../calculators/single'
import { countConversationsResolved } from '../queries/countConversationsResolved'

export const conversationsResolvedForEachDayGauge = {
  calculate: single,
  queries: [
    {
      ...countConversationsResolved,
      interval: 'daily',
      key: 'countConversationsResolvedForEachDay',
    },
  ],
  title: 'Resolutions',
}
