function selectBase(state) {
  return state.reports.customersHelped
}

export function selectCustomersHelpedForAgent(state, { id }) {
  const { teamsById } = selectBase(state)
  return teamsById[id]
}
export function selectCustomersHelpedErrored(state) {
  return selectBase(state).errored
}

export function selectCustomersHelpedLoaded(state) {
  return selectBase(state).loaded
}

export function selectCustomersHelpedLoading(state) {
  return selectBase(state).loading
}

export function selectCustomersHelpedForTeam(state, { id }) {
  const { agentsById } = selectBase(state)
  return agentsById[id]
}

export function selectShouldFetchCustomersHelped(state) {
  return (
    !selectCustomersHelpedLoaded(state) &&
    !selectCustomersHelpedErrored(state) &&
    !selectCustomersHelpedLoading(state)
  )
}
