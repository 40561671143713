import React from 'react'
import { compose } from 'redux'
import BusiestTimeView from './BusiestTimeView'

import withAutoFetchGauges from '../../enhancers/withAutoFetchGauges'
import withDecoratedGauges from '../../enhancers/withDecoratedGauges'
import withTimezoneOffset from '../../enhancers/withTimezoneOffset'
import withLoadingAndErroredStates from '../../enhancers/withLoadingAndErroredStates'

export default compose(
  withDecoratedGauges,
  withAutoFetchGauges,
  withLoadingAndErroredStates,
  withTimezoneOffset
)(BusiestTimeView)
