import React from 'react'

import { Overview14 } from 'ducks/reports/overviewLayouts'

import * as CustomPropTypes from 'ducks/reports/propTypes'
import AverageFirstResponseTime from '../AverageFirstResponseTime'
import AverageResponseTime from '../AverageResponseTime'
import AverageRepliesPerResolution from '../AverageRepliesPerResolution'
import AverageResolutionTime from '../AverageResolutionTime'
import ConversationsAssignedCreatedRatio from '../ConversationsAssignedCreatedRatio'
import FirstReplyResolvedRatio from '../FirstReplyResolvedRatio'
import ProductivityOverviewHeadlineLine from '../ProductivityOverviewHeadlineLine'
import HeadlineChartSelector from '../HeadlineChartSelector'

ProductivityOverview.propTypes = {
  queryBase: CustomPropTypes.queryBase.isRequired,
}

export default function ProductivityOverview({ queryBase }) {
  return (
    <Overview14
      title={<HeadlineChartSelector />}
      components={[
        <ProductivityOverviewHeadlineLine queryBase={queryBase} />,
        <FirstReplyResolvedRatio queryBase={queryBase} includePreviousPeriod />,
        <ConversationsAssignedCreatedRatio
          queryBase={queryBase}
          includePreviousPeriod
        />,
        <AverageFirstResponseTime queryBase={queryBase} includePrevious />,
        <AverageResponseTime queryBase={queryBase} includePrevious />,
        <AverageResolutionTime queryBase={queryBase} includePrevious />,
        <AverageRepliesPerResolution queryBase={queryBase} includePrevious />,
      ]}
    />
  )
}
