import cn from 'classnames'
import React from 'react'

import { Grid } from 'util/ui'

import { column50 } from './config'
import styles from './styles.less'

export default function Overview2({ title, components }) {
  return (
    <Grid stackable celled padded className={styles.overviewContainer}>
      <Grid.Row columns={2}>
        <Grid.Column {...column50} className={`${styles.chartContainer}`}>
          <h1 className={styles.reportSmallHeader}>{title}</h1>
          {components[0]}
        </Grid.Column>
        <Grid.Column {...column50} className={styles.noPadding}>
          <Grid celled className={cn(styles.maxHeight, styles.noMargin)}>
            <Grid.Row columns={2}>
              <Grid.Column className={styles.noPadding} verticalAlign="middle">
                {components[1]}
              </Grid.Column>
              <Grid.Column className={styles.noPadding} verticalAlign="middle">
                {components[2]}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={2}>
              <Grid.Column className={styles.noPadding} verticalAlign="middle">
                {components[3]}
              </Grid.Column>
              <Grid.Column className={styles.noPadding} verticalAlign="middle">
                {components[4]}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}
