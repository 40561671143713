export const countConversationsAssignedFirstTime = {
  analysisType: 'count',
  eventCollection: 'conversation.assigned',
  filters: [
    {
      propertyName: 'conversation.reassignment_count',
      operator: 'eq',
      propertyValue: 1,
    },
  ],
  key: 'countConversationsAssignedFirstTime',
}
