import { compose } from 'redux'

import LineChartView from './LineChartView'
import withAutoFetchGauges from '../../enhancers/withAutoFetchGauges'
import withDataForVisualizations from '../../enhancers/withDataForVisualizations'
import withDimensions from '../../enhancers/withDimensions'
import withLoadingAndErroredStates from '../../enhancers/withLoadingAndErroredStates'

export default compose(
  withAutoFetchGauges,
  withDataForVisualizations,
  withLoadingAndErroredStates,
  withDimensions
)(LineChartView)
