import { Grid } from 'util/ui'
import SingleRating from './SingleRating'

export default function RatingsCellView(props) {
  const { value } = props
  let ratings = []

  if (Array.isArray(value) && value.length > 0) {
    // Done this way to always render all 4 ratings event when
    // there is not data present for some of them. The single rating
    // component then just renders the total as "-""
    ratings = [0, 3, 7, 10].map(gradeInt => {
      const rating =
        value.find(
          r => r.grade_int === gradeInt || r.grade_int === gradeInt.toString()
        ) || {}
      return (
        <SingleRating
          key={`ratingscellview-${gradeInt}`}
          rating={gradeInt}
          total={rating.total}
        />
      )
    })
  }
  return <Grid columns={4}>{ratings}</Grid>
}
